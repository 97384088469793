export enum EAssetActionTypes {
  GET_ASSETS = 'GET_ASSETS',
  GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS',
  GET_ASSETS_ERROR = 'GET_ASSETS_ERROR',

  SAVE_ASSET = 'SAVE_ASSET',
  SAVE_ASSET_SUCCESS = 'SAVE_ASSET_SUCCESS',
  SAVE_ASSET_ERROR = 'SAVE_ASSET_ERROR',

  GET_ASSET = 'GET_ASSET',
  GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS',
  GET_ASSET_ERROR = 'GET_ASSET_ERROR',

  RESET_CURRENT_ASSET = 'RESET_CURRENT_ASSET',

  GET_ASSET_ATTRIBUTES = 'GET_ASSET_ATTRIBUTES',
  GET_ASSET_ATTRIBUTES_INPUT_SUCCESS = 'GET_ASSET_ATTRIBUTES_INPUT_SUCCESS',
  GET_ASSET_ATTRIBUTES_OUTPUT_SUCCESS = 'GET_ASSET_ATTRIBUTES_OUTPUT_SUCCESS',
  GET_ASSET_ATTRIBUTES_ERROR = 'GET_ASSET_ATTRIBUTES_ERROR',

  SAVE_ASSET_TEMPLATE = 'SAVE_ASSET_TEMPLATE',
  SAVE_ASSET_TEMPLATE_SUCCESS = 'SAVE_ASSET_TEMPLATE_SUCCESS',
  SAVE_ASSET_TEMPLATE_ERROR = 'SAVE_ASSET_TEMPLATE_ERROR',

  GET_JOURNEY_ASSETS = 'GET_JOURNEY_ASSETS',
  GET_JOURNEY_ASSETS_SUCCESS = 'GET_JOURNEY_ASSETS_SUCCESS',
  GET_JOURNEY_ASSETS_ERROR = 'GET_JOURNEY_ASSETS_ERROR',

  DELETE_JOURNEY_ASSETS = 'DELETE_JOURNEY_ASSETS',
  DELETE_JOURNEY_ASSETS_SUCCESS = 'DELETE_JOURNEY_ASSETS_SUCCESS',
  DELETE_JOURNEY_ASSETS_ERROR = 'DELETE_JOURNEY_ASSETS_ERROR',

  DELETE_ASSET_TEMPLATE = 'DELETE_ASSET_TEMPLATE',
  DELETE_ASSET_TEMPLATE_SUCCESS = 'DELETE_ASSET_TEMPLATE_SUCCESS',
  DELETE_ASSET_TEMPLATE_ERROR = 'DELETE_ASSET_TEMPLATE_ERROR',
  
  GET_ASSETS_PREVIOUS_STEP = 'GET_ASSETS_PREVIOUS_STEP',
  GET_ASSETS_PREVIOUS_STEP_SUCCESS = 'GET_ASSETS_PREVIOUS_STEP_SUCCESS',
  GET_ASSETS_PREVIOUS_STEP_ERROR = 'GET_ASSETS_PREVIOUS_STEP_ERROR',
}
