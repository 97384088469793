import React, { useEffect } from 'react';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';

import { AssetMediaContainer as Container } from './styles';

import { Label, MediaUpload } from '../../../Components';

interface Props {
  onChange: Function;
  isMarketableProduct?: boolean;
  assetMediaFirstLoad?: boolean;
  photos?: Array<any>;
  videos?: Array<any>;
  formErrors?: any;
}

const AssetMedia = ({ onChange, ...data }: Props) => {
  const {
    photos = [],
    videos = [],
    assetMediaFirstLoad = true,
    formErrors,
    isMarketableProduct,
  } = data;

  const {
    errors,
    setValue,
    register,
    setError,
    clearError,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    assetMediaFirstLoad === false && triggerValidation();
    onChange('assetMediaFirstLoad', false);
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      triggerValidation();
    }
  }, [formErrors]);

  useEffect(() => {
    setValue('photos', photos);

    if (photos.length === 0) {
      setError('photos', 'required', i18n.t('At least one photo is required'));
    } else {
      clearError('photos');
    }
  }, [photos]);

  return (
    <Container className="row">
      <div className="col-12 mb-3">
        <Label>
          {isMarketableProduct && `* `}
          {i18n.t(
            'Add one or more photos that you like displayed in the media gallery'
          )}
        </Label>
        <div className="instructions mt-2">
          {i18n.t('Drag the images to rearrange them')}
        </div>
      </div>
      <div className="col-12 media-container">
        <div>
          {errors.photos && isMarketableProduct && (
            <span className="inlineErrorMessage">{errors.photos.message}.</span>
          )}
        </div>
        <div className="mt-3">
          <MediaUpload
            proloadedItemsLinks={photos}
            handleChange={items => onChange('photos', items)}
            accept={['image/png', 'image/jpg', 'image/jpeg']}
            maxFileSize={2 * 1024 * 1024} // 2 MB
            showLink
          />
        </div>
      </div>
      <div className="col-12 mb-3">
        <Label>
          {i18n.t(
            'Add one or more videos that you like displayed in the media gallery'
          )}
        </Label>
        <div className="instructions mt-2">
          {i18n.t('Drag the videos to rearrange them')}
        </div>
      </div>
      <div className="col-12 media-container">
        <MediaUpload
          proloadedItemsLinks={videos}
          handleChange={items => onChange('videos', items)}
          type="video"
          accept={['video/mp4']}
          maxFileSize={10 * 1024 * 1024} // 10 MB
          featured={[0]}
        />
      </div>
    </Container>
  );
};

export default AssetMedia;
