import React, { useEffect } from 'react';
import i18n from 'i18next';
import { withRouter } from 'react-router-dom';
import {
  JourneyReviewSubmitContainer as Container,
  Header,
  CampaignReview,
} from './styles';
import { FacilityTypeIcons } from '../../../Lib/Configs';
import {
  EditableItem,
  JourneyStepsPreview,
  ContentBox,
  PencilIcon,
  CustomTooltip,
  Button,
} from '../../../Components';
import moment from 'moment';

interface Props {
  onChange: Function;
  onReview: (step: number, entityName?: string, entityId?: string) => void;
  formMethods: any;
  steps?: any;
  journeyName?: string;
  description?: string;
  stepTemplates?: any;
  campaigns?: Array<any>;
  facilities?: Array<any>;
  claims?: Array<any>;
  history?: any;
}

const formatSteps = ({ stepTemplates, facilities, claims, onReview }) => {
  return stepTemplates.map(step => {
    const newStep = {
      ...step,
      stepDescription: step.description,
      inboundAssets: { list: [] },
      outboundAssets: { list: [] },
      queryLink: () => onReview(2, 'currentFacilityId', step.facilityId), // `?tab=StepTemplates&step=${step.number}`,
      status: step.isActiveFacility ? i18n.t('Active') : i18n.t('Static'),
    };

    if (step) {
      if (step.facilityClaims) {
        newStep.claims = claims
          .filter(({ _id }) => step.facilityClaims.find(fc => fc === _id))
          .map(({ shortName, description, supportingVerifications }) => {
            const from = supportingVerifications.length
              ? supportingVerifications[0].issueDate
              : '';
            const to = supportingVerifications.length
              ? supportingVerifications[0].expirationDate
              : '';
            return {
              name: shortName,
              description: description,
              from: moment(from).format('MM/DD/YYYY'),
              to: moment(to).format('MM/DD/YYYY'),
            };
          });
      }

      if (step.facilityType) {
        newStep.name = step.facilityType;
        newStep.icon =
          FacilityTypeIcons[step.facilityTypeIcon || step.facilityType];
      }

      if (step.facilityId && facilities) {
        const selectedFacility = facilities.find(
          f => f._id === step.facilityId
        );
        if (selectedFacility) {
          newStep.value = selectedFacility.registeredName;
          newStep.description = selectedFacility.description;
        }
      }

      if (step.showInboundReceivedDate) {
        newStep.inboundAssets.showReceivedDate = step.showInboundReceivedDate;
      } else {
        newStep.inboundAssets.showReceivedDate = false;
      }

      if (step.showOutboundReceivedDate) {
        newStep.outboundAssets.showReceivedDate = step.showOutboundReceivedDate;
      } else {
        newStep.outboundAssets.showReceivedDate = false;
      }

      if (step.inboundAssetsInventory) {
        newStep.inboundAssets.list = newStep.inboundAssets.list.concat(
          step.inboundAssetsInventory
            .filter(({ selected }) => selected)
            .map(({ text }) => text)
        );
      }

      if (step.inboundAssetsReceived) {
        newStep.inboundAssets.list = newStep.inboundAssets.list.concat(
          step.inboundAssetsReceived
            .filter(({ selected }) => selected)
            .map(({ text }) => text)
        );
      }

      if (step.outboundAssetsInventory) {
        newStep.outboundAssets.list = newStep.outboundAssets.list.concat(
          step.outboundAssetsInventory
            .filter(({ selected }) => selected)
            .map(({ text }) => text)
        );
      }

      if (step.outboundAssetsReceived) {
        newStep.outboundAssets.list = newStep.outboundAssets.list.concat(
          step.outboundAssetsReceived
            .filter(({ selected }) => selected)
            .map(({ text }) => text)
        );
      }
    }

    return newStep;
  });
};

const JourneyReviewSubmit = ({
  onChange,
  onReview,
  facilities,
  claims,
  formMethods,
  ...data
}: Props) => {
  const { errors, clearError } = formMethods;

  useEffect(() => {
    clearError();
  }, []);

  const journeyNameError = !!errors.journeyName;
  const journeyDescriptionError = !!errors.description;
  const journeyStepsError = !!errors.stepTemplates;

  const { journeyName, description, stepTemplates, campaigns, history } = data;

  const stepsWithQueryLinks =
    stepTemplates &&
    formatSteps({ stepTemplates, facilities, claims, onReview });

  // Render
  return (
    <ContentBox>
      <Header>
        <div className="title">{i18n.t('Review & Submit')}</div>
      </Header>
      <Container>
        <EditableItem
          title={i18n.t('Jorney name')}
          incomplete={journeyNameError}
          linkTo={() => onReview(1)}
          content={<strong>{journeyName}</strong>}
        />
        <EditableItem
          title={i18n.t('Journey description')}
          incomplete={journeyDescriptionError}
          linkTo={() => onReview(1)}
          content={description}
        />
        <EditableItem
          title={i18n.t('Journey steps')}
          incomplete={journeyStepsError}
          linkTo={() => onReview(5)}
          editTooltip={i18n.t('Edit journey steps.')}
          content={<JourneyStepsPreview steps={stepsWithQueryLinks} />}
        />
        {/* <EditableItem
          title={i18n.t('Campaigns')}
          editable={false}
          content={campaigns?.map((c, index) => (
            <CampaignReview key={index}>
              <div className="title">
                <div data-tip data-for="campaign">
                  <Button
                    width={20}
                    height={20}
                    icon={<PencilIcon />}
                    type="roundDark"
                    action={() => onReview(5)}
                  />
                </div>
                <CustomTooltip id="campaign" placement="bottom">
                  <span>{i18n.t('Edit campaign.')}</span>
                </CustomTooltip>
                <div className="ml-2">{c.campaignName}</div>
              </div>
              <div>{c.campaignDescription}</div>
              <div>{`${i18n.t('From')} ${c.startDate} ${i18n.t('to')} ${
                c.endDate
              }`}</div>
            </CampaignReview>
          ))}
        /> */}
      </Container>
    </ContentBox>
  );
};

export default withRouter(JourneyReviewSubmit as any) as any;
