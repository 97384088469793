import { unAuthAxiosCall } from '../axiosCall';
import { AxiosResponse } from 'axios';
import { KEYCLOAK_REALM } from '../../../config';

export const login = async (
  username: string,
  password: string
): Promise<AxiosResponse<any> | undefined> => {
  const params = new URLSearchParams();
  params.append('client_id', 'discover-ui');
  params.append('username', username);
  params.append('password', password);
  params.append('grant_type', 'password');

  return unAuthAxiosCall(
    `/auth/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`,
    {
      method: 'POST',
      source: 'keycloak',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params,
    }
  );
};

export const refresh = async (
  refreshToken: string
): Promise<AxiosResponse<any> | undefined> => {
  const params = new URLSearchParams();
  params.append('client_id', 'discover-ui');
  params.append('refresh_token', refreshToken);
  params.append('grant_type', 'refresh_token');

  return unAuthAxiosCall(
    `/auth/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`,
    {
      method: 'POST',
      source: 'keycloak',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: params,
    }
  );
};
