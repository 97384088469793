import styled from 'styled-components';

const ActionButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  height: 45px;
`;

const StepsContainer = styled.div`
  margin-top: 30px;
  padding: 0 40px;
  ul {
    border: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  text-transform: uppercase;
`;

const Header = styled.div`
  background: radial-gradient(
    50% 25628.48% at 50% 0%,
    #3a3a3a 0%,
    #000000 100%
  );
  height: 50px;
  padding-left: 30px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  border-top-right-radius: 20px;
`;

const JourneyDetailsContainer = styled.div`
  margin: 20px 0 0;
  padding-bottom: 60px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #757575;
`;

const JourneyDetailsSubtitle = styled.div`
  margin-top: 20px;
`;

const JourneyDetailsChecklist = styled.div`
  > div {
    display: flex;
    margin-bottom: 22px;
    align-items: baseline;

    > div {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
    svg {
      min-width: 20px;
    }
    label {
      margin: -1px 5px 0 0;
      min-width: 100px;
    }
    p {
      margin-top: 5px;
      max-width: 530px;
      width: -webkit-fill-available;
    }
  }

  @media (max-width: 991px) {
    margin-left: 0;
  }
`;

const JourneyDetailsFormContainer = styled.div`
  padding: 15px 30px;
  > div {
    margin-top: 30px;
  }
  label {
    margin-bottom: 27px;
    display: flex;
    > div {
      margin-left: 6px;
    }
  }
`;

const RadioIcon = styled.div`
  width: 10px;
  height: 10px;
  background: #a7bf2e;
  border-radius: 100%;
`;

const JourneyStepsContainer = styled.div`
  display: flex;
  .tabs-wrapper {
    flex: 1;
  }
`;

const JourneyStepsFacilityContainer = styled.div`
  margin: 44px 35px 0;
  min-height: 520px;
  .row > div {
    margin-bottom: 31px;
  }
  label {
    margin-bottom: 12px;
    display: flex;
    > div {
      margin-left: 6px;
    }
  }
  .switch-wrapper {
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);
    width: fit-content;
  }
`;

const JourneyStepsInboundContainer = styled.div`
  margin: 44px 35px 0;
  min-height: 520px;
  padding-bottom: 30px;
  .row > div {
    margin-bottom: 25px;
  }
  label {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    > div {
      margin-left: 6px;
    }
  }
  .checkbox-wrapper {
    margin-left: -11px;
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);
  }
`;

const SwitchLabel = styled.div<{ selected?: boolean }>`
  ${props => `
    ${props.selected ? 'color: #303030;' : 'color: rgba(117, 117, 117, 0.3);'}
  `}
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

const JourneyReviewSubmitContainer = styled.div`
  padding: 40px 50px;
`;

const CampaignReview = styled.div`
  display: flex;
  align-items: baseline;
  font-family: Encode Sans;
  font-style: normal;
  font-size: 14px;
  margin-bottom: 15px;
  margin-left: 10px;
  color: #757575;

  > div {
    flex: 1;
    margin-right: 30px;
  }
  .title {
    font-weight: 600;
    display: flex;
    align-items: center;
  }
`;

export {
  ActionButtonsContainer,
  StepsContainer,
  TitleContainer,
  Header,
  JourneyDetailsContainer,
  JourneyDetailsChecklist,
  JourneyDetailsFormContainer,
  JourneyDetailsSubtitle,
  RadioIcon,
  JourneyStepsContainer,
  JourneyStepsFacilityContainer,
  JourneyStepsInboundContainer,
  SwitchLabel,
  JourneyReviewSubmitContainer,
  CampaignReview,
};
