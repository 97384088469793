import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';

import {
  AssetDetailsFormContainer as FormContainer,
  AssetDetailsContainer as Container,
  AssetDataListContainer,
  AssetDataListHeader,
  AssetDataListContent,
} from './styles';

import {
  Label,
  InfoIcon,
  CustomTooltip,
  Checkbox,
  Button,
} from '../../../Components';
import DynamicInput from '../../../Components/DynamicInput';
import { DynamicField } from './types';

interface Props {
  onChange: Function;
  fields?: Array<DynamicField>;
  noDataCapture?: boolean;
  assetDataFirstLoad?: boolean;
  saveAsset?: any;
}

const AssetData = ({
  onChange,
  fields = [],
  assetDataFirstLoad = true,
  noDataCapture,
  saveAsset,
}: Props) => {
  const [items, setItems] = useState([] as Array<DynamicField>);
  const [collapsed, setCollapsed] = useState(true);

  const { triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    assetDataFirstLoad === false && triggerValidation();
    onChange('assetDataFirstLoad', false);
  }, []);

  useEffect(() => {
    if (JSON.stringify(items) !== JSON.stringify(fields)) {
      setItems(fields);
    }
  }, [fields]);

  const handleToggleCollapse = () => {
    const updatedItems = items.map(item => ({
      ...item,
      collapsed: !collapsed,
      readonly: true,
    }));
    setItems(updatedItems);
    setCollapsed(!collapsed);
  };

  return (
    <Container>
      <FormContainer className="row">
        <div className="col-12">
          <div className="checkbox-wrapper">
            <Label>
              <Checkbox
                onChange={evt => onChange(evt.target.name, evt.target.checked)}
                checked={noDataCapture}
                name="noDataCapture"
              />
              {i18n.t('No data capture is required for this asset')}
              <div data-tip data-for="nodata">
                <InfoIcon />
              </div>
              <CustomTooltip id="nodata">
                <span>
                  {i18n.t(
                    'A scenario where data capture may not be desired is if the asset'
                  )}
                </span>
              </CustomTooltip>
            </Label>
          </div>
        </div>
        {!noDataCapture && (
          <div className="col-12">
            <AssetDataListContainer>
              <AssetDataListHeader>
                <div>
                  {i18n.t(
                    'Create the input data fields that will be used to capture'
                  )}
                </div>
                <Button
                  text={
                    collapsed ? i18n.t('Expand all') : i18n.t('Collapse all')
                  }
                  type="secondary"
                  action={() => handleToggleCollapse()}
                />
              </AssetDataListHeader>
              <AssetDataListContent>
                <DynamicInput
                  items={items}
                  setItems={setItems}
                  onSave={newItems => {
                    onChange('fields', newItems);
                  }}
                />
                {fields.length === 0 && (
                  <div className="empty-msg">
                    {i18n.t('No fields added yet')}
                  </div>
                )}
              </AssetDataListContent>
            </AssetDataListContainer>
          </div>
        )}
      </FormContainer>
    </Container>
  );
};

export default AssetData;
