import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import {
  ReviewSubmitContainer as Container,
  ReviewSubmitItemsContainer as ItemsContainer,
  ReviewSubmitItemsWrapper as ItemsWrapper,
} from './styles';

import { EditableItem } from '../../../Components';

interface Props {
  _id?: string;
  onChange: Function;
  formMethods: any;
  firstName?: string;
  lastName?: string;
  email?: string;
  userFacilities?: Array<string>;
  facilities?: Array<any>;
  isDraft?: boolean;
  entityId?: string;
}

const UserReviewSubmit = ({
  onChange,
  formMethods,
  isDraft,
  entityId,
  facilities = [],
  ...data
}: Props) => {
  const { _id, firstName, lastName, email, userFacilities = [] } = data;

  const { errors, clearError } = formMethods;

  useEffect(() => {
    clearError();
  }, []);

  const baseUrl = isDraft
    ? entityId
      ? `/users/${entityId}/draft`
      : '/users/draft'
    : `/users/${entityId}/edit`;

  const summaryError =
    !!errors.firstName || !!errors.lastName || !!errors.email || !!errors.userFacilities;

  return (
    <Container>
      <ItemsWrapper>
        <EditableItem
          title={i18n.t('Summary')}
          incomplete={summaryError}
          collapsible={false}
          linkTo={`${baseUrl}?tab=User Details`}
          content={
            <>
              <ItemsContainer className="contact-item">
                <EditableItem
                  editable={false}
                  title={i18n.t('Name')}
                  content={firstName}
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Last Name')}
                  content={lastName}
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Email')}
                  content={email}
                />
              </ItemsContainer>
              <ItemsContainer>
                <EditableItem
                  editable={false}
                  title={i18n.t('Facilities')}
                  content={userFacilities
                    .map(
                      facilityId =>
                        facilities.find(f => f._id === facilityId)
                          ?.registeredName
                    )
                    .join(', ')}
                />
              </ItemsContainer>
            </>
          }
        />
      </ItemsWrapper>
    </Container>
  );
};

export default UserReviewSubmit;
