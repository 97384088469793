import React from 'react';
import styled from 'styled-components';

import MenuTopBar from '../Menu/TopBar';
import MenuSideBar from '../Menu/SideBar';
import { getCurrentUser } from '../../Lib/Utils/auth';

interface IProps {
  children?: any;
  title?: string;
}

const Container = styled.div`
  position: relative;
  background: #fff;
  .content {
    padding: 24px 35px 35px;
    height: fit-content;
    position: relative;
    min-height: 100vh;
  }
  > div {
    display: flex;
    position: relative !important;
    height: 100vh;
  }
`;

const LoggedLayout = ({ children }: IProps) => {
  const currentUser = getCurrentUser() || {};
  return (
    <Container>
      <MenuSideBar>
        <MenuTopBar currentUser={currentUser} />
        <section className="content px-4">
          {React.cloneElement(children, { currentUser: currentUser })}
        </section>
      </MenuSideBar>
    </Container>
  );
};

export default LoggedLayout;
