import React from 'react';

export default (props: { color?: string }) => (
  <svg
    width="38"
    height="48"
    viewBox="0 0 33 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.34698 26.2001H10.5414V29.4001H7.34698V26.2001ZM16.9303 0.600098L0.95813 8.6001V29.4001H4.15255V16.6001H29.708V29.4001H32.9024V8.6001L16.9303 0.600098ZM10.5414 13.4001H4.15255V10.2001H10.5414V13.4001ZM20.1247 13.4001H13.7358V10.2001H20.1247V13.4001ZM29.708 13.4001H23.3191V10.2001H29.708V13.4001ZM7.34698 19.8001H10.5414V23.0001H7.34698V19.8001ZM13.7358 19.8001H16.9303V23.0001H13.7358V19.8001ZM13.7358 26.2001H16.9303V29.4001H13.7358V26.2001ZM20.1247 26.2001H23.3191V29.4001H20.1247V26.2001Z"
      fill={props.color ? props.color : "#A7BF2E" }
    />
  </svg>
);
