import * as catalogService from '../../Services/Api/catalogService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { ECatalogActionTypes } from './catalogActionsTypes';

const getBrands = createAction(ECatalogActionTypes.GET_BRANDS);
const getBrandsSuccess = createAction(
  ECatalogActionTypes.GET_BRANDS_SUCCESS,
  (brands: any) => ({ brands })
);
const getBrandsError = createAction(
  ECatalogActionTypes.GET_BRANDS_ERROR,
  (message: string) => ({ message })
);

const getIdentifierTypes = createAction(
  ECatalogActionTypes.GET_IDENTIFIER_TYPES
);
const getIdentifierTypesSuccess = createAction(
  ECatalogActionTypes.GET_IDENTIFIER_TYPES_SUCCESS,
  (identifierTypes: any) => ({ identifierTypes })
);
const getIdentifierTypesError = createAction(
  ECatalogActionTypes.GET_IDENTIFIER_TYPES_ERROR,
  (message: string) => ({ message })
);

const getCategories = createAction(ECatalogActionTypes.GET_CATEGORIES);
const getCategoriesSuccess = createAction(
  ECatalogActionTypes.GET_CATEGORIES_SUCCESS,
  (categories: any) => ({ categories })
);
const getCategoriesError = createAction(
  ECatalogActionTypes.GET_CATEGORIES_ERROR,
  (message: string) => ({ message })
);

const getMarketplaces = createAction(ECatalogActionTypes.GET_MARKETPLACES);
const getMarketplacesSuccess = createAction(
  ECatalogActionTypes.GET_MARKETPLACES_SUCCESS,
  (marketplaces: any) => ({ marketplaces })
);
const getMarketplacesError = createAction(
  ECatalogActionTypes.GET_MARKETPLACES_ERROR,
  (message: string) => ({ message })
);

const getClaimTypes = createAction(ECatalogActionTypes.GET_CLAIM_TYPES);
const getClaimTypesSuccess = createAction(
  ECatalogActionTypes.GET_CLAIM_TYPES_SUCCESS,
  (claimTypes: any) => ({ claimTypes })
);
const getClaimTypesError = createAction(
  ECatalogActionTypes.GET_CLAIM_TYPES_ERROR,
  (message: string) => ({ message })
);

const getAssetCatalog = createAction(ECatalogActionTypes.GET_ASSETCATALOG);
const getAssetCatalogInputSuccess = createAction(
  ECatalogActionTypes.GET_ASSETCATALOG_INPUT_SUCCESS,
  (catalogedAsset: any) => ({ catalogedAsset })
);
const getAssetCatalogOutputSuccess = createAction(
  ECatalogActionTypes.GET_ASSETCATALOG_OUTPUT_SUCCESS,
  (catalogedAsset: any) => ({ catalogedAsset })
);
const getAssetCatalogError = createAction(
  ECatalogActionTypes.GET_ASSETCATALOG_ERROR,
  (message: string) => ({ message })
);

export const doGetBrands = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(getBrands());
  return await tap(
    catalogService.getBrands(),
    response => {
      dispatch(getBrandsSuccess(response.data ? response.data.data : []));
    },
    error => {
      const _message = 'Failed to retrieve Brands. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getBrandsError(message));
    }
  );
};

export const doGetIdentifierTypes = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(getIdentifierTypes());
  return await tap(
    catalogService.getIdentifierTypes(),
    response => {
      dispatch(
        getIdentifierTypesSuccess(response.data ? response.data.data : [])
      );
    },
    error => {
      const _message = 'Failed to retrieve Brands. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getIdentifierTypesError(message));
    }
  );
};

export const doGetCategories = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(getCategories());
  return await tap(
    catalogService.getCategories(),
    response => {
      dispatch(getCategoriesSuccess(response.data ? response.data.data : []));
    },
    error => {
      const _message = 'Failed to retrieve Brands. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getCategoriesError(message));
    }
  );
};

export const doGetMarketplaces = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(getMarketplaces());
  return await tap(
    catalogService.getMarketplaces(),
    response => {
      dispatch(getMarketplacesSuccess(response.data ? response.data.data : []));
    },
    error => {
      const _message = 'Failed to retrieve Brands. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getMarketplacesError(message));
    }
  );
};

export const doGetClaimTypes = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(getClaimTypes());
  return await tap(
    catalogService.getClaimTypes(),
    response => {
      dispatch(getClaimTypesSuccess(response.data ? response.data.data : []));
    },
    error => {
      const _message = 'Failed to retrieve Brands. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getClaimTypesError(message));
    }
  );
};

export const doGetAssetCatalog = (
  payload,
  input = true
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getAssetCatalog());
  return await tap(
    catalogService.getAsset(payload),
    response => {
      if (input) {
        dispatch(
          getAssetCatalogInputSuccess(response.data ? response.data.data : [])
        );
      } else {
        dispatch(
          getAssetCatalogOutputSuccess(response.data ? response.data.data : [])
        );
      }
    },
    error => {
      const _message = 'Failed to retrieve Asset. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getAssetCatalogError(message));
    }
  );
};
