import React, { useState, useEffect, useMemo } from 'react';
import i18n from 'i18next';
import { SortableList } from './Sortable';

import {
  Container,
  Button,
  IconContainer,
  CustomLinkContainer,
} from './styles';
import { PlusIcon, InfoIcon } from '../CustomIcons';
import { FormInput, CustomTooltip } from '../index';
import { arrayMove } from '../../Lib/Utils/array';
import Lightbox from './Lightbox';
import { FormErrorLabel } from '../Label';

type MediaType = 'image' | 'video';

interface MediaObject {
  src: string;
  featured?: boolean;
  external?: boolean;
  subtype?: string;
}

interface Props {
  type?: MediaType;
  handleChange?: Function;
  accept?: any;
  maxFileSize?: number;
  proloadedItemsLinks?: Array<MediaObject>;
  readonly?: boolean;
  showLink?: boolean;
  featured?: Array<number>;
  linksList?: Array<number>;
  allowLinks?: boolean;
  addButtonText?: string;
  fileSizeErrorMessage?: string;
}

const DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

const MediaUpload = ({
  handleChange,
  type = 'image',
  accept = [],
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  proloadedItemsLinks = [],
  readonly = false,
  showLink = false,
  linksList = [],
  allowLinks = true,
  addButtonText,
  fileSizeErrorMessage,
}: Props) => {

  // Hooks
  const [items, setItems] = useState(proloadedItemsLinks);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({} as MediaObject);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [showExtensionError, setShowExtensionError] = useState(false);
  const [customLink, setCustomLink] = useState('');

  const inputElement = React.createRef<HTMLInputElement>();

  // Mount
  useEffect(() => {
    if (handleChange) {
      handleChange(items);
    }
  }, [items]);

  useEffect(() => {
    if (proloadedItemsLinks && proloadedItemsLinks.length === 0) {
      items.length !== 0 && setItems([]);
    } else if (proloadedItemsLinks && proloadedItemsLinks.length) {
      if (JSON.stringify(proloadedItemsLinks) !== JSON.stringify(items)) {
        setItems(proloadedItemsLinks);
        setShowExtensionError(false);
        setShowFileSizeError(false);
      }
    }
  }, [proloadedItemsLinks]);

  const handleUploadClick = e => {
    e.target.value = null;
  };

  const triggerFileUpload = () => {
    if (inputElement && inputElement.current) {
      inputElement.current.click();
    }
  };

  const readFile = file => {
    return new Promise(resolve => {
      const reader = new FileReader();

      // Read the image via FileReader API and save image result in state.
      reader.onload = function(e) {
        // Add the file name to the data URL
        if (e && e.target && e.target.result) {
          let dataURL = e.target.result.toString();
          dataURL = dataURL.replace(';base64', `;name=${file.name};base64`);
          resolve({ file, dataURL });
        }
        resolve({});
      };

      reader.readAsDataURL(file);
    });
  };

  const hasExtension = fileName => {
    const pattern =
      '(' +
      accept
        .join('|')
        .replace(/video\//g, '.')
        .replace(/image\//g, '.')
        .replace(/application\//g, '.') +
      ')$';
    return new RegExp(pattern, 'i').test(fileName);
  };

  const handleDropFile = e => {
    const targetFiles = e.target.files;
    const allFilePromises = [] as any;

    setShowExtensionError(false);
    setShowFileSizeError(false);

    // Iterate over all uploaded files
    for (let i = 0; i < targetFiles.length; i++) {
      const file = targetFiles[i];
      // Check for file extension and file size
      if (!hasExtension(file.name)) {
        setShowExtensionError(true);
      } else if (file.size > maxFileSize) {
        setShowFileSizeError(true);
      } else {
        allFilePromises.push(readFile(file));
      }
    }

    Promise.all(allFilePromises).then(newFilesData => {
      const dataURLs = items.slice() as any;

      newFilesData.forEach((newFileData: any) => {
        let filedata = {};
        if (
          newFileData.file &&
          newFileData.file.type &&
          newFileData.file.type === 'application/pdf'
        ) {
          filedata = {
            subtype: 'pdf',
            filename: newFileData.file.name,
          };
        }
        dataURLs.push({
          src: newFileData.dataURL,
          file: newFileData.file,
          ...filedata,
        });
      });

      setItems(dataURLs);
    });
  };

  const handleSort = ({ oldIndex, newIndex }) => {
    !readonly && setItems(arrayMove(items, oldIndex, newIndex));
  };

  const removeItem = (e, removeIndex) => {
    e.stopPropagation();
    const filteredItems = items.filter((e, index) => index !== removeIndex);
    setItems(filteredItems);
  };

  const handleOpenLightbox = item => {
    setSelectedItem(item);
    setLightboxOpen(true);
  };

  const handleSetFeatured = index => {
    setItems(
      items.map((item, i) => {
        if (i === index) {
          item.featured = !item.featured;
        } else {
          item.featured = false;
        }
        return item;
      })
    );
  };

  const handleAddCustomLink = () => {
    if (hasExtension(customLink)) {
      setItems([...items, { src: customLink, external: true }]);
      setCustomLink('');
      setShowExtensionError(false);
    } else {
      setShowExtensionError(true);
    }
  };

  return (
    <Container>
      {lightboxOpen && (
        <Lightbox
          resource={{ src: selectedItem.src, type }}
          onCloseCallback={() => setLightboxOpen(false)}
        />
      )}
      <div className="error-messages mb-2">
        {showFileSizeError && (
          <FormErrorLabel>
            {fileSizeErrorMessage
              ? fileSizeErrorMessage
              : type === 'image'
              ? i18n.t('Image File Size Error')
              : i18n.t('Video File Size Error')}
          </FormErrorLabel>
        )}
        {showExtensionError && (
          <FormErrorLabel>
            {type === 'image'
              ? i18n.t('Image Format Error')
              : i18n.t('Video Format Error')}
          </FormErrorLabel>
        )}
      </div>
      {useMemo(
        () => (
          <>
            <SortableList
              axis="xy"
              onSortEnd={handleSort}
              distance={3}
              items={items}
              type={type}
              readonly={readonly}
              triggerFileUpload={triggerFileUpload}
              removeItem={removeItem}
              handleOpenLightbox={handleOpenLightbox}
              handleSetFeatured={handleSetFeatured}
              addButtonText={addButtonText}
            />
            {!readonly && allowLinks && (
              <CustomLinkContainer className="custom-link">
                <div className="custom-url">
                  <FormInput
                    name="customMedia"
                    placeholder={
                      type === 'image'
                        ? i18n.t('Enter image URL')
                        : i18n.t('Enter video URL')
                    }
                    // defaultValue={customLink}
                    value={customLink}
                    onChange={evt => setCustomLink(evt.target.value)}
                  />
                </div>
                <div data-tip data-for={type}>
                  <InfoIcon />
                </div>
                <CustomTooltip id={type} placement="top">
                  {type === 'image'
                    ? i18n.t(
                        'External links must point to JPEG, JPG or PNG images'
                      )
                    : i18n.t('External links must point to MP4 videos')}
                </CustomTooltip>
                <Button onClick={() => handleAddCustomLink()}>
                  <IconContainer>
                    <PlusIcon />
                  </IconContainer>
                  {i18n.t('Add URL')}
                </Button>
              </CustomLinkContainer>
            )}
            <input
              type="file"
              ref={inputElement}
              multiple
              onChange={handleDropFile}
              onClick={handleUploadClick}
              accept={accept.join(', ')}
            />
          </>
        ),
        [items, maxFileSize, readonly, showLink, linksList, customLink]
      )}
    </Container>
  );
};

export default MediaUpload;
