import React from 'react';

export default () => (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.36364 8.81667L2.43038 5L6.36364 1.175L5.15274 0L0 5L5.15274 10L6.36364 8.81667Z"
      fill="#F9F9FB"
    />
  </svg>
);
