import styled from 'styled-components';

const Container = styled.div``;

const List = styled.ul`
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 20px 5px;
  border-left: 10px solid rgba(117, 117, 117, 0.25);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  font-family: Encode Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 0 10px;
  margin: 10px 0;
  color: #757575;
  path {
    fill: #757575;
  }
  &.active {
    border-color: #a7bf2e;
    color: #303030;
    path {
      fill: #303030;
      fill-opacity: 100%;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  max-width: 100%;
`;
const IconContainer = styled.div`
  margin-right: 10px;
  &.active {
    path {
      fill: #a7bf2e;
    }
  }
`;

const ActionsContainer = styled.div`
  color: #757575;
  display: flex;
  align-items: center;
  button {
    padding: 0;
  }
  > div:last-child {
    margin: 0 0 0 10px;
  }
`;

const ContentContainer = styled.div`
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #757575;
  flex: 1;
`;

export {
  Container,
  List,
  ListItem,
  TitleContainer,
  ActionsContainer,
  IconContainer,
  ContentContainer,
};
