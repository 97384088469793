import React, { useState } from 'react';
import styled from 'styled-components';
import Item from './Item';

interface Props {
  items: Array<{
    icon?: any;
    title?: any;
    open?: boolean;
    content?: any;
  }>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListPreview = ({ items }: Props) => {
  const findDefaultOpen = items && items.find(item => item.open);
  const [openItem, setOpenItem] = useState(
    findDefaultOpen ? findDefaultOpen.title : null
  );

  const handleOpenItem = itemTitle => {
    if (openItem === itemTitle) {
      setOpenItem(null);
    } else {
      setOpenItem(itemTitle);
    }
  };

  return (
    <Container>
      {items &&
        items.map(({ icon, title, open, content }, index) => {
          return (
            <Item
              key={`${index}-${title}`}
              icon={icon}
              title={title}
              open={title === openItem}
              content={content}
              openAction={handleOpenItem}
            />
          );
        })}
    </Container>
  );
};

export default ListPreview;
