import React from 'react';
import styled from 'styled-components';
import i18n from 'i18next';

const Img = styled.img`
  width: 100%;
  max-width: 120px;
  margin: 16px 0 0 30px;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.13em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  margin-left: 29px;
  height: 68px;
  background: #fff;
  color: #000;
  padding-left: 29px;
  margin: 34px 5px 0 0;

  small {
    font-size: 14px;
  }
`;

const HeaderContainer = styled.div`
  margin-bottom: 40px;
`;

const SidebarHeader = () => (
  <HeaderContainer>
    <Img
      className="img-responsive logo"
      src={require('../../Rsc/Images/logo.svg')}
    />
    <Title>
      <span>{i18n.t('SUKU Discover')}</span>
      <small>{i18n.t('Back Office')}</small>
    </Title>
  </HeaderContainer>
);

export default SidebarHeader;
