import React from 'react';
import styled from 'styled-components';

interface Props {
  children?: any;
}

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 35px;
`;

// const Header = styled.div`
//   height: 50px;
//   background: radial-gradient(
//     50% 25628.48% at 50% 0%,
//     #3a3a3a 0%,
//     #000000 100%
//   );
//   border-top-right-radius: 20px;
// `;

const ContentBox = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

export default ContentBox;
