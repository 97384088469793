import React, { useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { JourneyStepsInboundContainer as Container } from '../styles';
import {
  Label,
  FormErrorLabel,
  CustomTooltip,
  Checkbox,
  TransferList,
  InfoIcon,
} from '../../../../Components';

interface Props {
  onChange: Function;
  noInboundAssets?: boolean;
  noOutboundAssets?: boolean;
  showOutboundReceivedDate?: boolean;
  isLastStep?: boolean;
  outboundAssetsReceived?: Array<any>;
  outboundAssetsInventory?: Array<any>;
}

const JourneyStepsOutboundAssets = ({
  onChange,
  noInboundAssets,
  noOutboundAssets,
  showOutboundReceivedDate,
  isLastStep,
  outboundAssetsReceived = [],
  outboundAssetsInventory = [],
}: Props) => {
  const {
    errors,
    control,
    register,
    getValues,
    setValue,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setValue([
      { noOutboundAssets },
      { showOutboundReceivedDate },
      { outboundAssetsReceived },
      { outboundAssetsInventory },
    ]);
  }, [
    noOutboundAssets,
    showOutboundReceivedDate,
    outboundAssetsReceived,
    outboundAssetsInventory,
  ]);

  return (
    <Container>
      {!noOutboundAssets && (
        <FormErrorLabel className="mb-4">
          *{' '}
          {isLastStep
            ? i18n.t(
                'You must have at least one marketable product as an outbound asset'
              )
            : i18n.t('You must have at least one outbound asset')}
          .
        </FormErrorLabel>
      )}
      <div className="row">
        {!isLastStep && (
          <div className="col-12 col-lg-6">
            <div className="checkbox-wrapper">
              <Label>
                <Controller
                  as={Checkbox}
                  name="noOutboundAssets"
                  control={control}
                  defaultValue={noOutboundAssets}
                  checked={noOutboundAssets}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.checked);
                    return evt.target.checked;
                  }}
                />

                {i18n.t('This step has no outbound assets')}
                <div data-tip data-for="nooutbound">
                  <InfoIcon />
                </div>
                <CustomTooltip id="nooutbound" placement="right">
                  <span>
                    {i18n.t('Selecting this will not require the supply chain')}
                  </span>
                </CustomTooltip>
              </Label>
            </div>
          </div>
        )}
        {!isLastStep && (
          <div className="col-12 col-lg-6">
            <div className="checkbox-wrapper">
              <Label disabled={noOutboundAssets}>
                <Controller
                  as={Checkbox}
                  disabled={noOutboundAssets}
                  name="showOutboundReceivedDate"
                  control={control}
                  defaultValue={showOutboundReceivedDate}
                  checked={showOutboundReceivedDate}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.checked);
                    return evt.target.checked;
                  }}
                />

                {i18n.t('Show sent date of outbound assets')}
                <div data-tip data-for="nooutbound">
                  <InfoIcon />
                </div>
                <CustomTooltip id="nooutbound" placement="right">
                  <span>{i18n.t('For every step in the product journey')}</span>
                </CustomTooltip>
              </Label>
            </div>
          </div>
        )}
        <div className="col-12">
          <Label disabled={!isLastStep && noOutboundAssets}>
            {isLastStep
              ? i18n.t(
                  'Select the marketable product(s) that will use this product journey'
                )
              : i18n.t(
                  'Select the outbound assets that this facility produces'
                )}
            <div data-tip data-for="marketable">
              <InfoIcon />
            </div>
            <CustomTooltip id="marketable" placement="right">
              <span>
                {isLastStep
                  ? i18n.t(
                      'The product/s that will be higlighted by this journey'
                    )
                  : i18n.t('This allows assets to be passed directly')}
              </span>
            </CustomTooltip>
          </Label>
          {!isLastStep && !noInboundAssets && (
            <div>
              <TransferList
                disabled={!isLastStep && noOutboundAssets}
                fromPlaceholder={i18n.t('Search for assets from step inputs')}
                items={outboundAssetsReceived}
                handleChange={items =>
                  onChange('outboundAssetsReceived', items)
                }
              />
            </div>
          )}
        </div>
        <div className="col-12">
          <div>
            <TransferList
              disabled={noOutboundAssets}
              fromPlaceholder={
                isLastStep
                  ? i18n.t('Search for marketable products')
                  : i18n.t('Search for assets from asset inventory')
              }
              items={outboundAssetsInventory}
              handleChange={items => onChange('outboundAssetsInventory', items)}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default JourneyStepsOutboundAssets;
