import React from 'react';
import i18n from 'i18next';
import styled from 'styled-components';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ListItem = styled.div`
  flex: 1;
  border-bottom: 1px solid rgba(117, 117, 117, 0.15);
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 55px;
`;

const ListTitle = styled.div`
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  display: flex;
  flex: 1;
  width: 100%;
  border-bottom: 1px solid rgba(117, 117, 117, 0.15);
`;

const IconContainer = styled.div`
  margin: 0 10px 0 0;
  background: #a7bf2e;
  border-radius: 100%;
  height: 34px;
  width: 34px;
  align-items: center;
  justify-content: center;
  color: #a7bf2e;
  display: flex;
  & path {
    fill: #fff;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  padding: 10px 0;
  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
  }
`;

const ActionContainer = styled.button`
  &:focus {
    outline: none;
  }
  outline: none;
  border: 0;
  display: flex;
  background-color: #000;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
`;

const ItemContainer = styled.div<{ open?: boolean }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: height 0ms 400ms, opacity 400ms 0ms;
  height: ${({ open }) => (open ? 'auto' : '0px')};
  opacity: ${({ open }) => (open ? '1' : '0')};
  width: 100%;
  padding: ${({ open }) => (open ? '20px 0 20px 44px' : '0')};
`;

interface Props {
  icon?: any;
  title?: any;
  open?: boolean;
  content?: any;
}

const Step = ({
  icon,
  title,
  open = false,
  content,
  openAction,
}: Props & { openAction: any | null }) => {
  const handleActionClick = () => {
    openAction(title);
  };

  const IconComponent = icon && icon;

  return (
    <Container>
      <ListItem>
        <ListTitle>
          <TitleContainer>
            <IconContainer>{IconComponent}</IconContainer>
            <h3 className="position-relative">{title}</h3>
          </TitleContainer>
          <ActionContainer onClick={handleActionClick}>
            {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </ActionContainer>
        </ListTitle>
        <ItemContainer open={open}>{content}</ItemContainer>
      </ListItem>
    </Container>
  );
};

export default Step;
