import React from 'react';
import {
  FaLinkedin,
  FaTwitter,
  FaFacebookF,
  FaTelegramPlane,
  FaMediumM,
  FaRedditAlien,
} from 'react-icons/fa';

interface SocialItem {
  title: string;
  link: string;
  icon: any;
}

const social: Array<SocialItem> = [
  {
    title: 'Linkedin',
    link: 'https://www.linkedin.com/company/suku-ecosystem/',
    icon: <FaLinkedin />,
  },
  {
    title: 'Twitter',
    link: 'https://twitter.com/SUKUecosystem',
    icon: <FaTwitter />,
  },
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/SUKUecosystem/',
    icon: <FaFacebookF />,
  },
  {
    title: 'Telegram',
    link: 'https://t.me/SUKUecosystemm',
    icon: <FaTelegramPlane />,
  },
  {
    title: 'Medium',
    link: 'https://medium.com/@SUKUecosystem',
    icon: <FaMediumM />,
  },
  {
    title: 'Reddit',
    link: 'https://www.reddit.com/r/SUKUecosystem/',
    icon: <FaRedditAlien />,
  },
];
export default social;
