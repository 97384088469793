import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

import {
  FormInput,
  Label,
  EditableItem,
  CustomTooltip,
  Link,
  Table,
  ListPreview,
  CalendarIcon,
  ClaimsShow,
  DynamicTable,
  Checkbox,
  QrPdf,
} from '../../../../Components';

import {
  Container,
  Content,
  NoAssets,
  EditableContent,
  TabBubble,
  QRHeadContainer,
} from './styles';

interface Props {
  data: any;
  onChange?: any;
  assetsInput?: any;
  assetsOutput?: any;
  messages?: { inbound?: string; outbound?: string };
  lotId?: string;
}

const ReviewSubmit = ({
  data,
  onChange,
  assetsInput,
  assetsOutput,
  messages,
  lotId,
}: Props) => {
  const { outboundData, inboundData, claimsData, reviewSubmit } = data;

  const handleChange = (name, value) => {
    onChange('reviewSubmit', { [name]: value });
  };

  const { certified } = reviewSubmit || { certified: false };

  const [claimsListItems, setClaimsListItems] = useState([] as Array<any>);
  const [showClaims, setShowClaims] = useState(false);

  const hasClaimsExpired = claims => {
    if (claims) {
      const haveVerification = claims.find(
        c => c.supportingVerifications.length > 0
      );
      if (haveVerification) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const findExpiredClaims = claims.filter(claim => {
          const expirationDate = new Date(
            claim.supportingVerifications[0].expirationDate
          );
          return expirationDate < today;
        });

        return findExpiredClaims.length > 0;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    const newClaimsListItems = [
      {
        icon: <CalendarIcon />,
        title: (
          <span
            className={
              hasClaimsExpired(claimsData.periodicClaims) ? 'expiredClaim' : ''
            }
          >
            <span className="mr-2">Periodic</span>
            {claimsData.periodicClaims?.length > 0 && (
              <>
                <TabBubble data-tip data-for="claimtab_periodic">
                  {claimsData.periodicClaims?.length || 0}
                </TabBubble>
                <CustomTooltip id="claimtab_periodic">
                  {hasClaimsExpired(claimsData.periodicClaims)
                    ? i18n.t('One or more claims require attention.')
                    : i18n.t('All claims are up to date.')}
                </CustomTooltip>
              </>
            )}
          </span>
        ),
        content: (
          <ClaimsShow
            readonly={true}
            claims={
              claimsData.periodicClaims
                ? claimsData.periodicClaims.map(c => ({
                    ...c,
                    description: [],
                  }))
                : []
            }
            noClaimsMessage={i18n.t(
              'There are no periodic claims for this journey.'
            )}
          />
        ),
      },
      {
        icon: <CalendarIcon />,
        title: (
          <span
            className={
              hasClaimsExpired(claimsData.lotClaims) ? 'expiredClaim' : ''
            }
          >
            <span className="mr-2">Lot</span>
            {claimsData.lotClaims?.length > 0 && (
              <>
                <TabBubble data-tip data-for="claimtab_lot">
                  {claimsData.lotClaims?.length || 0}
                </TabBubble>
                <CustomTooltip id="claimtab_lot">
                  {hasClaimsExpired(claimsData.lotClaims)
                    ? i18n.t('One or more claims require attention.')
                    : i18n.t('All claims are up to date.')}
                </CustomTooltip>
              </>
            )}
          </span>
        ),
        content: (
          <ClaimsShow
            readonly={true}
            claims={
              claimsData.lotClaims
                ? claimsData.lotClaims.map(c => ({ ...c, description: [] }))
                : []
            }
            noClaimsMessage={i18n.t(
              'There are no lot-level claims for this journey.'
            )}
          />
        ),
      },
      {
        icon: <CalendarIcon />,
        title: (
          <span
            className={
              hasClaimsExpired(claimsData.onetimeClaims) ? 'expiredClaim' : ''
            }
          >
            <span className="mr-2">One-Time</span>
            {claimsData.onetimeClaims?.length > 0 && (
              <>
                <TabBubble data-tip data-for="claimtab_onetime">
                  {claimsData.onetimeClaims?.length || 0}
                </TabBubble>
                <CustomTooltip id="claimtab_onetime">
                  {hasClaimsExpired(claimsData.onetimeClaims)
                    ? i18n.t('One or more claims require attention.')
                    : i18n.t('All claims are up to date.')}
                </CustomTooltip>
              </>
            )}
          </span>
        ),
        content: (
          <ClaimsShow
            readonly={true}
            claims={
              claimsData.onetimeClaims
                ? claimsData.onetimeClaims.map(c => ({
                    ...c,
                    description: [],
                  }))
                : []
            }
            noClaimsMessage={i18n.t(
              'There are no one-time claims for this journey.'
            )}
          />
        ),
      },
      {
        icon: <CalendarIcon />,
        title: (
          <span
            className={
              hasClaimsExpired(claimsData.othersClaimsData)
                ? 'expiredClaim'
                : ''
            }
          >
            <span className="mr-2">Other</span>
            {claimsData.othersClaimsData?.length > 0 && (
              <>
                <TabBubble data-tip data-for="claimtab_others">
                  {claimsData.othersClaimsData?.length || 0}
                </TabBubble>
                <CustomTooltip id="claimtab_others">
                  {hasClaimsExpired(claimsData.othersClaimsData)
                    ? i18n.t('One or more claims require attention.')
                    : i18n.t('All claims are up to date.')}
                </CustomTooltip>
              </>
            )}
          </span>
        ),
        content: (
          <ClaimsShow
            readonly={true}
            claims={
              claimsData.othersClaimsData
                ? claimsData.othersClaimsData.map(c => ({
                    ...c,
                    description: [],
                  }))
                : []
            }
            noClaimsMessage={i18n.t(
              'There are no non-verifiable claims for this journey.'
            )}
          />
        ),
      },
    ];

    setClaimsListItems(newClaimsListItems);
    if (
      claimsData.periodicClaims ||
      claimsData.lotClaims ||
      claimsData.onetimeClaims ||
      claimsData.othersClaims
    ) {
      setShowClaims(true);
    }
  }, [outboundData, inboundData, claimsData]);

  const inboundTables = inboundData.tableFields || [];
  const outboundTables = outboundData.tableFields || [];

  return (
    <Container>
      <Content>
        {showClaims && (
          <EditableItem
            title={i18n.t('Claim summary')}
            editable={true}
            linkTo="?tab=Claims"
            content={
              <EditableContent>
                <ListPreview items={claimsListItems} />
              </EditableContent>
            }
          />
        )}

        {!showClaims && (
          <EditableItem
            title={i18n.t('Claim summary')}
            editable={true}
            linkTo="?tab=Claims"
            content={
              <EditableContent>
                <ListPreview items={[]} />
              </EditableContent>
            }
          />
        )}

        <EditableItem
          title={i18n.t('Inbound summary')}
          editable={true}
          linkTo="?tab=Inbound"
          content={
            <EditableContent>
              <div className="row">
                <div className="col-12 mb-3">
                  <Label>{i18n.t('Inbound message')}</Label>
                </div>
                <div className="col-12 mb-3">
                  <p>
                    {inboundData.inboundMessage ||
                      messages?.inbound ||
                      i18n.t('No message')}
                  </p>
                </div>
                {Object.entries(inboundTables).length > 0 ? (
                  Object.keys(inboundTables).map(tableKey => {
                    const table = inboundTables[tableKey];
                    const inboundTableHeaders = table[0]
                      ? table[0].map(field => field.header)
                      : [];

                    const inboundTableRows = table
                      ? Object.keys(table).map(key =>
                          table[key]?.map(f => f.value)
                        )
                      : [];

                    const tableTitle = () => {
                      const assetTemplate = assetsInput.find(
                        asset => asset?.assetTemplate._id == tableKey
                      )?.assetTemplate;
                      return (
                        assetTemplate && (
                          <div className="d-flex flex-row">
                            {assetTemplate?.name}
                            <QRHeadContainer>
                              <QrPdf
                                qr={[
                                  {
                                    lotId: lotId || '',
                                    skuku: assetTemplate?.skuku,
                                  },
                                ]}
                                tooltip={i18n.t(
                                  'Generate QR code for this asset only.'
                                )}
                              />
                            </QRHeadContainer>
                          </div>
                        )
                      );
                    };

                    return (
                      <div className="col-12 mb-3" key={tableKey}>
                        <DynamicTable
                          defaultShow={false}
                          readonly={true}
                          headers={inboundTableHeaders}
                          data={inboundTableRows}
                          header={tableTitle()}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="col-12 mb-3">
                    <NoAssets>
                      {i18n.t('There are no inbound assets for this facility.')}
                    </NoAssets>
                  </div>
                )}
              </div>
            </EditableContent>
          }
        />

        <EditableItem
          title={i18n.t('Outbound summary')}
          editable={true}
          linkTo="?tab=Outbound"
          content={
            <EditableContent>
              <div className="row">
                <div className="col-12 mb-3">
                  <Label>{i18n.t('Outbound message')}</Label>
                </div>
                <div className="col-12 mb-3">
                  <p>
                    {outboundData.outboundMessage ||
                      messages?.outbound ||
                      i18n.t('No message')}
                  </p>
                </div>
              </div>
              <div className="row">
                {Object.entries(outboundTables).length > 0 ? (
                  Object.keys(outboundTables).map(tableKey => {
                    const table = outboundTables[tableKey];
                    const outboundTableHeaders = table[0]
                      ? table[0]?.map(field => field.header)
                      : [];
                    const outboundTableRows = table
                      ? Object.keys(table).map(key =>
                          table[key]?.map(f => f.value)
                        )
                      : [];

                    const tableTitle = () => {
                      const assetTemplate = assetsOutput.find(
                        asset => asset?.assetTemplate._id == tableKey
                      )?.assetTemplate;

                      return (
                        assetTemplate && (
                          <div className="d-flex flex-row">
                            {assetTemplate.name}
                            <QRHeadContainer>
                              <QrPdf
                                qr={[
                                  {
                                    lotId: lotId || '',
                                    skuku: assetTemplate.skuku,
                                  },
                                ]}
                                tooltip={i18n.t(
                                  'Generate QR code for this asset only.'
                                )}
                              />
                            </QRHeadContainer>
                          </div>
                        )
                      );
                    };

                    return (
                      <div className="col-12 mb-3" key={tableKey}>
                        <DynamicTable
                          defaultShow={false}
                          readonly={true}
                          headers={outboundTableHeaders}
                          data={outboundTableRows}
                          header={tableTitle()}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="col-12 mb-3">
                    <NoAssets>
                      {i18n.t(
                        'There are no outbound assets for this facility.'
                      )}
                    </NoAssets>
                  </div>
                )}
              </div>
            </EditableContent>
          }
        />

        <EditableItem
          title={i18n.t('Certification')}
          editable={false}
          content={
            <EditableContent>
              <div className="row">
                <div className="col-12">
                  <div className="checkbox-wrapper">
                    <Label thin={true}>
                      <Checkbox
                        onChange={evt =>
                          handleChange(evt.target.name, evt.target.checked)
                        }
                        checked={certified}
                        name="certified"
                      />
                      {`${i18n.t(
                        'I certify that the above information is accurate to the best of my knowledge.'
                      )}`}
                    </Label>
                  </div>
                </div>
              </div>
            </EditableContent>
          }
        />
      </Content>
    </Container>
  );
};

export default withTranslation()(ReviewSubmit as any) as any;
