import React, { useState, useEffect, Dispatch, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
import { ObjectID } from 'bson';
import { Journey } from '../../../Lib/Utils/Validations';
import Toast from '../../../Lib/Utils/toast';
import { doGetFacilities } from '../../../Redux/Facility/facilityActions';
import { doGetClaims } from '../../../Redux/Claim/claimActions';
import NavigationPrompt from 'react-router-navigation-prompt';

import {
  doSaveJourneyTemplate,
  doGetJourneyTemplate,
  doGetJourneyTemplates,
  resetCurrentTemplate,
  doDeleteJourneyTemplate,
} from '../../../Redux/Journey/journeyActions';
import { doGetAssetTemplates } from '../../../Redux/Asset/assetActions';
import {
  JourneyIcon,
  Title,
  Button,
  Steps,
  OverviewIcon,
  ClaimAltIcon,
  FacilityAltIcon,
  AssetAltIcon,
  JourneyAltIcon,
  FinishIcon,
  CustomTooltip,
  Loading,
  PopUp,
} from '../../../Components';
import {
  ActionButtonsContainer,
  StepsContainer,
  TitleContainer,
} from './styles';
import JourneyDetailsStep from './JourneyDetailsStep';
import FacilityStep from './FacilityStep';
import AssetStep from './AssetStep';
import ClaimStep from './ClaimStep';
import JourneySteps from './JourneySteps';
import JourneySubmitStep from './JourneySubmitStep';
import { StepTemplate, DataProps } from './types';
import { FacilityTypeIcons } from '../../../Lib/Configs';
import {
  FaFlagCheckered,
  FaRegQuestionCircle,
  FaGalacticSenate,
} from 'react-icons/fa';
import { ClaimsContent } from '../../Dashboard/style';

interface RootState {
  facility: any;
  journey: any;
  claim: any;
  asset: any;
}

const mapState = (state: RootState) => ({
  facilities: state.facility.facilities,
  assets: state.asset.assets,
  journey: state.journey.currentTemplate,
  journeyTemplates: state.journey.journeyTemplates,
  claims: state.claim.claims || [],
  submitSuccess: state.journey.submitSuccess,
  saveSuccess: state.journey.saveSuccess,
  deleteSuccess: state.journey.deleteSuccess,
  loading: state.journey.loading,
  error: state.journey.error,
  refresh: state.journey.refresh,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getFacilitiesAction: includeDrafts =>
    dispatch(doGetFacilities(includeDrafts)),
  saveJourneyTemplateAction: (payload, actionType, notifySuccess) =>
    dispatch(doSaveJourneyTemplate(payload, actionType, notifySuccess)),
  getAssetsAction: includeDrafts =>
    dispatch(doGetAssetTemplates(includeDrafts)),
  getClaimsAction: includeDrafts => dispatch(doGetClaims(null, includeDrafts)),
  getJourneyTemplates: () => dispatch(doGetJourneyTemplates()),
  getJourneyTemplateAction: (journeyTemplateId, actionType) =>
    dispatch(doGetJourneyTemplate(journeyTemplateId, actionType)),
  resetFormAction: () => dispatch(resetCurrentTemplate()),
  deleteJourneyAction: (journeyTemplateId, isDraft) =>
    dispatch(doDeleteJourneyTemplate(journeyTemplateId, isDraft)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & { history?: any; location?: any; match?: any };

const JourneyWizard = (props: Props) => {
  const {
    saveJourneyTemplateAction,
    getJourneyTemplateAction,
    getFacilitiesAction,
    getAssetsAction,
    getClaimsAction,
    getJourneyTemplates,
    resetFormAction,
    deleteJourneyAction,
    journey,
    journeyTemplates,
    facilities,
    assets,
    claims,
    history,
    match,
    loading,
    submitSuccess,
    saveSuccess,
    deleteSuccess,
    error,
    location,
    refresh,
  } = props;

  const [data, setData] = useState({} as DataProps);
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState(
    [] as Array<{
      number: number;
      icon?: any;
      title: string;
      subTitle: string;
      disabled?: boolean;
      content?: any;
    }>
  );
  const [showDelete, setShowDelete] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [disableHistoryLock, setDisableHistoryLock] = useState(false);
  const [templatesChanged, setTemplatesChanged] = useState(false);

  const entityId = match.params.journeyId;
  const isDraft = !!match.path.match('/draft');

  const formMethods = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (entityId) {
      getJourneyTemplateAction(
        match.params.journeyId,
        isDraft ? 'get-draft' : 'get-entity'
      );
    }

    getJourneyTemplates();
    getFacilitiesAction(true);
    getAssetsAction(true);
    getClaimsAction(true);

    // will be called on component unmount
    return () => {
      resetFormAction();
    };
  }, []);

  useEffect(() => {
    if (submitSuccess) {
      Toast.success(i18n.t('Your data has been successfully submitted.'));
      resetFormAction();
      setDisableHistoryLock(true);
    } else if (saveSuccess) {
      Toast.success(i18n.t('Your data has been successfully saved.'));
    } else if (deleteSuccess) {
      Toast.success(i18n.t(`The journey was discarded`));
      setDisableHistoryLock(true);
    } else if (error) {
      Toast.error(error);
    }

    if (refresh && entityId) {
      setTemplatesChanged(false);
      getJourneyTemplateAction(
        match.params.journeyId,
        isDraft ? 'get-draft' : 'get-entity'
      );
    }
  }, [error, submitSuccess, deleteSuccess, saveSuccess, refresh]);

  useEffect(() => {
    if (disableHistoryLock) {
      setDisableHistoryLock(false);
      history.push(`/journeys`);
    }
  }, [disableHistoryLock]);

  useEffect(() => {
    const {
      _id,
      journeyName,
      templateLocator,
      description,
      stepTemplates = [],
      refFacilities = [],
      refAssets = [],
      refClaims = [],
    } = journey;

    let formattedTemplates = getFormattedTemplates(stepTemplates) as any;

    if (!formattedTemplates?.length) {
      formattedTemplates = [
        {
          number: -1,
          name: i18n.t('Last step'),
          icon: <FaFlagCheckered />,
          active: false,
          description: '',
          facilityClaims: [],
          inboundAssetsInventory: getFormattedAssets([]),
          outboundAssetsInventory: getFormattedAssets([]),
        },
        {
          number: 0,
          name: `${i18n.t('Step')} 0`,
          active: true,
          description: '',
          icon: <FaRegQuestionCircle />,
          facilityClaims: [],
          inboundAssetsInventory: getFormattedAssets([]),
          outboundAssetsInventory: getFormattedAssets([]),
        },
      ];
    }

    let journeyFacilities = refFacilities
      .concat(stepTemplates.map(st => st.stepTemplate.facilityId))
      .filter(f => !!f);

    journeyFacilities = [...new Set(journeyFacilities)]; // Keep unique

    let journeyAssets = refAssets.concat(data.refAssets || []);
    stepTemplates.forEach(st => {
      journeyAssets = journeyAssets
        .concat(st.stepTemplate.inputs.map(i => i.skuku))
        .concat(st.stepTemplate.outputs.map(o => o.skuku));
    });
    journeyAssets = [...new Set(journeyAssets)]; // Keep unique
    journeyAssets = journeyAssets.filter(ra =>
      assets.find(a => ra === a._id || ra === a.assetId || ra === a.skuku)
    );
    journeyAssets = journeyAssets.filter(a => !!a);

    let journeyClaims = refClaims.concat(data.refClaims || []);
    stepTemplates.forEach(st => {
      journeyClaims = journeyClaims.concat(st.stepTemplate.claims);
    });
    journeyClaims = journeyClaims.filter(c => !!c);
    journeyClaims = [...new Set(journeyClaims)]; // Keep unique

    setData({
      _id,
      journeyName,
      templateLocator,
      description,
      stepTemplates: formattedTemplates,
      assetsInventory: getFormattedAssets([]),
      refFacilities: journeyFacilities,
      refAssets: journeyAssets,
      refClaims: journeyClaims,
    });
  }, [journey, assets]);

  useEffect(() => {
    let journeyFacilities = [] as Array<any>;
    let journeyAssets = [] as Array<any>;
    let journeyClaims = [] as Array<any>;

    journeyFacilities =
      facilities?.filter(f =>
        data.refFacilities?.find(rf => rf === f._id || rf === f.facilityId)
      ) || [];

    journeyAssets =
      assets?.filter(a =>
        data.refAssets?.find(
          ra => ra === a._id || ra === a.assetId || ra === a.skuku
        )
      ) || [];

    journeyClaims =
      claims?.filter(c =>
        data.refClaims?.find(rc => rc === c._id || rc === c.claimId)
      ) || [];

    const journeySteps = [
      {
        number: 1,
        value: 'overview',
        title: '',
        subTitle: i18n.t('Journey overview'),
        content: JourneyDetailsStep,
        contentProps: {
          ...data,
          journeyTemplates,
          onChange: (name, value) => handleChange(name, value),
          onTemplateChange: handleTemplateChange,
          isDraft,
        },
        icon: <OverviewIcon />,
        done: data.journeyName && data.description,
      },
      {
        number: 2,
        value: 'facilities',
        title: '',
        subTitle: i18n.t('Create facilities'),
        facilities: journeyFacilities.map(f => f.registeredName),
        content: FacilityStep,
        contentProps: {
          facilities: journeyFacilities,
          currentFacilityId: data.currentFacilityId,
          refFacilities: data.refFacilities,
          onChange: handleChange,
          onRefresh: handleRefresh,
        },
        icon: <FacilityAltIcon />,
        done: journeyFacilities.length > 0,
        showAddButton: true,
      },
      {
        number: 3,
        value: 'assets',
        title: '',
        subTitle: i18n.t('Create assets'),
        content: AssetStep,
        contentProps: {
          assets: journeyAssets,
          refAssets: data.refAssets,
          onChange: handleChange,
          onRefresh: handleRefresh,
        },
        facilities: journeyAssets?.map(a => a.name) || [],
        icon: <AssetAltIcon />,
        done: journeyAssets.length > 0,
        showAddButton: true,
      },
      {
        number: 4,
        value: 'claims',
        title: '',
        subTitle: i18n.t('Create claims'),
        content: ClaimStep,
        facilities: journeyClaims?.map(c => c.name) || [],
        contentProps: {
          claims: journeyClaims,
          refClaims: data.refClaims,
          onChange: handleChange,
          onRefresh: handleRefresh,
        },
        icon: <ClaimAltIcon />,
        done: journeyClaims.length > 0,
        showAddButton: true,
      },
      {
        number: 5,
        value: 'journeys',
        title: '',
        subTitle: i18n.t('Set up journey steps'),
        content: JourneySteps,
        contentProps: {
          ...data,
          onChange: (name, value) => handleChange(name, value),
          onRefresh: handleRefresh,
          facilities: facilities.filter(f => !f.isDraft),
          claims,
          assets: assets.filter(a => !a.isDraft),
        },
        icon: <JourneyAltIcon />,
        done: !Journey.stepTemplates().validate.requiredFields(
          data.stepTemplates
        ),
      },
      {
        number: 6,
        value: 'submit',
        title: '',
        subTitle: i18n.t('Review & submit'),
        content: JourneySubmitStep,
        contentProps: {
          ...data,
          onChange: (name: string, value: any) => handleChange(name, value),
          onReview: handleReview,
          formMethods,
          facilities,
          claims,
        },
        icon: <FinishIcon />,
        done: false,
      },
    ];

    const steps = journeySteps.map(({ number, ...rest }) => {
      return {
        number,
        ...rest,
        title: `${i18n.t('Step')} ${number}`,
      };
    });

    setSteps(steps);
  }, [facilities, assets, data, claims, journeyTemplates, formMethods.errors]);

  const handleChange = (name: string, value: any) => {
    if (name === 'stepTemplates') {
      setTemplatesChanged(true);
    }
    setData({ ...data, [name]: value });
  };

  const handleReview = (
    step: number,
    entityName?: string,
    entityId?: string
  ) => {
    setCurrentStep(step);
    if (entityName && entityId) {
      handleChange(entityName, entityId);
    }
  };

  const handleRefresh = (entityName: string) => {
    switch (entityName) {
      case 'facility':
        getFacilitiesAction(true);
        break;
      case 'asset':
        getAssetsAction(true);
        break;
      case 'claim':
        getClaimsAction(true);
        break;
      default:
        break;
    }
  };

  const handleTemplateChange = (templateLocator?: string) => {
    if (templateLocator) {
      const journeyTemplate = journeyTemplates.find(
        jt => jt._id === templateLocator
      );
      const { stepTemplates } = journeyTemplate;

      let refFacilities = stepTemplates.map(st => st.stepTemplate.facilityId);
      refFacilities = [...new Set(refFacilities)]; // Keep unique

      let refAssets = [];
      stepTemplates.forEach(st => {
        refAssets = refAssets
          .concat(st.stepTemplate.inputs.map(i => i.skuku))
          .concat(st.stepTemplate.outputs.map(o => o.skuku));
      });
      refAssets = [...new Set(refAssets)]; // Keep unique
      refAssets = refAssets.filter(ra =>
        assets.find(a => ra === a._id || ra === a.assetId || ra === a.skuku)
      );
      refAssets = refAssets.filter(a => !!a);

      let refClaims = [];
      stepTemplates.forEach(st => {
        refClaims = refClaims.concat(st.stepTemplate.claims);
      });
      refClaims = refClaims.filter(c => !!c);
      refClaims = [...new Set(refClaims)]; // Keep unique

      const formattedTemplates = getFormattedTemplates(stepTemplates) as any;

      setData({
        ...data,
        stepTemplates: formattedTemplates,
        templateLocator,
        refFacilities,
        refAssets,
        refClaims,
      });
    } else {
      setData({
        ...data,
        stepTemplates: [],
        templateLocator,
        refFacilities: [],
        refAssets: [],
        refClaims: [],
      });
    }
  };

  const getFormattedAssets = (
    selection: Array<string> = [],
    includeAll = true
  ): Array<any> => {
    const format = ({ skuku, name, description }) => {
      return {
        _id: skuku,
        text: name,
        selected: selection.indexOf(skuku) !== -1,
        tooltip: {
          title: <span style={{ fontWeight: 'normal' }}>{description}</span>,
          items: [],
        },
        data: {
          matchKey: skuku,
        },
      };
    };
    if (includeAll) {
      return assets?.filter(a => !a.isDraft)?.map(asset => format(asset)) || [];
    } else {
      return selection.map(skuku => {
        const asset = assets?.find(a => a.skuku === skuku) || {};
        return format(asset);
      });
    }
  };

  const getFormattedTemplates = (stepTemplates: Array<any>) => {
    const formattedTemplates = stepTemplates
      ?.sort((a, b) => (a.order > b.order ? 1 : -1))
      ?.map((st, index) => {
        const { stepTemplate } = st;
        const {
          _id,
          facilityId,
          facilityType,
          facilityName,
          static: isStatic,
          layouts,
          showDate,
          description,
          claims,
          inputs = [] as Array<{ source: string; skuku: string }>,
          outputs = [] as Array<{ source: string; skuku: string }>,
        } = stepTemplate;
        const facilityTypeIcon = stepTemplate.facilityTypeIcon || facilityType;

        const isComplex = layouts?.mobile === 'multi-tab'
        const inboundAssetsReceived = [] as Array<string>;
        const outboundAssetsReceived = [] as Array<string>;
        const inboundAssetsInventory = [] as Array<string>;
        const outboundAssetsInventory = [] as Array<string>;

        inputs.forEach(({ source, skuku }) => {
          if (source === 'journey') {
            inboundAssetsReceived.push(skuku);
          } else {
            inboundAssetsInventory.push(skuku);
          }
        });
        outputs.forEach(({ source, skuku }) => {
          if (source === 'journey') {
            outboundAssetsReceived.push(skuku);
          } else {
            outboundAssetsInventory.push(skuku);
          }
        });
        const number = index === stepTemplates.length - 1 ? -1 : index; // Last step is the last step template
        const name = facilityType
          ? facilityType
          : number === -1
          ? i18n.t('Last step')
          : `${i18n.t('Step')} ${number}`;

        const icon = facilityType ? (
          FacilityTypeIcons[facilityTypeIcon]
        ) : (
          <FaRegQuestionCircle />
        );
        const prevActive = data?.stepTemplates?.find(st => st.active)?.number;

        return {
          number,
          active: prevActive ? prevActive === number : index === 0,
          _id,
          facilityId,
          facilityType,
          facilityTypeIcon,
          facilityName,
          isActiveFacility: !isStatic,
          isComplex,
          showInboundReceivedDate: ['all', 'received'].includes(
            showDate.toLowerCase()
          ),
          showOutboundReceivedDate: ['all', 'sent'].includes(
            showDate.toLowerCase()
          ),
          noInboundAssets: inputs.length === 0,
          noOutboundAssets: outputs.length === 0 && number !== -1,
          facilityClaims: claims,
          name,
          icon,
          description,
          inboundAssetsReceived: getFormattedAssets(
            inboundAssetsReceived,
            false
          ),
          outboundAssetsReceived: getFormattedAssets(
            outboundAssetsReceived,
            false
          ),
          inboundAssetsInventory: getFormattedAssets(
            inboundAssetsInventory,
            true
          ),
          outboundAssetsInventory: getFormattedAssets(
            outboundAssetsInventory,
            true
          ),
        };
      });
    return formattedTemplates;
  };

  const getMappedData = () => {
    const {
      _id,
      journeyName,
      templateLocator,
      description,
      stepTemplates = [],
      refFacilities = [],
      refAssets = [],
      refClaims = [],
    } = data;

    const mappedTemplates = stepTemplates.map(step => {
      // Generate inputs
      let inputs = [] as any;
      if (!step.noInboundAssets) {
        inputs = inputs.concat(
          (step?.inboundAssetsInventory || [])
            .filter(({ selected }) => selected)
            .map(({ _id }) => ({ skuku: _id, source: 'inventory' }))
        );
        inputs = inputs
          .concat(
            (step.inboundAssetsReceived || [])
              .filter(({ selected }) => selected)
              .map(({ _id }) => ({ skuku: _id, source: 'journey' }))
          )
          .filter(i => !!i.skuku);
      }

      // Generate outputs
      let outputs = [] as any;
      if (!step.noOutboundAssets) {
        outputs = outputs.concat(
          (step.outboundAssetsInventory || [])
            .filter(({ selected }) => selected)
            .map(({ _id }) => ({ skuku: _id, source: 'inventory' }))
        );
        outputs = outputs
          .concat(
            (step.outboundAssetsReceived || [])
              .filter(({ selected }) => selected)
              .map(({ _id }) => ({ skuku: _id, source: 'journey' }))
          )
          .filter(o => !!o.skuku);
      }

      const stepFacility =
        facilities?.find(f => f._id === step.facilityId) || {};

      const showDate =
        step.showInboundReceivedDate && step.showOutboundReceivedDate
          ? 'All'
          : step.showInboundReceivedDate
          ? 'Received'
          : step.showOutboundReceivedDate
          ? 'Sent'
          : 'None';

      return {
        order: step.number === -1 ? stepTemplates.length - 1 : step.number,
        stepTemplate: {
          _id: !templatesChanged && step._id ? step._id : undefined,
          facility: {
            _id: step.facilityId,
            name: stepFacility.registeredName,
          },
          facilityName: stepFacility.registeredName,
          facilityId: step.facilityId,
          description: step.description,
          facilityType: step.facilityType,
          facilityTypeIcon: step.facilityTypeIcon,
          templateLocator: step.templateLocator,
          static: !step.isActiveFacility,
          layouts: {
            mobile: step.isComplex ? 'multi-tab' : 'single-tab',
          },
          showDate,
          inputs,
          outputs,
          claims: step.facilityClaims,
        },
      };
    });

    let mappedAssets = [] as Array<string>;
    mappedTemplates.forEach(
      st =>
        (mappedAssets = mappedAssets.concat(
          st.stepTemplate.inputs
            .map(i => i.skuku)
            .concat(st.stepTemplate.outputs.map(o => o.skuku))
        ))
    );
    mappedAssets = [...new Set(mappedAssets)]; // Keep unique asset ids

    const mappedData = {
      _id: _id || entityId || new ObjectID().toString(),
      journeyName,
      description,
      assets: mappedAssets,
      stepTemplates: mappedTemplates,
      templateLocator,
      refFacilities,
      refAssets,
      refClaims,
    };

    return mappedData;
  };

  const validate = async () => {
    formMethods.register({ name: 'journeyName' }, Journey.name);
    formMethods.register({ name: 'description' }, Journey.description);
    formMethods.register({ name: 'stepTemplates' }, Journey.stepTemplates());

    formMethods.setValue(
      Object.keys(data).map(key => ({
        [key]: data[key],
      }))
    );

    const result = await formMethods.triggerValidation();
    if (!result) {
      setData({ ...data, validate: result });
    }

    return result;
  };

  const handleSave = async (notifySuccess?: boolean) => {
    // Name must be entered at minimum
    if (!data.journeyName) {
      Toast.error(i18n.t('Must enter journey name to save.'));
      setCurrentStep(1);
      return false;
    }

    if (!isDraft) {
      const result = await validate();
      if (!result) {
        Toast.error(i18n.t('There are items that require your attention.'));
        return false;
      }
    }

    const actionType = isDraft
      ? data._id
        ? 'save-draft'
        : 'create-draft'
      : 'save-entity';
    const mappedData = getMappedData();
    saveJourneyTemplateAction(mappedData, actionType, notifySuccess);
  };

  const handleDelete = () => {
    deleteJourneyAction(journey._id, isDraft);
    setShowDelete(false);
  };

  const submitJourney = async () => {
    const result = await validate();
    if (result) {
      const mappedData = getMappedData();
      const actionType =
        isDraft || !entityId ? 'submit-create-entity' : 'submit-save-entity';
      saveJourneyTemplateAction(mappedData, actionType, false);
    } else {
      Toast.error(i18n.t('There are items that require your attention.'));
    }
  };

  return (
    <>
      <TitleContainer className="mb-5">
        <Title
          title={i18n.t('Journey Wizard')}
          subtitle={
            steps && steps.length ? steps[currentStep - 1].subTitle : ''
          }
          icon={<JourneyIcon />}
        />
        <ActionButtonsContainer>
          <div data-tip data-for="delete">
            <Button
              text={isDraft ? i18n.t('Delete') : i18n.t('Archive')}
              type="dark"
              action={() => setShowDelete(true)}
            />
          </div>
          <CustomTooltip id="delete" placement="bottom">
            <span>{`${isDraft ? i18n.t('Delete') : i18n.t('Archive')} ${i18n.t(
              'journey and go to journey list.'
            )}`}</span>
          </CustomTooltip>
          <div data-tip data-for="save">
            <Button
              text={i18n.t('Save')}
              type="dark"
              action={() => handleSave(true)}
            />
          </div>
          <CustomTooltip id="save" placement="bottom">
            <span>{i18n.t('Save progress.')}</span>
          </CustomTooltip>
          {currentStep < steps.length && (
            <Button
              text={i18n.t('Save & next')}
              type="dark"
              action={() => {
                handleSave(false);
                setCurrentStep(currentStep + 1);
              }}
            />
          )}
          {currentStep === steps.length && (
            <Button
              text={i18n.t('Submit')}
              type="dark"
              action={() => submitJourney()}
              // disabled
            />
          )}
        </ActionButtonsContainer>
      </TitleContainer>
      <StepsContainer>
        <Steps
          steps={steps}
          onChange={step => {
            handleSave(false);
            setCurrentStep(step);
          }}
          defaultStep={currentStep}
          showCreate
          showFacilities={false}
        />
      </StepsContainer>
      <PopUp
        title={`${isDraft ? i18n.t('Delete') : i18n.t('Archive')} ${i18n.t(
          'journey template?'
        )}`}
        content={
          <div>
            {i18n.t('Are you sure you want to')}{' '}
            {isDraft ? i18n.t('delete') : i18n.t('archive')}{' '}
            {i18n.t('this journey template?')}
            <br />
            <br />
            {i18n.t(
              'Supply chain participants will no longer be able to create lots or submit data.'
            )}
          </div>
        }
        cancelText={i18n.t('Cancel')}
        confirmText={i18n.t('Yes')}
        confirmAction={handleDelete}
        cancelAction={() => setShowDelete(false)}
        show={showDelete}
      />

      <NavigationPrompt
        beforeConfirm={clb => {
          clb();
        }}
        renderIfNotActive={false}
        when={(crntLocation, nextLocation) =>
          (!nextLocation ||
            !nextLocation.pathname.startsWith(crntLocation.pathname)) &&
          !disableHistoryLock
        }
      >
        {({ isActive, onCancel, onConfirm }) => {
          setShowPopup(true);
          return (
            <PopUp
              title={i18n.t('Save Changes?')}
              content={i18n.t(
                'Unsaved work may be lost. Do you want to save changes?'
              )}
              cancelText={i18n.t('Cancel')}
              confirmText={i18n.t('Yes')}
              confirmAction={async () => {
                setShowPopup(false);
                const save = await handleSave(false);

                if (save === false) {
                  onCancel();
                } else {
                  onConfirm();
                }
              }}
              cancelAction={() => {
                setShowPopup(false);
                onCancel();
              }}
              negativeText="No"
              negativeAction={() => {
                setShowPopup(false);
                onConfirm();
              }}
              show={isActive && showPopup}
            />
          );
        }}
      </NavigationPrompt>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(JourneyWizard) as any) as any
);
