import React from 'react';

export default (props: { color?: string }) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9091 12.5001C13.5836 12.5001 13.2714 12.3708 13.0413 12.1407C12.8111 11.9105 12.6818 11.5983 12.6818 11.2728C12.6818 10.9473 12.8111 10.6352 13.0413 10.405C13.2714 10.1749 13.5836 10.0456 13.9091 10.0456C14.2346 10.0456 14.5467 10.1749 14.7769 10.405C15.0071 10.6352 15.1364 10.9473 15.1364 11.2728C15.1364 11.5983 15.0071 11.9105 14.7769 12.1407C14.5467 12.3708 14.2346 12.5001 13.9091 12.5001ZM15.1364 5.13647L16.74 7.18193H13.0909V5.13647H15.1364ZM4.09091 12.5001C3.76542 12.5001 3.45325 12.3708 3.2231 12.1407C2.99294 11.9105 2.86364 11.5983 2.86364 11.2728C2.86364 10.9473 2.99294 10.6352 3.2231 10.405C3.45325 10.1749 3.76542 10.0456 4.09091 10.0456C4.4164 10.0456 4.72856 10.1749 4.95872 10.405C5.18888 10.6352 5.31818 10.9473 5.31818 11.2728C5.31818 11.5983 5.18888 11.9105 4.95872 12.1407C4.72856 12.3708 4.4164 12.5001 4.09091 12.5001ZM15.5455 3.9092H13.0909V0.636475H1.63636C0.728182 0.636475 0 1.36466 0 2.27284V11.2728H1.63636C1.63636 11.9238 1.89497 12.5481 2.35528 13.0085C2.8156 13.4688 3.43992 13.7274 4.09091 13.7274C4.7419 13.7274 5.36622 13.4688 5.82654 13.0085C6.28685 12.5481 6.54545 11.9238 6.54545 11.2728H11.4545C11.4545 11.9238 11.7131 12.5481 12.1735 13.0085C12.6338 13.4688 13.2581 13.7274 13.9091 13.7274C14.5601 13.7274 15.1844 13.4688 15.6447 13.0085C16.105 12.5481 16.3636 11.9238 16.3636 11.2728H18V7.18193L15.5455 3.9092Z"
      fill={props.color ? props.color : "#A7BF2E" }
    />
  </svg>
);
