import { ObjectID } from 'bson';
import { authAxiosCall } from '../axiosCall';
import { uploadMedia } from '../mediaService';
import {
  AddClaimVerification,
  SaveClaim,
  GetClaimActionType,
  SaveClaimActionType,
} from './types';
import { SaveVerification } from '../verificationService/types';
import { saveVerification } from '../verificationService';
import { getUUIDForFile } from '../../../Lib/Utils/functions';
import { AxiosResponse } from 'axios';
import { isOrgAdmin } from '../../../Lib/Utils/auth';

export const getClaims = async (
  facilityId = null,
  includeDrafts?: boolean,
  payload?: any
): Promise<AxiosResponse<any> | undefined> => {
  let response = await authAxiosCall('/claims?limit=1000', {
    query: facilityId ? { ...payload, facilityId } : payload,
    method: 'GET',
  });
  let claims = response && response.data ? response.data.data : [];

  claims = claims.filter(c => !c.archived);

  if (isOrgAdmin() && includeDrafts) {
    response = await authAxiosCall('/drafts/collections/claims', {
      method: 'GET',
    });
    const drafts = response && response.data ? response.data.data : [];
    claims = claims.concat(
      drafts.map(d => ({
        ...d.draft,
        claimId: d.draft._id,
        _id: d._id,
        isDraft: true,
        createdAt: d.createdAt,
        updatedAt: d.updatedAt,
      }))
    );
  }
  return claims;
};

export const getClaim = async (
  claimId: string,
  actionType?: GetClaimActionType
): Promise<AxiosResponse<any> | undefined> => {
  const path =
    actionType === 'get-draft' ? `/drafts/${claimId}` : `/claims/${claimId}`;

  return authAxiosCall(path, {
    method: 'GET',
  });
};

export const getAttestors = async () =>
  authAxiosCall('/attestors?limit=1000', {
    method: 'GET',
  });

export const addClaimVerification = async ({
  verificationId,
  files,
}: AddClaimVerification) => {
  if (files) {
    const filesForUpload = files.map(file => {
      const _id = new ObjectID().toString();
      return {
        file: file.file,
        path: `verifications/${getUUIDForFile(_id)}_${verificationId}/${
          file.filename
        }`,
      };
    });

    const uploadFiles = await uploadMedia(filesForUpload);

    if (uploadFiles) {
      return authAxiosCall(`/verifications/${verificationId}`, {
        method: 'PUT',
        body: {
          links: uploadFiles,
        },
      });
    }
  }
};

export const saveClaim = async (
  { _id, ...claim }: SaveClaim,
  verification: SaveVerification,
  actionType: SaveClaimActionType
): Promise<AxiosResponse<any> | undefined> => {
  let path = '';
  let method = '';
  switch (actionType) {
    case 'create-draft':
      path = `/drafts/collections/claims`;
      method = 'POST';
      break;
    case 'save-draft':
      path = `/drafts/${_id}`;
      method = 'PUT';
      break;
    case 'submit-create-entity':
      path = `/claims`;
      method = 'POST';
      break;
    case 'save-entity':
    case 'submit-save-entity':
      path = `/claims/${_id}`;
      method = 'PUT';
      break;
  }

  const claimData = {
    ...claim,
    supportingVerifications: claim.supportingVerifications || [],
  };

  if (Object.keys(verification).length > 0 && verification.attestor !== '') {
    const newVerification = await saveVerification(verification);

    if (newVerification) {
      const verification = newVerification.data.data;
      if (['save-draft', 'create-draft'].includes(actionType)) {
        claimData.supportingVerifications = [verification];
      } else {
        const newVerificationId = verification._id;
        claimData.supportingVerifications = [newVerificationId];
      }
    }
  }

  const response = await authAxiosCall(path, {
    method,
    body: JSON.stringify({
      _id,
      ...claimData,
    }),
  });

  if (actionType === 'submit-create-entity') {
    await deleteDraft(_id);
  }

  return response;
};

export const deleteClaim = async (_id: string, isDraft: boolean) => {
  let response;
  if (isDraft) {
    response = await deleteDraft(_id);
  } else {
    response = await authAxiosCall(`/claims/${_id}`, {
      method: 'PUT',
      body: JSON.stringify({
        archived: true,
      }),
    });
  }
  return response;
};

const deleteDraft = async (_id?: string) => {
  if (_id) {
    try {
      await authAxiosCall(`/drafts/${_id}`, {
        method: 'DELETE',
      });
    } catch (err) {
      // Do not throw draft might not exist
      console.log(`ERR removing draft`, err.message);
    }
  }
  return true;
};
