import React, { useEffect } from 'react';
import i18n from 'i18next';

import {
  MappingFormContainer as FormContainer,
  MappingContainer as Container,
  MappingDisclaimerContainer as DisclaimerContainer,
} from './styles';

import { Label, Dropdown } from '../../../Components';

interface Props {
  onChange: Function;
  facilityOptions?: any;
  facilities?: Array<any>;
  formMethods?: any;
  facilityId?: string;
}

const ClaimMapping = ({
  onChange,
  facilities,
  formMethods,
  ...data
}: Props) => {
  const { errors, clearError, setError } = formMethods;

  const facilityOptionsDefault = facilities
    ? facilities?.map(facility => ({
        label: facility.registeredName,
        value: facility._id,
      }))
    : [];

  const { facilityOptions = facilityOptionsDefault, facilityId } = data;

  useEffect(() => {
    clearError();
    if (facilityId === '') {
      setError(
        'facilityId',
        'required',
        i18n.t('A claim must be mapped to a facility.')
      );
    }
  }, [facilityId]);

  return (
    <Container>
      <FormContainer>
        {errors.facilityId && (
          <div className="row">
            <div className="col-12 d-flex justify-content-flex-start">
              <DisclaimerContainer>
                {errors.facilityId.message}
              </DisclaimerContainer>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <p className="description">
              {i18n.t(
                'Defining claims alone is not sufficient.  A claim needs to be associated to a FACILITY.  Therefore, associate your claim to ONE facility below. Once associated, this claim will  appear everywhere the facility appears.  However, if you do not want the claim to appear for all journeys, you can restrict the claim for this facility to one or more journeys.'
              )}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Label>
              {i18n.t('Select one facility that this claim will be linked to')}
            </Label>
            <Dropdown
              options={facilityOptions}
              searchable={true}
              action={value => onChange('facilityId', value)}
              required={true}
              defaultOption={facilityId}
            />
          </div>
        </div>
      </FormContainer>
    </Container>
  );
};

export default ClaimMapping;
