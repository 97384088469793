import React, { useState } from 'react';
import {
  Container,
  ItemContainer,
  ItemTitle,
  ItemContent,
  ActionContainer,
} from './styles';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { FormInput, Label, Checkbox } from '../index';
import ViewItem from '../DynamicInput/ViewIitem';
import { FieldTypes } from '../../Lib/Configs';

interface Props {
  items: Array<{
    fieldName: string;
    fieldType: string;
    value: string;
    fieldPlaceholder: string;
    options: Array<string>;
    isMandatory?: boolean;
    maxChars?: number;
  }>;
  defaultOpen: number | null;
}

const InputsList = ({ items, defaultOpen = null }: Props) => {
  const [open, setOpen] = useState<number | null>(defaultOpen);

  const handleOpen = idx => {
    if (idx === open) {
      setOpen(null);
    } else {
      setOpen(idx);
    }
  };

  const getFriendlyFieldType = (fieldType: string): string | undefined => {
    return FieldTypes.find(ft => ft.value === fieldType)?.label;
  }

  return (
    <Container>
      {items.map((item, index) => {
        const {
          fieldName,
          fieldType,
          fieldPlaceholder,
          options,
          isMandatory,
          maxChars,
        } = item;
        const active = open === index;
        return (
          <ItemContainer key={index} className={active ? 'active' : ''}>
            <ItemTitle>
              <div>{fieldName}</div>
              <div>{getFriendlyFieldType(fieldType)}</div>
              <ActionContainer onClick={() => handleOpen(index)}>
                {active ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
              </ActionContainer>
            </ItemTitle>

            <ItemContent>
              <ViewItem
                index={index}
                name="view"
                fieldName={fieldName}
                fieldType={fieldType}
                fieldPlaceholder={fieldPlaceholder}
                options={options}
                isMandatory={isMandatory}
                maxChars={maxChars}
                onChange={() => {}}
              />
            </ItemContent>
          </ItemContainer>
        );
      })}
    </Container>
  );
};

export default InputsList;
