import React, { useState, useEffect } from 'react';
import i18n from 'i18next';

import { useForm, Controller } from 'react-hook-form';
import Validations from './Validations';

import { FieldTypes, DataSource, VisibilityScopes } from '../../Lib/Configs';
import Toast from '../../Lib/Utils/toast';
import {
  FormInput,
  Label,
  InfoIcon,
  CustomTooltip,
  Checkbox,
  Dropdown,
  Button,
} from '..';
import { EditItemContainer } from './styles';
import TextItem from './TextItem';
import NumberItem from './NumberItem';
import CheckboxItem from './CheckboxItem';
import RadioButtonItem from './RadioButtonItem';
import DropdownItem from './DropdownItem';
import ListboxItem from './ListboxItem';

interface Props {
  index: number;
  isMandatory?: boolean;
  visibilityScope?: string;
  fieldName?: string;
  fieldType: string;
  dataSource?: string;
  isEditable?: string;
  fieldPlaceholder?: string;
  options?: Array<any>;
  maxChars?: number;
  onChange: Function;
  onSave: Function;
  onCancel?: Function;
  itemFirstLoad?: boolean;
}

const Item = ({
  index,
  visibilityScope,
  fieldName,
  fieldType,
  dataSource,
  isMandatory,
  isEditable,
  fieldPlaceholder,
  maxChars,
  options = [],
  onChange,
  onSave,
  onCancel,
  itemFirstLoad = true,
}: Props) => {
  const { errors, control, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    itemFirstLoad === false && triggerValidation();
    onChange(index, 'itemFirstLoad', false);
  }, []);

  const handleSave = async () => {
    const isValid = await triggerValidation();
    if (isValid) {
      const listTypes = ['listBox', 'dropDown', 'checkBoxes', 'radioButtons'];
      if (listTypes.includes(fieldType)) {
        // validate options
        const blankOptions = options.reduce(
          (total, x) => (x ? total : total + 1),
          0
        );
        if (isMandatory && blankOptions > 0) {
          Toast.error(
            i18n.t('Blank options are not allowed for mandatory fields.')
          );
          return;
        } else if (blankOptions > 1) {
          Toast.error(i18n.t('Only one blank option is allowed.'));
          return;
        }
      }
      onSave(index, isValid);
    } else {
      Toast.error(i18n.t('There are items that require your attention.'));
    }
  };

  const handleCancel = () => {
    onCancel && onCancel(index);
  };

  return (
    <EditItemContainer className="row">
      <div className="col-7 mt-0">
        <div className="checkbox-wrapper">
          <Label>
            <Checkbox
              onChange={evt =>
                onChange(index, evt.target.name, evt.target.checked)
              }
              checked={isMandatory}
              name="isMandatory"
            />
            {i18n.t('Mandatory field')}
            <div data-tip data-for="mandatory">
              <InfoIcon />
            </div>
            <CustomTooltip id="mandatory">
              <span>
                {i18n.t(
                  'Indicate whether or not the field must contain a value'
                )}
              </span>
            </CustomTooltip>
          </Label>
        </div>
      </div>
      <div className="col-5 mt-0">
        <Label>
          {i18n.t('Visibility Scope')}
          <div data-tip data-for="visibilityscope">
            <InfoIcon />
          </div>
          <CustomTooltip id="visibilityscope">
            <p>
              <strong>{i18n.t('Private')}: </strong>
              {i18n.t(
                'This field will only be visible to the facility it is mapped to.'
              )}
              <br />
              <br />
              <strong>{i18n.t('Supply Chain')}: </strong>
              {i18n.t(
                'This field will be visible to any facility in the supply chain.'
              )}
              <br />
              <br />
              <strong>{i18n.t('Public Facility')}: </strong>
              {i18n.t(
                'This field will be visible across the supply chain and will be visible by end consumers (e.g., on the mobile app).'
              )}
              <br />
              <br />
              <strong>{i18n.t('Public Product')}: </strong>
              {i18n.t(
                'This field will be visible across the supply chain and will be visible by end consumers below the product description and when they drill into the facility (e.g., on the mobile app).'
              )}
              <br />
              <br />
            </p>
          </CustomTooltip>
        </Label>

        <Controller
          as={
            <Dropdown
              placeholder={i18n.t('Select field type')}
              action={values => onChange(index, 'visibilityScope', values)}
              required
              options={VisibilityScopes}
              defaultOption={visibilityScope}
            />
          }
          name="visibilityScope"
          value={visibilityScope}
          control={control}
          onChange={([newValue]) => {
            onChange(index, 'visibilityScope', newValue);
            return newValue;
          }}
          rules={Validations.visibility}
          defaultValue={visibilityScope}
        />
        {errors.visibilityScope && (
          <span className="inlineErrorMessage">
            {errors.visibilityScope.message}.
          </span>
        )}
      </div>
      <div className="col-12 col-lg-7">
        <Label>
          {i18n.t('Field name')}
          <div data-tip data-for="fieldname">
            <InfoIcon />
          </div>
          <CustomTooltip id="fieldname">
            <span>
              {i18n.t('This is the label that will appear above the field')}
            </span>
          </CustomTooltip>
        </Label>

        <Controller
          as={
            <FormInput
              required
              placeholder={`${i18n.t('Field name')} ${index + 1}`}
            />
          }
          name="fieldName"
          value={fieldName}
          control={control}
          onChange={([evt]) => {
            onChange(index, evt.target.name, evt.target.value);
            return evt.target.value;
          }}
          rules={Validations.name}
          defaultValue={fieldName}
        />
        {errors.fieldName && (
          <span className="inlineErrorMessage">
            {errors.fieldName.message}.
          </span>
        )}
      </div>
      <div className="col-12 col-lg-5">
        <Label>
          {i18n.t('Field type')}
          <div data-tip data-for="fieldtype">
            <InfoIcon />
          </div>
          <CustomTooltip id="fieldtype">
            <span>{i18n.t('These are all the posible data field types')}</span>
          </CustomTooltip>
        </Label>

        <Controller
          as={
            <Dropdown
              placeholder={i18n.t('Select field type')}
              action={values => onChange(index, 'fieldType', values)}
              required
              options={FieldTypes}
              defaultOption={fieldType}
            />
          }
          name="fieldType"
          value={fieldType}
          control={control}
          onChange={([newValue]) => {
            onChange(index, 'fieldType', newValue);
            return newValue;
          }}
          rules={Validations.type}
          defaultValue={fieldType}
        />
        {errors.fieldType && (
          <span className="inlineErrorMessage">
            {errors.fieldType.message}.
          </span>
        )}
      </div>
      <div className="col-12 col-lg-7">
        <Label>
          {i18n.t('Source of data')}
          <div data-tip data-for="sourceofdata">
            <InfoIcon />
          </div>
          <CustomTooltip id="sourceofdata">
            <span>
              {i18n.t(
                'If external system is selected, an integration will be required'
              )}
            </span>
          </CustomTooltip>
        </Label>
        <Controller
          as={
            <Dropdown
              placeholder={i18n.t('Select source')}
              action={values => onChange(index, 'dataSource', values)}
              required
              options={DataSource}
              defaultOption={dataSource}
            />
          }
          name="dataSource"
          value={dataSource}
          control={control}
          onChange={([newValue]) => {
            onChange(index, 'dataSource', newValue);
            return newValue;
          }}
          rules={Validations.type}
          defaultValue={dataSource}
        />
        {errors.dataSource && (
          <span className="inlineErrorMessage">
            {errors.dataSource.message}.
          </span>
        )}
      </div>
      {dataSource === 'external' && (
        <div className="col-12 col-lg-5">
          <Label>
            {i18n.t('Is the data editable by the user')}
            <div data-tip data-for="dataeditable">
              <InfoIcon />
            </div>
            <CustomTooltip id="dataeditable">
              <p>
                <br />
                <span>
                  {i18n.t(
                    'The user can manually enter or edit external data; the field will be editable'
                  )}
                </span>
                <br />
                <br />
                <span>
                  {i18n.t(
                    'The user cannot edit the data; the field will be read only for the user'
                  )}
                </span>
                <br />
                <br />
                <span>{i18n.t('default yes')}</span>
              </p>
            </CustomTooltip>
          </Label>
          <Controller
            as={
              <Dropdown
                placeholder={i18n.t('Select')}
                action={values => onChange(index, 'isEditable', values)}
                required
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
                defaultOption={isEditable}
              />
            }
            name="isEditable"
            value={isEditable}
            control={control}
            onChange={([newValue]) => {
              onChange(index, 'isEditable', newValue);
              return newValue;
            }}
            rules={Validations.type}
            defaultValue={isEditable}
          />
          {errors.isEditable && (
            <span className="inlineErrorMessage">
              {errors.isEditable.message}.
            </span>
          )}
        </div>
      )}
      {
        {
          string: (
            <TextItem
              index={index}
              fieldPlaceholder={fieldPlaceholder}
              maxChars={maxChars}
              onChange={onChange}
              Controller={Controller}
              control={control}
              errors={errors}
            />
          ),
          number: (
            <NumberItem
              index={index}
              fieldPlaceholder={fieldPlaceholder}
              maxChars={maxChars}
              onChange={onChange}
              Controller={Controller}
              control={control}
              errors={errors}
            />
          ),
          textArea: (
            <TextItem
              index={index}
              fieldPlaceholder={fieldPlaceholder}
              maxChars={maxChars}
              onChange={onChange}
              Controller={Controller}
              control={control}
              errors={errors}
            />
          ),
          checkBoxes: (
            <CheckboxItem index={index} onChange={onChange} options={options} />
          ),
          radioButtons: (
            <RadioButtonItem
              index={index}
              onChange={onChange}
              options={options}
            />
          ),
          dropDown: (
            <DropdownItem index={index} onChange={onChange} options={options} />
          ),
          listBox: (
            <ListboxItem index={index} onChange={onChange} options={options} />
          ),
        }[fieldType]
      }
      <div className="action-btn col-12">
        <Button text={i18n.t('Cancel')} action={() => handleCancel()} />
        <Button text={i18n.t('Save')} type="dark" action={() => handleSave()} />
      </div>
    </EditItemContainer>
  );
};

export default Item;
