import React from 'react';
import styled from 'styled-components';

interface Props {
  children?: any;
}

const Container = styled.div`
  margin-top: -215px;
  position: absolute;
  left: 0;
  right: 0;
`;

const FooterWrapper = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

export default FooterWrapper;
