import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #000;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default () => (
  <Container>
    <svg
      width="4"
      height="11"
      viewBox="0 0 4 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: 5, height: 12 }}
    >
      <path
        d="M2.01176 2.32C1.66642 2.32 1.37709 2.21267 1.14376 1.998C0.919758 1.774 0.807758 1.49867 0.807758 1.172C0.807758 0.845333 0.919758 0.574666 1.14376 0.359999C1.37709 0.135999 1.66642 0.0239995 2.01176 0.0239995C2.35709 0.0239995 2.64176 0.135999 2.86576 0.359999C3.09909 0.574666 3.21576 0.845333 3.21576 1.172C3.21576 1.49867 3.09909 1.774 2.86576 1.998C2.64176 2.21267 2.35709 2.32 2.01176 2.32ZM2.97776 3.244V11H1.01776V3.244H2.97776Z"
        fill="#D8F40C"
      />
    </svg>
  </Container>
);
