import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';
import { Journey, Facility } from '../../../../Lib/Utils/Validations';

import {
  JourneyStepsFacilityContainer as Container,
  SwitchLabel,
} from '../styles';

import {
  Label,
  CustomTooltip,
  Switch,
  InfoIcon,
  FormSelect,
  TransferList,
  Dropdown,
} from '../../../../Components';
import { FacilityTypes } from '../../../../Lib/Configs';

const facilityClaimsDefault = [
  {
    text: 'SENASA Certification',
    selected: false,
    description: [
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, atque.',
    ],
    from: '12/07/15',
    to: '05/20/20',
  },
  {
    text: 'HALAL Certification',
    selected: false,
    description: [
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, atque.',
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, atque.',
    ],
    from: '12/07/15',
    to: '05/20/20',
  },
  {
    text: 'ISO9001 Certification',
    selected: false,
    description: [
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, atque.',
    ],
    from: '12/07/15',
    to: '05/20/20',
  },
];

interface Props {
  onChange: Function;
  selectedFacility?: any;
  selectedFacilityType?: string;
  isActiveFacility?: boolean;
  facilityClaims?: Array<any>;
  facilities?: Array<any>;
  claims?: Array<any>;
}

const JourneyStepsFacility = (
  {
    onChange,
    selectedFacility,
    selectedFacilityType,
    isActiveFacility = false,
    facilities,
    facilityClaims,
    claims,
  }: Props,
  ref
) => {
  const { errors, control, setValue, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  const [defaultClaimsList, setDefaultClaimsList] = useState([] as any);

  useEffect(() => {
    if (claims) {
      const newFacilityClaims = claims?.map(claim => ({
        text: claim.name,
        selected: false,
        description: claim.description,
        from: '12/07/15',
        to: '05/20/20',
      }));
      setDefaultClaimsList(newFacilityClaims);
    }
  }, [claims]);

  useEffect(() => {
    setValue([
      { selectedFacility: selectedFacility },
      { selectedFacilityType: selectedFacilityType },
      { isActiveFacility: isActiveFacility },
      { facilityClaims: facilityClaims },
    ]);
  }, [
    selectedFacility,
    selectedFacilityType,
    isActiveFacility,
    facilityClaims,
  ]);

  useImperativeHandle(ref, () => ({
    validate() {
      return triggerValidation();
    },
  }));

  const facilityNameOptions = facilities
    ? facilities.map(f => ({
        value: f._id,
        label: f.registeredName,
      }))
    : [];

  const selectedFacilityDescription =
    selectedFacility &&
    selectedFacility._id &&
    facilities?.find(f => f._id === selectedFacility._id).description;

  return (
    <Container>
      <div className="row">
        <div className="col-12 col-lg-6">
          <Label>
            {i18n.t('Facility')}
            <div data-tip data-for="facility">
              <InfoIcon />
            </div>
            <CustomTooltip id="facility" placement="right">
              <span>{i18n.t('Select the facility for this step')}</span>
            </CustomTooltip>
          </Label>

          <Controller
            as={
              <Dropdown
                required
                options={facilityNameOptions}
                placeholder={i18n.t('Select a facility for this journey step')}
                action={newValue => {
                  onChange('isActiveFacility', true);
                  const newSelectedFacility = facilities?.find(
                    f => f._id === newValue
                  );
                  newValue && onChange('selectedFacility', newSelectedFacility);
                }}
                defaultOption={selectedFacility && selectedFacility._id}
              />
            }
            name="selectedFacility"
            value={selectedFacility && selectedFacility._id}
            control={control}
            onChange={([newValue]) => {
              onChange('isActiveFacility', true);
              const newSelectedFacility = facilities?.find(
                f => f._id === newValue
              );
              newValue && onChange('selectedFacility', newSelectedFacility);
              return newValue;
            }}
            rules={Facility.name}
            defaultValue={selectedFacility}
          />
          {errors.selectedFacility && (
            <span className="inlineErrorMessage">
              {errors.selectedFacility.message}.
            </span>
          )}
        </div>
        <div className="col-12 col-lg-6">
          <Label>
            {i18n.t('Short description')}
            <div data-tip data-for="description">
              <InfoIcon />
            </div>
            <CustomTooltip id="description" placement="right">
              <span>
                {i18n.t('This is a brief description of the facility')}
              </span>
            </CustomTooltip>
          </Label>
          <SwitchLabel selected={!!selectedFacility} className="mt-3">
            {selectedFacility
              ? selectedFacilityDescription
              : i18n.t('Select a facility to show the description')}
          </SwitchLabel>
        </div>
        <div className="col-12 col-lg-6">
          <Label>
            {i18n.t('Facility type s')}
            <div data-tip data-for="facilitytype">
              <InfoIcon />
            </div>
            <CustomTooltip id="facilitytype" placement="right">
              <span>{i18n.t('What type of facility is this?')}</span>
            </CustomTooltip>
          </Label>

          <Controller
            as={
              <Dropdown
                required
                options={FacilityTypes}
                placeholder={i18n.t('Select a facility type')}
                action={newValue => onChange('selectedFacilityType', newValue)}
                defaultOption={selectedFacilityType}
              />
            }
            name="selectedFacilityType"
            value={selectedFacilityType}
            control={control}
            onChange={([newValue]) => {
              onChange('selectedFacilityType', newValue);
              return newValue;
            }}
            rules={Facility.type}
            defaultValue={selectedFacilityType}
          />
          {errors.selectedFacilityType && (
            <span className="inlineErrorMessage">
              {errors.selectedFacilityType.message}.
            </span>
          )}
        </div>
        <div className="col-12 col-lg-6">
          <Label>
            {i18n.t('Set the facility status')}
            <div data-tip data-for="facilitystatus">
              <InfoIcon />
            </div>
            <CustomTooltip id="facilitystatus" placement="right">
              <p style={{ margin: 0 }}>
                <strong>{i18n.t('Static')}: </strong>
                {i18n.t('Facilities will not be required to capture any data')}
                <br />
                <br />
                <strong>{i18n.t('Active')}: </strong>
                {i18n.t('Facilities will require supply chain')}
                <br />
                <br />
                <strong>{i18n.t('Claims')}: </strong>
                {i18n.t(
                  'Facility claims that are added to the facility will be displayed to end consumers'
                )}
              </p>
            </CustomTooltip>
          </Label>
          <div className="switch-wrapper">
            <SwitchLabel selected={!isActiveFacility}>
              {i18n.t('Static')}
            </SwitchLabel>
            <Switch
              alwaysOn
              onChange={evt => onChange(evt.target.name, evt.target.checked)}
              checked={isActiveFacility}
              name="isActiveFacility"
            />
            <SwitchLabel selected={isActiveFacility}>
              {i18n.t('Active')}
            </SwitchLabel>
          </div>
        </div>
        {selectedFacility && (
          <div className="col-12">
            <Label>
              {i18n.t('Select the facility claims that')}
              <div data-tip data-for="facilityclaims">
                <InfoIcon />
              </div>
              <CustomTooltip id="facilityclaims" placement="right">
                <span>
                  {i18n.t(
                    'These are the claims that were created under Claim Management'
                  )}
                </span>
              </CustomTooltip>
            </Label>
            <div>
              <TransferList
                fromPlaceholder={i18n.t('Search the facility claims')}
                items={facilityClaims || defaultClaimsList}
                handleChange={items => onChange('facilityClaims', items)}
              />
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default forwardRef(JourneyStepsFacility);
