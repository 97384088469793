export enum EJourneyActionTypes {
  GET_JOURNEYS = 'GET_JOURNEYS',
  GET_JOURNEYS_SUCCESS = 'GET_JOURNEYS_SUCCESS',
  GET_JOURNEYS_ERROR = 'GET_JOURNEYS_ERROR',

  CLEAN_JOURNEYS = 'CLEAN_JOURNEYS',

  SAVE_JOURNEY = 'SAVE_JOURNEY',
  SAVE_JOURNEY_SUCCESS = 'SAVE_JOURNEY_SUCCESS',
  SAVE_JOURNEY_ERROR = 'SAVE_JOURNEY_ERROR',

  SAVE_JOURNEY_TEMPLATE = 'SAVE_JOURNEY_TEMPLATE',
  SAVE_JOURNEY_TEMPLATE_SUCCESS = 'SAVE_JOURNEY_TEMPLATE_SUCCESS',
  SAVE_JOURNEY_TEMPLATE_ERROR = 'SAVE_JOURNEY_TEMPLATE_ERROR',

  GET_JOURNEY = 'GET_JOURNEY',
  GET_JOURNEY_SUCCESS = 'GET_JOURNEY_SUCCESS',
  GET_JOURNEY_ERROR = 'GET_JOURNEY_ERROR',

  GET_JOURNEY_TEMPLATES = 'GET_JOURNEY_TEMPLATES',
  GET_JOURNEY_TEMPLATES_SUCCESS = 'GET_JOURNEY_TEMPLATES_SUCCESS',
  GET_JOURNEY_TEMPLATES_ERROR = 'GET_JOURNEY_TEMPLATES_ERROR',

  GET_JOURNEY_TEMPLATE = 'GET_JOURNEY_TEMPLATE',
  GET_JOURNEY_TEMPLATE_SUCCESS = 'GET_JOURNEY_TEMPLATE_SUCCESS',
  GET_JOURNEY_TEMPLATE_ERROR = 'GET_JOURNEY_TEMPLATE_ERROR',

  DELETE_JOURNEY_TEMPLATE = 'DELETE_JOURNEY_TEMPLATE',
  DELETE_JOURNEY_TEMPLATE_SUCCESS = 'DELETE_JOURNEY_TEMPLATE_SUCCESS',
  DELETE_JOURNEY_TEMPLATE_ERROR = 'DELETE_JOURNEY_TEMPLATE_ERROR',

  DELETE_JOURNEY = 'DELETE_JOURNEY',
  DELETE_JOURNEY_SUCCESS = 'DELETE_JOURNEY_SUCCESS',
  DELETE_JOURNEY_ERROR = 'DELETE_JOURNEY_ERROR',

  RESET_CURRENT_JOURNEY_TEMPLATE = 'RESET_CURRENT_JOURNEY_TEMPLATE',

  ACTIVE_JOURNEY_STATUS = 'ACTIVE_JOURNEY_STATUS',
  ACTIVE_JOURNEY_STATUS_SUCCESS = 'ACTIVE_JOURNEY_STATUS_SUCCESS',
  ACTIVE_JOURNEY_STATUS_ERROR = 'ACTIVE_JOURNEY_STATUS_ERROR',
}
