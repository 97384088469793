import i18n from 'i18next';

const name = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
};

const description = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 350, message: i18n.t('Max 50 characters') },
};

const steps = {
  required: { value: true, message: i18n.t('Required field') },
};

const stepTemplates = () => {
  return {
    validate: {
      requiredFields: steps => {
        let valid = false;
        if (steps && steps.length) {
          valid = true;
          steps.forEach(s => {
            valid = valid && !!s.facilityId && !!s.facilityType;
          });
          valid =
            valid &&
            !!steps.find(
              s =>
                s.inboundAssetsInventory?.find(i => i.selected) ||
                s.inboundAssetsReceived?.find(i => i.selected) ||
                s.outboundAssetsInventory?.find(i => i.selected) ||
                s.outboundAssetsReceived?.find(i => i.selected)
            );
        }
        return !valid ? i18n.t('Required field') : undefined;
      },
    },
  };
};

const lotId = () => {
  return {
    validate: {
      requiredField: value => {
        return value === undefined || value === null || value.length === 0
          ? i18n.t('This field is required')
          : undefined;
      },
      startsWith: value => {
        return value[0] === ' '
          ? i18n.t('Cannot start with blank spaces')
          : undefined;
      },
      maxLength: value => {
        return value.length > 30 ? i18n.t('Max 30 characters') : undefined;
      },
      pattern: value => {
        const regex = /^[a-zA-Z0-9-\.\-\_\~]+$/;
        return !value.match(regex)
          ? `${i18n.t(
              'Only alpha numeric characters and the following special characters are allowed'
            )}: ${i18n.t(
              'dash (-), underscore (_), period (.), and tilde (~)'
            )}`
          : undefined;
      },
    },
  };
};

export default {
  name,
  description,
  steps,
  stepTemplates,
  lotId,
};
