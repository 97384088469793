import styled from 'styled-components';

const FacilityActionButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const FacilityDetailsContainer = styled.div`
  margin: 0 32px;
  padding-bottom: 60px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #757575;
`;

// DETAILS
const FacilityDetailsFormContainer = styled.div`
  > div {
    margin-top: 33px;
  }
  label {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    > div {
      margin-left: 10px;
    }
  }

  li label {
    margin-bottom: 0;
  }
  .checkbox-wrapper {
    margin-left: -11px;
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);

    > label {
      margin-bottom: 5px;
    }
  }
`;

// USERS
const FacilityUsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 32px 32px 32px;
  padding-bottom: 60px;

  > div.pageContainer {
    display: flex;
    label {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      > div {
        margin-left: 6px;
      }
    }

    .action-btn {
      display: flex;
      align-items: flex-end;
    }

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  .reset-link {
    cursor: pointer;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #A7BF2E;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
    text-align: right;
    justify-content: flex-end;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const FacilityUsersFormContainer = styled.form`
  flex: 1;
  margin-right: 10px;
  .row {
    margin: 33px 0 0 0;
  }
`;

const FacilityUsersListContainer = styled.div`
  flex: 0.4;
  .row {
    margin: 33px 0 0 0;
  }
`;

const FormSubHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 32px;
  margin: 0 -32px;
  background: #303030;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  label {
    color: #ffffff;
    > span {
      padding: 0 10px 0 0;
    }
  }
`;

const CoordinatesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FacilityMediaContainer = styled.div`
  margin: 35px 32px;

  label {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    > div {
      margin-left: 10px;
    }
  }

  .instructions {
    font-family: Encode Sans;
    font-style: normal;
    font-size: 14px;
    color: #757575;
  }

  .media-container {
    margin-bottom: 50px;

    .error-messages > div, .custom-link {
      margin-left: 0;
    }

    ul {
      padding: 0;
    }
  }
`;

// CLAIMS
const FacilityClaimsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 32px 32px 32px;
  padding-bottom: 60px;

  > div.pageContainer {
    display: flex;
    label {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      > div {
        margin-left: 6px;
      }
    }

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
`;

const FacilityClaimsFormContainer = styled.form`
  flex: 1;
  margin-right: 10px;
  .row {
    margin: 33px 0 0 0;
  }
`;

const FacilityClaimsListContainer = styled.div`
  flex: 0.4;
  .row {
    margin: 33px 0 0 0;
  }
`;

const FacilityClaimsTypeContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: rgb(167, 191, 46);
    }
  }
  > span {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #757575;
    margin-left: 5px;
  }
`;

const FacilityClaimsTextContainer = styled.div`
  min-height: 40px;

  ul {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #757575;
    list-style: none;
    padding: 0;
    line-height: 200%;
  }
`;

// REVIEW & SUBMIT
const FacilityReviewSubmitContainer = styled.div`
  padding: 40px 50px;
`;

const FacilityReviewSubmitItemsWrapper = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(117, 117, 117, 0.25);
`;

const FacilityReviewSubmitItemsContainer = styled.div`
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  &.quote-item > div:first-child {
    flex: 2;
  }
  &.contact-item {
    > div:nth-child(1), > div:nth-child(2), > div:nth-child(4) {
      flex: 2;
    }
    > div:nth-child(3) {
      flex: 1;
    }
  }
  &.secondary-contact-item {
    > div:nth-child(1) {
      flex: 2;
    }
    > div:nth-child(2) {
      flex: 1;
    }
    > div:nth-child(3) {
      flex: 3;
    }
  }

  .address-display {
    display: flex;
    flex-direction: column;
  }
`;

const FacilityReviewItemsListHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: #757575;
  margin-left: 20px;
  margin-right: 56px;

  > div {
    display: flex;
    align-items: center;
  }

  .clickable {
    cursor: pointer;
    transition: all 0.2 ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }
`;

const FacilityReviewSubmitMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 200%;
  }
  ul {
    padding: 0;
  }
  .promotionalDisclaimer {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      margin-left: 5px;
      background-color: #000;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      color: #d8f40c;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const FacilityReviewSubmitItemsListContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const FacilityReviewClaimsListContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  > div {
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #757575;
    transition: all 0.2s ease-in-out;
    margin-bottom: 10px;

    &:hover {
      color: #A7BF2E;
    }
  }
`;
////

export {
  FacilityActionButtonsContainer,
  FacilityDetailsFormContainer,
  FacilityDetailsContainer,
  FacilityUsersContainer,
  FacilityUsersFormContainer,
  FacilityUsersListContainer,
  FormSubHeader,
  CoordinatesWrapper,
  FacilityMediaContainer,
  FacilityClaimsContainer,
  FacilityClaimsFormContainer,
  FacilityClaimsListContainer,
  FacilityClaimsTypeContainer,
  FacilityClaimsTextContainer,
  FacilityReviewSubmitMediaContainer,
  FacilityReviewSubmitContainer,
  FacilityReviewSubmitItemsContainer,
  FacilityReviewSubmitItemsWrapper,
  FacilityReviewItemsListHeaderContainer,
  FacilityReviewSubmitItemsListContent,
  FacilityReviewClaimsListContainer,
};
