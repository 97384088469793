import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import {
  ContentBox,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
  Loading,
  CustomTooltip,
} from '../../../../Components';
import Toast from '../../../../Lib/Utils/toast';

import { ActionButtonsContainer } from './styles';

import Outbound from './Outbound';
import Inbound from './Inbound';
import Claims from './Claims';
import ReviewSubmit from './ReviewSubmit';

import { generateNewRow } from './helper';

interface Props {
  number: number;
  history?: any;
  match?: any;
  location?: any;
  parentData?: {
    outboundData: any;
    inboundData: any;
    claimsData: any;
    reviewSubmit: any;
  };
  saveData?: any;
  claims?: Array<any>;
  step?: any;
  lotId?: string;
  periodicClaims?: any;
  lotClaims?: any;
  onetimeClaims?: any;
  othersClaims?: any;
  doAddClaimVerificationAction?: any;
  addVerification?: any;
  doSaveAssetsAction?: any;
  doUpdateStepAction?: any;
  doDeleteJourneyAssetsAction?: any;
  assetsInput?: any;
  assetsOutput?: Array<any>;
  asset?: any;
  inputsSkuku?: number;
  outputsSkuku?: number;
  userFacilities?: Array<string>;
  nextChecked?: boolean;
  previousChecked?: boolean;
  previousOutputs?: Array<any> | null;
}

const tabs = [
  i18n.t('Claims'),
  i18n.t('Inbound'),
  i18n.t('Outbound'),
  i18n.t('Review & Submit'),
];

const defaultParentData = {
  outboundData: {},
  inboundData: {},
  claimsData: {},
  reviewSubmit: {},
};

const StepContent = ({
  number,
  history,
  match,
  location,
  parentData = defaultParentData,
  saveData,
  claims,
  step,
  lotId,
  periodicClaims,
  lotClaims,
  onetimeClaims,
  othersClaims,
  doAddClaimVerificationAction,
  addVerification,
  doSaveAssetsAction,
  assetsInput,
  assetsOutput,
  asset,
  inputsSkuku,
  outputsSkuku,
  doUpdateStepAction,
  userFacilities,
  nextChecked,
  previousChecked,
  doDeleteJourneyAssetsAction,
  previousOutputs,
}: Props) => {
  const { outboundData, inboundData, claimsData, reviewSubmit } = parentData;

  const [data, setData] = useState({
    outboundData,
    inboundData,
    claimsData,
    reviewSubmit,
  });

  const [tabIndex, setTabIndex] = useState(0);

  const periodicClaimsData = periodicClaims;
  const lotClaimsData = lotClaims;
  const onetimeClaimsData = onetimeClaims;
  const othersClaimsData = othersClaims;

  const loading =
    addVerification.loading || asset.loading || asset.loadingPreviousOutputs;

  const userFacility =
    userFacilities?.includes(step.facility._id) ||
    !!(userFacilities?.length === 0); // is no user facilities then user is org admin

  const onChange = (type: string, newValue: any) => {
    setData({ ...data, [type]: { ...data[type], ...newValue } });
  };

  useEffect(() => {
    if (
      previousOutputs &&
      assetsInput[0]?.assets?.length === 0 &&
      !data.inboundData?.tableFields
    ) {
      const editLocked = !userFacility || nextChecked;

      const newInbounds = {};

      previousOutputs.forEach((previousAsset, rowNumber) => {
        if (!newInbounds[previousAsset.templateDetails[0]?._id]) {
          newInbounds[previousAsset.templateDetails[0]?._id] = [];
        }

        newInbounds[previousAsset.templateDetails[0]?._id][rowNumber] = [];

        Object.keys(previousAsset.attributes).forEach((header, headerIndex) => {
          newInbounds[previousAsset.templateDetails[0]?._id][rowNumber][
            headerIndex
          ] = {};
          newInbounds[previousAsset.templateDetails[0]?._id][rowNumber][
            headerIndex
          ] = {
            disabled: editLocked,
            fromServer: false,
            header: header,
            value: previousAsset.attributes[header],
            rowNumber,
            subDocumentId: null,
            type: previousAsset.templateDetails[0]?.version[0][header]?.type,
            mandatory:
              previousAsset.templateDetails[0]?.version[0][header]?.mandatory,
            values:
              previousAsset.templateDetails[0]?.version[0][header]?.values,
          };
        });
      });

      const newData = {
        tableFields: newInbounds,
      };

      onChange('inboundData', newData);
    }
  }, [previousOutputs, loading]);

  useEffect(() => {
    const editLocked = !userFacility || nextChecked;

    let newOutboundData = null as any;
    let newInboundData = null as any;

    if (
      Object.entries(outboundData).length === 0 &&
      assetsOutput &&
      Object.entries(assetsOutput).length > 0
    ) {
      let newFieldsStatus = {};
      assetsOutput.forEach(asset => {
        const { assetTemplate, assets } = asset;
        const newFields = [] as any;
        assets.forEach((asset, i) => {
          const newRow = generateNewRow(
            i,
            asset.attributes,
            assetTemplate,
            asset._id,
            asset.subDocumentId,
            editLocked,
            null,
            null
          );
          newFields.push(newRow);
        });
        newFieldsStatus = {
          ...newFieldsStatus,
          [assetTemplate._id]: newFields,
        };
      });
      if (
        JSON.stringify(newFieldsStatus) !==
        JSON.stringify(data.outboundData.tableFields)
      ) {
        newOutboundData = { tableFields: newFieldsStatus };
      }
    }

    if (
      Object.entries(inboundData).length === 0 &&
      Object.entries(assetsInput).length > 0
    ) {
      let newFieldsStatus = {};
      assetsInput.forEach(asset => {
        const { assetTemplate, assets } = asset;
        const newFields = [] as any;
        assets.forEach((asset, i) => {
          const newRow = generateNewRow(
            i,
            asset.attributes,
            assetTemplate,
            asset._id,
            asset.subDocumentId,
            editLocked,
            null,
            null
          );
          newFields.push(newRow);
        });
        newFieldsStatus = {
          ...newFieldsStatus,
          [assetTemplate._id]: newFields,
        };
      });
      if (
        JSON.stringify(newFieldsStatus) !==
        JSON.stringify(data.inboundData.tableFields)
      ) {
        newInboundData = {
          tableFields: newFieldsStatus,
        };
      }
    }

    const newData = {
      ...data,
      outboundData: newOutboundData
        ? { ...data.outboundData, ...newOutboundData }
        : data.outboundData,
      inboundData: newInboundData
        ? { ...data.inboundData, ...newInboundData }
        : data.outboundData,
    };

    setData(newData);
  }, [assetsInput, assetsOutput]);

  useEffect(() => {
    if (tabIndex === 0) {
      if (addVerification.verification) {
        Toast.success(i18n.t('Your data has been successfully submitted.'));
      } else if (addVerification.error) {
        Toast.error(addVerification.error);
      }
    }
  }, [addVerification]);

  useEffect(() => {
    if (tabIndex === 1 || tabIndex === 2) {
      if (asset.submitSuccess) {
        Toast.success(i18n.t('Your data has been successfully submitted.'));
      } else if (asset.error) {
        Toast.error(asset.error);
      }
    }
  }, [asset]);

  useEffect(() => {
    if (tabIndex === 3) {
      if (asset.submitSuccess || addVerification.verification) {
        Toast.success(i18n.t('Your data has been successfully submitted.'));
        const { journeyTemplateId } = match?.params;
        history.push(`/journeys/${journeyTemplateId}/view`);
      } else if (asset.error || addVerification.error) {
        if (asset.error) {
          Toast.error(asset.error);
        }
        if (addVerification.error) {
          Toast.error(addVerification.error);
        }
      }
    }
  }, [asset, addVerification]);

  useEffect(() => {
    if (Object.keys(data.claimsData).length === 0) {
      const newClaimsData = {
        ...data.claimsData,
        periodicClaims: periodicClaimsData,
        lotClaimsData: lotClaimsData,
        onetimeClaimsData: onetimeClaimsData,
        othersClaimsData: othersClaimsData,
      };

      onChange('claimsData', newClaimsData);
    }
  }, [data]);

  const getDefaultActiveTab = () => {
    const query = new URLSearchParams(location.search);
    const tabFromQuery = query.get('tab');
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/[^a-zA-Z0-9]/g, '') === tabFromQuery
    );
    return findTabIndex !== -1 ? findTabIndex : 0;
  };

  const handleTabChange = index => {
    const tabName = tabs[index].replace(/[^a-zA-Z0-9]/g, '');
    const query = new URLSearchParams(location.search);
    const stepFromQuery = query.get('step');
    const stepSearch = stepFromQuery ? `&step=${stepFromQuery}` : '';
    history.push({
      search: `?tab=${tabName}${stepSearch}`,
    });
    setTabIndex(index);
  };

  useEffect(() => {
    if (location) {
      const newIndex = getDefaultActiveTab();
      if (tabIndex !== newIndex) handleTabChange(newIndex);
    }
  }, [location, tabIndex]);

  const handleBackButton = () => {
    handleTabChange(tabIndex - 1);
  };

  const handleNextButton = () => {
    handleTabChange(tabIndex + 1);
  };

  useEffect(() => {
    // saveData(number, data);
  }, [data]);

  const saveClaims = () => {
    const {
      periodicClaims = [],
      lotClaims = [],
      onetimeClaims = [],
      othersClaims = [],
    } = data.claimsData;

    const newClaims = []
      .concat(periodicClaims)
      .concat(lotClaims)
      .concat(onetimeClaims)
      .concat(othersClaims);

    newClaims &&
      newClaims.length > 0 &&
      newClaims.forEach(
        ({
          mediaLinks,
          supportingVerifications,
        }: {
          mediaLinks?: Array<any>;
          supportingVerifications?: Array<any>;
        }) => {
          if (mediaLinks && mediaLinks.length > 0) {
            const { _id } =
              supportingVerifications && supportingVerifications[0];
            const userFiles = mediaLinks.filter(link => link.user !== false);
            doAddClaimVerificationAction(_id, userFiles);
          }
        }
      );
  };

  const saveAssets = (inbound = true) => {
    let newAssets = [] as any;

    if (inbound) {
      if (data.inboundData.inboundMessage) {
        const updatedStep = {
          templateLocator: step.templateLocator,
          messages: {
            inbound: data.inboundData.inboundMessage,
            outbound: step.messages.outbound,
          },
        };
        userFacility && doUpdateStepAction(step._id, updatedStep);
      }

      newAssets = Object.keys(data.inboundData.tableFields || [])
        .map(key => {
          return (
            data.inboundData.tableFields &&
            data.inboundData.tableFields[key]
              .filter(row => (row ? row[0].disabled === false : false))
              .map(row => row.map(field => ({ ...field, template: key })))
          );
        })
        .flat()
        .map(row => {
          const attributes = row
            .map(({ header, value }) => ({ [header]: value }))
            .reduce((result, item) => {
              const key = Object.keys(item)[0];
              result[key] = item[key];
              return result;
            }, {});
          const templateId = row[0].template;
          const assetId = row[0].id;
          const skuku = assetsInput?.find(
            ({ assetTemplate }) => assetTemplate._id === templateId
          )?.assetTemplate?.skuku;
          return {
            skuku: skuku,
            lotId: lotId,
            version: 0,
            attributes,
            assetId,
          };
        });

      if (newAssets.length) {
        userFacility && doSaveAssetsAction(newAssets, true, step._id);
      }

      if (data.inboundData.deletedRows) {
        const assetsToDelete = data.inboundData.deletedRows.map(
          ({ subDocumentId }) => ({
            stepId: step._id,
            subDocumentId: subDocumentId,
          })
        );
        doDeleteJourneyAssetsAction(assetsToDelete, true);
      }
    } else {
      if (data.outboundData.outboundMessage) {
        const updatedStep = {
          templateLocator: step.templateLocator,
          messages: {
            outbound: data.outboundData.outboundMessage,
            inbound: step.messages.inbound,
          },
        };
        userFacility && doUpdateStepAction(step._id, updatedStep);
      }

      newAssets = Object.keys(data.outboundData.tableFields || [])
        .map(key => {
          return data.outboundData.tableFields[key]
            .filter(row => row[0].disabled === false)
            .map(row => row.map(field => ({ ...field, template: key })));
        })
        .flat()
        .map(row => {
          const attributes = row
            .map(({ header, value }) => ({ [header]: value }))
            .reduce((result, item) => {
              const key = Object.keys(item)[0];
              result[key] = item[key];
              return result;
            }, {});
          const templateId = row[0].template;
          const assetId = row[0].id;
          const skuku = assetsOutput?.find(
            ({ assetTemplate }) => assetTemplate._id === templateId
          ).assetTemplate.skuku;
          return {
            skuku: skuku,
            lotId: lotId,
            version: 0,
            attributes,
            assetId,
          };
        });

      if (newAssets.length) {
        userFacility && doSaveAssetsAction(newAssets, false, step._id);
      }

      if (data.outboundData.deletedRows) {
        const assetsToDelete = data.outboundData.deletedRows.map(
          ({ subDocumentId }) => ({
            stepId: step._id,
            subDocumentId: subDocumentId,
          })
        );
        doDeleteJourneyAssetsAction(assetsToDelete, false);
      }
    }
  };

  const saveAction = () => {
    if (previousChecked) {
      if (tabIndex === 0) {
        saveClaims();
      }

      if (tabIndex === 1) {
        saveAssets(true);
      }

      if (tabIndex === 2) {
        saveAssets(false);
      }

      if (tabIndex === 3) {
        if (
          assetsOutput &&
          assetsOutput.length > 0 &&
          Object.values(data.outboundData.tableFields || {}).flat().length === 0
        ) {
          Toast.error(
            i18n.t(
              'You must capture data for all OUTPUT assets in order to complete the submission.'
            )
          );
        } else {
          saveClaims();
          if (assetsInput && assetsInput.length > 0) saveAssets(true);
          if (assetsOutput && assetsOutput.length > 0) saveAssets(false);
          doUpdateStepAction(step._id, { complete: true });
        }
      }
    } else {
      Toast.error(
        i18n.t(
          'You cannot submit until the previous facility completes data entry.'
        )
      );
    }
  };

  return (
    <>
      <ContentBox>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={handleTabChange}
          initialSelectedIndex={getDefaultActiveTab()}
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Tab key={index}>{tab}</Tab>
            ))}

            <ActionButtonsContainer>
              {tabIndex > 0 && (
                <Button
                  text={i18n.t('Back')}
                  type="secondary"
                  action={handleBackButton}
                  disabled={loading}
                />
              )}
              <div data-tip data-for="submit">
                <Button
                  text={tabIndex === 3 ? i18n.t('Submit') : i18n.t('Save')}
                  type="secondary"
                  action={saveAction}
                  disabled={
                    loading ||
                    (tabIndex === 3
                      ? data.reviewSubmit.certified
                        ? data.reviewSubmit.certified === false
                        : true
                      : false)
                  }
                />
              </div>
              {!data.reviewSubmit.certified && tabIndex === tabs.length - 1 && (
                <CustomTooltip id="submit">
                  <span>{i18n.t('You must agree to the certification.')}</span>
                </CustomTooltip>
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  text={i18n.t('Save & Next')}
                  type="secondary"
                  action={handleNextButton}
                  disabled={loading}
                />
              )}
            </ActionButtonsContainer>
          </TabList>

          <TabPanel>
            <Claims
              data={data.claimsData}
              onTabsEnd={handleNextButton}
              onChange={onChange}
              periodicClaims={periodicClaims}
              lotClaims={lotClaims}
              onetimeClaims={onetimeClaims}
              othersClaims={othersClaims}
              loading={loading}
            />
          </TabPanel>
          <TabPanel>
            {loading ? (
              <Loading />
            ) : (
              <Inbound
                dataProp={data}
                data={data.inboundData}
                onChange={onChange}
                message={step.messages.inbound}
                lotId={lotId}
                assets={assetsInput}
                userFacility={userFacility}
                locked={nextChecked}
              />
            )}
          </TabPanel>
          <TabPanel>
            <Outbound
              data={data.outboundData}
              onChange={onChange}
              message={step.messages.outbound}
              lotId={lotId}
              assets={assetsOutput}
              userFacility={userFacility}
              locked={nextChecked}
            />
          </TabPanel>
          <TabPanel>
            <ReviewSubmit
              data={data}
              messages={step.messages}
              onChange={onChange}
              assetsInput={assetsInput}
              assetsOutput={assetsOutput}
              lotId={lotId}
            />
          </TabPanel>
        </Tabs>
      </ContentBox>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(withTranslation()(StepContent as any) as any);
