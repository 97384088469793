import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps, useStore } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
import { Claim } from '../../../Lib/Utils/Validations';
import Toast from '../../../Lib/Utils/toast';
import moment from 'moment';
import { ObjectID } from 'bson';

import {
  ClaimIcon,
  ContentBox,
  Title,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
  Loading,
  PopUp,
} from '../../../Components';

import { ClaimActionButtonsContainer } from './styles';

import ClaimDetails from './ClaimDetails';
import ClaimReviewSubmit from './ClaimReviewSubmit';
import ClaimMapping from './ClaimMapping';
import ClaimVerification from './ClaimVerification';

import { doGetAttestors } from '../../../Redux/Attestor/attestorActions';
import { doGetFacilities } from '../../../Redux/Facility/facilityActions';

import {
  doSaveClaim,
  doGetClaim,
  doClearClaims,
} from '../../../Redux/Claim/claimActions';

interface RootState {
  claim: any;
  attestor: any;
  journey: any;
  facility: any;
}

interface InheritProps {
  showTitle?: boolean;
  onSave?: Function;
  claimId?: string;
  useStore?: Array<string>;
  draft?: boolean;
}

const mapState = (state: RootState) => ({
  claim: state.claim.currentClaim,
  loadingClaim: state.claim.loading,
  errorClaim: state.claim.error,
  loadingClaims: state.claim.loadingClaims,
  submitSuccess: state.claim.submitSuccess,
  saveSuccess: state.claim.saveSuccess,

  attestors: state.attestor.attestors,

  facilities: state.facility.facilities,
  facilitiesLoading: state.facility.loading,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  saveClaimAction: (claim, verification, actionType, notifySuccess) =>
    dispatch(doSaveClaim(claim, verification, actionType, notifySuccess)),
  getAttestorsAction: (payload = null) => dispatch(doGetAttestors(payload)),
  getFacilitiesAction: () => dispatch(doGetFacilities()),
  getClaimAction: (claimId, actionType) =>
    dispatch(doGetClaim(claimId, actionType)),
  clearClaims: () => dispatch(doClearClaims()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  InheritProps & { history?: any; location?: any; match?: any };

const NewClaim = (props: Props) => {
  const {
    saveClaimAction,
    history,
    match,
    location,
    showTitle = true,
    onSave,
    getAttestorsAction,
    attestors,
    getFacilitiesAction,
    facilities,
    facilitiesLoading,
    submitSuccess,
    saveSuccess,
    errorClaim,
    loadingClaim,
    useStore = [],
    getClaimAction,
    claim,
    claimId,
    draft,
    clearClaims,
  } = props;

  const isDraft = draft != null ? draft : !!match.path.match('/draft');
  const entityId = match.params.claimId || claimId;
  const loading = facilitiesLoading || loadingClaim;
  const claimName = (claim && claim.claimName) || '';

  const [data, setData] = useState({
    claimName,
    claimShortName: '',
    claimType: '',
    claimBullets: '',
    selectedVerificationOption: 0,
    attestorFrequency: 0,
    isAttestorNoEndDate: false,
    attestorPeriodEndDate: null,
    attestorPeriodStartDate: null,
    attestorInfo: {
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      link: '',
      _id: '',
    },
    attestorText: '',
    attestorFrequencyDays: '',
    facilityId: '',
    _id: null,
  } as any);

  const [tabIndex, setTabIndex] = useState(0);
  const [popUpShow, setPopUpShow] = useState(false);

  const formMethods = useForm({ mode: 'onBlur' });

  useEffect(() => {
    entityId && getClaimAction(entityId, isDraft ? 'get-draft' : 'get-entity');

    // will be called on component unmount
    return () => {
      clearClaims();
    };
  }, []);

  const attestorFrequencyOptions = {
    'one-time': 0,
    journey: 1,
    periodic: 2,
  };

  useEffect(() => {
    if (claim) {
      const newVerificationData = {
        selectedVerificationOption: 0,
        attestorFrequency: 0,
        attestorPeriodStartDate: '',
        attestorPeriodEndDate: '',
        attestorFrequencyDays: 0,
        attestorSelect: '',
        attestorText: '',
        links: [] as any,
        isAttestorNoEndDate: false,
      };

      if (
        claim.supportingVerifications &&
        claim.supportingVerifications.length > 0
      ) {

        const supportingVerifications = claim.supportingVerifications[0];

        newVerificationData.selectedVerificationOption =
          supportingVerifications.type === 'certification' ? 2 : 1;
        newVerificationData.attestorFrequency =
          attestorFrequencyOptions[supportingVerifications.renewalType];
        if (supportingVerifications.links?.length) {
          newVerificationData.links = supportingVerifications.links.map(p => ({
            src: p,
          }));
        }

        if (supportingVerifications.type === 'endorsement') {
          newVerificationData.attestorSelect = supportingVerifications.attestor;
          newVerificationData.attestorText =
            supportingVerifications.attestorStatement;
        }

        if (supportingVerifications.renewalType === 'periodic') {
          newVerificationData.attestorPeriodStartDate = moment(
            supportingVerifications.nextTriggerDate
          ).format('MM/DD/YYYY');

          newVerificationData.attestorFrequencyDays =
            supportingVerifications.renewalPeriod / 86400;
          if (supportingVerifications.lastTriggerDate === null) {
            newVerificationData.isAttestorNoEndDate = true;
            newVerificationData.attestorPeriodEndDate = "";
          } else {
            newVerificationData.attestorPeriodEndDate = moment(
              supportingVerifications.lastTriggerDate
            ).format('MM/DD/YYYY');
          }
        }
      }


      setData({
        ...data,
        ...newVerificationData,
        claimName: claim.name,
        claimShortName: claim.shortName,
        claimType: claim.type,
        claimBullets: claim.description,
        facilityId: claim.facilityId,
        _id: claim._id,
      });
    }
  }, [claim]);

  useEffect(() => {
    if (submitSuccess) {
      Toast.success(i18n.t('Your data has been successfully submitted.'));
      // If onSave exists then this component is controlled as child and should not redirect
      if (onSave) {
        history.push({
          search: '',
        });
        onSave(entityId, true);
      } else {
        history.push(`/claims`);
      }
    } else if (saveSuccess) {
      Toast.success(i18n.t('Your data has been successfully saved.'));
    } else if (errorClaim) {
      Toast.error(errorClaim);
    }
  }, [errorClaim, submitSuccess, saveSuccess]);

  const tabs = [
    i18n.t('Overview'),
    i18n.t('Verification'),
    i18n.t('Mapping'),
    isDraft || !entityId ? i18n.t('Review & Submit') : i18n.t('Review & Save'),
  ];

  useEffect(() => {
    const hash = location.hash;
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/\s/g, '') === hash.replace('#', '')
    );
    const tabIndex = findTabIndex !== -1 ? findTabIndex : 0;
    setTabIndex(tabIndex);

    getAttestorsAction();
    if (!useStore.includes('facilities')) {
      getFacilitiesAction();
    }
  }, []);

  const handleChange = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const getDefaultActiveTab = () => {
    const query = new URLSearchParams(location.search);
    const tabFromQuery = query.get('tab');
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/[^a-zA-Z0-9]/g, '') === tabFromQuery
    );
    return findTabIndex !== -1 ? findTabIndex : 0;
  };

  const handleTabChange = index => {
    const tabName = tabs[index].replace(/[^a-zA-Z0-9]/g, '');
    const query = new URLSearchParams(location.search);
    const stepFromQuery = query.get('step');
    const stepSearch = stepFromQuery ? `&step=${stepFromQuery}` : '';
    history.push({
      search: `?tab=${tabName}${stepSearch}`,
    });
    setTabIndex(index);
  };

  const handleBackButton = () => {
    saveClaim(false);
    handleTabChange(tabIndex - 1);
  };

  const handleNextButton = () => {
    saveClaim(false);
    handleTabChange(tabIndex + 1);
  };

  useEffect(() => {
    if (location) {
      const newIndex = getDefaultActiveTab();
      if (tabIndex !== newIndex) handleTabChange(newIndex);
    }
  }, [location, tabIndex]);

  const validate = async () => {
    const {
      isAttestorNoEndDate,
      attestorPeriodEndDate,
      attestorPeriodStartDate,
      selectedVerificationOption,
      attestorFrequency,
    } = data;

    formMethods.register({ name: 'claimShortName' }, Claim.shortName);
    formMethods.register({ name: 'claimName' }, Claim.shortName);
    formMethods.register({ name: 'claimType' }, Claim.claimType);
    formMethods.register({ name: 'claimBullets' }, Claim.bullets);

    if (selectedVerificationOption && selectedVerificationOption !== 0) {
      if (selectedVerificationOption === 1) {
        formMethods.register({ name: 'attestorSelect' }, Claim.attestor);
        formMethods.register({ name: 'attestorText' }, Claim.attestorText);
      }

      if (attestorFrequency == 2) {
        formMethods.register(
          { name: 'attestorFrequencyDays' },
          Claim.frequency
        );
        formMethods.register(
          { name: 'attestorPeriodStartDate' },
          Claim.startDate(isAttestorNoEndDate, attestorPeriodEndDate)
        );
        formMethods.register(
          { name: 'attestorPeriodEndDate' },
          Claim.endDate(isAttestorNoEndDate, attestorPeriodStartDate)
        );
      }
    }

    formMethods.register({ name: 'facilityId' }, Claim.facilityId);

    formMethods.setValue(
      Object.keys(data).map(key => ({
        [key]: data[key],
      }))
    );

    const result = await formMethods.triggerValidation();

    if (!result) {
      setData({ ...data, validate: result });
    }

    return result;
  };

  const getMappedData = () => {
    const {
      _id,
      claimName,
      claimShortName,
      claimType,
      claimBullets,
      selectedVerificationOption,
      facilityId,
    } = data;

    const claimMappedData = {
      _id: _id || entityId || new ObjectID().toString(),
      name: claimName,
      shortName: claimShortName,
      type: claimType,
      description:
        typeof claimBullets === 'object'
          ? claimBullets
          : claimBullets?.split('\n'),
      verifiable: selectedVerificationOption !== 0,
      facilityId: facilityId,
    };
    return claimMappedData;
  };

  const getVerificationMappedData = () => {
    const {
      isAttestorNoEndDate,
      attestorPeriodEndDate,
      attestorPeriodStartDate,
      selectedVerificationOption,
      attestorFrequency,
      attestorInfo,
      attestorText,
      attestorFrequencyDays,
      links,
    } = data;

    let verificationMappedData = {};

    const renewalTypes = ['one-time', 'journey', 'periodic'];

    if (selectedVerificationOption !== 0) {
      verificationMappedData = {
        renewalType: renewalTypes[attestorFrequency],
        type:
          selectedVerificationOption === 1 ? 'endorsement' : 'certification',
        links,
      };

      if (selectedVerificationOption === 1) {
        verificationMappedData = {
          ...verificationMappedData,
          issuer: {
            name: `${attestorInfo?.firstName} ${attestorInfo?.lastName}`,
            company: attestorInfo.company,
            email: attestorInfo.email,
            link: attestorInfo.link,
          },
          attestor: attestorInfo._id,
          attestorStatement: attestorText,
        };
      }

      if (attestorFrequency === 2) {
        verificationMappedData = {
          ...verificationMappedData,
          renewalPeriod: attestorFrequencyDays * 86400,
          nextTriggerDate: attestorPeriodStartDate,
          lastTriggerDate: isAttestorNoEndDate ? '' : attestorPeriodEndDate,
        };
      }
    }
    return verificationMappedData;
  };

  const saveClaim = async (notifySuccess?: boolean) => {
    // Name must be entered at minimum
    if (!data.claimName) {
      Toast.error(i18n.t('Must enter claim name/title to save.'));
      handleTabChange(0);
      return;
    }

    if (!isDraft) {
      const result = await validate();
      if (!result) {
        Toast.error(i18n.t('There are items that require your attention.'));
        return;
      }
    }

    const actionType = isDraft
      ? data._id
        ? 'save-draft'
        : 'create-draft'
      : 'save-entity';
    const claimMappedData = getMappedData();
    const verificationMappedData = getVerificationMappedData();
    saveClaimAction(
      claimMappedData,
      verificationMappedData,
      actionType,
      notifySuccess
    );
    onSave && onSave(claimMappedData._id);
  };

  const submitClaim = async () => {
    const result = await validate();
    if (result) {
      const claimMappedData = getMappedData();
      const verificationMappedData = getVerificationMappedData();
      const actionType =
        isDraft || !entityId ? 'submit-create-entity' : 'submit-save-entity';
      saveClaimAction(
        claimMappedData,
        verificationMappedData,
        actionType,
        false
      );
    } else {
      Toast.error(i18n.t('There are items that require your attention.'));
    }
  };

  const showPopUp = () => {
    setPopUpShow(true);
  };

  const confirmPopUp = async () => {
    setPopUpShow(false);
    if (!data.claimName) {
      Toast.error(i18n.t('Must enter claim name/title to save.'));
      handleTabChange(0);
      return;
    }

    if (!isDraft) {
      const result = await validate();
      if (!result) {
        Toast.error(i18n.t('There are items that require your attention.'));
        return;
      }
    }
    const actionType = isDraft
      ? data._id
        ? 'save-draft'
        : 'create-draft'
      : 'save-entity';
    const claimMappedData = getMappedData();
    const verificationMappedData = getVerificationMappedData();
    saveClaimAction(claimMappedData, verificationMappedData, actionType, true);
    history.push(`/claims/attestors/draft`);
  };

  return (
    <>
      {showTitle && (
        <Title
          title={i18n.t('New Claim')}
          subtitle={data.claimName}
          icon={<ClaimIcon />}
        />
      )}
      <ContentBox>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={handleTabChange}
          initialSelectedIndex={getDefaultActiveTab()}
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Tab key={index}>{tab}</Tab>
            ))}

            <ClaimActionButtonsContainer>
              {tabIndex > 0 && (
                <Button
                  text={i18n.t('Back')}
                  type="secondary"
                  action={handleBackButton}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  text={i18n.t('Save')}
                  type="secondary"
                  action={() => saveClaim(true)}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  action={handleNextButton}
                  text={i18n.t('Save & Next')}
                  type="secondary"
                />
              )}
              {tabIndex === tabs.length - 1 && (
                <Button
                  action={() => submitClaim()}
                  text={
                    isDraft || !entityId ? i18n.t('Submit') : i18n.t('Save')
                  }
                  type="secondary"
                />
              )}
            </ClaimActionButtonsContainer>
          </TabList>

          <FormContext {...formMethods}>
            <TabPanel>
              <ClaimDetails
                onChange={(name, value) => handleChange(name, value)}
                formMethods={formMethods}
                {...data}
              />
            </TabPanel>
            <TabPanel>
              <ClaimVerification
                onChange={(name, value) => handleChange(name, value)}
                formMethods={formMethods}
                {...data}
                attestors={attestors}
                showPopUp={showPopUp}
              />
            </TabPanel>
            <TabPanel>
              <ClaimMapping
                onChange={(name, value) => handleChange(name, value)}
                formMethods={formMethods}
                {...data}
                facilities={facilities.filter(f => !f.isDraft)}
              />
            </TabPanel>
            <TabPanel>
              <ClaimReviewSubmit
                onChange={(name, value) => handleChange(name, value)}
                formMethods={formMethods}
                {...data}
                facilities={facilities}
                isDraft={isDraft}
                entityId={entityId}
                embedded={!!onSave}
              />
            </TabPanel>
          </FormContext>
        </Tabs>
      </ContentBox>
      {loading && <Loading top={300} />}
      {popUpShow && (
        <PopUp
          title={i18n.t('Save progress and add attestor?')}
          content={
            <div className="d-flex flex-column">
              <p>
                {i18n.t(
                  'Do you want to save your journey wizard progress and be redirected to create an attestor?'
                )}
              </p>
              <p>
                {i18n.t(
                  'You will be able to navigate back to this "in progress" journey template and resume the journey wizard.'
                )}
              </p>
            </div>
          }
          cancelText={i18n.t('Cancel')}
          confirmText={i18n.t('Yes')}
          confirmAction={confirmPopUp}
          cancelAction={() => setPopUpShow(false)}
          show={popUpShow}
        />
      )}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(NewClaim) as any) as any
) as any;
