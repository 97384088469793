import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

interface Props {
  name: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: Function;
}

const HtmlCheckbox = withStyles(() => ({
  root: {
    color: '#757575',
    '&$checked': {
      color: '#757575',
    },
  },
  checked: {},
}))(Checkbox);

const CustomCheckbox = ({
  name,
  checked = false,
  disabled,
  onChange = () => {},
  ...rest
}: Props) => {
  return (
    <HtmlCheckbox
      checked={checked}
      name={name}
      disabled={disabled}
      color="default"
      onChange={evt => onChange(evt)}
      {...rest}
    />
  );
};

export default CustomCheckbox;
