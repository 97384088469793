import React from 'react';

export default () => (
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0V14L11 7L0 0Z" fill="#D8F40C" />
  </svg>
);
