import styled from 'styled-components';

const Content = styled.div`
  width: fit-content;
  text-transform: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Clickable = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: fit-content;
  filter: ${({ disabled }) => disabled && 'grayscale(100%)'};
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  label {
    align-items: center;
  }
`;

const QRIcon = styled.img`
  width: 20px;
  background-color: white;
`;

export { Container, Clickable, Content, CheckboxContainer, QRIcon };
