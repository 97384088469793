import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';
import { Campaign } from '../../../../Lib/Utils/Validations';

import {
  AdvertFormContainer as FormContainer,
  AdvertContainer as Container,
  FormSubHeader,
} from '../styles';

import {
  Label,
  FormInput,
  Checkbox,
  DatePicker,
  ItemsList,
  PopUp,
  Button,
  MediaUpload,
} from '../../../../Components';

import { Advert } from './IAdvert';

const generateTitle = item => {
  const startDate = item.advertStartDate
    ? item.advertStartDate instanceof Date
      ? item.advertStartDate.toLocaleDateString('en-US')
      : new Date(item.advertStartDate).toLocaleDateString('en-US')
    : '';

  const endDate = item.advertEndDate
    ? item.advertEndDate instanceof Date
      ? item.advertEndDate.toLocaleDateString('en-US')
      : new Date(item.advertEndDate).toLocaleDateString('en-US')
    : '';

  return (
    <div className="row w-100 d-flex justify-content-center align-items-center">
      <div className="col-6 text-truncate">{`${item.advertName || ''}`}</div>
      <div className="col-3 font-weight-light text-truncate text-center">{`${startDate ||
        ''}`}</div>
      <div className="col-3 font-weight-light text-truncate text-center">{`${
        item.advertHasEndDate ? i18n.t('Does not expire') : endDate || ''
      }`}</div>
    </div>
  );
};

const initAdvert = {
  advertName: '',
  advertDescription: '',
  advertStartDate: null,
  advertEndDate: null,
  advertHasEndDate: false,
  advertMedia: [],
};

interface Props {
  onChange: Function;
  advertItems?: any;
  endDate?: string;
  startDate?: string;
  noEndDate?: boolean;
  campaignAdvertFirstLoad?: boolean;
}

const CampaignAdvert = ({ onChange: onChangeFromProp, ...data }: Props) => {
  const {
    advertItems = [],
    startDate,
    endDate,
    noEndDate,
    campaignAdvertFirstLoad,
  } = data;
  const {
    watch,
    errors,
    clearError,
    register,
    control,
    setValue,
    getValues,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [items, setItems] = useState<any>([]);

  const [currentAdvert, setCurrentAdvert] = useState<Advert>(initAdvert);
  const [currentAdvertIndex, setCurrentAdvertIndex] = useState(-1);
  const [popUpShow, setPopUpShow] = useState(false);
  const [popupNewItems, setPopupNewItems] = useState<any>([]);

  const convertedItems =
    items &&
    items.map(item => {
      return {
        title: generateTitle(item),
        data: item,
      };
    });

  const {
    advertName,
    advertDescription,
    advertStartDate,
    advertEndDate,
    advertHasEndDate,
    advertMedia,
  } = currentAdvert;

  useEffect(() => {
    campaignAdvertFirstLoad === false && triggerValidation();
    onChangeFromProp('campaignAdvertFirstLoad', false);
  }, []);

  useEffect(() => {
    setValue([
      { advertName: advertName },
      { advertDescription: advertDescription },
      { advertStartDate: advertStartDate },
      { advertEndDate: advertEndDate },
      { advertHasEndDate: advertHasEndDate },
      { advertMedia: advertMedia },
    ]);
  }, [
    advertName,
    advertDescription,
    advertStartDate,
    advertEndDate,
    advertHasEndDate,
    advertMedia,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    campaignAdvertFirstLoad === false &&
      triggerValidation(['advertEndDate', 'advertStartDate']);
  }, [advertHasEndDate]);

  useEffect(() => {
    if (JSON.stringify(advertItems) !== JSON.stringify(items)) {
      setItems(advertItems);
    }
  }, [advertItems]);

  const onChange = (key: string, value: any) => {
    if (currentAdvert[key] !== value && key !== '') {
      const newValue = value && value.length === 0 ? initAdvert[key] : value;
      setCurrentAdvert({ ...currentAdvert, [key]: newValue });
    }
  };

  const currentItem = currentAdvert && {
    title: generateTitle(currentAdvert),
    data: currentAdvert,
  };

  const addNewItem = async event => {
    event.preventDefault();

    const result = await triggerValidation();

    if (result) {
      if (currentAdvertIndex === -1) {
        const newItems = [...items, currentItem.data];
        setItems(newItems);
        setCurrentAdvert(initAdvert);
        onChangeFromProp('advertItems', newItems);
      } else {
        const newItems = [...items];
        newItems.splice(currentAdvertIndex, 1, currentItem.data);
        setItems(newItems);
        setCurrentAdvert(initAdvert);
        onChangeFromProp('advertItems', newItems);
      }
      setCurrentAdvertIndex(-1);
      resetForm(null);
    }
  };

  const handleActiveAction = (item, index) => {
    setCurrentAdvert(item);
    setCurrentAdvertIndex(index);
  };

  const resetForm = event => {
    event && event.preventDefault();
    clearError();
    setCurrentAdvert({ ...initAdvert, advertMedia: [] });
    setCurrentAdvertIndex(-1);
  };

  const popUpConfirmAction = () => {
    if (popupNewItems) {
      setItems(popupNewItems);
    }
    setPopUpShow(false);
    resetForm(null);
  };

  const handleDeleteAction = index => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setPopUpShow(true);
    setPopupNewItems(newItems);
  };

  const selectedAdvert = items[currentAdvertIndex] ? true : false;

  const withErrors = Object.keys(errors).length !== 0;

  return (
    <Container>
      <FormSubHeader>
        <div className="mr-2">
          {i18n.t('Add one or more advertisements to the campaign')}
        </div>
      </FormSubHeader>

      <FormContainer id="AdvertForm">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
              <div className="col-md-7 col-sm-12">
                <Label>{i18n.t('Advertisement name')}</Label>

                <Controller
                  as={<FormInput required placeholder={i18n.t('Enter name')} maxLength={50} />}
                  name="advertName"
                  value={advertName}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.value);
                    return evt.target.value;
                  }}
                  rules={Campaign.advertName}
                  defaultValue={advertName}
                />
                {errors.advertName && (
                  <span className="inlineErrorMessage">
                    {errors.advertName.message}.
                  </span>
                )}
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <Label>{i18n.t('Advertisement description')}</Label>

                <Controller
                  as={
                    <FormInput
                      required
                      placeholder={i18n.t('Enter description')}
                      maxLength={75}
                    />
                  }
                  name="advertDescription"
                  value={advertDescription}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.value);
                    return evt.target.value;
                  }}
                  rules={Campaign.advertDescription}
                  defaultValue={advertDescription}
                />
                {errors.advertDescription && (
                  <span className="inlineErrorMessage">
                    {errors.advertDescription.message}.
                  </span>
                )}
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <Label>
                  {i18n.t('What is the validity period of this advertisement?')}
                </Label>
                <div className="row">
                  <div className="col-lg-4 col-md-12">
                    <Label>{i18n.t('Start date')}</Label>

                    <Controller
                      as={
                        <DatePicker
                          placeholder={i18n.t('Select')}
                          required
                          value={advertStartDate}
                          onChange={value => onChange('advertStartDate', value)}
                        />
                      }
                      name="advertStartDate"
                      value={advertStartDate}
                      control={control}
                      rules={Campaign.customAdvertStartDate(
                        startDate,
                        endDate,
                        noEndDate,
                        getValues
                      )}
                      onChange={([value]) => {
                        onChange('advertStartDate', value);
                        triggerValidation('advertStartDate');
                        return value;
                      }}
                      defaultValue={advertStartDate}
                    />
                    {errors.advertStartDate && (
                      <span className="inlineErrorMessage">
                        {errors.advertStartDate.message}.
                      </span>
                    )}
                  </div>
                  <div
                    className="col-lg-4 col-md-12"
                    style={{ opacity: !advertHasEndDate ? 1 : 0.5 }}
                  >
                    <Label>{i18n.t('End date')}</Label>

                    <Controller
                      as={
                        <DatePicker
                          placeholder={i18n.t('Select')}
                          required={!advertHasEndDate}
                          disabled={advertHasEndDate ? advertHasEndDate : false}
                          value={advertEndDate}
                          onChange={value => onChange('advertEndDate', value)}
                        />
                      }
                      name="advertEndDate"
                      inputRef={register}
                      value={advertEndDate}
                      control={control}
                      rules={Campaign.customAdvertEndtDate(
                        startDate,
                        endDate,
                        noEndDate,
                        getValues
                      )}
                      onChange={([value]) => {
                        onChange('advertEndDate', value);
                        triggerValidation('advertEndDate');
                        return value;
                      }}
                      defaultValue={advertEndDate}
                    />
                    {errors.advertEndDate && (
                      <span className="inlineErrorMessage">
                        {errors.advertEndDate.message}.
                      </span>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="checkbox-wrapper">
                      <Label className="light-label">
                        <Controller
                          as={
                            <Checkbox
                              name="advertHasEndDate"
                              disabled={!noEndDate}
                              checked={
                                advertHasEndDate ? advertHasEndDate : false
                              }
                              onChange={evt =>
                                onChange(evt.target.name, evt.target.checked)
                              }
                            />
                          }
                          name="advertHasEndDate"
                          inputRef={register}
                          value={advertHasEndDate}
                          control={control}
                          onChange={([evt]) => {
                            onChange('advertHasEndDate', evt.target.checked);
                            return evt.target.checked;
                          }}
                          defaultValue={advertHasEndDate}
                        />
                        {errors.advertHasEndDate && (
                          <span className="inlineErrorMessage">
                            {errors.advertHasEndDate.message}.
                          </span>
                        )}

                        {i18n.t('No end date')}
                      </Label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <Label>
                  {i18n.t('* Add one or more images for this advertisement')}
                </Label>
                <MediaUpload
                  accept={['image/png', 'image/jpg', 'image/jpeg']}
                  maxFileSize={2 * 1024 * 1024} // 2 MB
                  showLink={false}
                  proloadedItemsLinks={advertMedia ? advertMedia : []}
                  handleChange={items => onChange('advertMedia', items)}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12 d-flex justify-content-end">
                <Button text={i18n.t('Clear')} action={resetForm} />
                <Button
                  text={selectedAdvert ? i18n.t('Save') : i18n.t('Add')}
                  disabled={withErrors}
                  type="dark"
                  action={addNewItem}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mt-lg-0 mt-md-5">
            <Label>{i18n.t('Advert list')}</Label>
            <div className="row w-100 d-flex justify-content-center align-items-center listColumnHeaders">
              <div className="col-6"></div>
              <div className="col-3 text-truncate text-center">
                {i18n.t('Start Date')}
              </div>
              <div className="col-3 text-truncate text-center">
                {i18n.t('Expiry date')}
              </div>
            </div>
            <ItemsList
              items={convertedItems}
              defaultActiveIndex={currentAdvertIndex}
              deleteAction={handleDeleteAction}
              activeAction={handleActiveAction}
            />
          </div>
        </div>
      </FormContainer>

      <PopUp
        title={i18n.t('Delete Advert?')}
        content={i18n.t('Deleting the advert cannot be undone.')}
        cancelText={i18n.t('No')}
        confirmText={i18n.t('Yes')}
        confirmAction={popUpConfirmAction}
        cancelAction={() => setPopUpShow(false)}
        show={popUpShow}
      />
    </Container>
  );
};
export default CampaignAdvert;
