import React from 'react';

export default () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7275 7.00004H6.8184V11.9091H5.18204V7.00004H0.272949V5.36368H5.18204V0.45459H6.8184V5.36368H11.7275V7.00004Z"
      fill="white"
    />
  </svg>
);
