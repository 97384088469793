import React from 'react';

export default (props: { color?: string }) => (
  <svg
    width="33"
    height="40"
    viewBox="0 0 33 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2487 29.4001L21.4431 27.8001L24.6375 29.4001V18.2001H18.2487V29.4001ZM24.6375 10.2001V7.0001L21.4431 8.6001L18.2487 7.0001V10.2001L15.0542 11.8001L18.2487 13.4001V16.6001L21.4431 15.0001L24.6375 16.6001V13.4001L27.8319 11.8001L24.6375 10.2001ZM29.4292 0.600098H3.87375C3.02653 0.600098 2.21402 0.937239 1.61495 1.53736C1.01588 2.13747 0.679321 2.95141 0.679321 3.8001V19.8001C0.679321 20.6488 1.01588 21.4627 1.61495 22.0628C2.21402 22.663 3.02653 23.0001 3.87375 23.0001H15.0542V19.8001H3.87375V3.8001H29.4292V19.8001H27.8319V23.0001H29.4292C30.2764 23.0001 31.0889 22.663 31.688 22.0628C32.287 21.4627 32.6236 20.6488 32.6236 19.8001V3.8001C32.6236 2.95141 32.287 2.13747 31.688 1.53736C31.0889 0.937239 30.2764 0.600098 29.4292 0.600098ZM15.0542 8.6001H5.47096V5.4001H15.0542V8.6001ZM11.8598 13.4001H5.47096V10.2001H11.8598V13.4001ZM15.0542 18.2001H5.47096V15.0001H15.0542V18.2001Z"
      fill={props.color ? props.color : "#A7BF2E" }
    />
  </svg>
);
