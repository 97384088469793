import React, { useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { JourneyStepsInboundContainer as Container } from '../styles';
import {
  Label,
  FormErrorLabel,
  CustomTooltip,
  Checkbox,
  TransferList,
  InfoIcon,
} from '../../../../Components';

const previousAssetsDefault = [
  {
    _id: 'aaa',
    text: 'Bife de Vacio',
    selected: false,
    tooltip: {
      title: 'From 12/07/15 to 05/20/20',
      items: [
        'International Standard for a quality management system',
        'Demonstrates continuous improvement',
        'Requires re-certification every three years',
      ],
    },
  },
  {
    _id: 'aaa',
    text: 'Bife Ancho',
    selected: false,
    tooltip: {
      title: 'From 12/07/15 to 05/20/20',
      items: [
        'International Standard for a quality management system',
        'Demonstrates continuous improvement',
        'Requires re-certification every three years',
      ],
    },
  },
  {
    _id: 'aaa',
    text: 'Bife Angosto',
    selected: false,
    tooltip: {
      title: 'From 12/07/15 to 05/20/20',
      items: [
        'International Standard for a quality management system',
        'Demonstrates continuous improvement',
        'Requires re-certification every three years',
      ],
    },
  },
  {
    _id: 'aaa',
    text: 'Bife de Chorizo',
    selected: false,
    tooltip: {
      title: 'From 12/07/15 to 05/20/20',
      items: [
        'International Standard for a quality management system',
        'Demonstrates continuous improvement',
        'Requires re-certification every three years',
      ],
    },
  },
];

const assetInventoryDefault = [
  {
    _id: 'aaa',
    text: 'Juan Valdez - Pallet of Products',
    selected: false,
    tooltip: {
      title: 'From 12/07/15 to 05/20/20',
      items: [
        'International Standard for a quality management system',
        'Demonstrates continuous improvement',
        'Requires re-certification every three years',
      ],
    },
  },
  {
    _id: 'aaa',
    text: 'Juan Valdez - Bags of Products',
    selected: false,
    tooltip: {
      title: 'From 12/07/15 to 05/20/20',
      items: [
        'International Standard for a quality management system',
        'Demonstrates continuous improvement',
        'Requires re-certification every three years',
      ],
    },
  },
];

interface Props {
  onChange: Function;
  noOutboundAssets?: boolean;
  showOutboundReceivedDate?: boolean;
  isLastStep?: boolean;
  outboundPreviousAssets?: Array<any>;
  outboundAssetInventory?: Array<any>;
}

const JourneyStepsFacility = ({
  onChange,
  noOutboundAssets,
  showOutboundReceivedDate,
  isLastStep,
  outboundPreviousAssets = previousAssetsDefault,
  outboundAssetInventory = assetInventoryDefault,
}: Props) => {
  const { errors, control, register, setValue, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setValue([
      { noOutboundAssets: noOutboundAssets },
      { showOutboundReceivedDate: showOutboundReceivedDate },
      { outboundPreviousAssets: outboundPreviousAssets },
      { outboundAssetInventory: outboundAssetInventory },
    ]);
  }, [
    noOutboundAssets,
    showOutboundReceivedDate,
    outboundPreviousAssets,
    outboundAssetInventory,
  ]);

  return (
    <Container>
      {!noOutboundAssets && (
        <FormErrorLabel className="mb-4">
          *{' '}
          {isLastStep
            ? i18n.t(
                'You must have at least one marketable product as an outbound asset'
              )
            : i18n.t('You must have at least one outbound asset')}
          .
        </FormErrorLabel>
      )}
      <div className="row">
        {!isLastStep && (
          <div className="col-12 col-lg-6">
            <div className="checkbox-wrapper">
              <Label>
                <Controller
                  as={Checkbox}
                  name="noOutboundAssets"
                  control={control}
                  defaultValue={noOutboundAssets}
                  checked={noOutboundAssets}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.checked);
                    return evt.target.checked;
                  }}
                />

                {i18n.t('This step has no outbound assets')}
                <div data-tip data-for="nooutbound">
                  <InfoIcon />
                </div>
                <CustomTooltip id="nooutbound" placement="right">
                  <span>
                    {i18n.t('Selecting this will not require the supply chain')}
                  </span>
                </CustomTooltip>
              </Label>
            </div>
          </div>
        )}
        {!isLastStep && (
          <div className="col-12 col-lg-6">
            <div className="checkbox-wrapper">
              <Label disabled={noOutboundAssets}>
                <Controller
                  as={Checkbox}
                  disabled={noOutboundAssets}
                  name="showOutboundReceivedDate"
                  control={control}
                  defaultValue={showOutboundReceivedDate}
                  checked={showOutboundReceivedDate}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.checked);
                    return evt.target.checked;
                  }}
                />

                {i18n.t('Show sent date of outbound assets')}
                <div data-tip data-for="nooutbound">
                  <InfoIcon />
                </div>
                <CustomTooltip id="nooutbound" placement="right">
                  <span>{i18n.t('For every step in the product journey')}</span>
                </CustomTooltip>
              </Label>
            </div>
          </div>
        )}
        <div className="col-12">
          <Label disabled={noOutboundAssets}>
            {isLastStep
              ? i18n.t(
                  'Select the marketable product(s) that will use this product journey'
                )
              : i18n.t(
                  'Select the outbound assets that this facility produces'
                )}
            <div data-tip data-for="marketable">
              <InfoIcon />
            </div>
            <CustomTooltip id="marketable" placement="right">
              <span>
                {isLastStep
                  ? i18n.t(
                      'The product/s that will be higlighted by this journey'
                    )
                  : i18n.t('This allows assets to be passed directly')}
              </span>
            </CustomTooltip>
          </Label>
          <div>
            <TransferList
              disabled={noOutboundAssets}
              fromPlaceholder={
                isLastStep
                  ? i18n.t('Search for marketable products')
                  : i18n.t('Search for assets from step inputs')
              }
              items={outboundPreviousAssets}
              handleChange={items => onChange('outboundPreviousAssets', items)}
            />
          </div>
        </div>
        {!isLastStep && (
          <div className="col-12">
            <div>
              <TransferList
                disabled={noOutboundAssets}
                fromPlaceholder={i18n.t(
                  'Search for assets from asset inventory'
                )}
                items={outboundAssetInventory}
                handleChange={items =>
                  onChange('outboundAssetInventory', items)
                }
              />
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default JourneyStepsFacility;
