import styled from 'styled-components';

const Container = styled.div`
  padding-top: 33px;
  width: 200px;
  border-right: 1px solid #000000;
`;

const List = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  /* height: 100vh; */
`;

const Item = styled.li`
  padding-right: 20px;
  height: 35px;
  margin-bottom: 10px;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  & .itemControls {
    display: none;
  }
  &.active(:not(.lastItem)) {
    ::after {
      content: '';
      z-index: -1;
      position: absolute;
      width: 100%;
      right: 0;
      border-bottom: 1px solid #000;
    }
    & .itemControls {
      display: block;
    }
  }
`;

const ItemName = styled.p`
  flex: 1;
  height: 35px;
  align-items: center;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
  color: #b6b6b6;
  padding-right: 30px;
  margin: 14px -20px 14px 0;
  .addStep > & {
    color: #000;
  }
  .lastStep > & {
    color: #b6b6b6;
  }
  .active > & {
    background-color: #000000;
    color: #fff;
  }
`;

const ItemIcon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 35px;
  background: #d8f40c;
  color: #a7bf2e;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 18px;
  & path {
    fill: #a7bf2e;
  }
  .lastStep > & {
    background-color: #b6b6b6;
    color: #fff;
    & path {
      fill: #fff;
    }
  }
  .active > & {
    background-color: #000;
    color: #fff;
    & path {
      fill: #fff;
    }
  }
  .addStep > & {
    background-color: #000;
    color: #fff;
    & path {
      fill: #fff;
    }
  }
`;

const ParrallelLine = styled.div`
  position: absolute;
  left: -15px;
  height: calc(200% - 21px);
  width: 5px;
  background-color: black;
  top: calc(-100% + 3px);
  ::before {
    top: 0;
    height: 5px;
    content: '';
    background-color: black;
    width: 15px;
    left: 0;
    position: absolute;
  }
  ::after {
    bottom: 0;
    height: 5px;
    content: '';
    background-color: black;
    width: 15px;
    left: 0;
    position: absolute;
  }
`;

export { Container, List, Item, ItemName, ItemIcon, ParrallelLine };
