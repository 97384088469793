import React, { useState } from 'react';
import {
  TextPrimary,
  TextBorder,
  TextRoundBorder,
  InputContainer,
  TextAreaPrimary,
  TextAreaBorder,
  Chars,
  Password,
  PasswordButton,
  IconContainer,
} from './styles';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

type StyleType = 'primary' | 'border' | 'roundBorder';

interface Props {
  placeholder?: string;
  type?: string;
  subType?: string;
  name?: string;
  onChange?: Function;
  required?: boolean;
  showChars?: boolean;
  maxLength?: number;
  styleType?: StyleType;
  value?: any;
  defaultValue?: string;
  icon?: any;
  disabled?: boolean;
  ref?: any;
  min?: number;
  max?: number;
  autoFocus?: boolean;
  id?: string;
}

const FormInput = ({
  type = 'text',
  subType,
  placeholder,
  name,
  required,
  maxLength = 500,
  showChars = true,
  styleType = 'primary',
  disabled = false,
  onChange = () => {},
  icon,
  ref,
  ...rest
}: Props) => {
  const [charsQty, setCharsQty] = useState(0);
  const [visible, setVisisble] = useState(false);

  const inputHandler = evt => {
    onChange && onChange(evt);
    setCharsQty(evt.target.value.length);
  };

  let inputProps = {
    type: subType ? subType : type,
    name,
    placeholder,
    disabled,
    onChange: evt => onChange(evt),
    ref,
    ...rest,
  };

  if (type === 'text' || type === 'number') {
    return (
      <InputContainer className={icon ? 'icon' : ''}>
        {required && <span>*</span>}
        {
          {
            primary: <TextPrimary maxLength={maxLength} {...inputProps} />,
            border: <TextBorder maxLength={maxLength} {...inputProps} />,
            roundBorder: (
              <TextRoundBorder maxLength={maxLength} {...inputProps} />
            ),
          }[styleType]
        }
        {icon && <IconContainer>{icon()}</IconContainer>}
      </InputContainer>
    );
  }

  if (type === 'password') {
    return (
      <InputContainer>
        {required && <span>*</span>}
        <Password
          {...inputProps}
          type={visible ? 'text' : 'password'}
          style={{ paddingRight: '30px' }}
        />
        <PasswordButton onClick={() => setVisisble(!visible)}>
          {visible ? <MdVisibilityOff /> : <MdVisibility />}
        </PasswordButton>
      </InputContainer>
    );
  }

  inputProps = {
    ...inputProps,
    onChange: evt => inputHandler(evt),
    placeholder: required ? `*  ${placeholder}` : placeholder,
  };
  return (
    <InputContainer className="textarea">
      {
        {
          primary: (
            <TextAreaPrimary
              {...inputProps}
              maxLength={maxLength}
              required={required}
            />
          ),
          border: (
            <TextAreaBorder
              {...inputProps}
              maxLength={maxLength}
              required={required}
            />
          ),
        }[styleType]
      }
      {showChars && <Chars>{`${charsQty}/${maxLength}`}</Chars>}
    </InputContainer>
  );
};

export default FormInput;
