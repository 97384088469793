import { ClaimTypes } from '../../Components/CustomIcons';

const ClaimTypesIcons: Array<{ value: string; label: string; icon?: any }> = [
  {
    value: 'Nutrition_Animal',
    label: 'Animal Nutrition',
    icon: ClaimTypes.AnimalNutritionIcon,
  },
  {
    value: 'Certificate',
    label: 'Certificate',
    icon: ClaimTypes.CertificationIcon,
  },
  {
    value: 'Environment',
    label: 'Environment',
    icon: ClaimTypes.EnvironmentIcon,
  },
  { value: 'Safety_Food', label: 'Safety Food', icon: ClaimTypes.SafetyIcon },
  { value: 'Fresh', label: 'Fresh', icon: ClaimTypes.FreshIcon },
  { value: 'Health', label: 'Health', icon: ClaimTypes.HealthIcon },
  {
    value: 'Hormone_Free',
    label: 'Hormone Free',
    icon: ClaimTypes.HormoneFreeIcon,
  },
  { value: 'ISO', label: 'ISO', icon: ClaimTypes.ISOIcon },
  { value: 'Soft', label: 'Soft', icon: ClaimTypes.SoftnessIcon },
  { value: 'Taste', label: 'Taste', icon: ClaimTypes.TasteIcon },
  {
    value: 'Temperature',
    label: 'Temperature',
    icon: ClaimTypes.TemperatureIcon,
  },
  { value: 'Tender', label: 'Tender', icon: ClaimTypes.TenderIcon },
  { value: 'Vaccine', label: 'Vaccine', icon: ClaimTypes.VaccineIcon },
  {
    value: 'Welfare_Animal',
    label: 'Welfare Animal',
    icon: ClaimTypes.WelfareIcon,
  },
];
export default ClaimTypesIcons;
