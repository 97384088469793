import React, { useState, useEffect } from 'react';
import Button from '../Button';
import { MdClose } from 'react-icons/md';
import { Alert } from '../CustomIcons';

import {
  Container,
  Frame,
  CloseButton,
  Header,
  Content,
  ButtonsContainer,
  ButtonContainer,
} from './styles';

interface Props {
  show?: boolean;
  title?: string;
  content?: any;
  cancelText?: string;
  confirmText?: string;
  cancelAction?: any;
  confirmAction?: any;
  negativeText?: string;
  negativeAction?: any;
  showAlertIcon?: boolean;
  closeOnConfirm?: boolean;
  enterConfirm?: boolean;
}

const PopUp = ({
  show = false,
  title = 'Are you sure you want to clear this section?',
  content = 'By confirming this action you will not be able to recover unsaved changes....',
  cancelText = 'Cancel',
  cancelAction,
  confirmText,
  confirmAction,
  negativeText,
  negativeAction,
  showAlertIcon = true,
  closeOnConfirm = true,
  enterConfirm = false,
}: Props) => {
  const [showPopUp, setShowPopup] = useState(show);

  useEffect(() => {
    enterConfirm && document.addEventListener('keydown', handleKeyPress, false);

    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, []);

  useEffect(() => {
    setShowPopup(show);
  }, [show]);

  const handleCancelAction = () => {
    cancelAction && cancelAction();
    setShowPopup(false);
  };

  const handleConfirmAction = () => {
    confirmAction && confirmAction();
    closeOnConfirm && setShowPopup(false);
  };

  const handleNegativeAction = () => {
    negativeAction && negativeAction();
    closeOnConfirm && setShowPopup(false);
  };

  const handleClose = () => {
    cancelAction && cancelAction();
    setShowPopup(false);
  };

  const buttonsWidth = confirmText && negativeText ? 100 : 150;

  const handleKeyPress = event => {
    if (event.keyCode == 13 && enterConfirm) {
      handleConfirmAction();
    }
  };

  return (
    <Container showPopUp={showPopUp}>
      <Frame>
        <CloseButton onClick={handleClose}>
          <MdClose size="16px" />
        </CloseButton>
        <Header>
          {showAlertIcon && (
            <div>
              <Alert />
            </div>
          )}
          <div>{title}</div>
        </Header>
        <Content>{content}</Content>
        <ButtonsContainer>
          <ButtonContainer>
            <Button
              text={cancelText}
              action={handleCancelAction}
              type="grayOutline"
              width={buttonsWidth}
            />
          </ButtonContainer>
          {confirmText && (
            <ButtonContainer>
              <Button
                text={confirmText}
                action={handleConfirmAction}
                width={buttonsWidth}
              />
            </ButtonContainer>
          )}
          {negativeText && (
            <ButtonContainer>
              <Button
                text={negativeText}
                action={handleNegativeAction}
                width={buttonsWidth}
              />
            </ButtonContainer>
          )}
        </ButtonsContainer>
      </Frame>
    </Container>
  );
};

export default PopUp;
