import React from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

import { ClaimsShow, Label } from '../../../../../Components';

import { Container, Content } from '../styles';

interface Props {
  data: any;
  onChange?: any;
  claims?: Array<any>;
}

const Others = ({ data, onChange, claims }: Props) => {
  return (
    <Container>
      <Content>
        <div className="row">
          <div className="col-12">
            {claims && claims.length > 0 && (
              <Label className="mb-5">
                {i18n.t('These are non-verifiable claims.')}
              </Label>
            )}
            <ClaimsShow
              titleLink={false}
              titleTooltip={false}
              claims={claims}
              notExpires={true}
              noClaimsMessage={i18n.t(
                'There are no non-verifiable claims for this journey.'
              )}
            />
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default withTranslation()(Others as any) as any;
