import { EStepActionTypes } from './stepActionsType';

const initialState = {
  loading: false,
  error: null,
  stepTemplate: null,
};

interface State {
  loading: boolean;
  error?: string | null;
  stepTemplate?: any;
}

interface StepAction extends State {
  type: EStepActionTypes;
  payload: any;
}

type TStepReducerActions = StepAction;

export default function(
  state: State = initialState,
  action: TStepReducerActions
) {
  switch (action.type) {
    case EStepActionTypes.SAVE_STEP:
      return { ...state, loading: true };
    case EStepActionTypes.SAVE_STEP_SUCCESS:
      return { ...state, loading: false };
    case EStepActionTypes.SAVE_STEP_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    case EStepActionTypes.GET_STEP_TEMPLATE:
      return { ...state, loading: true };
    case EStepActionTypes.GET_STEP_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        stepTemplate: action.payload.stepTemplate,
      };
    case EStepActionTypes.GET_STEP_TEMPLATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        stepTemplate: null,
      };

    default:
      return state;
  }
}
