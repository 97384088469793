import React, { useState, useEffect } from 'react';
import i18n from 'i18next';

import {
  VerificationFormContainer as FormContainer,
  VerificationContainer as Container,
} from '../styles';

import {
  Label,
  InfoIcon,
  CustomTooltip,
  Checkbox,
} from '../../../../Components';

import Content from './Content';

interface Props {
  onChange: Function;
  selectedVerificationOption?: number;
  attestors?: Array<any>;
  formMethods?: any;
  showPopUp?: any;
}

const ClaimVerification = ({
  onChange,
  attestors,
  formMethods,
  showPopUp,
  ...data
}: Props) => {
  const { selectedVerificationOption = 0 } = data;
  const [checkboxSelected, setCheckboxSelected] = useState(
    selectedVerificationOption
  );

  useEffect(() => {
    if (selectedVerificationOption != checkboxSelected) {
      setCheckboxSelected(selectedVerificationOption);
    }
  }, [selectedVerificationOption]);

  const handleCheckboxClick = value => {
    setCheckboxSelected(value);
    onChange('selectedVerificationOption', value);
  };

  return (
    <Container>
      <FormContainer>
        <div className="row">
          <div className="col-12">
            <Label>
              {i18n.t('How will this claim will be verified?')}
              <div data-tip data-for="verified">
                <InfoIcon />
              </div>
              <CustomTooltip id="verified" placement="right">
                <span>
                  {i18n.t(
                    'Claims that are "verifiable" will be marked as such for the end consumer and the corresponding verification evidence will be exposed to the end consumer.'
                  )}
                </span>
              </CustomTooltip>
            </Label>
            <div className="inline-checkbox">
              <div className="checkbox-wrapper">
                <Label>
                  <Checkbox
                    onChange={() => handleCheckboxClick(0)}
                    checked={checkboxSelected === 0}
                    name="isNotVerificable"
                  />
                  {i18n.t('This claim is not verifiable')}
                </Label>
              </div>

              <div className="checkbox-wrapper">
                <Label>
                  <Checkbox
                    onChange={() => handleCheckboxClick(1)}
                    checked={checkboxSelected === 1}
                    name="is3partyAttestation"
                  />
                  {i18n.t('Use 3rd party attestation')}
                </Label>
              </div>

              <div className="checkbox-wrapper">
                <Label>
                  <Checkbox
                    onChange={() => handleCheckboxClick(2)}
                    checked={checkboxSelected === 2}
                    name="isSelfUpload"
                  />
                  {i18n.t('Self upload of evidence')}
                </Label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <Content
            checkboxSelected={checkboxSelected}
            onChange={onChange}
            {...data}
            attestors={attestors}
            formMethods={formMethods}
            showPopUp={showPopUp}
          />
        </div>
      </FormContainer>
    </Container>
  );
};

export default ClaimVerification;
