import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { BsFillGearFill } from 'react-icons/bs';
import Select, { components } from 'react-select';
import { DropdownIndicatorIcon } from '..';

interface Props {
  options: Array<{ label: string; action: Function; value: string; }>;
}

const Container = styled.div`
  display: inline-block;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Poppins',
    color: '#757575',
    backgroundColor: state.isFocused ? '#efefef' : 'none',
    minHeight: 30,
    fontSize: 14,
    cursor: 'pointer'
  }),
  indicatorSeparator: () => {},
  control: () => ({
    display: 'none',
  }),
  valueContainer: provided => ({ ...provided, paddingLeft: 0 }),
  menu: provided => ({
    ...provided,
    marginTop: '0',
    borderRadius: '0 0 8px 8px',
    background: '#F9F9FB',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    width: '150px',
    right: 0,
    top: '10px',
  }),
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorIcon />
    </components.DropdownIndicator>
  );
};

const Settings = ({ options }: Props) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('click', handleClickOutside, true);
    };
});

  const handleClickOutside = (event: Event) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  const handleChange = (option) => {
    if (option && option.action) {
      option.action();
    }
  }

  return (
    <Container onClick={() => setOpen(!open)} ref={selectRef}>
      <IconContainer>
        <BsFillGearFill color="white" size={24} />
      </IconContainer>
      <Select
        menuIsOpen={open}
        onChange={handleChange}
        options={options}
        styles={customStyles}
        components={{ DropdownIndicator }}
      />
    </Container>
  );
};

export default Settings;
