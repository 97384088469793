import * as attestorService from '../../Services/Api/attestorService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EAttestorActionTypes } from './attestorActionsTypes';
import { GetAttestorActionType, SaveAttestorActionType } from '../../Services/Api/attestorService/types';

const getAttestors = createAction(EAttestorActionTypes.GET_ATTESTORS);
const getAttestorsSuccess = createAction(
  EAttestorActionTypes.GET_ATTESTORS_SUCCESS,
  (attestors: any) => ({ attestors })
);
const getAttestorsError = createAction(
  EAttestorActionTypes.GET_ATTESTORS_ERROR,
  (message: string) => ({ message })
);

const saveAttestor = createAction(EAttestorActionTypes.SAVE_ATTESTOR);
const saveAttestorSuccess = createAction(
  EAttestorActionTypes.SAVE_ATTESTOR_SUCCESS,
  (
    attestor: any,
    isDraft?: boolean,
    submitted?: boolean,
    notifySuccess?: boolean
  ) => ({ attestor, isDraft, submitted, notifySuccess })
);
const saveAttestorError = createAction(
  EAttestorActionTypes.SAVE_ATTESTOR_ERROR,
  (message: string) => ({ message })
);


const getAttestor = createAction(EAttestorActionTypes.GET_ATTESTOR);
const getAttestorSuccess = createAction(
  EAttestorActionTypes.GET_ATTESTOR_SUCCESS,
  (attestor: any) => ({ attestor })
);
const getAttestorError = createAction(
  EAttestorActionTypes.GET_ATTESTOR_ERROR,
  (message: string) => ({ message })
);

const clearAttestor = createAction(EAttestorActionTypes.CLEAR_ATTESTOR);

const deleteAttestor = createAction(
  EAttestorActionTypes.DELETE_ATTESTOR,
  (attestorId: any) => ({ attestorId })
);
const deleteAttestorSuccess = createAction(
  EAttestorActionTypes.DELETE_ATTESTOR_SUCCESS,
  (payload: any) => payload
);
const deleteAttestorError = createAction(
  EAttestorActionTypes.DELETE_ATTESTOR_ERROR,
  (message: string) => ({ message })
);

export const doGetAttestors = (
  payload = null,
  includeDrafts?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getAttestors());
  return await tap(
    attestorService.getAttestors(payload, includeDrafts),
    attestors => {
      dispatch(getAttestorsSuccess(attestors));
    },
    error => {
      const _message = 'Failed to retrieve Attestors. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getAttestorsError(message));
    }
  );
};

export const doSaveAttestor = (
  attestor,
  actionType: SaveAttestorActionType,
  notifySuccess?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveAttestor());
  return await tap(
    attestorService.saveAttestor(attestor, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the facility _id
      }
      dispatch(
        saveAttestorSuccess(
          data,
          isDraft,
          !!actionType.match('submit'),
          notifySuccess
        )
      );
    },
    error => {
      const _message = 'Failed to save Attestor. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveAttestorError(message));
    }
  );
};


export const doGetAttestor = (
  attestorId,
  actionType: GetAttestorActionType
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getAttestor());
  return await tap(
    attestorService.getAttestor(attestorId, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the attestor _id
      }
      dispatch(getAttestorSuccess(data));
    },
    error => {
      const _message = 'Failed to retrieve Attestor. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getAttestorError(message));
    }
  );
};

export const doClearAttestor = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action<string>
> => async dispatch => {
  dispatch(clearAttestor());
};

export const doDeleteAttestor = (
  attestorId: string,
  email: string,
  isDraft: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(deleteAttestor(attestorId));
  return await tap(
    attestorService.deleteAttestor(attestorId, email, isDraft),
    response => {
      dispatch(deleteAttestorSuccess(response.data));
    },
    error => {
      const _message = 'Failed to delete Journey. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(deleteAttestorError(message));
    }
  );
};
