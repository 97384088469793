import i18n from 'i18next';

const name = {
  required: { value: true, message: i18n.t('This field is required') },
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
};

const type = {
  required: { value: true, message: i18n.t('This field is required') },
};

const visibility = {
  required: { value: true, message: i18n.t('Required field') },
};

export default {
  name,
  type,
  visibility,
};
