import React from 'react';

export default (props: { color?: string }) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.45448 13.0909V14.7273H5.72721V13.0909H2.45448ZM2.45448 9.81818V11.4545H10.6363V9.81818H2.45448ZM7.36357 13.0909V14.7273H10.6363V13.0909H7.36357ZM12.2727 9.81818V11.4545H15.5454V9.81818H12.2727ZM12.2727 13.0909V14.7273H15.5454V13.0909H12.2727ZM0.818115 16.3636V4.90909L4.90902 8.18182V4.90909L8.99993 8.18182V4.90909L13.0908 8.18182L13.909 0H16.3636L17.1818 8.18182V16.3636H0.818115Z"
      fill={props.color ? props.color : "#A7BF2E" }
    />
  </svg>
);
