import styled from 'styled-components';

interface Props {
  withBorderRadius?: boolean;
  backgroundColor?: string;
}

const Container = styled.div<Props>`
  ${({ backgroundColor, withBorderRadius }) => `

    .react-tabs__tab-list {
      color: white;
      background: ${
        backgroundColor
          ? backgroundColor
          : 'radial-gradient(50% 25628.48% at 50% 0%, #3A3A3A 0%, #000000 100%)'
      };
      height: 50px;
      ${
        withBorderRadius
          ? 'border-top-right-radius: 20px;'
          : 'border-top-right-radius: 0;'
      }
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      padding: 0 30px;
      border-bottom: none;
      margin-bottom: 0;
    }

    .react-tabs__tab {
      background: transparent;
      border: none;
      color: rgba(255,255,255, 0.5);
      margin-right: 20px;
      padding: 0;
    }

    .react-tabs__tab *{
      opacity: 0.5;
    }

    .react-tabs__tab--selected {
      opacity: 1;
      border-bottom: 5px solid #D7F30A;
      padding: 14px 0 9px 0;
      color: rgba(255,255,255, 1);
    }

    .react-tabs__tab--selected *{
      opacity: 1;
    }

    .react-tabs__tab--disabled { opacity: 0.3; padding: 14px 0 15px 0; * { opacity:1 } }

  `}
`;

export { Container };
