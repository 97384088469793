import React from 'react';

export default (props: { color?: string }) => (
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.01312 6.4H10.2075V25.6H7.01312V6.4ZM11.8048 6.4H13.402V25.6H11.8048V6.4ZM14.9992 6.4H19.7908V25.6H14.9992V6.4ZM21.388 6.4H22.9852V25.6H21.388V6.4ZM26.1797 6.4H29.3741V25.6H26.1797V6.4ZM30.9713 6.4H32.5685V25.6H30.9713V6.4ZM3.81869 3.2V9.6H0.624268V3.2C0.624268 2.35131 0.960822 1.53737 1.55989 0.937258C2.15896 0.337142 2.97148 0 3.81869 0H10.2075V3.2H3.81869ZM35.7629 0C36.6102 0 37.4227 0.337142 38.0217 0.937258C38.6208 1.53737 38.9574 2.35131 38.9574 3.2V9.6H35.7629V3.2H29.3741V0H35.7629ZM3.81869 22.4V28.8H10.2075V32H3.81869C2.97148 32 2.15896 31.6629 1.55989 31.0627C0.960822 30.4626 0.624268 29.6487 0.624268 28.8V22.4H3.81869ZM35.7629 28.8V22.4H38.9574V28.8C38.9574 29.6487 38.6208 30.4626 38.0217 31.0627C37.4227 31.6629 36.6102 32 35.7629 32H29.3741V28.8H35.7629Z"
      fill={props.color ? props.color : "#A7BF2E" }
    />
  </svg>
);
