import React, { useEffect } from 'react';
import i18n from 'i18next';
import { JourneyReviewSubmitContainer as Container } from './styles';
import { FacilityTypeIcons } from '../../../Lib/Configs';
import { EditableItem, JourneyStepsPreview } from '../../../Components';

interface Props {
  onChange: Function;
  formMethods: any;
  steps?: any;
  journeyName?: string;
  JourneyDescription?: string;
  journeySteps?: any;
}

const formatSteps = journeySteps => {
  return journeySteps.map(step => {
    const newStep = {
      ...step,
      step: { ...step.step },
      inboundAssets: { ...step.inboundAssets, list: [] },
      outboundAssets: { ...step.outboundAssets, list: [] },
      queryLink: `?tab=JourneySteps&step=${step.number}`,
      status: step.data.isActiveFacility
        ? i18n.t('Active')
        : i18n.t('Inactive'),
    };

    if (step.data) {
      if (step.data.facilityClaims) {
        newStep.step.claims = step.data.facilityClaims
          .filter(({ selected }) => selected)
          .map(claim => ({
            ...claim,
            name: claim.text,
          }));
      }

      if (step.data.selectedFacilityType) {
        newStep.name = step.data.selectedFacilityType;
        newStep.icon = FacilityTypeIcons[step.data.selectedFacilityType];
      }

      if (step.data.selectedFacility) {
        newStep.value = step.data.selectedFacility.registeredName;
        newStep.step.description = step.data.selectedFacility.description;
      }

      if (step.data.showInboundReceivedDate) {
        newStep.inboundAssets.showReceivedDate =
          step.data.showInboundReceivedDate;
      } else {
        newStep.inboundAssets.showReceivedDate = false;
      }

      if (step.data.showOutboundReceivedDate) {
        newStep.outboundAssets.showReceivedDate =
          step.data.showOutboundReceivedDate;
      } else {
        newStep.outboundAssets.showReceivedDate = false;
      }

      if (step.data.assetInventory) {
        newStep.inboundAssets.list = newStep.inboundAssets.list.concat(
          step.data.assetInventory
            .filter(({ selected }) => selected)
            .map(({ text }) => text)
        );
      }

      if (step.data.previousAssets) {
        newStep.inboundAssets.list = newStep.inboundAssets.list.concat(
          step.data.previousAssets
            .filter(({ selected }) => selected)
            .map(({ text }) => text)
        );
      }

      if (step.data.outboundAssetInventory) {
        newStep.outboundAssets.list = newStep.outboundAssets.list.concat(
          step.data.outboundAssetInventory
            .filter(({ selected }) => selected)
            .map(({ text }) => text)
        );
      }

      if (step.data.outboundPreviousAssets) {
        newStep.outboundAssets.list = newStep.outboundAssets.list.concat(
          step.data.outboundPreviousAssets
            .filter(({ selected }) => selected)
            .map(({ text }) => text)
        );
      }
    }

    return newStep;
  });
};

const JourneyReviewSubmit = ({ onChange, formMethods, ...data }: Props) => {
  const { errors, clearError } = formMethods;

  useEffect(() => {
    clearError();
  }, []);

  const journeyNameError = !!errors.journeyName;
  const journeyDescriptionError = !!errors.JourneyDescription;
  const journeyStepsError = !!errors.journeySteps;

  const { journeyName, JourneyDescription, journeySteps } = data;

  const stepsWithQueryLinks = journeySteps && formatSteps(journeySteps);

  // Render
  return (
    <Container>
      <EditableItem
        title={i18n.t('Jorney name')}
        incomplete={journeyNameError}
        linkTo="/journeys/new?tab=JourneyDetails"
        content={<strong>{journeyName}</strong>}
      />
      <EditableItem
        title={i18n.t('Journey description')}
        incomplete={journeyDescriptionError}
        linkTo="/journeys/new?tab=JourneyDetails"
        content={JourneyDescription}
      />
      <EditableItem
        title={i18n.t('Journey steps')}
        incomplete={journeyStepsError}
        editable={false}
        content={<JourneyStepsPreview steps={stepsWithQueryLinks} />}
      />
    </Container>
  );
};

export default JourneyReviewSubmit;
