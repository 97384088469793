import { authAxiosCall } from '../axiosCall';
import { AxiosResponse } from 'axios';

export const getMarketplaces = async payload =>
  authAxiosCall(`/marketplaces`, {
    method: 'GET',
    query: payload,
  });

export const getMarketplace = async marketplaceId =>
  authAxiosCall(`/marketplaces/${marketplaceId}`, {
    method: 'GET',
  });
