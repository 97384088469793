import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { FormInput, Label, DynamicTable, QrPdf } from '../../../../Components';

import {
  Container,
  Content,
  AddItemContainer,
  AddItemIcon,
  QRHeadContainer,
} from './styles';
import { MdAdd } from 'react-icons/md';
import { Controller, useForm } from 'react-hook-form';
import DynamicFields from './DynamicFields';

type Props = {
  data: any;
  onChange?: any;
  message?: string;
  lotId?: string;
  assets?: Array<any>;
  userFacility?: boolean;
  locked?: boolean;
};

const Outbound = ({
  data,
  onChange,
  message,
  assets,
  userFacility,
  locked = false,
  lotId,
}: Props) => {
  const editLocked = !userFacility || locked;

  const { tableFields, deletedRows = [] } = data;

  const [fields, setFields] = useState([]) as any;
  const [addAmount, setAddAmount] = useState(1);
  const [tableFieldValue, setTableFieldValue] = useState([]) as any;

  const handleChange = (name, value) => {
    onChange('outboundData', { [name]: value });
  };

  useEffect(() => {
    handleChange('tableFields', fields);
  }, [fields]);

  const generateNewRow = (
    rowNumber,
    valuesProp,
    template,
    id = null,
    subDocumentId = null
  ) => {
    if (template) {
      if (template.version && template.version[0]) {
        const newHeaders = Object.keys(template.version[0]).map(v => v);
        const newField = newHeaders.map((header, index) => {
          const {
            mandatory,
            type,
            values = null,
            placeholder = header,
          } = template.version[0][header];
          let defaultValue = '';
          if (valuesProp !== null && valuesProp[header]) {
            defaultValue = valuesProp[header];
          } else {
            defaultValue =
              (tableFieldValue[rowNumber] &&
                tableFieldValue[rowNumber][header]) ||
              '';
          }
          return {
            // disabled: valuesProp !== null,
            disabled: editLocked,
            rowNumber: rowNumber,
            index: index,
            header: header,
            type: type,
            mandatory: mandatory,
            values: values,
            value: defaultValue,
            tableFieldValue: tableFieldValue,
            setTableFieldValue: setTableFieldValue,
            fromServer: valuesProp !== null,
            id: id,
            subDocumentId: subDocumentId,
            placeholder,
          };
        });
        return newField;
      }
    }
  };

  useEffect(() => {
    setFields([]);
  }, []);

  useEffect(() => {
    if (Object.entries(fields).length === 0) {
      if (assets && assets.length > 0) {
        let newFieldsStatus = {};
        assets.forEach(asset => {
          const { assetTemplate, assets } = asset;
          const newFields = [] as any;
          assets.forEach((asset, i) => {
            const newRow = generateNewRow(
              i,
              asset.attributes,
              assetTemplate,
              asset._id,
              asset.subDocumentId
            );
            newFields.push(newRow);
          });
          newFieldsStatus = {
            ...newFieldsStatus,
            [assetTemplate._id]: newFields,
          };
        });
        setFields({ ...fields, ...newFieldsStatus });
      }
    }

    if (assets && assets.length > 0) {
      assets.forEach(asset => {
        const { assetTemplate, assets } = asset;

        if (
          fields &&
          fields[assetTemplate._id] &&
          fields[assetTemplate._id].length !== assets.length
        ) {
          let newFieldsStatus = {};
          const newFields = [] as any;
          assets.forEach((asset, i) => {
            const newRow = generateNewRow(
              i,
              asset.attributes,
              assetTemplate,
              asset._id,
              asset.subDocumentId
            );
            newFields.push(newRow);
          });
          newFieldsStatus = {
            ...newFieldsStatus,
            [assetTemplate._id]: newFields,
          };
          setFields({ ...fields, ...newFieldsStatus });
        }
      });
    }
  }, [assets]);

  useEffect(() => {
    if (tableFields && Object.entries(tableFields).length !== 0) {
      setFields(tableFields);
    }
  }, [tableFields]);

  const handleTableInputChange = (name, value, index, template) => {
    const newTableFields = [...fields[template._id]].map(fieldsSet => {
      const rowNumber = fieldsSet[0].rowNumber;
      if (rowNumber === index) {
        return fieldsSet.map(f => {
          if (f.header === name) {
            return {
              ...f,
              value: value,
            };
          } else {
            return f;
          }
        });
      } else {
        return fieldsSet;
      }
    });

    const newFields = {
      ...fields,
      [template._id]: newTableFields,
    };
    setFields(newFields);
  };

  const handleAddRows = template => {
    const newFields = fields[template._id]
      ? { ...fields }
      : { ...fields, [template._id]: [] };
    for (let i = 0; i < addAmount; i++) {
      let maxRowNumber =
        Math.max(...newFields[template._id].flat().map(e => e.rowNumber)) || 0;
      maxRowNumber = isFinite(maxRowNumber) ? maxRowNumber : 0;
      newFields[template._id].push(
        generateNewRow(maxRowNumber + 1 + i, null, template)
      );
    }
    setFields(newFields);
  };

  const handleDeleteRow = (rowId, template) => {
    const newTableFields = [...fields[template._id]].filter(
      field => field[0].rowNumber !== rowId
    );
    const newFields = {
      ...fields,
      [template._id]: newTableFields,
    };
    setFields(newFields);

    const deletedRow = fields[template._id].find(
      field => field[0].rowNumber === rowId
    )[0];

    if (deletedRow.id) {
      deletedRows.push(deletedRow);
      handleChange('deletedRows', deletedRows);
    }
  };

  const createHeaders = template => {
    if (template.version?.length) {
      const newHeaders = Object.keys(template.version[0]).map(v => v);
      return newHeaders;
    }
    return [];
  };

  const { errors, control } = useForm({
    mode: 'onBlur',
  });

  return (
    <Container>
      <Content className="px-0">
        {assets?.length === 0 ? (
          <div className="row">
            <div className="col-12 mx-4 my-3">
              <Label>
                {i18n.t(
                  'Per journey definition there are no outbound assets for this facility.'
                )}
              </Label>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 px-5 py-1">
              <Label>{i18n.t('Outbound message (optional)')}</Label>
              <FormInput
                maxLength={350}
                type="textarea"
                placeholder={i18n.t('Enter an outbound message')}
                name="outboundMessage"
                onChange={evt =>
                  handleChange(evt.target.name, evt.target.value)
                }
                value={data?.outboundMessage || message}
                disabled={editLocked}
              />
            </div>

            {assets &&
              assets.length > 0 &&
              assets.map(asset => {
                const { assetTemplate } = asset;
                const headers = createHeaders(assetTemplate);
                return (
                  headers.length > 0 && (
                    <div className="col-12 mt-5 p0" key={assetTemplate._id}>
                      <div className="table-header">
                        {assetTemplate.name}
                        <QRHeadContainer>
                          <QrPdf
                            qr={[
                              {
                                lotId: lotId || '',
                                skuku: assetTemplate.skuku,
                              },
                            ]}
                            tooltip={i18n.t(
                              'Generate QR code for this asset only.'
                            )}
                          />
                        </QRHeadContainer>
                      </div>
                      <DynamicTable
                        readonly={editLocked}
                        removable={true}
                        handleDelete={rowId =>
                          handleDeleteRow(rowId, assetTemplate)
                        }
                        headers={headers}
                        data={
                          fields[assetTemplate._id] &&
                          fields[assetTemplate._id].length > 0
                            ? fields[assetTemplate._id].map(
                                (field, pIndex) =>
                                  field &&
                                  field.map((f, cIndex) => {
                                    return (
                                      <DynamicFields
                                        key={`${pIndex}-${cIndex}`}
                                        {...f}
                                        handleTableInputChange={(
                                          name,
                                          value,
                                          index
                                        ) =>
                                          handleTableInputChange(
                                            name,
                                            value,
                                            index,
                                            assetTemplate
                                          )
                                        }
                                      />
                                    );
                                  })
                              )
                            : []
                        }
                        vertical={false}
                      />
                      {!editLocked && (
                        <div className="add-rows mt-3">
                          <Controller
                            as={
                              <FormInput
                                required
                                placeholder="Rows to add"
                                subType="number"
                                value={addAmount}
                                min={1}
                              />
                            }
                            name="addAmount"
                            value={addAmount}
                            control={control}
                            onChange={([evt]) => {
                              const value = evt.target.value;
                              if (value > 0) {
                                setAddAmount(value);
                                return value;
                              } else {
                                setAddAmount(addAmount || 1);
                                return addAmount;
                              }
                            }}
                            defaultValue={addAmount}
                          />
                          <AddItemContainer
                            className="ml-2"
                            onClick={() => handleAddRows(asset.assetTemplate)}
                          >
                            <span>{i18n.t('Add rows')}</span>
                            <AddItemIcon>
                              <MdAdd color="#fff" />
                            </AddItemIcon>
                          </AddItemContainer>
                        </div>
                      )}
                    </div>
                  )
                );
              })}
          </div>
        )}
      </Content>
    </Container>
  );
};

export default withTranslation()(Outbound as any) as any;
