import styled from 'styled-components';

const Container = styled.div`
  ul {
    padding: 0;
    list-style: none;
  }

  li {
    padding: 9px 70px 9px 27px;
    position: relative;
    display: flex;
    align-items: flex-start;
    border-left: 5px solid transparent;

    &.selected {
      border-left-color: #A7BF2E;
    }

    textarea {
      min-height: 40px;
      height: 40px;
      resize: vertical;
      padding-top: 9px;
      box-sizing: border-box;
    }

    input {
      box-sizing: border-box;
    }

    &:nth-child(even) {
      background: #F9F9FB;
    }

    .first-value {
      min-width: 180px;
      margin-right: 12px;
    }
    .second-value {
      flex: 1;
    }
  }

  .readonly {
    li {
      padding: 12px 70px 12px 27px;

      .first-value {
        color: #757575;
        font-weight: 600;
      }

      &:nth-child(even) {
        background: #fff;
      }
    }
  }
`;

const AddItemContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  float: right;
  margin-right: 32px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  > span {
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const AddItemIcon = styled.div`
  width: 34px;
  height: 34px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ControlsContainer = styled.div<{ first: boolean; last: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  right: 30px;
  top: -25px;
  flex-direction: column;
  height: 110px;
  z-index: 100;
  ${props => props.first && !props.last && `justify-content:flex-end`}
  ${props => props.last && !props.first && `justify-content:flex-start`}
  ${props => props.last && props.first && `justify-content:center`}
`;

const ControlsDeleteTooltip = styled.div`
  left: 40px;
`;

const ControlsTrashButton = styled.div`
  position: relative;
  border-radius: 100%;
  background-color: #000;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  cursor: pointer;

  & path {
    fill: #fff;
  }
  &:hover {
    ${ControlsDeleteTooltip} {
      display: flex;
    }
  }
`;

const ControlsArrowButton = styled.div`
  background-color: #000;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  margin: 5px 0;
  border: none;
  color: #fff;
  cursor: pointer;
  & path {
    fill: #fff;
  }
  &.up {
    border-radius: 100% 100% 50% 50%;
  }
  &.down {
    border-radius: 50% 50% 100% 100%;
  }
`;

export {
  Container,
  AddItemContainer,
  AddItemIcon,
  ControlsContainer,
  ControlsArrowButton,
  ControlsTrashButton,
}
