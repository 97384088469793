import React, { useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { JourneyStepsInboundContainer as Container } from '../styles';
import {
  Label,
  FormErrorLabel,
  CustomTooltip,
  Checkbox,
  TransferList,
  InfoIcon,
} from '../../../../Components';

interface Props {
  onChange: Function;
  isFirstStep?: boolean;
  activeStep?: any;
  noInboundAssets?: boolean;
  showInboundReceivedDate?: boolean;
  inboundAssetsReceived?: Array<any>;
  inboundAssetsInventory?: Array<any>;
}

const JourneyStepsInboundAssets = ({
  onChange,
  isFirstStep,
  noInboundAssets,
  showInboundReceivedDate,
  inboundAssetsReceived = [],
  inboundAssetsInventory = [],
}: Props) => {
  const { errors, control, register, setValue, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setValue([
      { noInboundAssets },
      { showInboundReceivedDate },
      { inboundAssetsReceived },
      { inboundAssetsInventory },
    ]);
  }, [
    noInboundAssets,
    showInboundReceivedDate,
    inboundAssetsReceived,
    inboundAssetsInventory,
  ]);

  return (
    <Container>
      {!noInboundAssets && (
        <FormErrorLabel className="mb-4">
          * {i18n.t('You must have at least one inbound asset')}.
        </FormErrorLabel>
      )}
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="checkbox-wrapper">
            <Label>
              <Controller
                as={Checkbox}
                name="noInboundAssets"
                control={control}
                defaultValue={noInboundAssets}
                checked={noInboundAssets}
                onChange={([evt]) => {
                  onChange(evt.target.name, evt.target.checked);
                  return evt.target.checked;
                }}
              />

              {i18n.t('This step has no inbound assets')}
              <div data-tip data-for="noinbound">
                <InfoIcon />
              </div>
              <CustomTooltip id="noinbound" placement="right">
                <span>
                  {i18n.t('Selecting this will not require the supply chain')}
                </span>
              </CustomTooltip>
            </Label>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="checkbox-wrapper">
            <Label disabled={noInboundAssets}>
              <Controller
                as={Checkbox}
                disabled={noInboundAssets}
                name="showInboundReceivedDate"
                control={control}
                defaultValue={showInboundReceivedDate}
                checked={showInboundReceivedDate}
                onChange={([evt]) => {
                  onChange(evt.target.name, evt.target.checked);
                  return evt.target.checked;
                }}
              />

              {i18n.t('Show received date of inbound assets')}
              <div data-tip data-for="showoutbound">
                <InfoIcon />
              </div>
              <CustomTooltip id="showoutbound" placement="right">
                <span>{i18n.t('For every step in the product journey')}</span>
              </CustomTooltip>
            </Label>
          </div>
        </div>
        <div className="col-12">
          <Label disabled={noInboundAssets}>
            {i18n.t('Select the inbound assets that this facility receives')}
          </Label>
          {!isFirstStep && (
            <div>
              <TransferList
                disabled={noInboundAssets}
                fromPlaceholder={i18n.t(
                  'Search for assets from previous step/facility'
                )}
                items={inboundAssetsReceived}
                handleChange={items => onChange('inboundAssetsReceived', items)}
              />
            </div>
          )}
        </div>
        <div className="col-12">
          <div>
            <TransferList
              disabled={noInboundAssets}
              fromPlaceholder={i18n.t('Search for assets from asset inventory')}
              items={inboundAssetsInventory}
              handleChange={items => onChange('inboundAssetsInventory', items)}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default JourneyStepsInboundAssets;
