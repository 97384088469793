import React from 'react';

import { Container } from './styles';
import { Spinner } from '..';

interface Props {
  top?: number;
}

export default ({ top }: Props) => (
  <Container className="loading-spinner">
    <Spinner top={top} />
  </Container>
);
