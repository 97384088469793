import React from 'react';

export default (props: { color?: string }) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.27266 0.636475V6.57647C1.79993 7.10011 0.818115 8.49102 0.818115 10.0456C0.818115 12.091 2.45448 13.7274 4.49993 13.7274C6.37357 13.7274 7.94448 12.3201 8.15721 10.4547H11.5936C11.5036 10.7165 11.4545 10.9947 11.4545 11.2728C11.4545 11.9238 11.7131 12.5481 12.1734 13.0085C12.6337 13.4688 13.258 13.7274 13.909 13.7274C14.56 13.7274 15.1843 13.4688 15.6447 13.0085C16.105 12.5481 16.3636 11.9238 16.3636 11.2728C16.3636 10.9947 16.3145 10.7165 16.2163 10.4547H17.1818V8.00011C17.1818 7.09193 16.4536 6.36375 15.5454 6.36375H11.4872L10.3499 0.636475H3.27266ZM4.90902 2.27284H8.99993L9.81812 6.36375V8.81829H7.96902C7.73591 8.16845 7.32592 7.59663 6.78526 7.16729C6.24461 6.73795 5.59478 6.46814 4.90902 6.38829V2.27284ZM4.49993 8.20466C4.98817 8.20466 5.45642 8.39861 5.80165 8.74385C6.14689 9.08908 6.34084 9.55733 6.34084 10.0456C6.34084 10.5338 6.14689 11.002 5.80165 11.3473C5.45642 11.6925 4.98817 11.8865 4.49993 11.8865C4.01169 11.8865 3.54345 11.6925 3.19821 11.3473C2.85298 11.002 2.65902 10.5338 2.65902 10.0456C2.65902 9.55733 2.85298 9.08908 3.19821 8.74385C3.54345 8.39861 4.01169 8.20466 4.49993 8.20466ZM13.909 10.0456C14.2345 10.0456 14.5467 10.1749 14.7768 10.405C15.007 10.6352 15.1363 10.9473 15.1363 11.2728C15.1363 11.5983 15.007 11.9105 14.7768 12.1407C14.5467 12.3708 14.2345 12.5001 13.909 12.5001C13.5835 12.5001 13.2714 12.3708 13.0412 12.1407C12.8111 11.9105 12.6818 11.5983 12.6818 11.2728C12.6818 10.9473 12.8111 10.6352 13.0412 10.405C13.2714 10.1749 13.5835 10.0456 13.909 10.0456Z"
      fill={props.color ? props.color : "#A7BF2E" }
    />
  </svg>
);
