import React from 'react';

export type VerticalTableProps = Array<{
  title: string;
  value: any;
  link?: string;
}>;
const VerticalTable = ({ rows }: { rows: VerticalTableProps }) => {
  return (
    <table>
      <tbody>
        {rows &&
          rows.map((row, rowKey) => (
            <tr key={rowKey}>
              <th>{row.title}</th>
              <td>
                {row.link ? (
                  <a target="_blank" rel="noreferrer" href={row.link}>
                    {row.value}
                  </a>
                ) : (
                  <span>{row.value}</span>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default VerticalTable;
