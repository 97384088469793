import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;

  #working {
    position: absolute;
    left: 5px;
    top: 8px;
  }
`;

export default () => (
  <Container>
    <svg
      id="working"
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.81699 1.98103C7.26373 2.41933 7.98786 2.41243 8.42617 1.96568C8.86447 1.51894 8.85757 0.794808 8.41083 0.356502C7.96408 -0.0818041 7.23995 -0.0749006 6.80164 0.371844C6.36334 0.818588 6.37024 1.54272 6.81699 1.98103ZM6.30383 2.01649C6.84525 2.0549 7.6947 3.11403 7.6947 3.11403L8.77108 6.98374L10.8155 8.21628C10.8155 8.21628 10.8155 7.64106 11.966 7.06583L14.2669 10.5172H8.51465C8.51465 10.5172 8.63901 9.36672 9.6651 9.36672C10.2403 9.36672 10.2403 8.7915 10.2403 8.7915L5.52153 5.94812L5.48729 5.98878L6.76275 7.13072L6.72596 10.518H5.59001L5.65609 7.69526L4.20497 6.76457L1.61193 10.518L0.461517 10.5175C0.965492 9.66029 1.39734 8.84592 1.81349 8.06115C2.40947 6.93726 2.97326 5.87409 3.67058 4.83279L1.731 3.66406L2.2655 3.06175L2.59929 3.26298L3.98578 1.96171L5.66403 2.00033C5.81402 1.99394 6.01265 1.99765 6.27048 2.01428L6.30227 2.01502L6.30383 2.01649ZM5.15085 2.93509L4.23923 2.75813L3.31851 3.69657L4.12607 4.18343C4.40846 3.79941 4.71405 3.4173 5.05133 3.0351C5.08287 2.99935 5.11612 2.96601 5.15085 2.93509ZM6.03736 5.33568L7.84249 6.42393L6.90957 4.30011L6.03736 5.33568Z"
        fill="white"
      />
    </svg>
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 20.7273H24L12 0L0 20.7273Z" fill="#757575" />
    </svg>
  </Container>
);
