import React, { Dispatch, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { doLogin } from '../../Redux/User/userActions';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  Input,
  Button,
  SimpleTitle,
  ContactFooter,
  Footer,
  FooterWrapper,
  Loading,
} from '../../Components';
import AuthLayout from '../../Components/Layouts/authLayout';
import social from '../../Lib/Configs/social';
import { FaUserCircle } from 'react-icons/fa';
import { MdLock } from 'react-icons/md';
import {
  Container,
  ButtonsContainer,
  ButtonContainer,
  Wrapper,
} from './styles';
import { Controller, useForm } from 'react-hook-form';
import User from '../../Lib/Utils/Validations/User';
import Toast from '../../Lib/Utils/toast';

interface RootState {
  user: any;
}

const mapState = (state: RootState) => ({
  loading: state.user.loading,
  loginSuccess: state.user.loginSuccess,
  error: state.user.error,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  doLoginAction: (email: string, password: string) =>
    dispatch(doLogin(email, password)),
});

const connector = connect(mapState, mapDispatch);

type IProps = RouteComponentProps;
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IProps & {};

const Login = (props: Props) => {
  const { doLoginAction, history, loginSuccess, loading, error } = props;

  const [data, setData] = useState(
    {} as { username: string; password: string }
  );

  const { errors, control, setValue, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setValue([{ username: data.username }, { password: data.password }]);
  }, [data]);

  useEffect(() => {
    if (loginSuccess) {
      history.push('/home');
    } else if (error) {
      Toast.error(error);
    }
  }, [error, loginSuccess]);

  if (loginSuccess) {
    history.push('/home');
  }

  const handleChange = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const handleLogin = async () => {
    const isValid = await triggerValidation();
    if (isValid) {
      doLoginAction(data.username, data.password);
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleForgotPassword = () => {
    window.location.href =
      'mailto:support@suku.world?subject=SUKU Discover Password Reset Request&body=Please include your SUKU Discover email that was used to sign in, if known.';
  };

  return (
    <Wrapper>
      <AuthLayout header={i18n.t('Discover')}>
        <Container>
          <SimpleTitle
            title={i18n.t('Sign In')}
            subTitle={i18n.t('Welcome Back!')}
            description={i18n.t('Please sign in into your account.')}
          />
          <div>
            <div className="form-field">
              <Controller
                as={
                  <Input
                    Icon={FaUserCircle}
                    placeholder={i18n.t('Username')}
                    onKeyPress={evt => handleKeyPress(evt)}
                    autoFocus
                  />
                }
                name="username"
                value={data.username}
                control={control}
                onChange={([value]) => {
                  handleChange('username', value);
                  return value;
                }}
                rules={User.loginUsername}
                defaultValue={data.username}
              />
              {errors.username && (
                <span className="inlineErrorMessage">
                  {errors.username.message}.
                </span>
              )}
            </div>
            <div className="form-field">
              <Controller
                as={
                  <Input
                    Icon={MdLock}
                    placeholder={i18n.t('Password')}
                    type="password"
                    onKeyPress={evt => handleKeyPress(evt)}
                  />
                }
                name="password"
                value={data.password}
                control={control}
                onChange={([value]) => {
                  handleChange('password', value);
                  return value;
                }}
                rules={User.loginPassword}
                defaultValue={data.password}
              />
              {errors.password && (
                <span className="inlineErrorMessage">
                  {errors.password.message}.
                </span>
              )}
            </div>
          </div>
          <ButtonsContainer>
            <ButtonContainer>
              <Button
                width={140}
                height={40}
                text={i18n.t('Sign In')}
                action={() => handleLogin()}
              />
            </ButtonContainer>
            <ButtonContainer>
              <Button
                type="text"
                text={i18n.t('Forgot Password?')}
                action={() => handleForgotPassword()}
              />
            </ButtonContainer>
          </ButtonsContainer>
        </Container>
      </AuthLayout>
      <FooterWrapper>
        <>
          <ContactFooter
            text={`${i18n.t(
              'Contact us to learn more or if you have any questions'
            )}:`}
            email="support@suku.world"
          />
          <Footer social={social} />
        </>
      </FooterWrapper>
      {loading && <Loading />}
    </Wrapper>
  );
};

export default withRouter(
  withTranslation()(connector(Login) as any) as any
) as any;
