import React from 'react';
import { CheckFillIcon, CloseFillIcon } from '../../Components';
import { toast } from 'react-toastify';

const success = message =>
  toast.success(
    <>
      <CheckFillIcon />
      {message}
    </>
  );
const error = message =>
  toast.error(
    <>
      <CloseFillIcon />
      {message}
    </>
  );
const dark = message =>
  toast.dark(
    <>
      <CloseFillIcon />
      {message}
    </>
  );

export default { success, error, dark };
