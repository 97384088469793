/* eslint-disable @typescript-eslint/camelcase */
import React, { Dispatch, useState, useEffect } from 'react';
import { connect, ConnectedProps, useStore } from 'react-redux';
import { doLogin } from '../../Redux/User/userActions';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import moment from 'moment';

import {
  HeaderFilter,
  MenuDashboard,
  DataSections,
  MenuAssetIcon,
  MenuClaim,
  MenuJourney,
  TagIcon,
  LocationIcon,
  ChartIcon,
  Table,
  Checkbox,
  Label,
  Dropdown,
  Map,
  Chart,
  Loading,
  DatePicker,
} from '../../Components';
import {
  IconContainer,
  NavigationButton,
  ClaimsContent,
  MapInfo,
  ChartDropdownContainer,
  ChartDatesControlContainer,
} from './style';

import { doGetFacilities } from '../../Redux/Facility/facilityActions';
import {
  doGetJourneys,
  doGetJourneyTemplates,
  doGetActiveJourneyStatus,
} from '../../Redux/Journey/journeyActions';
import { doGetClaims } from '../../Redux/Claim/claimActions';
import { doGetAssetTemplates } from '../../Redux/Asset/assetActions';
import { doGetMarketplaces } from '../../Redux/Marketplace/marketplaceActions';
import { doGetDashboard } from '../../Redux/Analytic/analyticActions';
import { doGetOrganizations } from '../../Redux/User/userActions';

import { getOrganization } from '../../Lib/Utils/auth';
import { select } from '@storybook/addon-knobs';

const headers = [
  { text: i18n.t('Journey'), order: false },
  { text: i18n.t('Lot ID'), order: false },
  { text: i18n.t('Prev. Facility Submission'), order: false },
  { text: i18n.t('Prev. Facility'), order: false },
  { text: i18n.t('Next Facility'), order: false },
  { text: i18n.t('Claim status (all facilities)'), order: false },
  { text: '', order: false },
];

const dropdownDaysOptions = [
  { value: '7', label: '7' },
  { value: '30', label: '30' },
  { value: '60', label: '60' },
  { value: '90', label: '90' },
  { value: '180', label: '180' },
];

const testDashboardData = {
  assetMarketableProducts: true,
  assetUnfinishedGoods: true,
  claimExpiringIn: false,
  claimDays: null,
  claimExpired: false,
  journeysValue: 0,
  assetsValue: 0,
  claimsValue: 0,
  facilitiesValue: 0,
  claimVerifiable: true,
  claimUnverifiable: true,
};

const datasetColors = [
  '#00b8d4',
  '#e91e63',
  '#9c27b0',
  '#2196f3',
  '#009688',
  '#cddc39',
  '#ffc107',
  '#ff9800',
];

const datasetsElements = {
  journeys: 'Journeys',
  assets: 'Assets',
  facilities: 'Facilities',
  verifable_claims: 'Verifiable claims',
  unverifable_claims: 'Unverifiable claims',
  marketable_products: 'Marketable products',
  unfinished_goods: 'Unfinished goods',
};

const defaultDatasetOptions = {
  fill: false,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 3,
  pointHitRadius: 30,
};

interface RootState {
  facility: any;
  journey: any;
  claim: any;
  asset: any;
  marketplace: any;
  analytic: any;
  user: any;
}

const mapState = (state: RootState) => ({
  claims: state.claim.claims,
  claimsLoading: state.claim.loading,

  facilities: state.facility.facilities,
  facilitiesLoading: state.facility.loading,

  assets: state.asset.assets,
  assetsLoading: state.asset.assetsLoading,

  journeys: state.journey.journeys,
  journeyTemplates: state.journey.journeyTemplates,
  journeysLoading: state.journey.loading,

  marketplaces: state.marketplace.marketplaces,
  marketplacesLoading: state.marketplace.loading,

  dashboard: state.analytic.dashboard,
  analyticLoading: state.analytic.loading,

  organizations: state.user.organizations,
  organizationsLoading: state.user.loading,

  activeJourneysStatus: state.journey.activeJourneysStatus,
  activeJourneysStatusLoading: state.journey.activeJourneysStatusLoading,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  doLoginAction: (email: string, password: string) =>
    dispatch(doLogin(email, password)),
  getFacilitiesAction: (payload = null as any) =>
    dispatch(doGetFacilities(false, false, payload)),
  getJourneysAction: () => dispatch(doGetJourneys()),
  getJourneyTemplatesAction: () => dispatch(doGetJourneyTemplates()),
  getClaimsAction: (payload = null as any) =>
    dispatch(doGetClaims(null, false, payload)),
  getAssetsAction: (payload = null as any) =>
    dispatch(doGetAssetTemplates(false, payload)),
  getMarketplacesAction: (payload = null) =>
    dispatch(doGetMarketplaces(payload)),
  getDashboardAction: (payload = null as any) =>
    dispatch(doGetDashboard(payload)),
  getOrganizationsAction: () => dispatch(doGetOrganizations()),
  getActiveJourneyStatusAction: (payload = null as any) =>
    dispatch(doGetActiveJourneyStatus(payload)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  history?: any;
  useStore?: Array<string>;
  currentUser?: any;
};

const Dashboard = (props: Props) => {
  const {
    history,
    getFacilitiesAction,
    getJourneysAction,
    getJourneyTemplatesAction,
    getClaimsAction,
    getAssetsAction,
    getMarketplacesAction,
    getDashboardAction,
    getOrganizationsAction,
    getActiveJourneyStatusAction,
    useStore = [],
    facilitiesLoading,
    assetsLoading,
    journeysLoading,
    claimsLoading,
    facilities,
    assets,
    journeys,
    journeyTemplates,
    claims,
    marketplaces,
    marketplacesLoading,
    currentUser,
    dashboard,
    analyticLoading,
    organizations,
    organizationsLoading,
    activeJourneysStatus,
    activeJourneysStatusLoading,
  } = props;

  const userOrganization = getOrganization();

  const loading =
    facilitiesLoading ||
    assetsLoading ||
    journeysLoading ||
    claimsLoading ||
    marketplacesLoading ||
    analyticLoading ||
    organizationsLoading ||
    activeJourneysStatusLoading;

  const [data, setData] = useState(testDashboardData);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  } as any);
  const [rows, setRows] = useState([] as any);
  const [activeJourneyStatusPayload, setActiveJourneyStatusPayload] = useState(
    {}
  );
  const [selectFilter, setSelectFilter] = useState({} as any);
  const [mapOptions, setMapOptions] = useState({
    zoom: 16,
    markers: [],
    center: { lng: 0, lat: 0 },
  });

  const [groupByGraph, setgroupByGraph] = useState('MM-DD-YYYY');

  const [selects, setSelects] = useState([
    {
      placeholder: i18n.t('Select one or more marketplaces'),
      options: [],
      key: 'marketplace',
      multi: true,
    },
    {
      placeholder: i18n.t('Select one or more journeys'),
      options: [],
      key: 'journey',
      multi: true,
    },
  ]);

  const [chartFromDate, setChartFromDate] = useState(new Date());
  const [chartToDate, setChartToDate] = useState(new Date());

  const {
    assetMarketableProducts,
    assetUnfinishedGoods,
    claimExpiringIn,
    journeysValue,
    assetsValue,
    claimsValue,
    claimDays,
    facilitiesValue,
    claimVerifiable,
    claimUnverifiable,
    claimExpired,
  } = data;

  const claimsFilter = () => {
    if (claims && claims.length) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const filteredClaims = claims.filter(claim => {
        const expirationDate =
          claim.supportingVerifications.length > 0
            ? new Date(claim.supportingVerifications[0].expirationDate)
            : null;

        const verifiableFilter = claimVerifiable && claim.verifiable;
        const unverifiableFilter = claimUnverifiable && !claim.verifiable;
        const expiredFilter = claimExpired
          ? expirationDate
            ? expirationDate < today
            : false
          : false;

        const expiringInFilter =
          claimExpiringIn && expirationDate && claimDays
            ? moment(expirationDate).isSameOrBefore(
                moment(today).add(claimDays, 'd')
              ) && expirationDate >= today
            : false;

        return (
          (verifiableFilter || unverifiableFilter) &&
          (claimExpiringIn === false && claimExpired === false
            ? true
            : expiredFilter || expiringInFilter)
        );
      });

      setData({
        ...data,
        claimsValue: filteredClaims.length,
      });
    }
  };

  const assetFilter = () => {
    if (assets && assets.length) {
      const filteredAssets = assets.filter(asset => {
        const marketableProductsFilter =
          assetMarketableProducts && asset.marketableProduct;
        const unfinishedGoodsFilter =
          assetUnfinishedGoods && !asset.marketableProduct;
        return marketableProductsFilter || unfinishedGoodsFilter;
      });

      setData({
        ...data,
        assetsValue: filteredAssets.length,
      });
    }
  };

  useEffect(() => {
    if (!useStore.includes('facilities')) {
      getFacilitiesAction();
    }

    if (!useStore.includes('journeys')) {
      getJourneysAction();
    }

    if (!useStore.includes('journeyTemplates')) {
      getJourneyTemplatesAction();
    }

    if (!useStore.includes('claims')) {
      getClaimsAction();
    }

    if (!useStore.includes('assets')) {
      getAssetsAction();
    }

    if (!useStore.includes('marketplaces')) {
      getMarketplacesAction();
    }

    if (!useStore.includes('dashboard')) {
      getDashboardAction();
    }

    if (!useStore.includes('organizations')) {
      getOrganizationsAction();
    }

    if (!useStore.includes('activeJourneyStatus')) {
      getActiveJourneyStatusAction();
    }
  }, []);

  useEffect(() => {
    if (activeJourneysStatus && activeJourneysStatus.length) {
      const newData = activeJourneysStatus.map((row, index) => {
        const claimStatus = row.claimStatus
          .map(
            ({ count, maxDaysToExpiration }) =>
              `${count ? count : i18n.t('No')} ${
                count === 1 ? i18n.t('claim') : i18n.t('claims')
              }  ${
                maxDaysToExpiration
                  ? `${i18n.t(
                      'expiring in the next'
                    )} ${maxDaysToExpiration} ${i18n.t('days')}`
                  : i18n.t('expired')
              }`
          )
          .join('; ');
        return {
          id: row._id,
          journey: row.name,
          lotId: row.lotId,
          prevFacilitySubmission:
            moment(row.lastestSubmission).format('MM-DD-YYYY H:mm:ss') ||
            i18n.t('N/A - New lot'),
          prevFacility: row.latestFacility,
          nextFacility: row.nextFacility,
          claimStatus: claimStatus,
          navigate: (
            <NavigationButton
              onClick={() =>
                history.push(`/journeys/${row.templateLocator}/view/${row._id}`)
              }
            >
              {`>`}
            </NavigationButton>
          ),
        };
      });

      const newRows = newData.map(row => Object.values(row));
      setRows(newRows);
    }
  }, [activeJourneysStatus]);

  useEffect(() => {
    if (
      organizations.length &&
      userOrganization &&
      userOrganization._id === 'organization'
    ) {
      const organizationSelect = {
        placeholder: i18n.t('Select one or more organizations'),
        options: organizations[0].subGroups.map(({ id, name }) => ({
          value: name,
          label: name,
        })),
        key: 'organization',
        multi: true,
      };
      setSelects([organizationSelect, ...selects]);
    }
  }, [organizations]);

  useEffect(() => {
    if (dashboard) {
      const groupedDates = Object.keys(dashboard)
        .map(key => dashboard[key])
        .flat()
        .map(({ _id }) => moment(_id));

      const minDate = moment.min(groupedDates);
      const maxDate = moment.max(groupedDates);

      setChartFromDate(new Date(minDate.format(groupByGraph)));
      setChartToDate(new Date(maxDate.format(groupByGraph)));
    }
  }, [dashboard]);

  useEffect(() => {
    if (dashboard) {
      const groupedDates = [
        ...new Set(
          Object.keys(dashboard)
            .map(key => dashboard[key])
            .flat()
            .map(({ _id }) => moment(_id).format(groupByGraph))
        ),
      ];

      const chartLabels =
        groupByGraph === 'YYYY-WW'
          ? [...new Set(groupedDates.sort())].filter(
              date =>
                moment(date, 'YYYY-WW').format('WWYYYY') >=
                  moment(chartFromDate).format('WWYYYY') &&
                moment(date, 'YYYY-WW').format('WWYYYY') <=
                  moment(chartToDate).format('WWYYYY')
            )
          : groupedDates
              .sort((a, b) => moment(a).diff(moment(b)))
              .filter(
                date =>
                  moment(date).isSameOrAfter(
                    moment(chartFromDate).format(groupByGraph)
                  ) &&
                  moment(date).isSameOrBefore(
                    moment(chartToDate).format(groupByGraph)
                  )
              );

      const chartDataset = Object.keys(dashboard).map((entityKey, index) => {
        return {
          label: datasetsElements[entityKey],
          borderColor: datasetColors[index % datasetColors.length],
          data: chartLabels.map(
            dateLabel =>
              dashboard[entityKey]
                .filter(
                  ({ _id }) =>
                    moment(_id).format(groupByGraph) === dateLabel &&
                    moment(_id).isSameOrAfter(moment(chartFromDate)) &&
                    moment(_id).isSameOrBefore(moment(chartToDate))
                )
                ?.map(({ count }) => count)
                .reduce((a, b) => a + b, 0) || 0
          ),
        };
      });

      const newChartData = {
        labels:
          groupByGraph === 'YYYY-WW'
            ? chartLabels.map(nDate =>
                moment(nDate, 'YYYY-WW').format('MM-DD-YYYY')
              )
            : chartLabels,
        datasets: chartDataset,
      };

      setChartData(newChartData);
    }
  }, [dashboard, groupByGraph, chartFromDate, chartToDate]);

  useEffect(() => {
    if (facilities && facilities.length) {
      setData({
        ...data,
        facilitiesValue: facilities.length,
      });

      const newMapOptions = {
        markers: facilities
          .filter(
            f => !!f.location.coordinates[0] && !!f.location.coordinates[1]
          )
          .filter(facility => facility.address && facility.location)
          .map(facility => ({
            lng: facility?.location?.coordinates[0] || 0,
            lat: facility?.location?.coordinates[1] || 0,
            address: `${facility?.address[0]?.addressLineOne}, ${facility?.address[0]?.city}`,
            infoText: (
              <MapInfo>
                <p className="name">{facility?.registeredName}</p>
                <p>{facility?.address[0]?.addressLineOne}</p>
              </MapInfo>
            ),
            linkTo: `/facilities?id=${facility._id}`,
          })),
      };

      setMapOptions({ ...mapOptions, ...newMapOptions });
    }
  }, [facilities]);

  useEffect(() => {
    if (assets && assets.length) {
      setData({
        ...data,
        assetsValue: assets.length,
      });

      assetFilter();
    }
  }, [assets]);

  useEffect(() => {
    if (journeyTemplates && journeyTemplates.length) {
      setData({
        ...data,
        journeysValue: journeyTemplates.length,
      });

      const newJourneySelect = selects.map(select => {
        if (select.key === 'journey') {
          const options = journeyTemplates.map(({ _id, journeyName }) => ({
            value: _id,
            label: journeyName,
          }));

          return {
            ...select,
            options: options,
          };
        } else {
          return select;
        }
      });

      setSelects(newJourneySelect);
    }
  }, [journeyTemplates]);

  useEffect(() => {
    if (marketplaces && marketplaces.length) {
      const newMarketplaceSelect = selects.map(select => {
        if (select.key === 'marketplace') {
          const options = marketplaces.map(({ _id, name }) => ({
            value: _id,
            label: name,
          }));

          return {
            ...select,
            options: options,
          };
        } else {
          return select;
        }
      });

      setSelects(newMarketplaceSelect);
    }
  }, [marketplaces]);

  useEffect(() => {
    if (claims && claims.length) {
      setData({
        ...data,
        claimsValue: claims.length,
      });
      claimsFilter();
    }
  }, [claims]);

  const removeEmpty = obj =>
    Object.fromEntries(
      Object.entries(obj)
        .filter(([k, v]) => v != null)
        .map(([k, v]) => (typeof v === 'object' ? [k, removeEmpty(v)] : [k, v]))
    );

  const applyFilters = () => {
    const newFilters = removeEmpty({
      organizationId: selectFilter.organization || null,
      organizations: selectFilter.organization || null,
      marketplaces: selectFilter.marketplace || null,
      journeys: selectFilter.journey || null,
    });

    getDashboardAction(newFilters);
    getClaimsAction(newFilters);
    getFacilitiesAction(newFilters);
    getAssetsAction(newFilters);

    Object.keys(selectFilter).forEach(key => {
      if (key === 'journey') {
        setActiveJourneyStatusPayload({
          ...activeJourneyStatusPayload,
          templateLocator: selectFilter[key] ? selectFilter[key] : null,
        });
      }

      if (key === 'organization') {
        setActiveJourneyStatusPayload({
          ...activeJourneyStatusPayload,
          organizationId: selectFilter[key] ? selectFilter[key] : null,
        });
      }
    });
  };

  useEffect(() => {
    if (
      activeJourneyStatusPayload &&
      Object.keys(activeJourneyStatusPayload).length !== 0
    ) {
      getActiveJourneyStatusAction(removeEmpty(activeJourneyStatusPayload));
    } else {
      getActiveJourneyStatusAction(null);
    }
  }, [activeJourneyStatusPayload]);

  useEffect(() => applyFilters(), [selectFilter]);

  const handleChange = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    assetFilter();
  }, [assetMarketableProducts, assetUnfinishedGoods]);

  useEffect(() => {
    claimsFilter();
  }, [
    claimVerifiable,
    claimUnverifiable,
    claimExpired,
    claimExpiringIn,
    claimDays,
  ]);

  return (
    <>
      <div className="home">
        <HeaderFilter
          title={`${i18n.t('Welcome')} ${currentUser.given_name}!`}
          titleIcon={
            <IconContainer>
              <MenuDashboard />
            </IconContainer>
          }
          selects={selects}
          actionButton={false}
          useDropdown={true}
          setFilters={setSelectFilter}
          searchInput={false}
        />

        <div className="mt-5">
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <div className="row h-100">
                <div className="col d-flex flex-column">
                  <DataSections
                    onClick={() => history.push(`/facilities`)}
                    title={i18n.t('of facilities')}
                    value={facilitiesValue}
                    icon={<LocationIcon />}
                    content={<Map {...mapOptions} />}
                    fullHeight={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="row">
                <div className="col-12">
                  <DataSections
                    onClick={() => history.push(`/journeys`)}
                    title={i18n.t('of journeys')}
                    value={journeysValue}
                    icon={<MenuJourney />}
                  />
                </div>
                <div className="col-12">
                  <DataSections
                    onClick={() => history.push(`/assets`)}
                    title={i18n.t('of assets')}
                    value={assetsValue}
                    icon={<MenuAssetIcon />}
                    content={
                      <>
                        <div className="checkbox-wrapper">
                          <Label thin={true}>
                            <Checkbox
                              onChange={evt =>
                                handleChange(
                                  evt.target.name,
                                  evt.target.checked
                                )
                              }
                              checked={assetMarketableProducts}
                              name="assetMarketableProducts"
                            />
                            {i18n.t('Marketable products')}
                          </Label>
                        </div>
                        <div className="checkbox-wrapper">
                          <Label thin={true}>
                            <Checkbox
                              onChange={evt =>
                                handleChange(
                                  evt.target.name,
                                  evt.target.checked
                                )
                              }
                              checked={assetUnfinishedGoods}
                              name="assetUnfinishedGoods"
                            />
                            {i18n.t('Unfinished goods')}
                          </Label>
                        </div>
                      </>
                    }
                  />
                  <DataSections
                    onClick={() => history.push(`/claims`)}
                    title={i18n.t('of claims')}
                    value={claimsValue}
                    icon={<MenuClaim />}
                    content={
                      <ClaimsContent>
                        <div className="checkbox-wrapper">
                          <Label thin={true}>
                            <Checkbox
                              onChange={evt =>
                                handleChange(
                                  evt.target.name,
                                  evt.target.checked
                                )
                              }
                              checked={claimVerifiable}
                              name="claimVerifiable"
                            />
                            {i18n.t('Verifiable')}
                          </Label>
                        </div>
                        <div className="checkbox-wrapper">
                          <Label thin={true}>
                            <Checkbox
                              onChange={evt =>
                                handleChange(
                                  evt.target.name,
                                  evt.target.checked
                                )
                              }
                              checked={claimUnverifiable}
                              name="claimUnverifiable"
                            />
                            {i18n.t('Unverifiable')}
                          </Label>
                        </div>
                        <div className="checkbox-wrapper">
                          <Label thin={true}>
                            <Checkbox
                              onChange={evt =>
                                handleChange(
                                  evt.target.name,
                                  evt.target.checked
                                )
                              }
                              checked={claimExpired}
                              name="claimExpired"
                            />
                            {i18n.t('Expired')}
                          </Label>
                        </div>
                        <div className="checkbox-wrapper">
                          <Label thin={true}>
                            <Checkbox
                              onChange={evt => {
                                evt.stopPropagation();
                                handleChange(
                                  evt.target.name,
                                  evt.target.checked
                                );
                              }}
                              checked={claimExpiringIn}
                              name="claimExpiringIn"
                            />
                            {i18n.t('Expiring in days')}:
                          </Label>
                          <div className="dropDownContainer">
                            <Dropdown
                              options={dropdownDaysOptions}
                              placeholder="Select"
                              action={value => handleChange('claimDays', value)}
                              defaultOption={claimDays}
                              disabled={!claimExpiringIn}
                            />
                          </div>
                        </div>
                      </ClaimsContent>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <DataSections
                title={i18n.t('Metrics chart')}
                value={
                  <ChartDropdownContainer>
                    <ChartDatesControlContainer>
                      <Label>From</Label>
                      <DatePicker
                        required={false}
                        value={chartFromDate}
                        onChange={val => setChartFromDate(val)}
                      />
                      <Label>To</Label>
                      <DatePicker
                        required={false}
                        value={chartToDate}
                        onChange={val => setChartToDate(val)}
                      />
                    </ChartDatesControlContainer>
                    <Dropdown
                      options={[
                        { label: 'Days', value: 'MM-DD-YYYY' },
                        { label: 'Week', value: 'YYYY-WW' },
                        { label: 'Month', value: 'YYYY-MM' },
                        { label: 'Year', value: 'YYYY' },
                      ]}
                      placeholder="Select"
                      action={value => setgroupByGraph(value)}
                      defaultOption={groupByGraph}
                    />
                  </ChartDropdownContainer>
                }
                icon={<ChartIcon />}
                numberOption={false}
                content={
                  <Chart
                    data={chartData}
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      legend: {
                        display: true,
                        position: 'left',
                        align: 'start',
                        labels: {
                          fontColor: '#000',
                          boxWidth: 10,
                          padding: 30,
                          fontFamily: 'Encode Sans',
                          fontSize: 14,
                        },
                        onHover: function(e) {
                          e.target.style.cursor = 'pointer';
                        },
                        onLeave: function(e) {
                          e.target.style.cursor = 'default';
                        },
                      },
                    }}
                  />
                }
              />
            </div>
          </div>

          <div className="mt-1">
            <Table
              pagesBtw={2}
              ignoreKeys={[0]}
              headers={headers}
              rows={rows}
              pagination
              rowsPerPage={10}
            />
          </div>
        </div>
      </div>
      {loading && <Loading top={400} />}
    </>
  );
};
export default withRouter(
  withTranslation()(connector(Dashboard) as any) as any
) as any;
