import { authAxiosCall } from '../axiosCall';

import {
  StepTemplate,
  SaveJourney,
  SaveJourneyTemplate,
  SaveJourneyActionType,
  GetJourneyActionType,
} from './types';
import { AxiosResponse } from 'axios';
import { isOrgAdmin } from '../../../Lib/Utils/auth';

export const getJourneys = async (journeyId = null) =>
  authAxiosCall(`/journeys/${journeyId ? journeyId : ''}`, {
    method: 'GET',
  });

export const getJourney = async journeyId =>
  authAxiosCall(`/journeys?templateLocator=${journeyId}`, {
    method: 'GET',
  });

export const saveJourney = async ({
  _id,
  lotId,
  templateLocator,
}: SaveJourney): Promise<AxiosResponse<any> | undefined> =>
  authAxiosCall(`/journeytemplates/${templateLocator}/new`, {
    method: 'POST',
    body: JSON.stringify({ _id, lotId }),
  });

export const getJourneyTemplates = async (
  includeDrafts?: boolean,
  includeArchived?: boolean
): Promise<AxiosResponse<any> | undefined> => {
  let response = await authAxiosCall('/journeytemplates', {
    method: 'GET',
  });
  let journeyTemplates = response && response.data ? response.data.data : [];

  if (isOrgAdmin() && includeDrafts) {
    response = await authAxiosCall('/drafts/collections/journeytemplates', {
      method: 'GET',
    });
    const drafts = response && response.data ? response.data.data : [];
    journeyTemplates = journeyTemplates.concat(
      drafts.map(d => ({
        ...d.draft,
        _id: d._id,
        isDraft: true,
        createdAt: d.createdAt,
        updatedAt: d.updatedAt,
      }))
    );
  }
  if (!includeArchived) {
    journeyTemplates = journeyTemplates.filter(jt => !jt.archived);
  }

  return journeyTemplates;
};

export const getJourneyTemplate = async (
  journeyTemplateId: string,
  actionType?: GetJourneyActionType
): Promise<AxiosResponse<any> | undefined> => {
  const path =
    actionType === 'get-draft'
      ? `/drafts/${journeyTemplateId}`
      : `/journeytemplates/${journeyTemplateId}`;

  return authAxiosCall(path, {
    method: 'GET',
  });
};

export const saveJourneyTemplate = async (
  {
    _id,
    journeyName,
    templateLocator,
    description,
    assets,
    stepTemplates,
    refFacilities,
    refAssets,
    refClaims,
  }: SaveJourneyTemplate,
  actionType: SaveJourneyActionType
) => {
  let path = '';
  let method = '';
  switch (actionType) {
    case 'create-draft':
      path = `/drafts/collections/journeytemplates`;
      method = 'POST';
      break;
    case 'save-draft':
      path = `/drafts/${_id}`;
      method = 'PUT';
      break;
    case 'submit-create-entity':
      path = `/journeytemplates`;
      method = 'POST';
      break;
    case 'save-entity':
    case 'submit-save-entity':
      path = `/journeytemplates/${_id}`;
      method = 'PUT';
      break;
  }

  let journeyTemplates = [] as Array<
    string | { order: number; stepTemplate: StepTemplate | string }
  >;

  if (
    ['save-entity', 'submit-create-entity', 'submit-save-entity'].includes(
      actionType
    )
  ) {
    const templates = [] as Array<StepTemplate>;

    stepTemplates
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .forEach(st => {
        if (!st.stepTemplate._id) {
          templates.push(st.stepTemplate);
        }
      });

    if (templates && templates.length) {
      const result = await authAxiosCall('/steptemplates', {
        method: 'POST',
        body: JSON.stringify(templates),
      });
      if (result?.data?.data) {
        const data = result?.data?.data; // Who said data?
        journeyTemplates = data.map(({ _id }, index) => ({
          order: index,
          stepTemplate: _id,
        }));
      }
    } else {
      journeyTemplates = stepTemplates.map((st, index) => ({
        order: index,
        stepTemplate: st.stepTemplate._id,
      }));
    }
  } else {
    journeyTemplates = stepTemplates;
  }

  const response = await authAxiosCall(path, {
    method,
    body: JSON.stringify({
      _id,
      journeyName,
      templateLocator,
      description,
      assets,
      stepTemplates: journeyTemplates,
      refFacilities,
      refAssets,
      refClaims,
    }),
  });

  if (actionType === 'submit-create-entity') {
    await deleteDraft(_id);
  }

  return response;
};

export const deleteJourneyTemplate = async (_id: string, isDraft: boolean) => {
  let response;
  if (isDraft) {
    response = await deleteDraft(_id);
  } else {
    response = await authAxiosCall(`/journeytemplates/${_id}`, {
      method: 'PUT',
      body: JSON.stringify({
        archived: true,
      }),
    });
  }
  return response;
};

const deleteDraft = async (_id?: string) => {
  if (_id) {
    try {
      await authAxiosCall(`/drafts/${_id}`, {
        method: 'DELETE',
      });
    } catch (err) {
      // Do not throw draft might not exist
      console.log(`ERR removing draft`, err.message);
    }
  }
  return true;
};

export const deleteJourney = async (_id?: string) => {
  if (_id) {
    try {
      await authAxiosCall(`/journeys/${_id}`, {
        method: 'DELETE',
      });
    } catch (err) {
      // Do not throw draft might not exist
      console.log(`ERR removing Journey`, err.message);
    }
  }
  return true;
};

export const getActiveJourneyStatus = async payload =>
  authAxiosCall(`/journeys/activeJourneyStatus`, {
    method: 'GET',
    query: payload,
  });
