import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { Container, customStyles } from './styles';
import { DropdownIndicatorIcon } from '../index';

interface Props {
  options: Array<{ value: string; label: string; icon?: any }>;
  defaultOption?: string | null;
  placeholder?: string;
  required?: boolean;
  action: (selected: string) => void;
  onChange?: (selected: string) => void;
  disabled?: boolean;
  searchable?: boolean;
  multi?: boolean;
  onBlur?: () => void;
}

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorIcon />
    </components.DropdownIndicator>
  );
};

const { Option } = components;
const CustomSelectOption = props => (
  <Option {...props}>
    {props.data.icon && (
      <span style={{ marginRight: 10 }}>{props.data.icon}</span>
    )}
    {props.data.label}
  </Option>
);

const CustomSelectValue = props => (
  <div>
    {props.data.icon && (
      <span style={{ marginRight: 10 }}>{props.data.icon}</span>
    )}
    {props.data.label}
  </div>
);

const Dropdown = ({
  options,
  action,
  onChange,
  onBlur,
  placeholder,
  defaultOption,
  required = false,
  disabled = false,
  searchable = false,
  multi = false,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<any>(defaultOption);

  useEffect(() => {
    if (multi) {
      let newOptions;
      if (defaultOption) {
        newOptions = options.filter(o => defaultOption.includes(o.value));
        setSelectedOption(newOptions || '');
      }
    } else {
      const newOption = options.find(o => o.value === defaultOption);
      setSelectedOption(newOption || '');
    }
  }, [defaultOption, options]);

  const handleChange = selected => {
    setSelectedOption(selected);
    if (multi) {
      const values = selected ? selected.map(e => e.value).join(', ') : '';
      action && action(values);
      onChange && onChange(values);
    } else {
      action && action(selected.value);
      onChange && onChange(selected.value);
    }
  };

  return (
    <Container>
      {required && <div className="field-required">*</div>}
      <Select
        className="select-input"
        value={selectedOption || ''}
        onChange={handleChange}
        options={options}
        styles={customStyles}
        placeholder={placeholder}
        components={{
          DropdownIndicator,
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
        }}
        isDisabled={disabled}
        isSearchable={searchable}
        isMulti={multi}
        onBlur={() => onBlur && onBlur()}
      />
    </Container>
  );
};

export default Dropdown;
