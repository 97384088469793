import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useForm } from 'react-hook-form';

import {
  AssetDetailsFormContainer as FormContainer,
  AssetSmartTagsContainer as Container,
} from './styles';

import {
  Label,
  InfoIcon,
  Tooltip,
  Checkbox,
  TransferList,
} from '../../../Components';

const tagsOptions = [
  {
    text: 'GLASS TAG for High Temperatures',
    data: {
      description: [
        'International Standard for a quality management system',
        'Demonstrates continuous improvement',
        'Requires re-certification every three years',
      ],
      images: [
        'https://source.unsplash.com/200x150/?coffee?1',
        'https://source.unsplash.com/200x150/?coffee?2',
        'https://source.unsplash.com/200x150/?coffee?3',
        'https://source.unsplash.com/200x150/?coffee?4',
        'https://source.unsplash.com/200x150/?coffee?5',
      ],
    },
    selected: false,
  },
  {
    text: 'GLASS TAG for Low Temperatures',
    data: {
      description: [
        'Requires re-certification every three years',
        'International Standard for a quality management system',
      ],
      images: [
        'https://source.unsplash.com/200x150/?coffee?6',
        'https://source.unsplash.com/200x150/?coffee?7',
        'https://source.unsplash.com/200x150/?coffee?8',
        'https://source.unsplash.com/200x150/?coffee?9',
        'https://source.unsplash.com/200x150/?coffee?10',
      ],
    },
    selected: false,
  },
  {
    text: 'CATTLE EAR TAG',
    data: {
      description: ['Requires re-certification every three years'],
      images: [
        'https://source.unsplash.com/200x150/?coffee?11',
        'https://source.unsplash.com/200x150/?coffee?12',
        'https://source.unsplash.com/200x150/?coffee?13',
      ],
    },
    selected: false,
  },
  {
    text: 'COIL & CHEAP for ear tags',
    data: {
      description: [
        'Requires re-certification every three years',
        'Demonstrates continuous improvement',
        'Requires re-certification every three years',
        'International Standard for a quality management system',
      ],
      images: ['https://source.unsplash.com/200x150/?coffee?14'],
    },
    selected: false,
  },
];

interface Props {
  onChange: Function;
  assetSmartTagsFirstLoad?: boolean;
  requirementsNotMet?: boolean;
  contactMe?: boolean;
  tagsList?: Array<any>;
}

const AssetSmartTags = ({ onChange, ...data }: Props) => {
  const {
    requirementsNotMet,
    contactMe,
    tagsList = tagsOptions,
    assetSmartTagsFirstLoad = true,
  } = data;

  const [selected, setSelected] = useState(null as any);

  const { errors, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    assetSmartTagsFirstLoad === false && triggerValidation();
    onChange('assetSmartTagsFirstLoad', false);
  }, []);

  const handleSelectOption = item => {
    if (item && item.checked) {
      setSelected(item);
    }
  };

  return (
    <Container>
      <FormContainer className="row">
        <div className="col-12 plain-text">
          {i18n.t('If you are interested in more information')}
        </div>
        <div className="col-12">
          {i18n.t('Please contact')}
          {` `}
          <a href="mailto:support@suku.world">support@suku.world</a>
          {` `}
          {i18n.t('if you like more information about smart tags')}
        </div>

        <div className="col-12">
          <Label>
            {i18n.t('What tags are you interested in?')}
            <Tooltip text={i18n.t('Make a selection to learn more')}>
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>
          <div className="tags-checkboxes">
            <Label>
              <Checkbox
                onChange={evt => onChange(evt.target.name, evt.target.checked)}
                checked={requirementsNotMet}
                name="requirementsNotMet"
              />
              {i18n.t('None of the tags below appear to meet my requirements')}
            </Label>
            {requirementsNotMet && (
              <Label>
                <Checkbox
                  onChange={evt =>
                    onChange(evt.target.name, evt.target.checked)
                  }
                  checked={contactMe}
                  name="contactMe"
                />
                {i18n.t(
                  'Do not contact me to discuss the use of smart tags in the supply chain'
                )}
              </Label>
            )}
          </div>
        </div>
        {!requirementsNotMet && (
          <div className="col-12 mt-3">
            <div>
              <TransferList
                fromPlaceholder={i18n.t('Search the tags')}
                toPlaceholder={i18n.t('Type to filter')}
                items={tagsList}
                handleChange={items => onChange('tagsList', items)}
                handleSelectOption={item => handleSelectOption(item)}
              />
            </div>
          </div>
        )}
        {!requirementsNotMet && selected && (
          <div className="col-12 col-lg-6 highlighted-description">
            <div className="mb-3">{selected.text}</div>
            <ul>
              {selected.data &&
                selected.data.description.map(d => <li>{d}</li>)}
            </ul>
          </div>
        )}
        {!requirementsNotMet &&
          selected &&
          selected.data &&
          selected.data.images && (
            <div className="col-12 col-lg-6 gallery-wrapper mb-5">
              <ImageGallery
                items={selected.data.images.map(img => ({
                  original: img,
                  thumbnail: img,
                }))}
                thumbnailPosition="left"
                showFullscreenButton={false}
                showPlayButton={false}
                showNav={false}
              />
            </div>
          )}
      </FormContainer>
    </Container>
  );
};

export default AssetSmartTags;
