import * as analyticService from '../../Services/Api/analyticService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EAnalyticsActionTypes } from './analyticActionsTypes';

const getDashboard = createAction(EAnalyticsActionTypes.GET_DASHBOARD);
const getDashboardSuccess = createAction(
  EAnalyticsActionTypes.GET_DASHBOARD_SUCCESS,
  (dashboard: any) => ({ dashboard })
);
const getDashboardError = createAction(
  EAnalyticsActionTypes.GET_DASHBOARD_ERROR,
  (message: string) => ({ message })
);

export const doGetDashboard = (
  payload = null
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getDashboard());
  return await tap(
    analyticService.getDashboard(payload),
    dashboard => {
      dispatch(getDashboardSuccess(dashboard.data.data));
    },
    error => {
      const _message =
        'Failed to retrieve dashboard data. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getDashboardError(message));
    }
  );
};
