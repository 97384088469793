import React, { useState } from 'react';
import styled from 'styled-components';
import { EditableItem, Tooltip, CheckIcon, UncheckIcon } from '../index';
import i18n from 'i18next';

interface Props {
  description?: any;
  stepDescription?: string;
  claims?: any;
  inboundAssets?: any;
  outboundAssets?: any;
  showInboundReceivedDate?: boolean;
  showOutboundReceivedDate?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const DescriptionContainer = styled.p`
  max-width: 600px;
`;

const ClaimsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const Claims = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Certification = styled.div`
  display: flex;
  align-items: center;
`;

const Dates = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
  & span {
    margin 0 3px;
  }
`;

const ClaimRow = styled.div<{ active?: boolean }>`
  height: 18px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  & > div {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    flex: 1;
  }
  &:hover {
    ${Certification} {
      color: #a7bf2e;
    }
    ${Dates} {
      color: #75757550;
      & span {
        color: #a7bf2e;
      }
    }
  }
`;

const ClaimContainer = styled.div`
  margin-bottom: 10px;
`;

const DescriptionListContainer = styled.ul`
  margin: 0;
  padding: 0 0 0 20px;
  color: #303030;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  & li {
    margin: 13px 0;
  }
`;

const InboundOutboundContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;

const InboundContainer = styled.div`
  flex: 0.5;
`;

const OutboundContainer = styled.div`
  flex: 0.5;
`;

const AssetsList = styled.ul`
  margin: 0;
  padding: 0;
  & li {
    list-style-type: none;
    margin: 0 0 13px 0;
    padding: 0;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #757575;
  }
`;

const VisibleDetailContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  & svg {
    margin-right: 5px;
  }
`;

const StepDetail = ({
  description = '',
  stepDescription = '',
  claims = [],
  inboundAssets = [],
  outboundAssets = [],
}: Props) => {
  const [activeName, setActiveName] = useState<[] | null>(null);

  const activeItem = activeName && claims.find(c => c.name === activeName);

  const CheckedVisible = ({ text }: { text: string }) => (
    <VisibleDetailContainer>
      <CheckIcon />
      {text}
    </VisibleDetailContainer>
  );

  const UncheckedVisible = ({ text }: { text: string }) => (
    <VisibleDetailContainer>
      <UncheckIcon />
      {text}
    </VisibleDetailContainer>
  );

  return (
    <Container>
      <EditableItem
        editable={false}
        title={i18n.t('Step description')}
        content={<DescriptionContainer>{stepDescription}</DescriptionContainer>}
      />
      <EditableItem
        editable={false}
        title={i18n.t('Facility description')}
        content={<DescriptionContainer>{description}</DescriptionContainer>}
      />
      <EditableItem
        editable={false}
        editTooltip={i18n.t('Edit claims.')}
        title={i18n.t('Facility claims exposed to the end consumer')}
        content={
          <ClaimsContainer>
            <Claims>
              {!claims || claims.length === 0 && i18n.t('No information captured or not applicable!')}
              {claims &&
                claims.map(({ name, from, to, description }) => (
                  <ClaimRow
                    key={name}
                    onMouseEnter={() => setActiveName(name)}
                    active={activeItem && activeItem.name === name}
                  >
                    <Tooltip
                      text={
                        <DescriptionListContainer>
                          {description &&
                            description.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                        </DescriptionListContainer>
                      }
                      placement="bottom-start"
                      secondary
                    >
                      <ClaimContainer>
                        <Certification>{name}</Certification>
                        {from !== 'Invalid date' && to !== 'Invalid date' && (
                          <Dates>
                            From <span>{from}</span> to <span>{to}</span>
                          </Dates>
                        )}
                      </ClaimContainer>
                    </Tooltip>
                  </ClaimRow>
                ))}
            </Claims>
          </ClaimsContainer>
        }
      />
      <InboundOutboundContainer>
        <InboundContainer>
          <EditableItem
            editable={false}
            editTooltip={i18n.t('Edit inbound assets.')}
            title={i18n.t('Inbound assets that this facility receives')}
            content={
              <>
                <AssetsList>
                  {inboundAssets.list &&
                    inboundAssets.list.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                </AssetsList>
                {inboundAssets.showReceivedDate ? (
                  <CheckedVisible
                    text={i18n.t(
                      'Received date of assets will not be visible to end consumers'
                    )}
                  />
                ) : (
                  <UncheckedVisible
                    text={i18n.t(
                      'Received date of assets will not be visible to end consumers'
                    )}
                  />
                )}
              </>
            }
          />
        </InboundContainer>
        <OutboundContainer>
          <EditableItem
            editable={false}
            editTooltip={i18n.t('Edit outbound assets.')}
            title={i18n.t('Outbound assets that this facility produces')}
            content={
              <>
                <AssetsList>
                  {outboundAssets.list &&
                    outboundAssets.list.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                </AssetsList>
                {outboundAssets.showReceivedDate ? (
                  <CheckedVisible
                    text={i18n.t(
                      'Sent date of asssets will be visible to the end consumers'
                    )}
                  />
                ) : (
                  <UncheckedVisible
                    text={i18n.t(
                      'Sent date of assets will not be visible to end consumers'
                    )}
                  />
                )}
              </>
            }
          />
        </OutboundContainer>
      </InboundOutboundContainer>
    </Container>
  );
};

export default StepDetail;
