import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { MdAdd, MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';

import ItemControls from './ItemControls';
import EditItem from './EditItem';
import ViewItem from './ViewIitem';
import {
  Container,
  AddItemContainer,
  AddItemIcon,
  ItemWrapper,
  ItemTitle,
} from './styles';
import { PencilIcon } from '../CustomIcons';
import { Button } from '..';
import { FieldTypes } from '../../Lib/Configs';

type ListType = 'primary' | 'readonly';

interface Props {
  items: any;
  setItems: (value: Array<any>) => void;
  onSave?: (value: Array<any>) => void;
  listType?: ListType;
}

const DynamicInput = ({ items, setItems, onSave }: Props) => {
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [selectedRow, setSelectedRow] = useState(-1);

  const [currentItems, setCurrentItems] = useState(items || []);
  const [itemsLoaded, setItemsLoaded] = useState(false);

  useEffect(() => {
    if (items.toString() !== currentItems.toString() && !itemsLoaded) {
      setCurrentItems(items);
      setItemsLoaded(true);
    }
  }, [items]);

  const addItem = () => {
    setCurrentItems([
      ...currentItems,
      {
        readonly: false,
        fieldName: '',
        visibilityScope: 'supplyChain',
        dataSource: '',
        fieldType: '',
        isEditable: 'yes',
        collapsed: false,
        isMandatory: false,
        fieldPlaceholder: '',
        maxChars: null,
        options: [],
        valid: false,
      },
    ]);
  };

  const handleChange = (index: number, name: string, value: any) => {
    const updatedItems = currentItems.map((el, idx) =>
      idx === index ? { ...el, [name]: value } : el
    );
    setCurrentItems(updatedItems);
  };

  const getFieldLabel = fieldType => {
    const type = FieldTypes.find(ft => ft.value == fieldType);
    return type ? type.label : '';
  };

  const handleSave = (index: number, valid: boolean) => {
    const updatedItems = currentItems.map((el, idx) => ({
      ...el,
      collapsed: true,
      readonly: true,
      options: el.options.sort((a, b) => (a == '' ? -1 : 0)), // Blank options go first
      valid,
    }));
    setItems(updatedItems);
    setCurrentItems(updatedItems);

    if (onSave) {
      onSave(updatedItems);
    }
  };

  const handleEdit = index => {
    const updatedItems = currentItems.map((el, idx) =>
      idx === index ? { ...el, collapsed: false, readonly: false } : el
    );
    setCurrentItems(updatedItems);
  };

  const handleCancel = (index: number) => {
    const updatedItems = items.map((el, idx) =>
      idx === index
        ? {
            ...el,
            collapsed: true,
            readonly: true,
          }
        : el
    );
    setCurrentItems(updatedItems);
  };

  return (
    <Container>
      <ul>
        {currentItems.map((item, index) => (
          <li
            key={index}
            onClick={() => setSelectedRow(index)}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(-1)}
          >
            <ItemWrapper
              className="row"
              readonly={item.readonly}
              collapsed={item.collapsed}
            >
              <div className="action-buttons">
                <Button
                  width={24}
                  height={24}
                  icon={<PencilIcon color={item.readonly ? '' : '#fff'} />}
                  type={item.readonly ? 'roundDark' : 'roundGreen'}
                  action={() => handleEdit(index)}
                />
                {item.readonly && (
                  <Button
                    width={24}
                    height={24}
                    icon={
                      item.collapsed ? (
                        <MdKeyboardArrowUp size={24} color="fff" />
                      ) : (
                        <MdKeyboardArrowDown size={24} color="fff" />
                      )
                    }
                    type="roundDark"
                    action={() =>
                      handleChange(index, 'collapsed', !item.collapsed)
                    }
                  />
                )}
              </div>
              {(item.collapsed || item.readonly) && (
                <ItemTitle>
                  <div>{item.fieldName}</div>
                  <div>{getFieldLabel(item.fieldType)}</div>
                </ItemTitle>
              )}
              <div className="fields-wrapper">
                {item.readonly ? (
                  <ViewItem
                    {...item}
                    index={index}
                    selected={selectedRow === index}
                  />
                ) : (
                  <EditItem
                    {...item}
                    index={index}
                    selected={selectedRow === index}
                    onChange={handleChange}
                    onSave={handleSave}
                    onCancel={handleCancel}
                  />
                )}
              </div>
            </ItemWrapper>
            {hoverIndex === index && (
              <ItemControls
                number={index}
                itemsList={currentItems}
                setItemsList={newItems => {
                  setCurrentItems(newItems);
                  setItems(newItems);
                  onSave && onSave(newItems);
                }}
              />
            )}
          </li>
        ))}
      </ul>
      <AddItemContainer onClick={() => addItem()}>
        <span>{i18n.t('Add field')}</span>
        <AddItemIcon>
          <MdAdd color="#fff" />
        </AddItemIcon>
      </AddItemContainer>
    </Container>
  );
};

export default DynamicInput;
