import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Router from './Router';

interface RootState {
  user: any;
}

const mapState = (state: RootState) => ({
  user: state.user,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
type IProps = PropsFromRedux & {};

class App extends Component<IProps, {}> {
  render() {
    const { user } = this.props;
    return (
      <div className="app">
        <Router />
        <ToastContainer hideProgressBar={true} toastClassName="custom-notify" />
      </div>
    );
  }
}

export default connector(App);
