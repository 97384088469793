import React, { useState } from 'react';
import { generateQRUrl } from '../../Lib/Utils/QR';
import { generateQrPdf } from '../../Lib/Utils/PDF';
import { v4 as uuidv4 } from 'uuid';
import { PopUp, Label, Checkbox, CustomTooltip } from '..';
import i18n from 'i18next';
import { QR } from '../../Lib/Configs';

import {
  Content,
  Container,
  Clickable,
  CheckboxContainer,
  QRIcon,
} from './styles';

interface Props {
  qr: Array<{
    lotId: string;
    skuku: string;
  }>;
  children?: any;
  tooltip?: string;
  disabled?: boolean;
}

const QrPdf = ({ qr, children, tooltip, disabled = false }: Props) => {
  const { baseUrl } = QR;
  const generateUrl = (skuku, lotId) =>
    `${baseUrl}?skuku=${skuku}&lotId=${lotId}`;
  const id = `qr-${uuidv4()}`;

  const qrsData = qr.map(({ lotId, skuku }, index) => {
    const qrId = `qr-${index}-${uuidv4()}`;
    return { skuku, lotId, qrId };
  });

  const [popUpShow, setPopUpShow] = useState(false);
  const [lotIdSelected, setLotIdSelected] = useState(true);
  const [skukuSelected, setSkukuSelected] = useState(false);

  const confirmGenerateAction = () => {
    generateQrPdf(qrsData, lotIdSelected, skukuSelected);
    setPopUpShow(false);
  };

  const cancelAction = () => {
    setPopUpShow(false);
  };

  return (
    <Content>
      {qrsData.map(({ skuku, lotId, qrId }) =>
        generateQRUrl(generateUrl(skuku, lotId), qrId)
      )}
      <Clickable
        onClick={() => !disabled && setPopUpShow(true)}
        data-tip
        data-for={`qr-code-${id}`}
        disabled={disabled}
      >
        {children ? (
          children
        ) : (
          <QRIcon src={require('../../Rsc/Images/qr-icon.png')} />
        )}
      </Clickable>
      <CustomTooltip id={`qr-code-${id}`}>
        <span>{tooltip || i18n.t('Generate QR code.')}</span>
      </CustomTooltip>
      <PopUp
        title={i18n.t('QR Code Generation')}
        content={
          <Container>
            <p>
              {i18n.t(
                "Please select what you'd like to include in the QR code file."
              )}
            </p>
            <CheckboxContainer>
              <div>
                <Label>
                  <Checkbox
                    onChange={evt => setLotIdSelected(evt.target.checked)}
                    checked={lotIdSelected}
                    name="lotIdSelected"
                  />
                  Lot #
                </Label>
              </div>
              <div>
                <Label>
                  <Checkbox
                    onChange={evt => setSkukuSelected(evt.target.checked)}
                    checked={skukuSelected}
                    name="skukuSelected"
                  />
                  SKUKU
                </Label>
              </div>

              <div>
                <Label data-tip data-for={`qr-code-required-${id}`}>
                  <Checkbox
                    checked={true}
                    name="sukuLogoSelected"
                    disabled={true}
                  />
                  SUKU Logo
                </Label>
                <CustomTooltip id={`qr-code-required-${id}`}>
                  <span>{i18n.t('Required.')}</span>
                </CustomTooltip>
              </div>
            </CheckboxContainer>
          </Container>
        }
        cancelText={i18n.t('Cancel')}
        confirmText={i18n.t('Generate')}
        confirmAction={confirmGenerateAction}
        cancelAction={cancelAction}
        negativeAction={cancelAction}
        show={popUpShow}
      />
    </Content>
  );
};

export default QrPdf;
