import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import ItemControls from './ItemControls';
import { MdAdd } from 'react-icons/md';
import { FaFlagCheckered, FaRegQuestionCircle } from 'react-icons/fa';
import {
  Container,
  List,
  Item,
  ItemName,
  ItemIcon,
  ParrallelLine,
} from './styles';
import PopUp from '../PopUp';
import { FacilityTypeIcons } from '../../Lib/Configs';

interface EnumListItem {
  number: number;
  name: string;
  active: boolean;
  icon: any;
  facilityType?: string;
  facilityTypeIcon?: string;
  facilityClaims?: Array<string>;
  isParallel?: boolean;
}

interface Props {
  items: Array<EnumListItem>;
  handleStepsChange: any;
  assetsInventory?: Array<any>;
}

const StepTabs = ({ items, assetsInventory, handleStepsChange }: Props) => {
  const [itemsList, setItemsList] = useState(items);

  useEffect(() => {
    if (items && items.length) {
      if (JSON.stringify(items) !== JSON.stringify(itemsList))
        setItemsList(items);
    }
  }, [items]);

  useEffect(() => {
    const initialItems = [...items];
    setItemsList([...initialItems]);
  }, []);

  const [popUpShow, setPopUpShow] = useState(false);
  const [popUpAction, setPopUpAction] = useState<() => void>();

  useEffect(() => {
    handleStepsChange(itemsList);
  }, [itemsList]);

  const numberSort = ({ number: numberA }, { number: numberB }) => {
    if (numberA != null && numberB != null) {
      if (numberA > numberB) {
        return 1;
      }
      if (numberB > numberA) {
        return -1;
      }
    }
    return 0;
  };

  const handleItemClick = selectedNumber => {
    const newItemsList = itemsList.map(item => ({
      ...item,
      active: item.number === selectedNumber,
    }));
    setItemsList(newItemsList);
  };

  const handleAddStep = () => {
    const maxItemNumber = Math.max(...itemsList.map(i => i.number));
    const newStep = {
      number: maxItemNumber + 1,
      name: `${i18n.t('Step')} ${maxItemNumber + 1}`,
      active: false, // maxItemNumber === -1,
      icon: <FaRegQuestionCircle />,
      description: '',
      facilityClaims: [],
      facilityType: '',
      facilityTypeIcon: 'QuestionMark',
      inboundAssetsReceived: [],
      outboundAssetsReceived: [],
      inboundAssetsInventory: assetsInventory,
      outboundAssetsInventory: assetsInventory,
      isParallel: false,
    };
    const newItemsList = [...itemsList];
    newItemsList.push(newStep);
    setItemsList(newItemsList);
  };

  const handleDeleteStep = action => {
    setPopUpShow(true);
    setPopUpAction(action);
  };

  const popUpConfirmAction = () => {
    if (popUpAction) {
      popUpAction();
    }
    setPopUpShow(false);
  };

  const lastItem = itemsList.find(i => i.number === -1);
  const LastItemIcon =
    lastItem && lastItem.facilityTypeIcon ? (
      FacilityTypeIcons[lastItem.facilityTypeIcon]
    ) : (
      <FaFlagCheckered />
    );

  i18n.on('languageChanged', () => {
    // Last step item won't update if not replaced
    setItemsList(
      itemsList.map(i => {
        if (i.number === -1) {
          i.name = i18n.t('Last step');
        }
        return i;
      })
    );
  });

  return (
    <Container>
      <List>
        <Item className="addStep" onClick={handleAddStep}>
          <ItemName>{i18n.t('Add step')}</ItemName>
          <ItemIcon>
            <MdAdd />
          </ItemIcon>
        </Item>

        {itemsList
          .filter(i => i.number !== -1)
          .sort(numberSort)
          .map(
            (
              {
                number,
                name,
                active,
                icon,
                facilityType,
                facilityTypeIcon,
                isParallel,
              },
              index
            ) => {
              const IconComponent = facilityTypeIcon
                ? FacilityTypeIcons[facilityTypeIcon]
                : icon;
              return (
                <Item
                  key={index}
                  onClick={() => handleItemClick(number)}
                  className={active ? 'active' : ''}
                >
                  {isParallel && <ParrallelLine></ParrallelLine>}
                  <ItemName>
                    {facilityType && facilityType.length > 12
                      ? facilityType.substring(0, 12) + ' ...'
                      : facilityType || name}
                  </ItemName>
                  <ItemIcon>{IconComponent}</ItemIcon>
                  {active && (
                    <ItemControls
                      number={number}
                      itemsList={itemsList}
                      setItemsList={setItemsList}
                      deleteItemAction={handleDeleteStep}
                    />
                  )}
                </Item>
              );
            }
          )}
        {lastItem && (
          <Item
            className={`lastStep ${lastItem.active && 'active'}`}
            onClick={() => handleItemClick(-1)}
          >
            {lastItem.isParallel && <ParrallelLine></ParrallelLine>}
            <ItemName>{lastItem.facilityType || lastItem.name}</ItemName>
            <ItemIcon>{LastItemIcon}</ItemIcon>
          </Item>
        )}
      </List>
      <PopUp
        title={i18n.t('Delete this step?')}
        content={i18n.t(
          'Are you sure you want to delete this step? All information will be lost.'
        )}
        cancelText={i18n.t('No')}
        confirmText={i18n.t('Yes')}
        confirmAction={popUpConfirmAction}
        cancelAction={() => setPopUpShow(false)}
        show={popUpShow}
      />
    </Container>
  );
};

export default StepTabs;
