import { authAxiosCall } from '../axiosCall';

export const getBrands = async () =>
  authAxiosCall('/brands', {
    method: 'GET',
    excludeOrganization: true,
  });

export const getIdentifierTypes = async () =>
  authAxiosCall('/types/identifiers', {
    method: 'GET',
  });

export const getCategories = async () =>
  authAxiosCall('/types/categories', {
    method: 'GET',
  });

export const getMarketplaces = async () =>
  authAxiosCall('/marketplaces', {
    method: 'GET',
  });

export const getClaimTypes = async () =>
  authAxiosCall('/claims/types', {
    method: 'GET',
  });

export const getAsset = async payload =>
  authAxiosCall(`/assets`, {
    query: { ...payload },
    method: 'GET',
  });

export const getCatalogedAsset = async suku =>
  authAxiosCall(`/assettemplates/${suku}`, {
    method: 'GET',
  });
