import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props extends RouteComponentProps {
  linkTo: string,
  name: string,
  active: boolean,
  Icon: any,
  history: any,
}

const Container = styled.div<{active: boolean}>`
  ${props => `
    height: 43px;
    opacity: ${props.active ? 1 : 0.5 };
    background: ${props.active ? '#000' : ''};
    display: flex;
    align-items: center;
    padding-left: 31px;
    border-right: ${props.active ? '5px solid #D7F30A' : 'none'};
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
      opacity: ${props.active ? 0.8 : 1};
    }

    svg {
      fill: #fff;
    }
  `}
`;

const ItemName = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin-left: 8px;
  color: #fff;
`;

const NavItem = ({ linkTo, name, Icon, active, history }: Props) => {
  return (
    <div onClick={() => history.push(`/${linkTo}`)}>
      <Container className="nav-item-container" active={active}>
        <Icon />
        <ItemName className="item-name">{name}</ItemName>
      </Container>
    </div>
  );
}

export default withRouter(NavItem);
