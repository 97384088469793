import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;

  .select-input {
    flex: 1;

    > div {
      background: white;
    }
  }

  .field-required {
    margin-right: 5px;
    font-size: 14px;
    color: #757575;
    float: left;
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Encode Sans, sans-serif',
    color: '#757575',
    backgroundColor: state.isFocused ? '#efefef' : 'none',
    minHeight: 30,
    margin: 0,
  }),
  indicatorSeparator: () => {},
  placeholder: () => ({
    fontFamily: 'Encode Sans, sans-serif',
    fontSize: '14px',
    fontWeight: 'normal',
    color: 'rgba(117, 117, 117, 0.5)',
  }),
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    borderBottom: state.isFocused
      ? '1px solid #A7BF2E !important'
      : '1px solid #97979735 !important',
    borderRadius: '0',
    outline: 'none',
    boxShadow: 'none',
    color: '#303030',
    fontFamily: 'Encode Sans, sans-serif',
  }),
  valueContainer: provided => ({
    ...provided,
  }),
  menu: provided => ({
    ...provided,
    margin: 0,
    padding: 0,
    borderRadius: '0 0 8px 8px',
    background: '#F9F9FB',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    whiteSpace: 'nowrap',
    width: '100%',
  }),
  menuList: provided => ({
    ...provided,
    paddingLeft: 0,
    marginLeft: 0,
  }),
};

export { Container, customStyles };
