import { EModalActionTypes } from './modalActionTypes';

const initialState = {
  props: {
    isOpen: false,
    className: 'modal-lg',
  },
  body: null,
  headerText: '',
  footer: null,
};

interface State {
  props: any;
  body: any;
  headerText: string;
  footer: any;
}

interface ModalAction extends State {
  type: EModalActionTypes;
  payload: any;
}

type TModalReducerActions = ModalAction;

export default function(
  state: State = initialState,
  action: TModalReducerActions
) {
  switch (action.type) {
    case EModalActionTypes.SHOW_MODAL:
      return {
        ...state,
        props: {
          ...state.props,
          isOpen: true,
          className: action.payload && action.payload.className,
        },
        headerText: action.payload && action.payload.headerText,
        body: action.payload && action.payload.body,
        footer: action.payload && action.payload.footer,
      };

    case EModalActionTypes.HIDE_MODAL:
      return {
        ...state,
        props: {
          ...state.props,
          isOpen: false,
        },
      };
    case EModalActionTypes.CLEAN_MODAL:
      return {
        ...initialState,
        props: {
          ...initialState.props,
        },
      };
    default:
      return state;
  }
}
