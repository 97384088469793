import React, { useEffect } from 'react';
import i18n from 'i18next';
import {
  AssetReviewSubmitContainer as Container,
  AssetReviewSubmitItemsContainer as ItemsContainer,
  AssetReviewSubmitMarketableDisclaimer as MarketableDisclaimer,
  AssetReviewSubmitItemsWrapper as ItemsWrapper,
  AssetReviewSubmitExtraDataList as ExtraDataList,
  AssetReviewSubmitInputsListContainer as InputsListContainer,
  AssetReviewSubmitMediaContainer as MediaContainer,
  AssetReviewSubmitSmartTagsContainer as SmartTagsContainer,
} from './styles';

import {
  EditableItem,
  CheckIcon,
  Link,
  InputsList,
  MediaUpload,
} from '../../../Components';
import { DynamicField } from './types';

interface Props {
  onChange: Function;
  onStepsChange?: Function;
  formMethods: any;
  _id?: string;
  skuku?: string;
  identifierTypes?: Array<string>;
  categories?: Array<string>;
  brands?: Array<any>;
  markets?: Array<any>;
  assetDetailFirstLoad?: boolean;
  isMarketableProduct?: boolean;
  name?: string;
  brandId?: string;
  type?: string;
  value?: string;
  description?: string;
  category?: string;
  website?: string;
  marketplaces?: Array<string>;
  extras?: Array<{ title?: string; description?: string }>;
  noExtras?: boolean;
  photos?: Array<any>;
  videos?: Array<any>;
  fields?: Array<DynamicField>;
  isDraft?: boolean;
  entityId?: string;
  embedded?: boolean;
}

const AssetReviewSubmit = ({
  onChange,
  onStepsChange,
  formMethods,
  isDraft,
  entityId,
  embedded,
  ...data
}: Props) => {
  const {
    _id,
    skuku,
    name,
    brandId,
    brands,
    type,
    value,
    description,
    category,
    website,
    marketplaces,
    markets,
    isMarketableProduct,
    extras,
    noExtras,
    photos,
    videos,
    fields = [],
  } = data;

  const { errors, clearError } = formMethods;

  useEffect(() => {
    clearError();
  }, []);

  const baseUrl = isDraft
    ? entityId
      ? `/assets/${entityId}/draft`
      : '/assets/draft'
    : `/assets/${entityId}/edit`;

  const summaryError =
    !!errors.name ||
    !!errors.brandId ||
    !!errors.type ||
    !!errors.value ||
    !!errors.description ||
    !!errors.category ||
    !!errors.website;

  const mediaError = !!errors.photos;
  const fieldsError = !!errors.fields;

  console.log('=====================');
  console.log('photos', photos);
  console.log('videos', videos);
  console.log('=====================');

  return (
    <Container>
      <ItemsWrapper>
        <EditableItem
          title={i18n.t('Summary')}
          incomplete={summaryError}
          collapsible={true}
          linkTo={
            embedded ? { search: '?tab=Overview' } : `${baseUrl}?tab=Overview`
          }
          content={
            <>
              {isMarketableProduct && (
                <MarketableDisclaimer>
                  <CheckIcon />
                  This asset is a “marketable product”
                </MarketableDisclaimer>
              )}

              <EditableItem
                editable={false}
                title={i18n.t('Asset name')}
                content={name && <strong>{name}</strong>}
              />

              <ItemsContainer>
                <EditableItem
                  editable={false}
                  title={i18n.t('Brand')}
                  content={
                    brandId ? brands?.find(b => b._id === brandId)?.name : ''
                  }
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Identifier type')}
                  content={type ? type.toUpperCase() : ''}
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Identifier value')}
                  content={value}
                />
              </ItemsContainer>

              <EditableItem
                editable={false}
                title={i18n.t('Asset description')}
                content={description && <p>{description}</p>}
              />

              <ItemsContainer>
                <EditableItem
                  editable={false}
                  title={i18n.t('Asset type')}
                  content={category}
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Website')}
                  content={website && <Link url={website || ''} />}
                />
              </ItemsContainer>

              <EditableItem
                editable={false}
                title={i18n.t(
                  'Marketplace(s) that this asset will be available on'
                )}
                content={
                  marketplaces && (
                    <div>
                      {marketplaces?.map((m, key) => {
                        const item = markets?.find(({ _id }) => _id === m);
                        if (item) {
                          return (
                            <div key={key}>
                              {item.name}
                              <br />
                            </div>
                          );
                        }
                        return <div key={key} />;
                      })}
                    </div>
                  )
                }
              />
            </>
          }
        />
      </ItemsWrapper>

      <ItemsWrapper>
        <EditableItem
          collapsible={true}
          title={i18n.t('Extra detail')}
          linkTo={
            embedded ? { search: '?tab=Extras' } : `${baseUrl}?tab=Extras`
          }
          content={
            extras &&
            extras.length > 0 &&
            !(
              extras.length === 1 &&
              extras[0].title === '' &&
              extras[0].description === ''
            ) && (
              <ExtraDataList>
                {extras?.map(({ title, description }, index) => (
                  <div key={index}>
                    <span>{title}</span>
                    <p>{description}</p>
                  </div>
                ))}
              </ExtraDataList>
            )
          }
        />
      </ItemsWrapper>
      <ItemsWrapper>
        <EditableItem
          collapsible={true}
          title={i18n.t('Data input')}
          overflow={'visible'}
          incomplete={fieldsError}
          content={
            fields.length > 0 && (
              <InputsListContainer>
                <InputsList
                  items={fields.map(
                    ({
                      fieldName,
                      fieldType,
                      fieldPlaceholder,
                      options,
                      isMandatory,
                      maxChars,
                    }) => ({
                      fieldName,
                      fieldType,
                      fieldPlaceholder,
                      value: '',
                      options,
                      isMandatory,
                      maxChars,
                    })
                  )}
                  defaultOpen={null}
                />
              </InputsListContainer>
            )
          }
          linkTo={embedded ? { search: '?tab=Data' } : `${baseUrl}?tab=Data`}
        />
      </ItemsWrapper>

      <ItemsWrapper>
        <EditableItem
          title={i18n.t('Smart tags')}
          collapsible={true}
          editable={false}
          content={
            <SmartTagsContainer>
              <h3>{i18n.t('Coming soon...')}</h3>
            </SmartTagsContainer>
          }
        />
      </ItemsWrapper>

      <EditableItem
        title={i18n.t('Media')}
        collapsible={true}
        incomplete={mediaError}
        linkTo={embedded ? { search: '?tab=Media' } : `${baseUrl}?tab=Media`}
        content={
          ((photos && photos.length > 0) || (videos && videos.length > 0)) && (
            <>
              {photos && photos.length > 0 && (
                <MediaContainer>
                  <h3>
                    {i18n.t(
                      'Photos displayed in the media gallery of your product'
                    )}
                  </h3>
                  <MediaUpload
                    proloadedItemsLinks={photos}
                    readonly
                    showLink
                    linksList={[2]}
                  />
                </MediaContainer>
              )}
              {videos && videos.length > 0 && (
                <MediaContainer>
                  <h3>
                    {i18n.t(
                      'Videos displayed in the media gallery of your product'
                    )}
                  </h3>
                  <MediaUpload
                    type="video"
                    proloadedItemsLinks={videos}
                    readonly
                    showLink
                    linksList={[1]}
                  />
                </MediaContainer>
              )}
            </>
          )
        }
      />
    </Container>
  );
};

export default AssetReviewSubmit;
