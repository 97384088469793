import * as campaignService from '../../Services/Api/campaignService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { ECampaignActionTypes } from './campaignActionsTypes';
import {
  SaveCampaignActionType,
  GetCampaignActionType,
} from '../../Services/Api/campaignService/types';

const getCampaigns = createAction(ECampaignActionTypes.GET_CAMPAIGNS);
const getCampaignsSuccess = createAction(
  ECampaignActionTypes.GET_CAMPAIGNS_SUCCESS,
  (campaigns: any) => ({ campaigns })
);
const getCampaignsError = createAction(
  ECampaignActionTypes.GET_CAMPAIGNS_ERROR,
  (message: string) => ({ message })
);

const getCampaign = createAction(ECampaignActionTypes.GET_CAMPAIGN);
const getCampaignSuccess = createAction(
  ECampaignActionTypes.GET_CAMPAIGN_SUCCESS,
  (campaign: any) => ({ campaign })
);
const getCampaignError = createAction(
  ECampaignActionTypes.GET_CAMPAIGN_ERROR,
  (message: string) => ({ message })
);

const saveCampaign = createAction(ECampaignActionTypes.SAVE_CAMPAIGN);
const saveCampaignSuccess = createAction(
  ECampaignActionTypes.SAVE_CAMPAIGN_SUCCESS,
  (
    campaign: any,
    isDraft?: boolean,
    submitted?: boolean,
    notifySuccess?: boolean
  ) => ({ campaign, isDraft, submitted, notifySuccess })
);

const saveCampaignError = createAction(
  ECampaignActionTypes.SAVE_CAMPAIGN_ERROR,
  (message: string) => ({ message })
);

const deleteCampaign = createAction(
  ECampaignActionTypes.DELETE_CAMPAIGN,
  (campaignId: any) => ({ campaignId })
);
const deleteCampaignSuccess = createAction(
  ECampaignActionTypes.DELETE_CAMPAIGN_SUCCESS,
  (payload: any) => payload
);
const deleteCampaignError = createAction(
  ECampaignActionTypes.DELETE_CAMPAIGN_ERROR,
  (message: string) => ({ message })
);

export const doGetCampaigns = (
  payload = {},
  includeDrafts?: boolean,
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getCampaigns());
  return await tap(
    campaignService.getCampaigns(payload, includeDrafts),
    campaigns => {
      dispatch(getCampaignsSuccess(campaigns));
    },
    error => {
      const _message = 'Failed to retrieve Campaigns. Please try again later.';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getCampaignsError(message));
    }
  );
};

export const doGetCampaign = (
  id: string,
  actionType: GetCampaignActionType
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getCampaign());
  return await tap(
    campaignService.getCampaign(id, actionType),
    campaign => {
      dispatch(getCampaignSuccess(campaign));
    },
    error => {
      const _message = 'Failed to retrieve Campaigns. Please try again later.';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getCampaignError(message));
    }
  );
};

export const doSaveCampaign = (
  campaign,
  actionType: SaveCampaignActionType,
  notifySuccess?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveCampaign());
  return await tap(
    campaignService.saveCampaign(campaign, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the claim _id
      }
      dispatch(
        saveCampaignSuccess(
          data,
          isDraft,
          !!actionType.match('submit'),
          notifySuccess
        )
      );
    },
    error => {
      const _message = 'Failed to save Campaign. Please try again later.';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveCampaignError(message));
    }
  );
};


export const doDeleteCampaign = (
  campaignId: string,
  isDraft: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(deleteCampaign(campaignId));
  return await tap(
    campaignService.deleteCampaign(campaignId, isDraft),
    response => {
      dispatch(deleteCampaignSuccess(response.data));
    },
    error => {
      const _message = 'Failed to delete Campaign. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(deleteCampaignError(message));
    }
  );
};
