const FieldTypes: Array<{ value: string; label: string }> = [
  { value: 'checkBoxes', label: 'Check box' },
  { value: 'date', label: 'Date' },
  { value: 'dropDown', label: 'Drop down' },
  { value: 'listBox', label: 'List box (multi-select)' },
  { value: 'number', label: 'Number' },
  { value: 'radioButtons', label: 'Radio buttons' },
  { value: 'textArea', label: 'Text area' },
  { value: 'string', label: 'Text input' },
];
export default FieldTypes;
