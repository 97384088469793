import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';
import { Campaign } from '../../../../Lib/Utils/Validations';

import {
  NumberBadge,
  CampaignTargetingFormContainer as FormContainer,
} from '../styles';

import {
  Label,
  CustomTooltip,
  Checkbox,
  InfoIcon,
  DatePicker,
  FormInput,
  Switch,
} from '../../../../Components';

interface Props {
  onChange: Function;
  isBornOnOrAfter?: boolean;
  isBornNoLaterThan?: boolean;
  isNumberOfScans?: boolean;
  isNumberOfReviews?: boolean;
  isSukuEarned?: boolean;
  isCompletedBasicProfile?: boolean;
  isWalletCreated?: boolean;
  isPushNotificationEnabled?: boolean;
  bornOnOrAfter?: Date;
  bornNoLaterThan?: Date;
  numberOfScans?: number;
  numberOfReviews?: number;
  sukuEarned?: number;
  notificationMessage?: string;
  campaignTargetingUserFirstLoad?: boolean;
}

const JourneySteps = ({ onChange, ...data }: Props) => {
  const {
    isBornOnOrAfter = false,
    isBornNoLaterThan = false,
    isNumberOfScans = false,
    isNumberOfReviews = false,
    isSukuEarned = false,
    isCompletedBasicProfile = false,
    isWalletCreated = false,
    isPushNotificationEnabled = false,
    bornOnOrAfter,
    bornNoLaterThan,
    numberOfScans,
    numberOfReviews,
    sukuEarned,
    notificationMessage,
    campaignTargetingUserFirstLoad,
  } = data;

  const {
    errors,
    setError,
    clearError,
    control,
    setValue,
    getValues,
    triggerValidation,
    register,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    campaignTargetingUserFirstLoad === false && triggerValidation();
    onChange('campaignTargetingUserFirstLoad', false);
  }, []);

  useEffect(() => {

    setValue([
      { isBornOnOrAfter: isBornOnOrAfter },
      { isBornNoLaterThan: isBornNoLaterThan },
      { isNumberOfScans: isNumberOfScans },
      { isNumberOfReviews: isNumberOfReviews },
      { isSukuEarned: isSukuEarned },
      { isCompletedBasicProfile: isCompletedBasicProfile },
      { isWalletCreated: isWalletCreated },
      { isPushNotificationEnabled: isPushNotificationEnabled },
      { bornOnOrAfter: bornOnOrAfter },
      { bornNoLaterThan: bornNoLaterThan },
      { numberOfScans: numberOfScans },
      { numberOfReviews: numberOfReviews },
      { sukuEarned: sukuEarned },
      { notificationMessage: notificationMessage },
    ]);

    // Custom validations
    if (isPushNotificationEnabled) {
      setError(
        'notificationMessage',
        'customNotificationMessage',
        Campaign.notificationMessage(notificationMessage)
      );
    } else {
      clearError('notificationMessage');

    }

    if (isPushNotificationEnabled) {
      setError(
        'notificationMessage',
        'customNotificationMessage',
        Campaign.notificationMessage(notificationMessage)
      );
    } else {
      clearError('notificationMessage');
    }

  }, [
    isBornOnOrAfter,
    isBornNoLaterThan,
    isNumberOfScans,
    isNumberOfReviews,
    isSukuEarned,
    isCompletedBasicProfile,
    isWalletCreated,
    isPushNotificationEnabled,
    bornOnOrAfter,
    bornNoLaterThan,
    numberOfScans,
    numberOfReviews,
    sukuEarned,
    notificationMessage,
  ]);

  useEffect(() => {
    triggerValidation('numberOfScans');
  }, [isNumberOfScans]);

  useEffect(() => {
    triggerValidation('numberOfReviews');
  }, [isNumberOfReviews]);

  useEffect(() => {
    triggerValidation('sukuEarned');
  }, [isSukuEarned]);

  useEffect(() => {

    campaignTargetingUserFirstLoad === false &&
      triggerValidation(['bornOnOrAfter']);
  }, [isBornOnOrAfter]);

  useEffect(() => {
    campaignTargetingUserFirstLoad === false &&
      triggerValidation(['bornNoLaterThan']);
  }, [isBornNoLaterThan]);

  return (
    <FormContainer className="row">
      <div className="col-md-6 col-sm-12">
        <Label>
          {i18n.t('What users would you like to target in this campaign?')}
          <div data-tip data-for="target">
            <InfoIcon />
          </div>
          <CustomTooltip id="target" placement="right">
            <span>
              {i18n.t(
                'Use the options below to narrow down who will receive the campaign.'
              )}
            </span>
          </CustomTooltip>
        </Label>
        <div>
          <ul>
            <li className="mt-3">
              <div className="row">
                <div className="col-6">
                  <div className="checkbox-wrapper">
                    <Label thin={true}>
                      <Controller
                        as={
                          <Checkbox
                            onChange={evt =>
                              onChange(evt.target.name, evt.target.checked)
                            }
                            checked={isBornOnOrAfter ? isBornOnOrAfter : false}
                            name="isBornOnOrAfter"
                          />
                        }
                        inputRef={register}
                        name="isBornOnOrAfter"
                        value={isBornOnOrAfter}
                        control={control}
                        onChange={([evt]) => {
                          onChange(evt.target.name, evt.target.checked);
                          return evt.target.checked;
                        }}
                        defaultValue={isBornOnOrAfter}
                      />
                      {i18n.t('Born on or after')}:
                    </Label>
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <Controller
                    as={
                      <DatePicker
                        onChange={value => onChange('bornOnOrAfter', value)}
                        value={bornOnOrAfter}
                        required={isBornOnOrAfter}
                      />
                    }
                    required={isBornOnOrAfter}
                    disabled={!isBornOnOrAfter}
                    name="bornOnOrAfter"
                    value={bornOnOrAfter}
                    control={control}
                    onChange={([value]) => {
                      onChange('bornOnOrAfter', value);
                      triggerValidation('bornOnOrAfter');
                      triggerValidation('bornNoLaterThan');
                      return value;
                    }}
                    rules={Campaign.customBornOnOrAfter(getValues)}
                    defaultValue={bornOnOrAfter}
                  />
                  {errors.bornOnOrAfter && (
                    <span className="inlineErrorMessage mt-5">
                      {errors.bornOnOrAfter.message}.
                    </span>
                  )}
                </div>
              </div>
            </li>

            <li className="mt-3">
              <div className="row">
                <div className="col-6">
                  <div className="checkbox-wrapper">
                    <Label thin={true}>
                      <Controller
                        as={
                          <Checkbox
                            onChange={evt =>
                              onChange(evt.target.name, evt.target.checked)
                            }
                            checked={
                              isBornNoLaterThan ? isBornNoLaterThan : false
                            }
                            name="isBornNoLaterThan"
                          />
                        }
                        inputRef={register}
                        name="isBornNoLaterThan"
                        value={isBornNoLaterThan}
                        control={control}
                        onChange={([evt]) => {
                          onChange(evt.target.name, evt.target.checked);
                          return evt.target.checked;
                        }}
                        defaultValue={isBornNoLaterThan}
                      />
                      {i18n.t('Born no later than')}:
                    </Label>
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <Controller
                    as={
                      <DatePicker
                        onChange={value => onChange('bornNoLaterThan', value)}
                        value={bornNoLaterThan}
                      />
                    }
                    required={isBornNoLaterThan}
                    disabled={!isBornNoLaterThan}
                    name="bornNoLaterThan"
                    value={bornNoLaterThan}
                    control={control}
                    onChange={([value]) => {
                      onChange('bornNoLaterThan', value);
                      return value;
                    }}
                    rules={Campaign.customBornNoLaterThan(getValues)}
                    defaultValue={bornNoLaterThan}
                  />
                  {errors.bornNoLaterThan && (
                    <span className="inlineErrorMessage  mt-5">
                      {errors.bornNoLaterThan.message}.
                    </span>
                  )}
                </div>
              </div>
            </li>

            <li className="mt-3">
              <div className="row">
                <div className="col-6">
                  <div className="checkbox-wrapper">
                    <Label thin={true}>
                      <Controller
                        as={
                          <Checkbox
                            onChange={evt =>
                              onChange(evt.target.name, evt.target.checked)
                            }
                            checked={isNumberOfScans}
                            name="isNumberOfScans"
                          />
                        }
                        name="isNumberOfScans"
                        value={isNumberOfScans}
                        control={control}
                        onChange={([evt]) => {
                          onChange(evt.target.name, evt.target.checked);
                          return evt.target.checked;
                        }}
                        defaultValue={isNumberOfScans}
                      />
                      {i18n.t('Completed number of scans')}:
                    </Label>
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <Controller
                    as={
                      <FormInput
                        required
                        placeholder={i18n.t('Enter a number')}
                        subType="number"
                        min={0}
                      />
                    }
                    disabled={!isNumberOfScans}
                    required={isNumberOfScans}
                    name="numberOfScans"
                    value={numberOfScans}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Campaign.numberOfScans(getValues)}
                    defaultValue={numberOfScans}
                  />
                  {errors.numberOfScans && (
                    <span className="inlineErrorMessage mt-5">
                      {errors.numberOfScans.message}.
                    </span>
                  )}
                </div>
              </div>
            </li>

            <li className="mt-3">
              <div className="row">
                <div className="col-6">
                  <div className="checkbox-wrapper">
                    <Label thin={true}>
                      <Controller
                        as={
                          <Checkbox
                            onChange={evt =>
                              onChange(evt.target.name, evt.target.checked)
                            }
                            checked={isNumberOfReviews}
                            name="isNumberOfReviews"
                          />
                        }
                        name="isNumberOfReviews"
                        value={isNumberOfReviews}
                        control={control}
                        onChange={([evt]) => {
                          onChange(evt.target.name, evt.target.checked);
                          return evt.target.checked;
                        }}
                        defaultValue={isNumberOfReviews}
                      />
                      {i18n.t('Completed number of reviews')}:
                    </Label>
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <Controller
                    as={
                      <FormInput
                        required
                        placeholder={i18n.t('Enter a number')}
                        subType="number"
                        min={0}
                      />
                    }
                    disabled={!isNumberOfReviews}
                    required={isNumberOfReviews}
                    name="numberOfReviews"
                    value={numberOfReviews}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Campaign.numberOfReviews(getValues)}
                    defaultValue={numberOfReviews}
                  />
                  {errors.numberOfReviews && (
                    <span className="inlineErrorMessage mt-5">
                      {errors.numberOfReviews.message}.
                    </span>
                  )}
                </div>
              </div>
            </li>

            <li className="mt-3">
              <div className="row">
                <div className="col-6">
                  <div className="checkbox-wrapper">
                    <Label thin={true}>
                      <Controller
                        as={
                          <Checkbox
                            onChange={evt =>
                              onChange(evt.target.name, evt.target.checked)
                            }
                            checked={isSukuEarned}
                            name="isSukuEarned"
                          />
                        }
                        name="isSukuEarned"
                        value={isSukuEarned}
                        control={control}
                        onChange={([evt]) => {
                          onChange(evt.target.name, evt.target.checked);
                          return evt.target.checked;
                        }}
                        defaultValue={isSukuEarned}
                      />
                      {i18n.t('SUKU earned (min)')}:
                    </Label>
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <Controller
                    as={
                      <FormInput
                        required
                        placeholder={i18n.t('Enter amount')}
                        subType="number"
                        min={0}
                      />
                    }
                    disabled={!isSukuEarned}
                    required={isSukuEarned}
                    name="sukuEarned"
                    value={sukuEarned}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Campaign.sukuEarned(getValues)}
                    defaultValue={sukuEarned}
                  />
                  {errors.sukuEarned && (
                    <span className="inlineErrorMessage mt-5">
                      {errors.sukuEarned.message}.
                    </span>
                  )}
                </div>
              </div>
            </li>

            <li className="mt-3">
              <div className="row">
                <div className="col-12">
                  <div className="checkbox-wrapper">
                    <Label thin={true}>
                      <Checkbox
                        onChange={evt =>
                          onChange(evt.target.name, evt.target.checked)
                        }
                        checked={isCompletedBasicProfile}
                        name="isCompletedBasicProfile"
                      />
                      {i18n.t('Completed basic profile')}
                    </Label>
                  </div>
                </div>
              </div>
            </li>

            <li className="mt-3">
              <div className="row">
                <div className="col-12">
                  <div className="checkbox-wrapper">
                    <Label thin={true}>
                      <Checkbox
                        onChange={evt =>
                          onChange(evt.target.name, evt.target.checked)
                        }
                        checked={isWalletCreated}
                        name="isWalletCreated"
                      />
                      {i18n.t('Wallet created')}
                    </Label>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div className="row">
            <div className="col-6 d-flex">
              <Label style={{ margin: 0 }}>
                {i18n.t('Number of users impacted')}
              </Label>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <NumberBadge>742</NumberBadge>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-sm-12">
        <Label>
          {i18n.t(
            'Do you want to push notifications to impacted mobile devices?'
          )}
          <div data-tip data-for="notifications">
            <InfoIcon />
          </div>
          <CustomTooltip id="notifications" placement="right">
            <span>
              {i18n.t(
                'A banner notification will be pushed to all devices that have notifications enabled in their user preferences.'
              )}
            </span>
          </CustomTooltip>
        </Label>

        <div className="checkbox-wrapper">
          <Label thin={true}>
            <span className={!isPushNotificationEnabled ? '' : 'disabledText'}>
              {i18n.t('No')}
            </span>
            <Switch
              name="isPushNotificationEnabled"
              checked={isPushNotificationEnabled}
              onChange={evt => onChange(evt.target.name, evt.target.checked)}
            />
            <span className={isPushNotificationEnabled ? '' : 'disabledText'}>
              {i18n.t('Yes')}
            </span>
          </Label>
        </div>

        <div className="mt-4">
          <Label className={isPushNotificationEnabled ? '' : 'disabledText'}>
            {i18n.t('What is the notification message to push to users?')}
          </Label>

          <Controller
            as={
              <FormInput
                maxLength={150}
                type="textarea"
                placeholder={i18n.t('Enter message')}
              />
            }
            required={isPushNotificationEnabled}
            disabled={!isPushNotificationEnabled}
            name="notificationMessage"
            value={notificationMessage}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
          />
          {errors.notificationMessage && (
            <span className="inlineErrorMessage">
              {errors.notificationMessage.message}.
            </span>
          )}
        </div>
      </div>
    </FormContainer>
  );
};

export default JourneySteps;
