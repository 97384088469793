import React from 'react';

export default () => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.8 15.6923L9 8.42645L13.8 13.2623L24 1.70463L22.308 0L13.8 9.63542L9 4.79957L0 13.8789L1.8 15.6923Z"
      fill="white"
    />
  </svg>
);
