import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { RiUserFollowLine, RiUserUnfollowLine } from 'react-icons/ri';

import { Link, CustomTooltip, Label, MediaUpload } from '../index';

import { DescriptionList, MediaContainer } from './styles';

interface Props {
  claims?: Array<{
    _id?: string;
    organizationId?: string;
    shortName?: string;
    description?: Array<string>;
    supportingVerifications?: any;
    userLinks?: Array<string>;
    verifiable?: boolean;
    mediaLinks?: Array<any>;
    links?: Array<string>;
  }>;
  readonly?: boolean;
  onChange?: any;
  notExpires?: boolean;
  noClaimsMessage?: string;
  titleLink?: boolean;
  titleTooltip?: boolean;
}

const ClaimsShow = ({
  claims,
  readonly = false,
  onChange,
  notExpires = false,
  noClaimsMessage = i18n.t(
    'There are no facility-level claims for this journey.'
  ),
  titleLink = true,
  titleTooltip = true,
}: Props) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [newClaims, setNewClaims] = useState(claims);

  useEffect(() => {
    onChange && onChange(newClaims);
  }, [newClaims]);

  const handleMediaChange = (_id, items) => {
    const newClaimsWithItems = newClaims?.map(claim => {
      if (claim._id === _id) {
        return {
          ...claim,
          mediaLinks: items,
        };
      } else {
        return claim;
      }
    });
    setNewClaims(newClaimsWithItems);
  };

  useEffect(() => {
    setNewClaims(claims);
  }, [claims]);

  return (
    <>
      {newClaims?.length === 0 ? (
        <Label>{noClaimsMessage}</Label>
      ) : (
        newClaims?.map(claim => {
          const {
            _id,
            organizationId,
            shortName,
            description,
            supportingVerifications,
            mediaLinks = [],
            verifiable,
            links,
          } = claim;

          const expirationDate = supportingVerifications[0]
            ? new Date(supportingVerifications[0].expirationDate)
            : '';
          const dateVerifiable = moment(expirationDate).isSameOrAfter(
            moment(today)
          );

          const defaultLinks = supportingVerifications[0]
            ? supportingVerifications[0].links.map(link => ({
                src: link,
                user: false,
              }))
            : [];

          const showMedia = readonly || verifiable;
          const allowUpload =
            verifiable &&
            supportingVerifications[0] &&
            supportingVerifications[0].attestor === '';

          return (
            <div
              key={_id}
              className={`row mb-4 endorsement${
                dateVerifiable ? ' endorsement-valid' : ''
              }`}
            >
              <div className="col-md-6 endorsement-title mb-3 flex-column">
                <div className="mb-4 flex-row d-flex">
                  {dateVerifiable ||
                  supportingVerifications.length === 0 ||
                  notExpires ? (
                    <div data-tip data-for={_id} className="mr-2">
                      <RiUserFollowLine color="#A7BF2E" size={24} />
                    </div>
                  ) : (
                    <div data-tip data-for={_id} className="mr-2">
                      <RiUserUnfollowLine color="#F40C28" size={24} />
                    </div>
                  )}
                  {titleTooltip && (
                    <CustomTooltip id={_id}>
                      <span>
                        {dateVerifiable
                          ? i18n.t('This claim has a valid endorsement.')
                          : i18n.t(
                              'This claim has an expired endorsement. This does not prevent you from submitting your data.'
                            )}
                      </span>
                    </CustomTooltip>
                  )}
                  <div className="pt-1">
                    {titleLink &&
                    links &&
                    links.length > 0 &&
                    links[0] !== '' ? (
                      <Link url={links[0]} label={shortName} />
                    ) : (
                      <Label className="float-left">{shortName}</Label>
                    )}
                    {notExpires ? (
                      supportingVerifications[0] ? (
                        <span className="ml-2">
                          {i18n.t('Does not expire')}
                        </span>
                      ) : (
                        <></>
                      )
                    ) : (
                      supportingVerifications[0] && (
                        <span className="ml-2">{`${i18n.t('Expires')} (${moment(
                          supportingVerifications[0].expirationDate
                        ).format('MM/DD/YYYY')})`}</span>
                      )
                    )}
                  </div>
                </div>
                <div>
                  {!readonly && (
                    <DescriptionList>
                      {description &&
                        description.map((description, index) => (
                          <li key={index}>{description}</li>
                        ))}
                    </DescriptionList>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                {showMedia && (
                  <MediaContainer>
                    <MediaUpload
                      accept={[
                        'image/png',
                        'image/jpg',
                        'image/jpeg',
                        'application/pdf',
                      ]}
                      maxFileSize={5 * 1024 * 1024}
                      allowLinks={false}
                      addButtonText={i18n.t('Add verification')}
                      handleChange={items => handleMediaChange(_id, items)}
                      proloadedItemsLinks={
                        mediaLinks.length === 0 ? defaultLinks : mediaLinks
                      }
                      readonly={readonly || !allowUpload}
                    />
                  </MediaContainer>
                )}
              </div>
            </div>
          );
        })
      )}
    </>
  );
};

export default withTranslation()(ClaimsShow as any) as any;
