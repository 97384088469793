import React from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

import { ClaimsShow } from '../../../../../Components';

import { Container, Content } from '../styles';

interface Props {
  onChange?: any;
  claims: Array<any>;
}

const Periodic = ({ onChange, claims }: Props) => {
  return (
    <Container>
      <Content>
        <div className="row">
          <div className="col-12">
            <ClaimsShow
              claims={claims}
              onChange={newClaims => {
                onChange('periodicClaims', newClaims);
              }}
              noClaimsMessage={i18n.t(
                'There are no periodic claims for this journey.'
              )}
            />
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default withTranslation()(Periodic as any) as any;
