import React, { useState, useEffect } from 'react';
import i18n from 'i18next';

import {
  ProductFilterButton,
  CampaignTargetingFormContainer as FormContainer,
  TargetingProductContainer as Container,
} from '../styles';
import {
  FormErrorLabel,
  Label,
  Dropdown,
  FilterIcon,
  TransferList,
  FilterClearIcon,
  Checkbox,
  CustomTooltip,
  InfoIcon,
} from '../../../../Components';

interface Props {
  onChange: Function;
  marketFilter?: string;
  brandFilter?: string;
  marketableProducts?: Array<any>;
  selectedMarketableProducts?: Array<string>;
  noProductTargeting?: boolean;
  assets?: Array<any>;
  marketplaces?: Array<any>;
  brands?: Array<any>;
}

const ProductTargeting = ({
  onChange,
  assets,
  marketplaces,
  brands,
  selectedMarketableProducts,
  ...data
}: Props) => {
  const [defaultMarketableProducts, setDefatultMarketableProducts] = useState(
    [] as any
  );

  useEffect(() => {
    const defaultProducts = assets
      ?.filter(({ marketableProduct }) => marketableProduct)
      .map(asset => ({
        text: asset.name,
        selected: !!selectedMarketableProducts?.find(s => s === asset.skuku),
        data: asset,
      }));
    setDefatultMarketableProducts(defaultProducts);
  }, [assets]);

  const defaultBrandOptions = brands
    ? brands.map(brand => ({
        value: brand._id,
        label: brand.name,
      }))
    : [];

  const defaultMarketplaceOptions = marketplaces
    ? marketplaces.map(marketplace => ({
        value: marketplace._id,
        label: marketplace.name,
      }))
    : [];

  const {
    marketFilter,
    brandFilter,
    marketableProducts = defaultMarketableProducts,
    noProductTargeting = false,
  } = data;

  const filterProduct = p => {
    let matches = true;
    if (marketFilter) {
      matches = marketFilter.includes(p.data.marketplaces);
    }
    if (matches && brandFilter) {
      matches = brandFilter.includes(p.data.brandId);
    }
    return matches;
  };

  const clearFilters = () => {
    if (marketFilter) {
      onChange('marketFilter', '');
    }
    if (brandFilter) {
      onChange('brandFilter', '');
    }
  };

  return (
    <Container>
      <FormContainer className="row">
        {/* <div className="col-12">
          <div className="checkbox-wrapper">
            <Label thin={true}>
              <Checkbox
                onChange={evt => onChange(evt.target.name, evt.target.checked)}
                checked={noProductTargeting}
                name="noProductTargeting"
              />
              {i18n.t(
                'No product targeting; notification will be sent to target user'
              )}
              <div data-tip data-for="target">
                <InfoIcon />
              </div>
              <CustomTooltip id="target" placement="right">
                <span>
                  {i18n.t(
                    'If selected, no product adverts will be pushed and only User Targeting will be available to send a notification to targeted users.'
                  )}
                </span>
              </CustomTooltip>
            </Label>
          </div>
        </div> */}

        {!noProductTargeting && (
          <>
            <div className="col-12">
              <Label>
                {i18n.t(
                  'What marketable products would you like to target in this campaign?'
                )}
              </Label>
              <FormErrorLabel>
                {i18n.t('You must have at least one marketable product')}.
              </FormErrorLabel>
            </div>

            <div className="col-12 col-lg-6 mt-1 mb-3">
              <Label disabled>
                {i18n.t('Narrow down the products by marketplace')}
              </Label>
              <div className="product-filter">
                <ProductFilterButton onClick={() => clearFilters()}>
                  {marketFilter || brandFilter ? (
                    <FilterClearIcon />
                  ) : (
                    <FilterIcon />
                  )}
                </ProductFilterButton>
                <div className="filter-select">
                  <Dropdown
                    placeholder={i18n.t(
                      'Narrow down the product list by marketplace'
                    )}
                    action={values => onChange('marketFilter', values)}
                    required={false}
                    defaultOption={marketFilter}
                    options={defaultMarketplaceOptions}
                    searchable
                    multi
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 mt-1 mb-3">
              <Label disabled>
                {i18n.t('Narrow down the products by brand')}
              </Label>
              <div className="filter-select">
                <Dropdown
                  placeholder={i18n.t('Narrow down the product list by brand')}
                  action={values => onChange('brandFilter', values)}
                  required={false}
                  defaultOption={brandFilter}
                  options={defaultBrandOptions}
                  searchable
                  multi
                />
              </div>
            </div>

            <div className="col-12">
              <Label disabled>
                {i18n.t('Select and add marketable products to the campaign')}
              </Label>
              <TransferList
                fromPlaceholder={i18n.t('Search for marketable products')}
                toPlaceholder={i18n.t('Type to filter')}
                items={marketableProducts || []}
                filter={filterProduct}
                handleChange={items => onChange('marketableProducts', items)}
              />
            </div>
          </>
        )}
      </FormContainer>
    </Container>
  );
};

export default ProductTargeting;
