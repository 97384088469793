import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { doGetClaims, doDeleteClaim } from '../../../Redux/Claim/claimActions';
import {
  doGetAttestors,
  doDeleteAttestor,
} from '../../../Redux/Attestor/attestorActions';
import { doGetFacilities } from '../../../Redux/Facility/facilityActions';
import { doGetJourneyTemplates } from '../../../Redux/Journey/journeyActions';
import { doGetClaimTypes } from '../../../Redux/Catalog/catalogActions';
import {
  Table,
  HeaderFilter,
  AttestorIcon,
  ClaimIcon,
  InProgressButton,
  Loading,
} from '../../../Components';
import { Container } from './styles';
import Toast from '../../../Lib/Utils/toast';
import moment from 'moment';
import { isOrgAdmin } from '../../../Lib/Utils/auth';

interface RootState {
  claim: any;
  attestor: any;
  facility: any;
  journey: any;
  catalog: any;
}

interface InheritProps {
  showHeader?: boolean;
  parentClaims?: Array<any>;
  editCallback?: Function;
  showAttestors?: boolean;
  useStore?: Array<string>;
}

const mapState = (state: RootState) => ({
  claims: state.claim.claims || [],
  attestors: state.attestor.attestors,
  loadingClaims: state.claim.loading,
  loadingAttestors: state.attestor.loading,
  error: state.claim.error,
  facilities: state.facility.facilities,
  journeyTemplates: state.journey.journeyTemplates,
  claimTypes: state.catalog.claimTypes,
  deleteClaimSuccess: state.claim.deleteSuccess,
  deleteAttestorSuccess: state.attestor.deleteSuccess,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getClaimsAction: includeDrafts => dispatch(doGetClaims(null, includeDrafts)),
  getAttestorsAction: includeDrafts =>
    dispatch(doGetAttestors(null, includeDrafts)),
  getFacilitiesAction: () => dispatch(doGetFacilities()),
  getJourneyTemplatesAction: () => dispatch(doGetJourneyTemplates()),
  getClaimTypesAction: () => dispatch(doGetClaimTypes()),
  deleteClaimAction: (claimId, isDraft) =>
    dispatch(doDeleteClaim(claimId, isDraft)),
  deleteAttestorAction: (attestorId, email, isDraft) =>
    dispatch(doDeleteAttestor(attestorId, email, isDraft)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & InheritProps & { history?: any };

const ClaimsList = (props: Props) => {
  const {
    history,
    getClaimsAction,
    getAttestorsAction,
    getFacilitiesAction,
    getJourneyTemplatesAction,
    getClaimTypesAction,
    deleteClaimAction,
    deleteAttestorAction,
    loadingAttestors,
    loadingClaims,
    deleteClaimSuccess,
    deleteAttestorSuccess,
    claims,
    parentClaims,
    attestors,
    error,
    facilities,
    journeyTemplates,
    claimTypes,
    editCallback,
    showHeader = true,
    showAttestors = true,
    useStore = [],
  } = props;

  const [claimRows, setClaimRows] = useState([] as any);
  const [attestorRows, setAttestorRows] = useState([] as any);
  const [filteredClaimRows, setFilteredClaimRows] = useState([] as any);
  const [filteredAttestorRows, setFilteredAttestorRows] = useState([] as any);

  const [claimFilters, setClaimFilters] = useState({});
  const [attestorFilters, setAttestorFilters] = useState({});

  const [claimTextFilter, setClaimTextFilter] = useState('');
  const [attestorTextFilter, setAttestorTextFilter] = useState('');

  useEffect(() => {
    if (!parentClaims) {
      getClaimsAction(true);
      getAttestorsAction(true);
    }
    if (!useStore.includes('facilities')) {
      getFacilitiesAction();
    }
    getJourneyTemplatesAction();
    getClaimTypesAction();
  }, []);

  useEffect(() => {
    if (error) {
      Toast.error(error);
    } else if (deleteClaimSuccess) {
      Toast.success(i18n.t(`The claim was discarded`));
      getClaimsAction(true);
    } else if (deleteAttestorSuccess) {
      Toast.success(i18n.t(`The attestor was discarded`));
      getAttestorsAction(true);
    }
  }, [error, deleteClaimSuccess, deleteAttestorSuccess]);

  useEffect(() => {
    const defaultRows = (parentClaims || claims)
      .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
      .map(claim => {
        const {
          _id,
          facilityId,
          shortName,
          name,
          type,
          createdAt,
          updatedAt,
          supportingVerifications,
          isDraft,
          verifiable,
        } = claim;

        return {
          _id,
          facilityId,
          name,
          shortName,
          type,
          createdAt: moment(createdAt).format('MM/DD/YYYY'),
          updatedAt: moment(updatedAt).format('MM/DD/YYYY'),
          expires: supportingVerifications[0]
            ? moment(supportingVerifications[0].expirationDate).format(
                'MM/DD/YYYY'
              )
            : '-',
          inProgress: isDraft ? (
            <InProgressButton
              action={() =>
                editCallback
                  ? editCallback(_id)
                  : history.push(`/claims/${_id}/draft`)
              }
            />
          ) : (
            false
          ),
          verifiable,
        };
      })
      .map(row => Object.values(row));
    setClaimRows(defaultRows);
  }, [claims, parentClaims]);

  useEffect(() => {
    const defaultRows = attestors
      .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
      .map(attestor => {
        const {
          _id,
          shortName,
          firstName,
          lastName,
          company,
          createdAt,
          updatedAt,
          isDraft,
          email,
        } = attestor;

        return {
          _id,
          shortName,
          name: `${firstName} ${lastName}`,
          company,
          createdAt: moment(createdAt).format('MM/DD/YYYY'),
          updatedAt: moment(updatedAt).format('MM/DD/YYYY'),
          inProgress: isDraft ? (
            <InProgressButton
              action={() => history.push(`/claims/attestors/${_id}/draft`)}
            />
          ) : (
            false
          ),
          email,
        };
      })
      .map(row => Object.values(row));
    setAttestorRows(defaultRows);
  }, [attestors]);

  useEffect(() => applyFilters('claim'), [
    claimFilters,
    claimTextFilter,
    claimRows,
  ]);

  useEffect(() => applyFilters('attestor'), [
    attestorFilters,
    attestorTextFilter,
    attestorRows,
  ]);

  const getFacilitiesOptions = () => {
    return (
      facilities &&
      facilities.map(f => ({
        label: f.registeredName,
        value: f._id,
      }))
    );
  };

  const getJourneysOptions = () => {
    return (
      journeyTemplates &&
      journeyTemplates.map(j => ({
        label: j.journeyName,
        value: j._id,
      }))
    );
  };

  const getClaimTypesOptions = () => {
    return (
      claimTypes &&
      claimTypes.map(c => ({
        label: c,
        value: c,
      }))
    );
  };

  const getCompaniesOptions = () => {
    return (
      attestors &&
      attestors.map(a => ({
        label: a.company,
        value: a.company,
      }))
    );
  };

  const claimHeaders = [
    { text: i18n.t('ID'), order: true },
    { text: i18n.t('Claim Name'), order: true },
    { text: i18n.t('Short Name'), order: true },
    { text: i18n.t('Claim Type'), order: true },
    { text: i18n.t('Created'), order: true },
    { text: i18n.t('Updated'), order: true },
    { text: i18n.t('Expires'), order: true },
    { text: '', order: false, key: 'inProgress' },
  ];

  const attestorHeaders = [
    { text: i18n.t('ID'), order: true },
    { text: i18n.t('Short Name'), order: true },
    { text: i18n.t('Attestor Name'), order: true },
    { text: i18n.t('Company'), order: true },
    { text: i18n.t('Created'), order: true },
    { text: i18n.t('Updated'), order: true },
    { text: '', order: false, key: 'inProgress' },
  ];

  const claimSelects = [
    {
      type: 'checkbox',
      label: i18n.t('In progress'),
      key: 'inprogress',
    },
    {
      type: 'checkbox',
      label: i18n.t('Verifiable'),
      key: 'verifiable',
    },
    {
      type: 'checkbox',
      label: i18n.t('Unverifiable'),
      key: 'unverifiable',
    },
    {
      placeholder: i18n.t('Search by journey'),
      options: getJourneysOptions(),
      key: 'journey',
    },
    {
      placeholder: i18n.t('Search by facility'),
      options: getFacilitiesOptions(),
      key: 'facility',
    },
    {
      placeholder: i18n.t('Search by claim type'),
      options: getClaimTypesOptions(),
      key: 'claimType',
    },
  ];

  const attestorSelects = [
    {
      type: 'checkbox',
      label: i18n.t('In progress'),
      key: 'inprogress',
    },
    {
      placeholder: i18n.t('Search by company'),
      options: getCompaniesOptions(),
      key: 'company',
    },
  ];

  const inputHandler = (type = 'claim', value) => {
    if (type === 'claim') {
      setClaimTextFilter(value);
    } else {
      setAttestorTextFilter(value);
    }
  };

  const handleResetFilters = (type = 'claim') => {
    if (type === 'claim') {
      setClaimFilters({});
    } else {
      setAttestorFilters({});
    }
  };

  const applyFilters = (type = 'claim') => {
    if (type === 'claim') {
      let newFilteredRows = claimRows;

      Object.keys(claimFilters).forEach(key => {
        if (claimFilters[key]) {
          if (key === 'inprogress') {
            newFilteredRows = newFilteredRows.filter(row => !!row[8]);
          }

          if (key === 'verifiable') {
            newFilteredRows = newFilteredRows.filter(row => row[9]);
          }

          if (key === 'unverifiable') {
            newFilteredRows = newFilteredRows.filter(row => !row[9]);
          }

          if (key === 'facility') {
            newFilteredRows = newFilteredRows.filter(
              row => row[1] == claimFilters[key]
            );
          }

          if (key === 'claimType') {
            newFilteredRows = newFilteredRows.filter(
              row => row[4] == claimFilters[key]
            );
          }

          if (key === 'journey' && journeyTemplates) {
            const selectedJourney = journeyTemplates.find(
              j => j._id === claimFilters[key]
            );
            if (selectedJourney && selectedJourney.stepTemplates) {
              let claimIds = [] as Array<string>;
              selectedJourney.stepTemplates.forEach(st => {
                if (st.stepTemplate && st.stepTemplate.claims) {
                  claimIds = claimIds.concat(st.stepTemplate.claims);
                }
              });
              newFilteredRows = newFilteredRows.filter(row =>
                claimIds.includes(row[0])
              );
            }
          }
        }
      });

      if (claimTextFilter && claimTextFilter !== '') {
        newFilteredRows = newFilteredRows.filter(
          row =>
            row
              .join(' ')
              .toUpperCase()
              .search(claimTextFilter.toUpperCase()) !== -1
        );
      }

      setFilteredClaimRows(newFilteredRows);
    } else {
      let newFilteredRows = attestorRows;

      Object.keys(attestorFilters).forEach(key => {
        if (attestorFilters[key]) {
          if (key === 'inprogress') {
            newFilteredRows = newFilteredRows.filter(row => !!row[6]);
          }

          if (key === 'company') {
            newFilteredRows = newFilteredRows.filter(
              row => row[3] == attestorFilters[key]
            );
          }
        }
      });

      if (attestorTextFilter && attestorTextFilter !== '') {
        newFilteredRows = newFilteredRows.filter(
          row =>
            row
              .join(' ')
              .toUpperCase()
              .search(attestorTextFilter.toUpperCase()) !== -1
        );
      }

      setFilteredAttestorRows(newFilteredRows);
    }
  };

  return (
    <>
      <Container>
        <div className="claim-table">
          {showHeader && (
            <HeaderFilter
              title={i18n.t('Existing Claims')}
              titleIcon={<ClaimIcon />}
              searchPlaceholder={i18n.t('Search all columns')}
              actionButtonText={`+ ${i18n.t('New Claim')}`}
              setFilters={setClaimFilters}
              inputHandler={value => inputHandler('claim', value)}
              actionButtonHandler={() => history.push('/claims/draft')}
              selects={claimSelects}
              resetFiltersHandler={() => handleResetFilters('claim')}
              name="claims"
              actionButton={!!isOrgAdmin()}
              useDropdown={true}
            />
          )}
          <Table
            headers={claimHeaders}
            rows={filteredClaimRows}
            pagination
            rowsPerPage={20}
            pagesBtw={2}
            editable={!!isOrgAdmin()}
            handleEdit={data => {
              editCallback
                ? editCallback(data[0])
                : data[8]
                ? history.push(`/claims/${data[0]}/draft`)
                : history.push(`/claims/${data[0]}/edit`);
            }}
            ignoreKeys={[1, 9]}
            archivable={!!isOrgAdmin()}
            archiveTitle={i18n.t(
              'Are you sure you want to archive this claim?'
            )}
            // archiveContent={i18n.t(
            //   'Mappings to any facilities for this claim will also be removed.'
            // )}
            handleArchive={data => deleteClaimAction(data[0], !!data[8])}
          />
        </div>

        {showAttestors && (
          <div className="claim-table mt-5">
            {showHeader && (
              <HeaderFilter
                title={i18n.t('Existing Attestors')}
                titleIcon={<AttestorIcon />}
                searchPlaceholder={i18n.t('Search all columns')}
                actionButtonText={`+ ${i18n.t('New Attestor')}`}
                setFilters={setAttestorFilters}
                inputHandler={value => inputHandler('attestor', value)}
                actionButtonHandler={() =>
                  history.push('/claims/attestors/draft')
                }
                selects={attestorSelects}
                resetFiltersHandler={() => handleResetFilters('attestor')}
                name="attestors"
                actionButton={!!isOrgAdmin()}
                useDropdown={true}
              />
            )}
            <Table
              headers={attestorHeaders}
              rows={filteredAttestorRows}
              pagination
              rowsPerPage={20}
              pagesBtw={2}
              editable={!!isOrgAdmin()}
              handleEdit={data =>
                data[6]
                  ? history.push(`/claims/attestors/${data[0]}/draft`)
                  : history.push(`/claims/attestors/${data[0]}/edit`)
              }
              ignoreKeys={[7]}
              archivable={!!isOrgAdmin()}
              archiveTitle={i18n.t(
                'Are you sure you want to archive this attestor?'
              )}
              handleArchive={data =>
                deleteAttestorAction(data[0], data[7], !!data[6])
              }
            />
          </div>
        )}
      </Container>
      {(loadingClaims || loadingAttestors) && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(ClaimsList) as any) as any
) as any;
