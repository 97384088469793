import React from 'react';
import styled from 'styled-components';


interface Props {
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  hoverColor?: string;
}

const Container = styled.div<{
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  hoverColor?: string;
}>`
  width: ${({ width }) => (width ? `${width}px` : '34px')};
  height: ${({ height }) => (height ? `${height}px` : '34px')};
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#757575'};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    svg path {
      fill: ${({ hoverColor, color }) => hoverColor ? hoverColor : color }
    }
  }
`;

export default (props: Props) => (
  <Container {...props}>
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3986 15.7542L13.9503 13.2536L11.3986 10.7531L12.67 9.50728L15.2217 11.9901L17.7286 9.50728L19 10.7531L16.4841 13.2536L19 15.7542L17.7286 17L15.2217 14.4906L12.67 17L11.3986 15.7542ZM9.84968 14.9148C9.88549 15.1798 9.79596 15.4626 9.59003 15.6481C9.5072 15.73 9.40881 15.795 9.3005 15.8394C9.19219 15.8837 9.07608 15.9065 8.95882 15.9065C8.84156 15.9065 8.72545 15.8837 8.61714 15.8394C8.50883 15.795 8.41044 15.73 8.32761 15.6481L4.73731 12.105C4.63966 12.0108 4.56541 11.8956 4.52037 11.7683C4.47533 11.6411 4.46071 11.5053 4.47767 11.3716V6.84771L0.189012 1.43139C0.0436172 1.24719 -0.0219882 1.01369 0.00653195 0.781903C0.0350521 0.550116 0.155379 0.338892 0.341219 0.194387C0.511333 0.0706861 0.699353 0 0.896327 0H13.431C13.628 0 13.816 0.0706861 13.9861 0.194387C14.172 0.338892 14.2923 0.550116 14.3208 0.781903C14.3493 1.01369 14.2837 1.24719 14.1383 1.43139L9.84968 6.84771V14.9148ZM2.72281 1.76715L6.26834 6.23805V11.1154L8.05901 12.8825V6.22921L11.6045 1.76715H2.72281Z"
        fill={props.color ? props.color : 'white'}
      />
    </svg>
  </Container>
);
