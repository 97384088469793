import React, { useState, useEffect, Dispatch, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { doSaveJourney } from '../../../Redux/Journey/journeyActions';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { doGetFacilities } from '../../../Redux/Facility/facilityActions';
import { doGetClaims } from '../../../Redux/Claim/claimActions';
import { useForm, FormContext } from 'react-hook-form';
import { Journey } from '../../../Lib/Utils/Validations';
import Toast from '../../../Lib/Utils/toast';

import {
  JourneyIcon,
  ContentBox,
  Title,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
} from '../../../Components';

import { JourneyActionButtonsContainer } from './styles';
import JourneyDetails from './JourneyDetails';
import JourneySteps from './JourneySteps';
import JourneyReviewSubmit from './JourneyReviewSubmit';

interface RootState {
  facility: any;
  journey: any;
  claim: any;
}

const mapState = (state: RootState) => ({
  facilities: state.facility.facilities,
  journey: state.journey.currentJourney,
  claims: state.claim.claims || [],
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getFacilitiesAction: () => dispatch(doGetFacilities()),
  saveJourneyAction: payload => dispatch(doSaveJourney(payload)),
  getClaimsAction: payload => dispatch(doGetClaims(payload)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & { history?: any; location?: any };

const NewJourney = (props: Props) => {
  const {
    saveJourneyAction,
    history,
    location,
    getFacilitiesAction,
    facilities,
    getClaimsAction,
    claims,
  } = props;

  const formMethods = useForm({ mode: 'onBlur' });

  const [data, setData] = useState(props.journey);

  const detailsRef = useRef() as any;
  const stepsRef = useRef() as any;

  useEffect(() => {
    getFacilitiesAction();
    getClaimsAction({ facilityId: 1 });
    const populatedData = location.state && location.state.data;
    if (populatedData) {
      setData(populatedData);
    }
  }, []);

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    i18n.t('Journey Details'),
    i18n.t('Journey Steps'),
    i18n.t('Review & Submit'),
  ];

  const handleChange = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const getDefaultActiveTab = () => {
    const query = new URLSearchParams(location.search);
    const tabFromQuery = query.get('tab');
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/[^a-zA-Z0-9]/g, '') === tabFromQuery
    );
    return findTabIndex !== -1 ? findTabIndex : 0;
  };

  const handleTabChange = index => {
    const tabName = tabs[index].replace(/[^a-zA-Z0-9]/g, '');
    const query = new URLSearchParams(location.search);
    const stepFromQuery = query.get('step');
    const stepSearch = stepFromQuery ? `&step=${stepFromQuery}` : '';
    history.push({
      search: `?tab=${tabName}${stepSearch}`,
    });
    setTabIndex(index);
  };

  const handleBackButton = () => {
    handleTabChange(tabIndex - 1);
  };

  const handleNextButton = () => {
    handleTabChange(tabIndex + 1);
  };

  const handleSave = () => {
    detailsRef && detailsRef.current && detailsRef.current.validate();
    stepsRef && stepsRef.current && stepsRef.current.validate();
    // saveJourneyAction();
  };

  useEffect(() => {
    if (location) {
      const newIndex = getDefaultActiveTab();
      if (tabIndex !== newIndex) handleTabChange(newIndex);
    }
  }, [location, tabIndex]);

  const submitNewJourney = async () => {
    const {
      journeyName,
      journeyTemplate,
      JourneyDescription,
      journeySteps,
    } = data;

    const mappedData = {
      journeyName,
      JourneyDescription,
      steps:
        journeySteps &&
        journeySteps.map(step => {
          // Generate inputs
          let inputs = [];
          inputs = inputs.concat(
            step.data &&
              step.data.assetInventory &&
              step.data.assetInventory
                .filter(({ selected }) => selected)
                .map(({ _id }) => ({ assetId: _id }))
          );
          inputs = inputs.concat(
            step.data &&
              step.data.previousAssets &&
              step.data.previousAssets
                .filter(({ selected }) => selected)
                .map(({ _id }) => ({ assetId: _id }))
          );

          // Generate outputs
          let outputs = [];
          outputs = outputs.concat(
            step.data &&
              step.data.outboundAssetInventory &&
              step.data.outboundAssetInventory
                .filter(({ selected }) => selected)
                .map(({ _id }) => ({ assetId: _id }))
          );
          outputs = outputs.concat(
            step.data &&
              step.data.outboundPreviousAssets &&
              step.data.outboundPreviousAssets
                .filter(({ selected }) => selected)
                .map(({ _id }) => ({ assetId: _id }))
          );

          return {
            order: step.number === -1 ? journeySteps.length - 1 : step.number,
            step: {
              facility: {
                _id: step.data?.selectedFacility?._id,
                name: step.data?.selectedFacility?.registeredName,
              },
              description: step.data?.selectedFacility?.description,
              inputs,
              outputs,
            },
          };
        }),
    };

    formMethods.register({ name: 'journeyName' }, Journey.name);
    formMethods.register({ name: 'JourneyDescription' }, Journey.description);
    formMethods.register({ name: 'journeySteps' }, Journey.steps);

    formMethods.setValue(
      Object.keys(data).map(key => ({
        [key]: data[key],
      }))
    );

    const result = await formMethods.triggerValidation();

    if (result) {
      saveJourneyAction(mappedData);
      Toast.success(i18n.t('Your data has been successfully submitted.'));
    } else {
      Toast.error(i18n.t('There are items that require your attention.'));
    }
  };

  return (
    <>
      <Title
        title={i18n.t('New Journey')}
        subtitle={data.journeyName}
        icon={<JourneyIcon />}
      />
      <ContentBox>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={handleTabChange}
          initialSelectedIndex={getDefaultActiveTab()}
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Tab key={index}>{tab}</Tab>
            ))}

            <JourneyActionButtonsContainer>
              {tabIndex > 0 && (
                <Button
                  text={i18n.t('Back')}
                  type="secondary"
                  action={handleBackButton}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  text={i18n.t('Save')}
                  type="secondary"
                  action={handleSave}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  text={i18n.t('Next')}
                  type="secondary"
                  action={handleNextButton}
                />
              )}
              {tabIndex === tabs.length - 1 && (
                <Button
                  action={() => submitNewJourney()}
                  text={i18n.t('Submit')}
                  type="secondary"
                />
              )}
            </JourneyActionButtonsContainer>
          </TabList>

          <FormContext {...formMethods}>
            <TabPanel>
              <JourneyDetails
                onChange={(name, value) => handleChange(name, value)}
                ref={detailsRef}
                {...data}
              />
            </TabPanel>
            <TabPanel>
              <JourneySteps
                ref={stepsRef}
                onChange={(name, value) => handleChange(name, value)}
                {...data}
                facilities={facilities}
                claims={claims}
              />
            </TabPanel>
            <TabPanel>
              <JourneyReviewSubmit
                onChange={(name, value) => handleChange(name, value)}
                {...data}
                formMethods={formMethods}
              />
            </TabPanel>
          </FormContext>
        </Tabs>
      </ContentBox>
    </>
  );
};

export default withRouter(
  withTranslation()(connector(NewJourney) as any) as any
);
