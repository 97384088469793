export function tap(prom, successH, errorH) {
  return prom
    .then(response => {
      successH(response);
      return response;
    })
    .catch(error => {
      errorH(error);
    });
}
