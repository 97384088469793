import styled from 'styled-components';

const ClaimActionButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

// DETAILS
const DetailsContainer = styled.div`
  margin: 0 32px;
  padding-bottom: 60px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #757575;
`;

const DetailsFormContainer = styled.div`
  > div {
    margin-top: 33px;
  }
  label {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    > div {
      margin-left: 10px;
    }
  }

  li label {
    margin-bottom: 0;
  }
  .checkbox-wrapper {
    margin-left: -11px;
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);

    > label {
      margin-bottom: 5px;
    }
  }
`;

// ATTESTOR REVIEW SUBMIT
const AttestorReviewSubmitContainer = styled.div`
  margin: 32px;
  padding-bottom: 60px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #757575;
`;

const AttestorReviewSubmitItemsWrapper = styled.div`
  margin-bottom: 30px;
`;

export {
  ClaimActionButtonsContainer,
  DetailsFormContainer,
  DetailsContainer,
  AttestorReviewSubmitContainer,
  AttestorReviewSubmitItemsWrapper,
};
