import { combineReducers } from 'redux';

import userReducer from '../User/userReducer';
import globalReducer from '../Global/globalReducer';
import alertReducer from '../Alert/alertReducer';
import modalReducer from '../Modal/modalReducer';
import journeyReducer from '../Journey/journeyReducer';
import assetReducer from '../Asset/assetReducer';
import facilityReducer from '../Facility/facilityReducer';
import claimReducer from '../Claim/claimReducer';
import campaignReducer from '../Campaign/campaignReducer';
import catalogReducer from '../Catalog/catalogReducer';
import attestorReducer from '../Attestor/attestorReducer';
import marketplaceReducer from '../Marketplace/marketplaceReducer';
import stepReducer from '../Step/stepReducer';
import analyticReducer from '../Analytic/analyticReducer';

const rootReducer = combineReducers({
  user: userReducer,
  global: globalReducer,
  alert: alertReducer,
  modal: modalReducer,
  journey: journeyReducer,
  asset: assetReducer,
  facility: facilityReducer,
  claim: claimReducer,
  campaign: campaignReducer,
  catalog: catalogReducer,
  attestor: attestorReducer,
  marketplace: marketplaceReducer,
  step: stepReducer,
  analytic: analyticReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
