import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { Facility } from '../../../Lib/Utils/Validations';
import { Salutations, PhoneTypes } from '../../../Lib/Configs';
import {
  FacilityDetailsFormContainer as FormContainer,
  FacilityDetailsContainer as Container,
  FormSubHeader,
  CoordinatesWrapper,
} from './styles';

import {
  Label,
  FormInput,
  InfoIcon,
  CustomTooltip,
  Dropdown,
} from '../../../Components';

const countriesList = require('../../../Rsc/countries.json');
const countryOptions = countriesList.map(c => ({
  value: c.country,
  label: c.country,
}));

interface Props {
  onChange: Function;
  formErrors?: any;
  contactSalutation?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhone?: string;
  contactPhoneExt?: string;
  contactCountryCode?: string;
  contactPhoneType?: string;
  addressName?: string;
  country?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  longitude?: number;
  latitude?: number;
  email?: string;
  facilityCountryCode?: string;
  facilityPhoneType?: string;
  facilityPhone?: string;
  facilityPhoneExt?: string;
  facilityContactFirstLoad?: boolean;
  validate?: boolean;
}

const FacilityContact = ({
  onChange,
  formErrors,
  validate,
  ...data
}: Props) => {
  const {
    contactSalutation,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone = '',
    contactPhoneExt = '',
    contactCountryCode = '',
    contactPhoneType,
    addressName,
    country,
    state,
    city,
    zipCode,
    addressLineOne,
    addressLineTwo,
    longitude,
    latitude,
    email,
    facilityCountryCode = '',
    facilityPhone = '',
    facilityPhoneExt,
    facilityPhoneType,
    facilityContactFirstLoad = true,
  } = data;

  const [stateOptions, setStateOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null as any);

  const { errors, control, setValue, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    facilityContactFirstLoad === false && triggerValidation();
    onChange('facilityContactFirstLoad', false);
  }, []);

  useEffect(() => {
    setValue([
      { addressName },
      { country },
      { state },
      { city },
      { zipCode },
      { addressLineOne },
      { addressLineTwo },
      { latitude },
      { longitude },
      { email },
      { facilityCountryCode },
      { facilityPhoneType },
      { facilityPhone },
      { facilityPhoneExt },
      { contactSalutation },
      { contactFirstName },
      { contactLastName },
      { contactEmail },
      { contactCountryCode },
      { contactPhone },
      { contactPhoneType },
      { contactPhoneExt },
    ]);
  }, [
    addressName,
    country,
    state,
    city,
    zipCode,
    addressLineOne,
    addressLineTwo,
    latitude,
    longitude,
    email,
    facilityCountryCode,
    facilityPhoneType,
    facilityPhone,
    facilityPhoneExt,
    contactSalutation,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactCountryCode,
    contactPhone,
    contactPhoneType,
    contactPhoneExt,
  ]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      triggerValidation();
    }
  }, [formErrors, validate]);

  useEffect(() => {
    if (country) {
      selectCountry(country);
      onChange('state', state);
    }
  }, [country]);

  const selectCountry = value => {
    const country = countriesList.find(c => c.country === value);
    const states = country?.states?.map(s => ({ value: s, label: s })) || [];
    setSelectedCountry(country);
    setStateOptions(states);
    onChange('country', value);
  };

  const isActiveStateSelection = () => {
    return (
      selectedCountry &&
      selectedCountry.states &&
      selectedCountry.states.length > 0
    );
  };

  return (
    <Container>
      <FormSubHeader>
        <div className="mr-2">{i18n.t('Facility point of contact')}</div>
        <div data-tip data-for="facilitypoc">
          <InfoIcon />
        </div>
        <CustomTooltip id="facilitypoc" placement="right">
          <span>{i18n.t('The primary support contact for this facility')}</span>
        </CustomTooltip>
      </FormSubHeader>
      <FormContainer className="row">
        <div className="col-4 col-sm-2">
          <Label>{i18n.t('Salutation')}</Label>
          <Controller
            as={
              <Dropdown
                required
                options={Salutations}
                placeholder={i18n.t('Select')}
                action={newValue => onChange('contactSalutation', newValue)}
                defaultOption={contactSalutation}
              />
            }
            name="contactSalutation"
            value={contactSalutation}
            control={control}
            onChange={([newValue]) => {
              onChange('contactSalutation', newValue);
              return newValue;
            }}
            rules={Facility.salutation}
            defaultValue={contactSalutation}
          />
          {errors.contactSalutation && (
            <span className="inlineErrorMessage">
              {errors.contactSalutation.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-sm-5">
          <Label>{i18n.t('First Name')}</Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the contact name')}
                maxLength={75}
              />
            }
            name="contactFirstName"
            value={contactFirstName}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Facility.contactName}
            defaultValue={contactFirstName}
          />
          {errors.contactFirstName && (
            <span className="inlineErrorMessage">
              {errors.contactFirstName.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-sm-5">
          <Label>{i18n.t('Last Name')}</Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the contact last name')}
                maxLength={75}
              />
            }
            name="contactLastName"
            value={contactLastName}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Facility.contactName}
            defaultValue={contactLastName}
          />
          {errors.contactLastName && (
            <span className="inlineErrorMessage">
              {errors.contactLastName.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-sm-3">
          <Label>{i18n.t('Email')}</Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={`${i18n.t('Enter email address')}...`}
                maxLength={100}
              />
            }
            name="contactEmail"
            value={contactEmail}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Facility.email}
            defaultValue={contactEmail}
          />
          {errors.contactEmail && (
            <span className="inlineErrorMessage">
              {errors.contactEmail.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-sm-2">
          <Label>{i18n.t('Country code')}</Label>
          <Controller
            as={<FormInput required placeholder={i18n.t('Enter code')} maxLength={10} />}
            name="contactCountryCode"
            value={contactCountryCode}
            control={control}
            onChange={([evt]) => {
              const regex = Facility.countryCode.pattern.value;
              const value = evt.target.value;

              if (!value || regex.test(value)) {
                onChange(evt.target.name, evt.target.value);
                return evt.target.value;
              } else {
                onChange(evt.target.name, contactCountryCode);
                return contactCountryCode;
              }
            }}
            rules={Facility.countryCode}
            defaultValue={contactCountryCode}
          />
          {errors.contactCountryCode && (
            <span className="inlineErrorMessage">
              {errors.contactCountryCode.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-sm-3">
          <Label>{i18n.t('Phone number')}</Label>
          <Controller
            as={
              <FormInput required placeholder={i18n.t('Enter phone number')} />
            }
            name="contactPhone"
            value={contactPhone}
            control={control}
            onChange={([evt]) => {
              const regex = Facility.phone.pattern.value;
              const value = evt.target.value;

              if (!value || regex.test(value)) {
                onChange(evt.target.name, evt.target.value);
                return evt.target.value;
              } else {
                onChange(evt.target.name, contactPhone);
                return contactPhone;
              }
            }}
            rules={Facility.phone}
            defaultValue={contactPhone}
          />
          {errors.contactPhone && (
            <span className="inlineErrorMessage">
              {errors.contactPhone.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-sm-2">
          <Label>{i18n.t('Ext')}</Label>
          <Controller
            as={
              <FormInput subType="number" placeholder={i18n.t('Enter ext')} />
            }
            name="contactPhoneExt"
            value={contactPhoneExt}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Facility.phoneExt}
            defaultValue={contactPhoneExt}
          />
          {errors.contactPhoneExt && (
            <span className="inlineErrorMessage">
              {errors.contactPhoneExt.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-sm-2">
          <Label>{i18n.t('Phone type')}</Label>
          <Controller
            as={
              <Dropdown
                required
                options={PhoneTypes}
                placeholder={i18n.t('Select')}
                action={newValue => onChange('contactPhoneType', newValue)}
                defaultOption={contactPhoneType}
              />
            }
            name="contactPhoneType"
            value={contactPhoneType}
            control={control}
            onChange={([newValue]) => {
              onChange('contactPhoneType', newValue);
              return newValue;
            }}
            rules={Facility.phoneType}
            defaultValue={contactPhoneType}
          />
          {errors.contactPhoneType && (
            <span className="inlineErrorMessage">
              {errors.contactPhoneType.message}.
            </span>
          )}
        </div>
      </FormContainer>
      <FormSubHeader className="mt-4">
        <div className="mr-2">{i18n.t('Facility information')}</div>
        <div data-tip data-for="facilityinfo">
          <InfoIcon />
        </div>
        <CustomTooltip id="facilityinfo" placement="right">
          <span>
            {i18n.t('This information will be seen by end consumers')}
          </span>
        </CustomTooltip>
      </FormSubHeader>
      <FormContainer className="row">
        <div className="col-12">
          <div className="row">
            <FormContainer className="col-12 col-lg-7 row">
              <div className="col-12 mt-0">
                <Label>{i18n.t('Address name')}</Label>
                <Controller
                  as={<FormInput placeholder={i18n.t('e.g., Head Office')} maxLength={75} />}
                  name="addressName"
                  value={addressName}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.value);
                    return evt.target.value;
                  }}
                  rules={Facility.addressName}
                  defaultValue={addressName}
                />
                {errors.addressName && (
                  <span className="inlineErrorMessage">
                    {errors.addressName.message}.
                  </span>
                )}
              </div>
              <div className="col-12 col-md-6">
                <Label>{i18n.t('Country')}</Label>
                <Controller
                  as={
                    <Dropdown
                      required
                      options={countryOptions}
                      placeholder={i18n.t('Select country')}
                      defaultOption={country}
                      action={newValue => selectCountry(newValue)}
                      searchable
                    />
                  }
                  name="country"
                  value={country}
                  control={control}
                  onChange={([newValue]) => {
                    onChange('country', newValue);
                    return newValue;
                  }}
                  rules={Facility.country}
                  defaultValue={country}
                />
                {errors.country && (
                  <span className="inlineErrorMessage">
                    {errors.country.message}.
                  </span>
                )}
              </div>
              <div className="col-12 col-lg-6">
                <Label disabled={!isActiveStateSelection()}>
                  {i18n.t('State / province / region')}
                </Label>
                <Controller
                  as={
                    <Dropdown
                      required
                      disabled={!isActiveStateSelection()}
                      options={stateOptions}
                      placeholder={i18n.t('Select an option')}
                      defaultOption={state}
                      action={newValue => onChange('state', newValue)}
                      searchable
                    />
                  }
                  name="state"
                  value={state}
                  control={control}
                  onChange={([newValue]) => {
                    onChange('state', newValue);
                    return newValue;
                  }}
                  rules={Facility.state(country)}
                  defaultValue={state}
                />
                {errors.state && (
                  <span className="inlineErrorMessage">
                    {errors.state.message}.
                  </span>
                )}
              </div>
              <div className="col-12 col-lg-8">
                <Label>{i18n.t('City')}</Label>
                <Controller
                  as={
                    <FormInput
                      required
                      placeholder={i18n.t('Enter the city')}
                      maxLength={50}
                    />
                  }
                  name="city"
                  value={city}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.value);
                    return evt.target.value;
                  }}
                  rules={Facility.city}
                  defaultValue={city}
                />
                {errors.city && (
                  <span className="inlineErrorMessage">
                    {errors.city.message}.
                  </span>
                )}
              </div>
              <div className="col-12 col-lg-4">
                <Label>{i18n.t('Zip code')}</Label>
                <Controller
                  as={
                    <FormInput
                      required
                      placeholder={i18n.t('Enter zip code')}
                      maxLength={10}
                    />
                  }
                  name="zipCode"
                  value={zipCode}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.value);
                    return evt.target.value;
                  }}
                  rules={Facility.zipCode}
                  defaultValue={zipCode}
                />
                {errors.zipCode && (
                  <span className="inlineErrorMessage">
                    {errors.zipCode.message}.
                  </span>
                )}
              </div>
              <div className="col-12">
                <Label>{i18n.t('Address line 1')}</Label>
                <Controller
                  as={
                    <FormInput
                      required
                      placeholder={i18n.t('Enter the street address')}
                      maxLength={75}
                    />
                  }
                  name="addressLineOne"
                  value={addressLineOne}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.value);
                    return evt.target.value;
                  }}
                  rules={Facility.address}
                  defaultValue={addressLineOne}
                />
                {errors.addressLineOne && (
                  <span className="inlineErrorMessage">
                    {errors.addressLineOne.message}.
                  </span>
                )}
              </div>
            </FormContainer>
            <div className="col-6">{/* TODO MAP */}</div>
          </div>
        </div>
        <div className="col-12 col-lg-7">
          <Label>{i18n.t('Address line 2')}</Label>
          <FormInput
            name="addressLineTwo"
            placeholder={i18n.t('Enter address line 2')}
            onChange={evt => onChange(evt.target.name, evt.target.value)}
            defaultValue={addressLineTwo}
            maxLength={75}
          />
        </div>
        <div className="col-12 col-lg-4">
          <Label>
            {i18n.t('Coordinates')}
            <div data-tip data-for="coordinates">
              <InfoIcon />
            </div>
            <CustomTooltip id="coordinates" placement="right">
              <span>
                {i18n.t('GPS coordinates for the use of geo-location')}
              </span>
            </CustomTooltip>
          </Label>
          <CoordinatesWrapper>
            <Controller
              as={<FormInput type="number" placeholder={i18n.t('Latitude')} />}
              name="latitude"
              value={latitude}
              control={control}
              onChange={([evt]) => {
                onChange(evt.target.name, evt.target.value);
                return evt.target.value;
              }}
              rules={Facility.location}
              defaultValue={latitude}
            />
            {errors.latitude && (
              <span className="inlineErrorMessage">
                {errors.latitude.message}.
              </span>
            )}
            <Controller
              as={<FormInput type="number" placeholder={i18n.t('Longitude')} />}
              name="longitude"
              value={longitude}
              control={control}
              onChange={([evt]) => {
                onChange(evt.target.name, evt.target.value);
                return evt.target.value;
              }}
              rules={Facility.location}
              defaultValue={longitude}
            />
            {errors.longitude && (
              <span className="inlineErrorMessage">
                {errors.longitude.message}.
              </span>
            )}
          </CoordinatesWrapper>
        </div>
        <div className="col-12 col-lg-7">
          <Label>{i18n.t('Email')}</Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={`${i18n.t('Enter email address')}...`}
                maxLength={75}
              />
            }
            name="email"
            value={email}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Facility.email}
            defaultValue={email}
          />
          {errors.email && (
            <span className="inlineErrorMessage">{errors.email.message}.</span>
          )}
        </div>
        <div className="col-0 col-lg-5"></div>
        <div className="col-4 col-lg-2">
          <Label>{i18n.t('Country code')}</Label>
          <Controller
            as={<FormInput required placeholder={i18n.t('Enter code')} maxLength={10} />}
            name="facilityCountryCode"
            value={facilityCountryCode}
            control={control}
            onChange={([evt]) => {
              const regex = Facility.countryCode.pattern.value;
              const value = evt.target.value;

              if (!value || regex.test(value)) {
                onChange(evt.target.name, evt.target.value);
                return evt.target.value;
              } else {
                onChange(evt.target.name, facilityCountryCode);
                return facilityCountryCode;
              }
            }}
            rules={Facility.countryCode}
            defaultValue={facilityCountryCode}
          />
          {errors.facilityCountryCode && (
            <span className="inlineErrorMessage">
              {errors.facilityCountryCode.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-lg-3">
          <Label>{i18n.t('Phone number')}</Label>
          <Controller
            as={
              <FormInput required placeholder={i18n.t('Enter phone number')} />
            }
            name="facilityPhone"
            value={facilityPhone}
            control={control}
            onChange={([evt]) => {
              const regex = Facility.phone.pattern.value;
              const value = evt.target.value;

              if (!value || regex.test(value)) {
                onChange(evt.target.name, evt.target.value);
                return evt.target.value;
              } else {
                onChange(evt.target.name, facilityPhone);
                return facilityPhone;
              }
            }}
            rules={Facility.phone}
            defaultValue={facilityPhone}
          />
          {errors.facilityPhone && (
            <span className="inlineErrorMessage">
              {errors.facilityPhone.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-lg-2">
          <Label>{i18n.t('Ext')}</Label>
          <Controller
            as={
              <FormInput subType="number" placeholder={i18n.t('Enter ext')} />
            }
            name="facilityPhoneExt"
            value={facilityPhoneExt}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Facility.phoneExt}
            defaultValue={facilityPhoneExt}
          />
          {errors.facilityPhoneExt && (
            <span className="inlineErrorMessage">
              {errors.facilityPhoneExt.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-lg-2">
          <Label>{i18n.t('Phone type')}</Label>
          <Controller
            as={
              <Dropdown
                required
                options={PhoneTypes}
                placeholder={i18n.t('Select')}
                action={newValue => onChange('facilityPhoneType', newValue)}
                defaultOption={facilityPhoneType}
              />
            }
            name="facilityPhoneType"
            value={facilityPhoneType}
            control={control}
            onChange={([newValue]) => {
              onChange('facilityPhoneType', newValue);
              return newValue;
            }}
            rules={Facility.phoneType}
            defaultValue={facilityPhoneType}
          />
          {errors.facilityPhoneType && (
            <span className="inlineErrorMessage">
              {errors.facilityPhoneType.message}.
            </span>
          )}
        </div>
      </FormContainer>
    </Container>
  );
};

export default FacilityContact;
