import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  &.icon {
    input {
      padding-right: 35px;
    }
  }

  &.textarea {
    align-items: flex-start;
    flex-direction: column;

    + .inlineErrorMessage {
      margin-top: -18px;
    }
  }

  color: rgba(117, 117, 117, 0.5);
  > span {
    margin-right: 5px;
  }
`;

const Input = styled.input`
  max-width: 100%;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  font-family: Encode Sans, sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #000;
  outline: none;
  background: none;
  ::placeholder {
    color: #adadad;
  }
`;

const TextPrimary = styled(Input)`
  border: none;
  border-bottom: 2px solid #f2f2f2;

  &:focus {
    border-bottom: 2px solid #a7bf2e;
    ::placeholder {
      color: #000;
    }
  }
`;

const TextBorder = styled(Input)`
  border: 1px solid rgba(151, 151, 151, 0.35);
  background: #f9f9fb;
  padding: 1px 9px;

  &:focus {
    border: 1px solid #a7bf2e;
  }
`;

const TextRoundBorder = styled(Input)`
  background: #ffffff;
  border: 2px solid rgba(117, 117, 117, 0.25);
  box-sizing: border-box;
  border-radius: 10px 20px 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  min-width: 140px;
`;

const TextArea = styled.textarea`
  max-width: 100%;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-family: Encode Sans, sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #000;
  outline: none;
  min-height: 60px;
  height: 160px;
  padding: 15px 19px;
  margin: 0px;
  background: rgb(249, 249, 251);
  border: 1px solid rgba(151, 151, 151, 0.35);
  padding: 15px 19px;
  resize: vertical;
  ::placeholder {
    color: rgba(117, 117, 117, 0.5);
  }
`;

const TextAreaPrimary = styled(TextArea)`
  &:focus {
    border-bottom: 2px solid #a7bf2e;
    ::placeholder {
      color: #000;
    }
  }
`;

const TextAreaBorder = styled(TextArea)`
  background: #f9f9fb;
  padding: 1px 9px;

  &:focus {
    border: 1px solid #a7bf2e;
  }
`;

const Chars = styled.div`
  font-size: 12px;
  font-family: Encode Sans, sans-serif;
  margin-top: 5px;
  align-self: flex-end;
`;

const Password = styled(Input)`
  border: none;
  border-bottom: 2px solid #f2f2f2;
  &:focus {
    border-bottom: 2px solid #a7bf2e;
    ::placeholder {
      color: #000;
    }
  }
`;

const PasswordButton = styled.div`
  position: absolute;
  right: 0;
  font-size: 24px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 5px;
`;

export {
  InputContainer,
  TextPrimary,
  TextBorder,
  TextAreaPrimary,
  TextAreaBorder,
  Chars,
  Password,
  PasswordButton,
  IconContainer,
  TextRoundBorder,
};
