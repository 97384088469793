import React from 'react';
import styled from 'styled-components';
import LangSelector from '../LangSelector';
import i18n from 'i18next';
import { availableLanguages } from '../../Rsc/lang';

interface Props {
  title?: string;
  langSelector?: boolean;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 30px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1400px) {
    padding: 15px 30px;
  }
  @media (max-width: 576px) {
    padding: 20px 10px 0 10px;
  }
  @media (max-width: 319px) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Img = styled.img`
  width: 100%;
  max-width: 140px;

  @media (max-width: 576px) {
    min-width: 55px;
  }
`;

const TextContainer = styled.div`
  border-left: 2px solid #d8f40c;
  margin-top: 10px;
  margin-left: 25px;
  padding-left: 20px;
  height: 40px;
  align-items: center;
  display: flex;
  @media (max-width: 576px) {
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 10px;
  }
`;

const RightMenu = styled.div`
  color: #fff;
`;

const H1 = styled.h1`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #fff;
  letter-spacing: 0.13em;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const AuthHeader = ({ title, langSelector }: Props) => {
  return (
    <Container>
      <TitleContainer>
        <div>
          <Img
            className="img-responsive"
            src={require('../../Rsc/Images/logo.svg')}
          />
        </div>
        <TextContainer>
          <H1>{title}</H1>
        </TextContainer>
      </TitleContainer>
      {langSelector && (
        <RightMenu>
          <LangSelector
            options={availableLanguages}
            defaultOption={i18n.language}
            action={newValue => i18n.changeLanguage(newValue)}
          />
        </RightMenu>
      )}
    </Container>
  );
};

export default AuthHeader;
