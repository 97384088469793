import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';

interface Props {
  data: { labels: Array<string>; datasets: Array<any> };
  options?: any;
}

const Chart = (props: Props) => {
  const { data, options } = props;

  // Render
  return (
    <div>
      <Line data={data} width={100} height={500} options={options} />
    </div>
  );
};

export default Chart;
