import React from 'react';

export default () => (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17ZM5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83Z"
      fill="#757575"
    />
  </svg>
);
