import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';
import { Journey } from '../../../Lib/Utils/Validations';

import {
  JourneyDetailsChecklist as Checklist,
  JourneyDetailsFormContainer as FormContainer,
  JourneyDetailsContainer as Container,
  JourneyDetailsSubtitle as Subtitle,
} from './styles';

import {
  TickIcon,
  InvalidIcon,
  Label,
  FormInput,
  FormSelect,
  InfoIcon,
  CustomTooltip,
} from '../../../Components';

const templateOptions = {
  cheese: 'Cheese',
  coffee: 'Coffee',
  eggs: 'Eggs',
  fruit: 'Fruit',
  meat: 'Meat (Pieces, Sausages, Cold Cuts)',
};

interface Props {
  onChange: Function;
  journeyName?: string;
  JourneyDescription?: string;
  journeyTemplate?: string;
  journeyDetailFirstLoad?: boolean;
}

////////////////////////////////
// MOVE ANY ADDITIONAL LOGIC TO Wizard/JourneyDetails.tsx
////////////////////////////////

const JourneyDetails = ({ onChange, ...data }: Props, ref) => {
  const {
    journeyName,
    JourneyDescription,
    journeyTemplate,
    journeyDetailFirstLoad = true,
  } = data;

  const { errors, control, setValue, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    journeyDetailFirstLoad === false && triggerValidation();
    onChange('journeyDetailFirstLoad', false);
  }, []);

  useEffect(() => {
    setValue([
      { journeyName: journeyName },
      { JourneyDescription: JourneyDescription },
      { journeyTemplate: journeyTemplate },
    ]);
  }, [journeyName, JourneyDescription, journeyTemplate]);

  useImperativeHandle(ref, () => ({
    validate() {
      return triggerValidation();
    }
  }));

  return (
    <Container>
      <Label>{i18n.t('Readiness checklist')}</Label>
      <Subtitle>{i18n.t('Before you can complete a product journey')}</Subtitle>
      <Checklist>
        <div>
          <TickIcon />
          <div>
            <Label>{i18n.t('Facility Management')}</Label>
            <p>{i18n.t('Create facilities for each step')}</p>
          </div>
        </div>
        <div>
          <TickIcon />
          <div>
            <Label>{i18n.t('Claims Management')}</Label>
            <p>{i18n.t('Create claims that each facility')}</p>
          </div>
        </div>
        <div>
          <InvalidIcon />
          <div>
            <Label>{i18n.t('Assets Management')}</Label>
            <p>{i18n.t('Create a marketable product')}</p>
          </div>
        </div>
      </Checklist>

      <FormContainer className="row">
        <div className="col-12 col-lg-6">
          <Label>
            {i18n.t('Journey name')}
            <div data-tip data-for="journeyname">
              <InfoIcon />
            </div>
            <CustomTooltip id="journeyname" placement="right">
              <span>{i18n.t('Unique name to identify journey template')}</span>
            </CustomTooltip>
          </Label>

          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the name you want for this journey')}
                maxLength={50}
              />
            }
            name="journeyName"
            value={journeyName}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Journey.name}
            defaultValue={journeyName}
          />
          {errors.journeyName && (
            <span className="inlineErrorMessage">
              {errors.journeyName.message}.
            </span>
          )}
        </div>
        <div className="col-12 col-lg-6">
          <Label>
            {i18n.t('Journey templates')}
            <div data-tip data-for="templates">
              <InfoIcon />
            </div>
            <CustomTooltip id="templates" placement="right">
              <span>{i18n.t('Template to accelerate the journey steps')}</span>
            </CustomTooltip>
          </Label>
          <FormSelect
            defaultOption={journeyTemplate}
            options={templateOptions}
            placeholder={i18n.t(
              'Select a template to accelerate creation of the journey'
            )}
            action={newValue => onChange('journeyTemplate', newValue)}
          />
        </div>
        <div className="col-12">
          <Label>
            {i18n.t('Journey description')}
            <div data-tip data-for="description">
              <InfoIcon />
            </div>
            <CustomTooltip id="description" placement="right">
              <span>
                {i18n.t('Description that explains what this journey is')}
              </span>
            </CustomTooltip>
          </Label>

          <Controller
            as={
              <FormInput
                required
                maxLength={350}
                type="textarea"
                placeholder={i18n.t(
                  'Enter the description you want for this journey'
                )}
              />
            }
            name="JourneyDescription"
            value={JourneyDescription}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Journey.description}
            defaultValue={JourneyDescription}
          />
          {errors.JourneyDescription && (
            <span className="inlineErrorMessage">
              {errors.JourneyDescription.message}.
            </span>
          )}
        </div>
      </FormContainer>
    </Container>
  );
};

export default forwardRef(JourneyDetails);
