import React, { useState } from 'react';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import i18n from 'i18next';

import { TrashIcon } from '../CustomIcons';
import Tooltip from '../Tooltip';
import {
  ControlsContainer as Container,
  ControlsArrowButton as ArrowButton,
  ControlsTrashButton as TrashButton,
} from './styles';
import { PopUp } from '..';

interface Props {
  number: number;
  itemsList: Array<any>;
  setItemsList: (value: Array<any>) => void;
  confirmDelete?: boolean;
  deleteTitle?: string;
  deleteContent?: any;
}

const ItemControls = ({
  number,
  itemsList,
  setItemsList,
  confirmDelete,
  deleteTitle,
  deleteContent,
}: Props) => {

  const [popUpShow, setPopUpShow] = useState(false);

  const first = number === 0;
  const last = number === itemsList.length - 1;

  const handleUpArrowClick = e => {
    e.stopPropagation();

    let updatedItems = itemsList.slice();
    let element = updatedItems[number];
    updatedItems.splice(number, 1);
    updatedItems.splice(number - 1, 0, element);

    setItemsList(updatedItems);
  };

  const handleDownArrowClick = e => {
    e.stopPropagation();

    let updatedItems = itemsList.slice();
    let element = updatedItems[number];
    updatedItems.splice(number, 1);
    updatedItems.splice(number + 1, 0, element);

    setItemsList(updatedItems);
  };

  const handleDeleteClick = () => {
    if (confirmDelete) {
      setPopUpShow(true);
    } else {
      confirmDeleteAction();
    }
  };

  const confirmDeleteAction = () => {
    const updatedItems = itemsList.filter((_, index) => index !== number);
    setItemsList(updatedItems);
  }

  return (
    <Container first={first} last={last}>
      {!first && (
        <ArrowButton className="up" onClick={handleUpArrowClick}>
          <MdKeyboardArrowUp size="3em" />
        </ArrowButton>
      )}
      <Tooltip text={i18n.t('Delete this row.')}>
        <TrashButton onClick={handleDeleteClick} data-for="trashTooltip">
          <TrashIcon color="white" />
        </TrashButton>
      </Tooltip>
      {!last && (
        <ArrowButton className="down" onClick={handleDownArrowClick}>
          <MdKeyboardArrowDown size="3em" />
        </ArrowButton>
      )}
      {confirmDelete && (
        <PopUp
          title={deleteTitle}
          content={deleteContent}
          cancelText={i18n.t('No')}
          confirmText={i18n.t('Yes')}
          confirmAction={confirmDeleteAction}
          cancelAction={() => setPopUpShow(false)}
          show={popUpShow}
        />
      )}
    </Container>
  );
};

export default ItemControls;
