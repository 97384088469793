import i18n from 'i18next';

const loginPassword = {
  required: { value: true, message: i18n.t('Required field') },
};

const loginUsername = {
  required: { value: true, message: i18n.t('Required field') },
};

const name = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
};

const email = {
  required: { value: true, message: i18n.t('This field is required') },
  pattern: {
    value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
    message: i18n.t('Enter a valid email'),
  },
};

const facilities = () => {
  return {
    validate: {
      facilitiesLength: value => {
        return value === undefined || value === null || value.length === 0
          ? i18n.t('Required field')
          : undefined;
      },
    },
  };
};

const password = {
  required: { value: true, message: i18n.t('This field is required') },
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
  pattern: {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,}$/i,
    message: i18n.t(
      'Password must contain at least one upper case letter, a number and an special character'
    ),
  },
};

export default {
  loginPassword,
  loginUsername,
  name,
  email,
  facilities,
  password,
};
