import React from 'react';

const Uncheck = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3852 8.14232L12.971 6.72811L10.1426 9.55654L7.31415 6.72811L5.89994 8.14232L8.72836 10.9707L5.89994 13.7992L7.31415 15.2134L10.1426 12.385L12.971 15.2134L14.3852 13.7992L11.5568 10.9707L14.3852 8.14232ZM17.2136 3.89968C13.3104 -0.00354838 6.97474 -0.00354838 3.07151 3.89968C-0.831719 7.80291 -0.831719 14.1386 3.07151 18.0418C6.97474 21.945 13.3104 21.945 17.2136 18.0418C21.1169 14.1386 21.1169 7.80291 17.2136 3.89968ZM4.48572 16.6276C1.36738 13.5093 1.36738 8.43224 4.48572 5.31389C7.60406 2.19555 12.6811 2.19555 15.7994 5.31389C18.9178 8.43224 18.9178 13.5093 15.7994 16.6276C12.6811 19.7459 7.60406 19.7459 4.48572 16.6276Z"
      fill="#F40C28"
    />
  </svg>
);

export default Uncheck;
