import React from 'react';
import i18n from 'i18next';
import {
  SortableContainer,
  SortableElement,
  SortableContainerProps,
  SortableElementProps,
} from 'react-sortable-hoc';
import { MdPictureAsPdf } from 'react-icons/md';
import {
  ButtonContainer,
  Button,
  IconContainer,
  ImagePreview,
  DeleteButton,
  LinkButton,
  VideoPreview,
  PlayButton,
  FeaturedIcon,
} from './styles';
import { PlusIcon, CrossIcon, Tooltip, PlayIcon } from '../index';
import { FaLink } from 'react-icons/fa';
import { MdStar } from 'react-icons/md';

interface SortableListProps {
  items: any;
  type: string;
  readonly?: boolean;
  removeItem: Function;
  triggerFileUpload: Function;
  handleOpenLightbox: Function;
  handleSetFeatured: Function;
  addButtonText?: string;
}

interface SortableItemProps {
  item: any;
  type: string;
  indexRef: number;
  readonly?: boolean;
  removeItem: Function;
  handleOpenLightbox: Function;
  handleSetFeatured: Function;
}

type SortableListComponentProps = Readonly<
  SortableListProps & SortableContainerProps
>;

type SortableItemComponentProps = Readonly<
  SortableItemProps & SortableElementProps
>;

const SortableItem = SortableElement(
  ({
    item,
    type,
    indexRef,
    readonly,
    handleOpenLightbox,
    removeItem,
    handleSetFeatured,
  }: SortableItemComponentProps) => {
    const index = indexRef;
    const { subtype, filename } = item;

    const urlExtension = item.src.split('.').pop();

    return type === 'image' ? (
      <ImagePreview
        onClick={() => {
          if (subtype === 'pdf' || urlExtension.toUpperCase() === 'PDF') {
            let pdfWindow = window.open('');
            if (pdfWindow) {
              pdfWindow.document.write(
                `<iframe width='100%' height='100%' src='${encodeURI(
                  item.src
                )}'></iframe>`
              );
            }
          } else {
            handleOpenLightbox(item);
          }
        }}
      >
        {subtype === 'pdf' || urlExtension.toUpperCase() === 'PDF' ? (
          <div className="file-subtype">
            <MdPictureAsPdf size={40} color="#ef6d6d" />
            <span className="mt-2">{filename}</span>
          </div>
        ) : (
          <img
            src={item.src}
            onError={e => {
              const thumbImage = e.target as HTMLImageElement;
              thumbImage.src = require('../../Rsc/Images/imagePlaceholder.svg');
            }}
          />
        )}
        {!readonly && (
          <DeleteButton onClick={e => removeItem(e, index)}>
            <CrossIcon />
          </DeleteButton>
        )}
        {item.external && (
          <Tooltip text={i18n.t('External image')}>
            <LinkButton
              href={item.src}
              target="_blank"
              onClick={e => e.stopPropagation()}
            >
              <FaLink />
            </LinkButton>
          </Tooltip>
        )}
      </ImagePreview>
    ) : (
      <VideoPreview>
        <video muted preload={'auto'} src={item.src} />
        <PlayButton onClick={() => handleOpenLightbox(item)}>
          <PlayIcon />
        </PlayButton>
        {!readonly && (
          <DeleteButton onClick={e => removeItem(e, index)}>
            <CrossIcon />
          </DeleteButton>
        )}
        {readonly && item.featured && (
          <Tooltip text={i18n.t('Promotional video')}>
            <FeaturedIcon selected>
              <MdStar />
            </FeaturedIcon>
          </Tooltip>
        )}
        {!readonly && (
          <Tooltip text={i18n.t('Promotional video')}>
            <FeaturedIcon
              style={{ cursor: 'pointer' }}
              selected={item.featured}
              onClick={() => handleSetFeatured(index)}
            >
              <MdStar />
            </FeaturedIcon>
          </Tooltip>
        )}
        {item.external && (
          <Tooltip text={i18n.t('External video')}>
            <LinkButton
              href={item.src}
              target="_blank"
              onClick={e => e.stopPropagation()}
            >
              <FaLink />
            </LinkButton>
          </Tooltip>
        )}
      </VideoPreview>
    );
  }
);

const SortableList = SortableContainer(
  ({
    items,
    readonly,
    type,
    triggerFileUpload,
    removeItem,
    handleOpenLightbox,
    handleSetFeatured,
    addButtonText,
  }: SortableListComponentProps) => (
    <ul>
      {items.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          indexRef={index}
          item={item}
          removeItem={removeItem}
          type={type}
          handleOpenLightbox={handleOpenLightbox}
          handleSetFeatured={handleSetFeatured}
          readonly={readonly}
        />
      ))}
      {!readonly && (
        <ButtonContainer>
          <Button onClick={() => triggerFileUpload()}>
            <IconContainer>
              <PlusIcon />
            </IconContainer>
            {addButtonText
              ? addButtonText
              : type === 'image'
              ? i18n.t('Add Images')
              : i18n.t('Add Videos')}
          </Button>
        </ButtonContainer>
      )}
    </ul>
  )
);

export { SortableList };
