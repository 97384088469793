import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ top?: number }>`
  .spin {
    animation: anim_spin 1.5s linear infinite;
    transform-origin: center center;
  }

  position: absolute;
  ${({ top }) => top ? `top: ${top}px;` : `top: 0; bottom: 0;`}
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @keyframes anim_spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface Props {
  top?: number;
}

export default ({ top }: Props) => (
  <Container top={top}>
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="spin">
        <circle cx="29.353" cy="53.8235" r="6.17645" fill="#A7BF2E" />
        <circle
          cx="29.3529"
          cy="3.52932"
          r="3.5294"
          transform="rotate(-180 29.3529 3.52932)"
          fill="#A7BF2E"
        />
        <circle
          cx="11.2961"
          cy="45.4727"
          r="2.20588"
          transform="rotate(45 11.2961 45.4727)"
          fill="#A7BF2E"
        />
        <circle
          cx="46.439"
          cy="10.9095"
          r="3.97058"
          transform="rotate(-135 46.439 10.9095)"
          fill="#A7BF2E"
        />
        <circle
          cx="4.64714"
          cy="28.2354"
          r="2.64705"
          transform="rotate(90 4.64714 28.2354)"
          fill="#A7BF2E"
        />
        <circle
          cx="53.6176"
          cy="27.7937"
          r="4.85293"
          transform="rotate(-90 53.6176 27.7937)"
          fill="#A7BF2E"
        />
        <circle
          cx="11.6614"
          cy="10.5437"
          r="3.08823"
          transform="rotate(135 11.6614 10.5437)"
          fill="#A7BF2E"
        />
        <circle
          cx="47.1695"
          cy="45.6865"
          r="5.73528"
          transform="rotate(-45 47.1695 45.6865)"
          fill="#A7BF2E"
        />
      </g>
    </svg>
  </Container>
);
