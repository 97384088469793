import React from 'react';
import styled from 'styled-components';

type TypeOptions =
  | 'primary'
  | 'secondary'
  | 'text'
  | 'icon'
  | 'grayOutline'
  | 'dark'
  | 'roundDark'
  | 'roundGreen';

interface Props {
  text?: any;
  action?: (event?) => void;
  disabled?: boolean;
  type?: TypeOptions;
  width?: number;
  height?: number;
  icon?: any;
}

const Container = styled.div`
  display: inline-block;
  margin: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
`;

const Button = styled.button<{ disabled: boolean }>`
  &:focus {
    outline: none;
  }
  outline: none;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  box-sizing: border-box;
  border-radius: 10px 20px 15px;
  font-family: Poppins, sans-serif;
  line-height: 21px;
  align-items: center;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;

const Primary = styled(Button)`
  background: #fff;
  border: 2px solid #000000;
  color: #000;
  padding: 0 30px;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background: #000;
    color: #fff;
  }
`;

const Secondary = styled(Primary)`
  background: none;
  border-color: #d8f40c;
  color: #fff;
  &:hover {
    background: #d8f40c;
    color: #000;
  }
`;

const GrayOutline = styled(Primary)`
  color: #7f7f7f;
  border-color: #7f7f7f;
  &:hover {
    border-color: #000;
  }
`;

const Text = styled(Button)`
  background: none;
  border: none;
  color: #a7bf2e;
`;

const Dark = styled(Button)`
  background: #000;
  color: #d8f40c;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #fff;
  }
`;

const RoundDark = styled(Dark)`
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0;
  width: 34px;
  height: 34px;
`;

const RoundGreen = styled(RoundDark)`
  background: #a7bf2e;
  &:hover {
    opacity: 0.8;
  }
`;

const Icon = styled(Button)`
  color: #757575;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0;
  width: 34px;
  height: 34px;

  &:hover {
    opacity: 0.7;
  }
`;

const CustomButton = ({
  text,
  action,
  disabled = false,
  type = 'primary',
  width,
  height,
  icon,
}: Props) => {
  const buttonProps = {
    disabled,
    onClick: action,
    style: {
      width: width ? `${width}px` : '',
      height: height ? `${height}px` : '35px',
    },
  };
  return (
    <Container>
      <ButtonContainer>
        {
          {
            primary: <Primary {...buttonProps}>{text}</Primary>,
            secondary: <Secondary {...buttonProps}>{text}</Secondary>,
            text: <Text {...buttonProps}>{text}</Text>,
            grayOutline: <GrayOutline {...buttonProps}>{text}</GrayOutline>,
            dark: <Dark {...buttonProps}>{text}</Dark>,
            roundDark: <RoundDark {...buttonProps}>{icon}</RoundDark>,
            roundGreen: <RoundGreen {...buttonProps}>{icon}</RoundGreen>,
            icon: <Icon {...buttonProps}>{icon}</Icon>,
          }[type]
        }
      </ButtonContainer>
    </Container>
  );
};

export default CustomButton;
