import * as assetService from '../../Services/Api/assetService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EAssetActionTypes } from './assetActionsTypes';
import {
  SaveAssetActionType,
  GetAssetActionType,
} from '../../Services/Api/assetService/types';

const getAssetTemplates = createAction(EAssetActionTypes.GET_ASSETS);
const getAssetTemplatesSuccess = createAction(
  EAssetActionTypes.GET_ASSETS_SUCCESS,
  (assets: any) => ({ assets })
);
const getAssetTemplatesError = createAction(
  EAssetActionTypes.GET_ASSETS_ERROR,
  (message: string) => ({ message })
);

const saveAsset = createAction(EAssetActionTypes.SAVE_ASSET);
const saveAssetSuccess = createAction(EAssetActionTypes.SAVE_ASSET_SUCCESS);
const saveAssetError = createAction(
  EAssetActionTypes.SAVE_ASSET_ERROR,
  (message: string) => ({ message })
);

const getAssetTemplate = createAction(EAssetActionTypes.GET_ASSET);
const getAssetTemplateSuccess = createAction(
  EAssetActionTypes.GET_ASSET_SUCCESS,
  (asset: any) => ({ asset })
);
const getAssetTemplateError = createAction(
  EAssetActionTypes.GET_ASSET_ERROR,
  (message: string) => ({ message })
);

export const resetCurrentAsset = createAction(
  EAssetActionTypes.RESET_CURRENT_ASSET
);

const getAssetAttributes = createAction(EAssetActionTypes.GET_ASSET_ATTRIBUTES);
const getAssetAttributesInputSuccess = createAction(
  EAssetActionTypes.GET_ASSET_ATTRIBUTES_INPUT_SUCCESS,
  (assets: any) => ({ assets })
);
const getAssetAttributesOutputSuccess = createAction(
  EAssetActionTypes.GET_ASSET_ATTRIBUTES_OUTPUT_SUCCESS,
  (assets: any) => ({ assets })
);
const getAssetAttributesError = createAction(
  EAssetActionTypes.GET_ASSET_ATTRIBUTES_ERROR,
  (message: string) => ({ message })
);

const saveAssetTemplate = createAction(EAssetActionTypes.SAVE_ASSET_TEMPLATE);
const saveAssetTemplateSuccess = createAction(
  EAssetActionTypes.SAVE_ASSET_TEMPLATE_SUCCESS,
  (
    asset: any,
    isDraft?: boolean,
    submitted?: boolean,
    notifySuccess?: boolean
  ) => ({
    asset,
    isDraft,
    submitted,
    notifySuccess,
  })
);
const saveAssetTemplateError = createAction(
  EAssetActionTypes.SAVE_ASSET_TEMPLATE_ERROR,
  (message: string) => ({ message })
);

const getJourneyAssets = createAction(EAssetActionTypes.GET_JOURNEY_ASSETS);
const getJourneyAssetsSuccess = createAction(
  EAssetActionTypes.GET_JOURNEY_ASSETS_SUCCESS,
  (inputs: any, outputs: any) => ({ inputs, outputs })
);
const getJourneyAssetsError = createAction(
  EAssetActionTypes.GET_JOURNEY_ASSETS_ERROR,
  (message: string) => ({ message })
);

const deleteJourneyAssets = createAction(
  EAssetActionTypes.DELETE_JOURNEY_ASSETS
);
const deleteJourneyAssetsSuccess = createAction(
  EAssetActionTypes.DELETE_JOURNEY_ASSETS_SUCCESS
);
const deleteJourneyAssetsError = createAction(
  EAssetActionTypes.DELETE_JOURNEY_ASSETS_ERROR,
  (message: string) => ({ message })
);

const deleteAssetTemplate = createAction(
  EAssetActionTypes.DELETE_ASSET_TEMPLATE,
  (assetId: any) => ({ assetId })
);
const deleteAssetTemplateSuccess = createAction(
  EAssetActionTypes.DELETE_ASSET_TEMPLATE_SUCCESS,
  (payload: any) => payload
);
const deleteAssetTemplateError = createAction(
  EAssetActionTypes.DELETE_ASSET_TEMPLATE_ERROR
);

const doGetAssetsFromPreviousStep = createAction(
  EAssetActionTypes.GET_ASSETS_PREVIOUS_STEP
);
const doGetAssetsFromPreviousStepSuccess = createAction(
  EAssetActionTypes.GET_ASSETS_PREVIOUS_STEP_SUCCESS,
  (inputs: any) => ({ inputs })
);
const doGetAssetsFromPreviousStepError = createAction(
  EAssetActionTypes.GET_ASSETS_PREVIOUS_STEP_ERROR,
  (message: string) => ({ message })
);

export const doGetAssetTemplates = (
  includeDrafts?: boolean,
  payload?: any
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getAssetTemplates());
  return await tap(
    assetService.getAssetTemplates(includeDrafts, payload),
    assets => {
      dispatch(getAssetTemplatesSuccess(assets));
    },
    error => {
      const _message = 'Failed to retrieve Assets. Please try again later.';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getAssetTemplatesError(message));
    }
  );
};

export const doSaveAssetTemplate = (
  payload,
  actionType: SaveAssetActionType,
  notifySuccess?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveAssetTemplate());
  return await tap(
    assetService.saveAssetTemplate(payload, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id, skuku: data._id }; // The Draft has its own _id different from the asset _id
      }
      dispatch(
        saveAssetTemplateSuccess(
          data,
          isDraft,
          !!actionType.match('submit'),
          notifySuccess
        )
      );
    },
    error => {
      const _message = 'Failed to save Asset. Please try again later.';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveAssetTemplateError(message));
    }
  );
};

export const doGetAssetTemplate = (
  skuku: string,
  actionType: GetAssetActionType
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getAssetTemplate());
  return await tap(
    assetService.getAssetTemplate(skuku, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id, skuku: data._id }; // The Draft has its own _id different from the asset _id/skuku
      }
      dispatch(getAssetTemplateSuccess(data));
    },
    error => {
      const _message = 'Failed to retrieve Asset. Please try again later.';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getAssetTemplateError(message));
    }
  );
};

export const doSaveAssets = (
  assets,
  inputs = true,
  stepId
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveAsset());
  return await tap(
    assetService.saveAssets(assets, inputs, stepId),
    response => {
      dispatch(saveAssetSuccess(response.data));
    },
    error => {
      const _message = 'Failed to save Assets. Please try again later.';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveAssetError(message));
    }
  );
};

export const doGetAssetAttributes = (
  payload,
  input
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getAssetAttributes());
  return await tap(
    assetService.getAssetAttributes(payload),
    response => {
      if (input) {
        dispatch(
          getAssetAttributesInputSuccess(
            response.data ? response.data.data : []
          )
        );
      } else {
        dispatch(
          getAssetAttributesOutputSuccess(
            response.data ? response.data.data : []
          )
        );
      }
    },
    error => {
      const _message = 'Failed to save Assets. Please try again later.';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getAssetAttributesError(message));
    }
  );
};

export const doGetJourneyAssets = (
  lotId,
  stepTemplateId,
  assetIds
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getJourneyAssets());
  return await tap(
    assetService.getJourneyAssets(lotId, stepTemplateId, assetIds),
    ({ inputs, outputs }) => {
      dispatch(getJourneyAssetsSuccess(inputs, outputs));
    },
    error => {
      const _message = 'Failed to retrieve Assets. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getJourneyAssetsError(message));
    }
  );
};

export const doGetOutpustPreviousStep = (
  assetIds
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(doGetAssetsFromPreviousStep());
  return await tap(
    assetService.getAssetsArray(assetIds),
    ({ data }) => {
      dispatch(
        doGetAssetsFromPreviousStepSuccess(
          data.data.map(asset => {
            delete asset._id;
            return asset;
          })
        )
      );
    },
    error => {
      const _message = 'Failed to retrieve Assets. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(doGetAssetsFromPreviousStepError(message));
    }
  );
};

export const doDeleteJourneyAssets = (
  assets: Array<{ stepId: string; subDocumentId: string }>,
  inputs
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(deleteJourneyAssets());
  return await tap(
    assetService.deleteAssets(assets, inputs),
    ({ inputs, outputs }) => {
      dispatch(deleteJourneyAssetsSuccess(inputs, outputs));
    },
    error => {
      const _message = 'Failed to retrieve Assets. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(deleteJourneyAssetsError(message));
    }
  );
};

export const doDeleteAssetTemplate = (
  assetId: string,
  isDraft: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(deleteAssetTemplate(assetId));
  return await tap(
    assetService.deleteAssetTemplate(assetId, isDraft),
    response => {
      dispatch(deleteAssetTemplateSuccess(response.data));
    },
    error => {
      const _message = 'Failed to delete Journey. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(deleteAssetTemplateError(message));
    }
  );
};
