import React from 'react';

export default () => (
  <svg
    width="10"
    height="17"
    viewBox="0 0 10 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12L5 17L10 12H0Z" fill="white" />
    <path d="M0 5L5 0L10 5H0Z" fill="white" />
  </svg>
);
