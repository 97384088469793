import React, { useState, useRef, useEffect } from 'react';
import { Container, Select } from './styles';
declare const InstallTrigger: any;

interface Props {
  options: object;
  defaultOption?: string;
  placeholder?: string;
  includeEmpty?: boolean;
  required?: boolean;
  disabled?: boolean;
  action: (selected: string) => void;
}

const FormSelect = ({
  options,
  action,
  placeholder,
  defaultOption,
  includeEmpty = true,
  required = false,
  disabled = false,
}: Props) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  const [selected, setSelected] = useState(defaultOption);

  useEffect(() => setSelected(defaultOption), [defaultOption]);

  const isFirefox = typeof InstallTrigger !== 'undefined';

  const handleChange = event => {
    const newValue = event.target.value;
    setSelected(newValue);
    action(newValue);
  };
  let selectStyle = {};
  if (!selected || selected === '') {
    selectStyle = { color: isFirefox ? '#adadad90' : '#adadad' };
  }

  return (
    <Container>
      {required && <div className="field-required">*</div>}
      <Select
        onChange={handleChange}
        value={selected}
        ref={selectRef}
        style={selectStyle}
        disabled={disabled}
      >
        {includeEmpty && (
          <option value="" selected>
            {placeholder}
          </option>
        )}
        {Object.keys(options).map(key => {
          const value = options[key];
          return (
            <option key={key} value={key}>
              {value}
            </option>
          );
        })}
      </Select>
    </Container>
  );
};

export default FormSelect;
