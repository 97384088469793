import React from 'react';

export default (props: { color?: string }) => (
  <svg
    width="34"
    height="41"
    viewBox="0 0 31 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.722 4H30.9024V24H16.9268L16.1282 20H4.94774V34H0.954712V0H18.9234L19.722 4ZM18.9234 20H22.9164V16H26.9094V12H22.9164V8H18.9234V12L16.9268 8V4H12.9338V8H8.94077V4H4.94774V8H8.94077V12H4.94774V16H8.94077V12H12.9338V16H16.9268V12L18.9234 16V20ZM12.9338 12V8H16.9268V12H12.9338ZM18.9234 12H22.9164V16H18.9234V12Z"
      fill={props.color ? props.color : "#A7BF2E" }
    />
  </svg>
);
