import React from 'react';
import AuthHeader from '../AuthHeader';
import styled from 'styled-components';

interface Props {
  header: string;
  children: any;
}

const Section = styled.section`
  background: linear-gradient(90deg, #000000 0%, #3a3a3a 50.52%, #000000 100%);
  height: fit-content;
  position: relative;
`;

const AuthContainer = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 100px;
  padding: 0;

  @media (max-width: 1400px) {
    margin-top: 25px;
  }
`;

const AuthLayout = ({ header, children }: Props) => (
  <Section className="container-fluid bg-dark p-0">
    <div className="">
      <AuthHeader title={header} langSelector />
    </div>
    <div className="d-flex justify-content-center mt-4 mb-5">
      <AuthContainer className="col-10 col-sm-9 col-md-8 col-lg-8 col-xl-7 bg-light text-center overflow-hidden">
        {children}
      </AuthContainer>
    </div>
  </Section>
);
export default AuthLayout;
