import axios from 'axios';
import moment from 'moment';
import {
  API_URL,
  KEYCLOAK_URL,
  KEYCLOAK_API,
  PRESIGNED_URL,
  API_URL_PREFIX,
} from '../../config';
import {
  getToken,
  setToken,
  deleteToken,
  deleteCurrentUser,
  getOrganization,
} from '../../Lib/Utils/auth';
import { ClientError } from '../../Lib/Utils/exceptions';
import * as authService from './authService';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  validateStatus: status => status < 400,
});

const getAPIUrl = source => {
  if (!source) {
    return API_URL + API_URL_PREFIX;
  }

  return {
    keycloak: KEYCLOAK_URL,
    keycloak_api: KEYCLOAK_API,
    presigned: PRESIGNED_URL,
    api: API_URL + API_URL_PREFIX,
    custom: '',
  }[source];
};

const axiosCall = async (url, { query, ...requestOptions }) => {
  try {
    const requestUrl = encodeQueryParams(
      `${getAPIUrl(requestOptions.source)}${url}`,
      query
    );

    try {
      const organizationId = getOrganization()?._id;
      if (organizationId && !requestOptions.excludeOrganization) {
        if (!requestUrl.searchParams.get('organizationId')) {
          requestUrl.searchParams.set('organizationId', organizationId);
        }
        if (requestOptions.body) {
          const body = JSON.parse(requestOptions.body);
          if (!body.organizationId) {
            body.organizationId = organizationId;
          }
          requestOptions.body = JSON.stringify(body);
        }
      }
    } catch (err) {
      console.log('err adding org id', err);
    }

    const response = await axiosInstance({
      method: requestOptions.method,
      url: requestUrl,
      data: requestOptions.body,
      headers: requestOptions.headers,
    });

    if (response.status >= 200 && response.status < 400) {
      return response;
    }
  } catch (error) {
    if (error && error.response && error.response.status < 500) {
      throw new ClientError(error.response.data, error.response.status);
    } else {
      throw new Error('Internal error');
    }
  }
};

const encodeQueryParams = (url, query) => {
  const encodeURL = new URL(url);
  if (query) {
    Object.entries(query).forEach(([k, v]) =>
      encodeURL.searchParams.append(k, v)
    );
  }
  return encodeURL;
};

export const unAuthAxiosCall = (url, requestOptions) =>
  axiosCall(url, requestOptions);

export const getAuthToken = async () => {
  const currentTime = moment().add(1, 'minute'); // add 1 minute in case the request goes out at the same expiration time
  let token = getToken();

  if (moment(token.expires_at).isSameOrBefore(currentTime)) {
    if (moment(token.refresh_expires_at).isSameOrBefore(currentTime)) {
      deleteToken();
      deleteCurrentUser();
      window.location.href = '/login?action=session_finished';
      return;
    } else {
      const response = await authService.refresh(token.refresh_token);
      token = response.data;
      setToken(token);
    }
  }
  return token;
};

export const authAxiosCall = async (url, requestOptions) => {
  const token = await getAuthToken();

  return axiosCall(url, {
    ...requestOptions,
    headers: {
      ...requestOptions.headers,
      Authorization: `Bearer ${token.access_token}`,
    },
  });
};
