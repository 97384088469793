import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 28px;
  > div {
    margin-bottom: 19px;
  }
`;
export {
  Container
}