import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  ul {
    padding: 0;
    list-style: none;
  }

  li {
    padding: 9px 70px 9px 27px;
    position: relative;
    display: flex;
    align-items: flex-start;
    border-left: 5px solid transparent;
  }
`;

const AddItemContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  float: right;
  margin-right: 32px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  > span {
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const AddItemIcon = styled.div`
  width: 34px;
  height: 34px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ControlsContainer = styled.div<{ first: boolean; last: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  right: 40px;
  top: -25px;
  flex-direction: column;
  height: 110px;
  z-index: 100;
  ${props => props.first && !props.last && `justify-content:flex-end`}
  ${props => props.last && !props.first && `justify-content:flex-start`}
  ${props => props.last && props.first && `justify-content:center`}
`;

const ControlsDeleteTooltip = styled.div`
  left: 40px;
`;

const ControlsTrashButton = styled.div`
  position: relative;
  border-radius: 100%;
  background-color: #000;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  cursor: pointer;

  & path {
    fill: #fff;
  }
  &:hover {
    ${ControlsDeleteTooltip} {
      display: flex;
    }
  }
`;

const ControlsArrowButton = styled.div`
  background-color: #000;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  margin: 5px 0;
  border: none;
  color: #fff;
  cursor: pointer;
  & path {
    fill: #fff;
  }
  &.up {
    border-radius: 100% 100% 50% 50%;
  }
  &.down {
    border-radius: 50% 50% 100% 100%;
  }
`;

const ItemWrapper = styled.div<{ readonly?: boolean; collapsed?: boolean }>`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 20px 5px;
  border-left: 5px solid transparent;
  padding: 5px 40px 40px;
  position: relative;
  max-height: 10000px;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  min-height: 55px;

  border-left-color: ${({ readonly }) => (readonly ? `#757575;` : `#A7BF2E`)};

  ${({ collapsed }) =>
    collapsed &&
    `
    padding-bottom: 6px !important;
    border-left-color: rgba(117, 117, 117, 0.25);

    .fields-wrapper {
      opacity: 0;
      height: 40px;
    };
  `}

  .fields-wrapper {
    width: 100%;
  }

  .action-buttons {
    position: absolute;
    right: 10px;
    top: 10px;
    margin: 0;
    display: flex;
    align-items: center;
    z-index: 1;
  }
`;

const EditItemContainer = styled.div`
  width: 100%;
  margin-top: 12px;
  > div {
    margin-top: 33px;
  }
  .action-btn {
    display: flex;
    justify-content: flex-end;
  }
`;

const ViewItemContainer = styled.div`
  margin-top: 50px;
`;

const ItemTitle = styled.div`
  position: absolute;
  top: 5px;
  left: 40px;
  right: 90px;
  display: flex;
  align-items: center;
  height: 40px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #303030;
  justify-content: space-between;
`;

const CheckboxItemContainer = styled.div`
  width: 100%;
  ul li {
    padding: 0;
    margin-left: -16px;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
      margin: 0;
    }
  }

  .delete-option {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }

  .delete-input {
    flex: 1;
  }

  .add-option {
    float: none;
    .add-option-icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
`;

const ArrowButton = styled.div`
  background-color: #000;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  margin: 5px 0;
  border: none;
  color: #fff;
  cursor: pointer;
  & path {
    fill: #fff;
  }
  &.up {
    border-radius: 100% 100% 50% 50%;
  }
  &.down {
    border-radius: 50% 50% 100% 100%;
  }
`;

const SortableListItem = styled.li`
  padding-right: 35px !important;
  min-height: 65px;
  margin-right: -35px;
  .sort {
    display: none;
    margin-left: -25px;
    position: relative;
    left: 35px;
  }

  :hover {
    .sort {
      display: block;
    }
  }
`;

export {
  Container,
  AddItemContainer,
  AddItemIcon,
  ControlsContainer,
  ControlsArrowButton,
  ControlsTrashButton,
  ItemWrapper,
  ViewItemContainer,
  ItemTitle,
  EditItemContainer,
  CheckboxItemContainer,
  SortableListItem,
  ArrowButton,
};
