import React from 'react';
import { FaShoppingCart, FaRegQuestionCircle, FaFlagCheckered } from 'react-icons/fa';

import {
  ProducerIcon,
  LogisticIcon,
  ProcessorIcon,
  DistributorIcon,
} from '../../Components/CustomIcons';

const FacilityTypeIcons = {
  QuestionMark: <FaRegQuestionCircle fill="#000" />,
  Flag: <FaFlagCheckered fill="#000" />,
  Producer: <ProducerIcon color="#000" />,
  Logistics: <LogisticIcon color="#000" />,
  Processor: <ProcessorIcon color="#000" />,
  Distributor: <DistributorIcon color="#000" />,
  Supermarket: <FaShoppingCart fill="#000" />
};

const FacilityTypes: Array<{ value: string; label: string, icon?: any }> = [
  { value: 'QuestionMark', label: 'Question Mark', icon: FacilityTypeIcons.QuestionMark  },
  { value: 'Flag', label: 'Flag', icon: FacilityTypeIcons.Flag },
  { value: 'Distributor', label: 'Distributor', icon: FacilityTypeIcons.Distributor },
  { value: 'Logistics', label: 'Logistics', icon: FacilityTypeIcons.Logistics },
  { value: 'Processor', label: 'Processor', icon: FacilityTypeIcons.Processor },
  { value: 'Producer', label: 'Producer', icon: FacilityTypeIcons.Producer },
  { value: 'Supermarket', label: 'Supermarket', icon: FacilityTypeIcons.Supermarket },
];

export default FacilityTypes;
export { FacilityTypeIcons };
