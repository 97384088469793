import { ECatalogActionTypes } from './catalogActionsTypes';

const initialState = {
  brands: [],
  identifierTypes: [],
  categories: [],
  marketplaces: [],
  claimTypes: [],
  // catalogedAssetInput: [],
  // catalogedAssetOutput: [],
  loading: false,
  assetsInput: [],
  assetsOutput: [],
};

interface State {
  brands: Array<any>;
  identifierTypes: Array<string>;
  categories: Array<string>;
  marketplaces: Array<any>;
  claimTypes: Array<any>;
  // catalogedAssetInput: Array<any>;
  // catalogedAssetOutput: Array<any>;
  loading: boolean;
  error?: string;
  assets?: Array<any>;
  assetsInput?: Array<any>;
  assetsOutput?: Array<any>;
}

interface CatalogAction extends State {
  type: ECatalogActionTypes;
  payload: any;
}

type TAssetReducerActions = CatalogAction;

export default function(
  state: State = initialState,
  action: TAssetReducerActions
) {
  switch (action.type) {
    case ECatalogActionTypes.GET_BRANDS:
      return { ...state, loading: true };
    case ECatalogActionTypes.GET_BRANDS_SUCCESS:
      return { ...state, brands: action.payload.brands, loading: false };
    case ECatalogActionTypes.GET_BRANDS_ERROR:
      return { ...state, error: action.error, loading: false };

    case ECatalogActionTypes.GET_IDENTIFIER_TYPES:
      return { ...state, loading: true };
    case ECatalogActionTypes.GET_IDENTIFIER_TYPES_SUCCESS:
      return {
        ...state,
        identifierTypes: action.payload.identifierTypes,
        loading: false,
      };
    case ECatalogActionTypes.GET_IDENTIFIER_TYPES_ERROR:
      return { ...state, error: action.error, loading: false };

    case ECatalogActionTypes.GET_CATEGORIES:
      return { ...state, loading: true };
    case ECatalogActionTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories,
        loading: false,
      };
    case ECatalogActionTypes.GET_CATEGORIES_ERROR:
      return { ...state, error: action.error, loading: false };

    case ECatalogActionTypes.GET_MARKETPLACES:
      return { ...state, loading: true };
    case ECatalogActionTypes.GET_MARKETPLACES_SUCCESS:
      return {
        ...state,
        marketplaces: action.payload.marketplaces,
        loading: false,
      };
    case ECatalogActionTypes.GET_MARKETPLACES_ERROR:
      return { ...state, error: action.error, loading: false };

    case ECatalogActionTypes.GET_CLAIM_TYPES:
      return { ...state, loading: true };
    case ECatalogActionTypes.GET_CLAIM_TYPES_SUCCESS:
      return {
        ...state,
        claimTypes: action.payload.claimTypes,
        loading: false,
      };
    case ECatalogActionTypes.GET_CLAIM_TYPES_ERROR:
      return { ...state, error: action.error, loading: false };

    case ECatalogActionTypes.GET_ASSETCATALOG:
      return {
        ...state,
        assets: [],
        loading: false,
      };
    case ECatalogActionTypes.GET_ASSETCATALOG_INPUT_SUCCESS:
      return {
        ...state,
        assetsInput: action.payload.assets,
        loading: false,
      };
    case ECatalogActionTypes.GET_ASSETCATALOG_OUTPUT_SUCCESS:
      return {
        ...state,
        assetsOutput: action.payload.assets,
        loading: false,
      };

    default:
      return state;
  }
}
