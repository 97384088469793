import React from 'react';

export default () => (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8.81667L3.93326 5L0 1.175L1.21089 0L6.36364 5L1.21089 10L0 8.81667Z"
      fill="#F9F9FB"
    />
  </svg>
);
