import { ObjectID } from 'bson';
import { authAxiosCall } from '../axiosCall';
import { uploadMedia } from '../mediaService';
import { SaveVerification, PatchVerificationLinks } from './types';

export const saveVerification = async (verification: SaveVerification) => {
  const links = [] as Array<string>;
  if (verification.links?.length) {
    for (const link of verification.links) {
      if (link && link.file) {
        const filePath = `verifications/${link.file.name}`;
        const [fileUrl] = await uploadMedia([
          {
            file: link.file,
            path: filePath,
          },
        ]);
        if (fileUrl) {
          links.push(fileUrl);
        }
      } else {
        links.push(link.src);
      }
    }
  }

  return authAxiosCall('/verifications', {
    method: 'POST',
    body: JSON.stringify({
      ...verification,
      links,
    }),
  });
};

export const addVerificationLinks = async ({
  verificationId,
  links,
}: PatchVerificationLinks) => {
  const newLinks = [] as Array<string>;
  if (links?.length) {
    for (const link of links) {
      if (link && link.file) {
        const filePath = `verifications/${link.file.name}`;
        const [fileUrl] = await uploadMedia([
          {
            file: link.file,
            path: filePath,
          },
        ]);
        if (fileUrl) {
          newLinks.push(fileUrl);
        }
      } else {
        newLinks.push(link.src);
      }
    }
  }

  return authAxiosCall(`/verifications/${verificationId}/links`, {
    method: 'PATCH',
    body: JSON.stringify(newLinks),
  });
};
