import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router';

interface Props {
  url: string;
  label?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

interface NavProps extends RouteComponentProps {
  url: string;
  label?: string;
  history: any;
  data?: any;
}

const A = styled.a`
  color: #a7bf2e;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  :hover {
    color: #a7bf2e;
    text-decoration: underline;
  }
`;

const Link = ({ url, label = url, target = '_blank' }: Props) => {
  return (
    <A onClick={(evt) => evt.stopPropagation()} href={url} target={target}>
      {label}
    </A>
  );
};

const Nav = styled.div`
  color: #a7bf2e;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  :hover {
    color: #a7bf2e;
    text-decoration: underline;
  }
`;

const NavLink = withRouter(({ url, label = url, history, data }: NavProps) => {
  return (
    <Nav onClick={() => history.push({ pathname: url, state: { data } })}>
      {label}
    </Nav>
  );
});

export default Link;
export { NavLink };
