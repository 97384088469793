import { AxiosResponse } from 'axios';
import { ObjectID } from 'bson';
import { authAxiosCall } from '../axiosCall';
import { GetUserActionType, SaveUserActionType, SaveUser } from './types';
import { uploadMedia } from '../mediaService';
import { getUUIDForFile } from '../../../Lib/Utils/functions';

export const getUsers = async (
  includeDrafts?: boolean,
  includeAdmins?: boolean
): Promise<AxiosResponse<any> | undefined> => {
  let response = await authAxiosCall('/users', {
    method: 'GET',
    source: 'keycloak_api',
  });
  let users = response?.data || [];
  users = users.filter(u => !!u.email && !!u.firstName);

  if (!includeAdmins) {
    // Get top-level users
    response = await authAxiosCall(`/organizations/organizations/members`, {
      method: 'GET',
      source: 'keycloak_api',
    });
    const orgUsers = response?.data || [];

    users = users.filter(u => !orgUsers.find(o => o.id === u.id));
  }

  if (includeDrafts) {
    response = await authAxiosCall('/drafts/collections/users', {
      method: 'GET',
    });
    const drafts = response && response.data ? response.data.data : [];
    users = users.concat(
      drafts.map(d => ({
        ...d.draft,
        userId: d.draft.id,
        _id: d._id,
        isDraft: true,
        createdAt: d.createdAt,
        updatedAt: d.updatedAt,
      }))
    );
  }
  return users;
};

export const getUser = async (
  userId: string,
  actionType?: GetUserActionType
): Promise<AxiosResponse<any> | undefined> => {
  if (actionType === 'get-draft') {
    return authAxiosCall(`/drafts/${userId}`, {
      method: 'GET',
    });
  } else {
    return authAxiosCall(`/users/${userId}`, {
      method: 'GET',
      source: 'keycloak_api',
    });
  }
};

export const saveUser = async (
  {
    _id, // the draft _id
    id,
    firstName,
    lastName,
    email,
    password,
    userPermission,
    groups,
    previousGroups,
  }: SaveUser,
  actionType: SaveUserActionType
): Promise<AxiosResponse<any> | undefined> => {
  let path = '';
  let method = '';
  let source = 'api';

  switch (actionType) {
    case 'create-draft':
      path = `/drafts/collections/users`;
      method = 'POST';
      break;
    case 'save-draft':
      path = `/drafts/${_id}`;
      method = 'PUT';
      break;
    case 'submit-create-entity':
      path = `/users`;
      method = 'POST';
      source = 'keycloak_api';
      break;
    case 'save-entity':
    case 'submit-save-entity':
      path = `/users/${id}`;
      method = 'PUT';
      source = 'keycloak_api';
      break;
  }

  try {
    const response = await authAxiosCall(path, {
      method,
      source,
      body: JSON.stringify({
        id,
        firstName,
        lastName,
        email,
        username: email,
        password,
        groups,
      }),
    });

    // For existing users update the group permissions
    if (['save-entity', 'submit-save-entity'].includes(actionType)) {
      const groupsToRemove = [...previousGroups].filter(
        pg => !groups.find(g => g === pg)
      );
      const groupsToAdd = groups;

      // add to groups
      await Promise.all(
        groupsToAdd.map(async groupId => {
          await authAxiosCall(`/users/${id}/add-to-group?group=${groupId}`, {
            method: 'PATCH',
            source: 'keycloak_api',
          });
        })
      );

      await Promise.all(
        groupsToRemove.map(async groupId => {
          return authAxiosCall(
            `/users/${id}/remove-from-group?group=${groupId}`,
            {
              method: 'PATCH',
              source: 'keycloak_api',
            }
          );
        })
      );
    }

    // Drop the draft when new entity
    if (actionType === 'submit-create-entity') {
      try {
        await authAxiosCall(`/drafts/${_id}`, {
          method: 'DELETE',
        });
      } catch (err) {
        // Do not throw
        console.log(`ERR removing draft`, err.message);
      }
    }

    return response;
  } catch (e) {
    if (e.message === 'user already exists') {
    } else {
      throw e;
    }
  }
};

export const getOrganizations = async () =>
  await authAxiosCall(`/organizations`, {
    method: 'GET',
    source: 'keycloak_api',
  });
