import React, { useEffect } from 'react';
import i18n from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { Facility } from '../../../Lib/Utils/Validations';

import {
  FacilityDetailsFormContainer as FormContainer,
  FacilityDetailsContainer as Container,
} from './styles';

import {
  Label,
  FormInput,
  InfoIcon,
  Tooltip,
  Checkbox,
  Button,
} from '../../../Components';

interface Props {
  onChange: Function;
  registeredName?: string;
  description?: string;
  quote?: string;
  quoteSource?: string;
  website?: string;
  noWebsite?: boolean;
  ethAddress?: string;
  facilityDetailFirstLoad?: boolean;
  formErrors?: any;
  validate?: boolean;
}

const FacilityDetails = ({ onChange, formErrors, validate, ...data }: Props) => {
  const {
    registeredName,
    description,
    quote,
    quoteSource,
    website,
    noWebsite,
    ethAddress,
    facilityDetailFirstLoad = true,
  } = data;

  const { errors, control, setValue, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    facilityDetailFirstLoad === false && triggerValidation();
    onChange('facilityDetailFirstLoad', false);
  }, []);

  useEffect(() => {
    setValue([
      { registeredName },
      { description },
      { quote },
      { quoteSource },
      { ethAddress },
      { website },
    ]);
  }, [
    registeredName,
    description,
    quote,
    quoteSource,
    ethAddress,
    website,
  ]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      triggerValidation();
    }
  }, [formErrors, validate]);

  return (
    <Container>
      <FormContainer className="row">
        <div className="col-12 col-lg-8">
          <Label>
            {i18n.t('Name')}
            <Tooltip text={i18n.t('The name of the facility')}>
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the facility name')}
                maxLength={50}
              />
            }
            name="registeredName"
            value={registeredName}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Facility.name}
            defaultValue={registeredName}
          />
          {errors.registeredName && (
            <span className="inlineErrorMessage">
              {errors.registeredName.message}.
            </span>
          )}
        </div>

        <div className="col-12">
          <Label>
            {i18n.t('Description')}
            <Tooltip text={i18n.t('Description of the facility')}>
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>
          <Controller
            as={
              <FormInput
                required
                type="textarea"
                maxLength={300}
                placeholder={i18n.t('Enter a brief description of the facility')}
              />
            }
            name="description"
            value={description}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Facility.longDescription}
            defaultValue={description}
          />
          {errors.description && (
            <span className="inlineErrorMessage">
              {errors.description.message}.
            </span>
          )}
        </div>

        <div className="col-12">
          <Label>
            {i18n.t('Quote')}
            <Tooltip text={i18n.t('Quote of the facility')}>
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>
          <Controller
            as={
              <FormInput
                required
                type="textarea"
                maxLength={150}
                placeholder={i18n.t('Enter the facility quote')}
              />
            }
            name="quote"
            value={quote}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Facility.quote}
            defaultValue={quote}
          />
          {errors.quote && (
            <span className="inlineErrorMessage">{errors.quote.message}.</span>
          )}
        </div>

        <div className="col-12">
          <Label>
            {i18n.t('Quote source')}
            <Tooltip text={i18n.t('Quote source of the facility')}>
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the facility quote source')}
                maxLength={75}
              />
            }
            name="quoteSource"
            value={quoteSource}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Facility.quoteSource}
            defaultValue={quoteSource}
          />
          {errors.quoteSource && (
            <span className="inlineErrorMessage">
              {errors.quoteSource.message}.
            </span>
          )}
        </div>

        <div className="col-12">
          <div className="checkbox-wrapper ml-0">
            <Label>
              {i18n.t('Website')}
              <Tooltip text={i18n.t('Website of the facility')}>
                <div>
                  <InfoIcon />
                </div>
              </Tooltip>
            </Label>
            <Label className="ml-4">
              <Checkbox
                onChange={evt => onChange(evt.target.name, evt.target.checked)}
                checked={noWebsite}
                name="noWebsite"
              />
              {i18n.t('No website')}
            </Label>
          </div>
          {!noWebsite && (
            <>
              <Controller
                as={
                  <FormInput
                    required
                    placeholder={i18n.t('Enter the facility website')}
                    maxLength={75}
                  />
                }
                name="website"
                value={website}
                control={control}
                onChange={([evt]) => {
                  onChange(evt.target.name, evt.target.value);
                  return evt.target.value;
                }}
                rules={Facility.website(noWebsite)}
                defaultValue={website}
              />
              {errors.website && (
                <span className="inlineErrorMessage">
                  {errors.website.message}.
                </span>
              )}
            </>
          )}
        </div>

        {/* <div className="col-12 col-lg-8">
          <Label>
            {i18n.t('SUKU wallet address (Ethereum address)')}
            <Tooltip text={i18n.t('SUKU wallet address of the facility')}>
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>
          <div className="col-12 d-flex flex-row">
            <div className="flex-fill">
              <FormInput
                name="ethAddress"
                disabled
                placeholder={i18n.t('SUKU Ethereum address')}
                onChange={evt => onChange(evt.target.name, evt.target.value)}
                value={ethAddress}
              />
            </div>
            <div className="ml-3">
              <Button
                text={i18n.t('Auto Generate')}
                type="dark"
                action={() =>
                  onChange(
                    'ethAddress',
                    Array(32)
                      .fill(0)
                      .map(x =>
                        Math.random()
                          .toString(36)
                          .charAt(2)
                      )
                      .join('')
                      .toUpperCase()
                  )
                }
              />
            </div>
          </div>
        </div> */}
      </FormContainer>
    </Container>
  );
};

export default FacilityDetails;
