import React from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

import { ClaimsShow } from '../../../../../Components';

import { Container, Content } from '../styles';

interface Props {
  data: any;
  onChange?: any;
  claims?: Array<any>;
}

const OneTime = ({ data, onChange, claims }: Props) => {
  return (
    <Container>
      <Content>
        <div className="row">
          <div className="col-12">
            <ClaimsShow
              claims={claims}
              onChange={newClaims => onChange('onetimeClaims', newClaims)}
              noClaimsMessage={i18n.t(
                'There are no one-time claims for this journey.'
              )}
              notExpires={true}
            />
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default withTranslation()(OneTime as any) as any;
