import React, { useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';
import { Asset } from '../../../Lib/Utils/Validations';

import {
  AssetDetailsFormContainer as FormContainer,
  AssetDetailsContainer as Container,
} from './styles';

import {
  Label,
  FormInput,
  InfoIcon,
  CustomTooltip,
  Checkbox,
  TransferList,
  Dropdown,
  Tooltip,
} from '../../../Components';

interface Props {
  onChange: Function;
  formErrors?: any;
  identifierTypes?: Array<string>;
  categories?: Array<string>;
  brands?: Array<any>;
  markets?: Array<any>;
  assetDetailFirstLoad?: boolean;
  isMarketableProduct?: boolean;
  name?: string;
  brandId?: string;
  type?: string;
  value?: string;
  description?: string;
  category?: string;
  website?: string;
  marketplaces?: Array<string>;
  validate?: boolean;
}

const AssetDetails = ({
  onChange,
  identifierTypes,
  categories,
  brands,
  markets = [],
  formErrors,
  validate,
  ...data
}: Props) => {
  const {
    isMarketableProduct = false,
    name,
    brandId,
    type,
    value,
    description,
    category,
    website,
    marketplaces = [],
    assetDetailFirstLoad = true,
  } = data;

  const {
    errors,
    control,
    setValue,
    register,
    setError,
    clearError,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    assetDetailFirstLoad === false && triggerValidation();
    onChange('assetDetailFirstLoad', false);
  }, []);

  useEffect(() => {
    setValue([
      { name },
      { brandId },
      { type },
      { value },
      { description },
      { category },
      { website },
    ]);
  }, [name, brandId, type, value, description, category, website]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      triggerValidation();
    }
  }, [formErrors, validate]);

  useEffect(() => {
    setValue('marketplaces', marketplaces);
    const marketplacesErrors =
      isMarketableProduct === false
        ? true
        : marketplaces && marketplaces.length;

    if (!marketplacesErrors) {
      setError(
        'marketplaces',
        'required',
        i18n.t('You must have at least one marketplace fr this asset')
      );
    } else {
      errors.marketplaces && clearError('marketplaces');
    }
  }, [marketplaces, isMarketableProduct]);

  return (
    <Container>
      <FormContainer className="row">
        <div className="col-12">
          <div className="checkbox-wrapper">
            <Label>
              <Checkbox
                onChange={evt => onChange(evt.target.name, evt.target.checked)}
                checked={isMarketableProduct}
                name="isMarketableProduct"
              />
              {i18n.t('This asset is a marketable product')}
              <Tooltip text={i18n.t('If the asset is a marketable product')}>
                <div>
                  <InfoIcon />
                </div>
              </Tooltip>
            </Label>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <Label>
            {i18n.t('Name')}
            <Tooltip text={i18n.t('The name of the asset')}>
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>

          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the asset name')}
                maxLength={50}
              />
            }
            name="name"
            value={name}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Asset.name}
            defaultValue={name}
          />
          {errors.name && (
            <span className="inlineErrorMessage">{errors.name.message}.</span>
          )}
        </div>
        <div className="col-12 col-lg-5">
          <Label>
            {i18n.t('Brand')}
            <Tooltip text={i18n.t('The brand of the asset')}>
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>

          <Controller
            as={
              <Dropdown
                required={isMarketableProduct}
                options={(!isMarketableProduct
                  ? [{ label: '', value: '' }]
                  : []
                ).concat(
                  brands?.map(({ _id, name }) => ({
                    value: _id.toString(),
                    label: name,
                  })) || []
                )}
                placeholder={i18n.t('Select a brand')}
                action={newValue => onChange('brandId', newValue || null)}
                defaultOption={brandId}
              />
            }
            name="brandId"
            value={brandId}
            control={control}
            onChange={([newValue]) => {
              onChange('brandId', newValue || null);
              return newValue || null;
            }}
            rules={Asset.brand}
            defaultOption={brandId}
          />
          {errors.brandId && isMarketableProduct && (
            <span className="inlineErrorMessage">
              {errors.brandId.message}.
            </span>
          )}
        </div>
        <div className="col-12 col-md-5 col-lg-3">
          <Label>
            {i18n.t('Identifier type')}
            <Tooltip text={i18n.t('The type of identifier used to')}>
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>

          <Controller
            as={
              <Dropdown
                required={isMarketableProduct}
                options={(!isMarketableProduct
                  ? [{ label: '', value: '' }]
                  : []
                ).concat(
                  identifierTypes?.map(i => ({
                    value: i,
                    label: i,
                  })) || []
                )}
                placeholder={i18n.t('Select a type')}
                action={newValue => onChange('type', newValue || null)}
                defaultOption={type}
              />
            }
            name="type"
            value={type}
            control={control}
            onChange={([newValue]) => {
              onChange('type', newValue || null);
              return newValue || null;
            }}
            rules={Asset.type}
            defaultValue={type}
          />
          {errors.type && isMarketableProduct && (
            <span className="inlineErrorMessage">{errors.type.message}.</span>
          )}
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <Label>
            {i18n.t('Identifier value')}
            <Tooltip
              text={i18n.t('For example the value of the SKU for this asset')}
            >
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>

          <Controller
            as={
              <FormInput
                required={isMarketableProduct}
                placeholder={i18n.t('Set the value')}
                maxLength={50}
              />
            }
            name="value"
            value={value}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Asset.identifierValue}
            defaultValue={value}
          />
          {errors.value && isMarketableProduct && (
            <span className="inlineErrorMessage">{errors.value.message}.</span>
          )}
        </div>
        <div className="col-12">
          <Label>
            {i18n.t('Description')}
            <Tooltip
              text={i18n.t(
                'If the asset is a marketable product, then description'
              )}
            >
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>

          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the description for this asset')}
                type="textarea"
                maxLength={350}
              />
            }
            name="description"
            value={description}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Asset.description}
            defaultValue={description}
          />
          {errors.description && (
            <span className="inlineErrorMessage">
              {errors.description.message}.
            </span>
          )}
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <Label>
            {i18n.t('Asset type')}
            <Tooltip
              text={i18n.t('The category or type of asset being created')}
            >
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>

          <Controller
            as={
              <Dropdown
                required
                options={categories?.map(i => ({ value: i, label: i })) || []}
                placeholder={i18n.t('Select a type')}
                action={newValue => onChange('category', newValue)}
                defaultOption={category}
                searchable
              />
            }
            name="category"
            value={category}
            control={control}
            onChange={([newValue]) => {
              onChange('category', newValue);
              return newValue;
            }}
            rules={Asset.typeOptions}
            defaultValue={category}
          />
          {errors.category && (
            <span className="inlineErrorMessage">
              {errors.category.message}.
            </span>
          )}
        </div>
        <div className="col-12 col-md-6 col-lg-8">
          <Label>
            {i18n.t('Website')}
            <Tooltip text={i18n.t('The website that will be shown')}>
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>

          <Controller
            as={
              <FormInput
                required={isMarketableProduct}
                placeholder={i18n.t('Enter the website URL')}
              />
            }
            name="website"
            value={website}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Asset.website}
            defaultValue={website}
          />
          {errors.website &&
            !(!isMarketableProduct && errors.website.type === 'required') && (
              <span className="inlineErrorMessage">
                {errors.website.message}.
              </span>
            )}
        </div>
        {isMarketableProduct && (
          <div className="col-12">
            <Label>
              {i18n.t('Marketplace(s) that this asset will be available on')}
              <Tooltip
                text={i18n.t(
                  'If this product is a marketable product, select a marketplace'
                )}
              >
                <div>
                  <InfoIcon />
                </div>
              </Tooltip>
            </Label>
            <div>
              <TransferList
                fromPlaceholder={i18n.t('Search for marketplaces')}
                toPlaceholder={i18n.t(
                  'You must have at least one marketplace for this asset'
                )}
                items={
                  (markets &&
                    markets?.map(({ _id, name }) => ({
                      text: name,
                      selected: marketplaces?.includes(_id),
                      data: _id,
                    }))) ||
                  []
                }
                handleChange={items => {
                  const selections = [] as Array<string>;
                  items.forEach(({ data, selected }) => {
                    if (selected) selections.push(data);
                  });
                  onChange('marketplaces', selections);
                }}
              />
              {errors.marketplaces && (
                <span className="inlineErrorMessage pull-right mr-3 mt-3">
                  {errors.marketplaces.message}.
                </span>
              )}
            </div>
          </div>
        )}
      </FormContainer>
    </Container>
  );
};

export default AssetDetails;
