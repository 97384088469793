import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

import translationEn from './translations/en.json';
import translationEsp from './translations/esp.json';

import { I18N_DEBUG } from '../../config';

export const availableLanguages = {
  en: 'English',
  // esp: 'Español',
};

i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    useLocalStorage: true,
    localStorageExpirationTime: 86400000,
    debug: I18N_DEBUG,
    lng: 'en',
    whitelist: Object.keys(availableLanguages),
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translations: translationEn,
      },
      esp: {
        translations: translationEsp,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    react: {
      wait: true,
    },
  });

export default i18n;
