import { authAxiosCall } from '../axiosCall';
import {
  SaveAttestor,
  GetAttestorActionType,
  SaveAttestorActionType,
} from './types';
import { AxiosResponse } from 'axios';
import { isOrgAdmin } from '../../../Lib/Utils/auth';

export const getAttestors = async (
  payload,
  includeDrafts?: boolean
): Promise<AxiosResponse<any> | undefined> => {
  let response = await authAxiosCall('/attestors?limit=1000', {
    query: payload && { payload },
    method: 'GET',
  });
  let attestors = response && response.data ? response.data.data : [];

  attestors = attestors.filter(a => !a.archived);

  if (isOrgAdmin() && includeDrafts) {
    response = await authAxiosCall('/drafts/collections/attestors', {
      method: 'GET',
    });
    const drafts = response && response.data ? response.data.data : [];
    attestors = attestors.concat(
      drafts.map(d => ({
        ...d.draft,
        attestorId: d.draft._id,
        _id: d._id,
        isDraft: true,
        createdAt: d.createdAt,
        updatedAt: d.updatedAt,
      }))
    );
  }
  return attestors;
};

export const getAttestor = async (
  attestorId: string,
  actionType?: GetAttestorActionType
): Promise<AxiosResponse<any> | undefined> => {
  const path =
    actionType === 'get-draft'
      ? `/drafts/${attestorId}`
      : `/attestors/${attestorId}`;

  return authAxiosCall(path, {
    method: 'GET',
  });
};

export const saveAttestor = async (
  { _id, ...attestor }: SaveAttestor,
  actionType: SaveAttestorActionType
) => {
  let path = '';
  let method = '';
  switch (actionType) {
    case 'create-draft':
      path = `/drafts/collections/attestors`;
      method = 'POST';
      break;
    case 'save-draft':
      path = `/drafts/${_id}`;
      method = 'PUT';
      break;
    case 'submit-create-entity':
      path = `/attestors`;
      method = 'POST';
      break;
    case 'save-entity':
    case 'submit-save-entity':
      path = `/attestors/${_id}`;
      method = 'PUT';
      break;
  }

  const response = await authAxiosCall(path, {
    method,
    body: JSON.stringify({
      _id,
      ...attestor,
    }),
  });

  if (actionType === 'submit-create-entity') {
    await deleteDraft(_id);
  }

  return response;
};

export const deleteAttestor = async (
  _id: string,
  email: string,
  isDraft: boolean
) => {
  let response;
  if (isDraft) {
    response = await deleteDraft(_id);
  } else {
    response = await authAxiosCall(`/attestors/${_id}`, {
      method: 'PUT',
      body: JSON.stringify({
        archived: true,
        email,
      }),
    });
  }
  return response;
};

const deleteDraft = async (_id?: string) => {
  if (_id) {
    try {
      await authAxiosCall(`/drafts/${_id}`, {
        method: 'DELETE',
      });
    } catch (err) {
      // Do not throw draft might not exist
      console.log(`ERR removing draft`, err.message);
    }
  }
  return true;
};
