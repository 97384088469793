import { EJourneyActionTypes } from './journeyActionsTypes';

const initialState = {
  journeys: null,
  journeyTemplates: [],
  currentJourney: [],
  currentTemplate: {},
  loading: false,
  submitSuccess: false,
  saveSuccess: false,
  deleteSuccess: false,
  currentLot: {},
  refresh: false,
  activeJourneysStatus: [],
  activeJourneysStatusLoading: false,
};

interface State {
  journeys: any;
  journeyTemplates: Array<any>;
  currentJourney: any;
  currentTemplate: any;
  currentLot: any;
  loading: boolean;
  submitSuccess?: boolean;
  deleteSuccess?: boolean;
  error?: string;
  refresh?: boolean;
  activeJourneysStatus?: Array<any>;
  activeJourneysStatusLoading?: boolean;
}

interface JourneyAction extends State {
  type: EJourneyActionTypes;
  payload: any;
}

type TJourneyReducerActions = JourneyAction;

export default function(
  state: State = initialState,
  action: TJourneyReducerActions
) {
  switch (action.type) {
    case EJourneyActionTypes.GET_JOURNEYS:
      return { ...state, loading: true, journeys: null };
    case EJourneyActionTypes.GET_JOURNEYS_SUCCESS:
      return {
        ...state,
        journeys: action.payload.journeys.data,
        loading: false,
      };
    case EJourneyActionTypes.GET_JOURNEYS_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    case EJourneyActionTypes.CLEAN_JOURNEYS:
      return {
        ...state,
        loading: false,
        journeys: null,
        currentLot: null,
        error: null,
      };

    case EJourneyActionTypes.GET_JOURNEY:
      return {
        ...state,
        submitSuccess: false,
        saveSuccess: false,
        currentLot: null,
        currentJourney: [],
        loading: true,
      };
    case EJourneyActionTypes.GET_JOURNEY_SUCCESS:
      return { ...state, currentJourney: action.payload, loading: false };
    case EJourneyActionTypes.GET_JOURNEY_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        currentJourney: [],
      };

    case EJourneyActionTypes.SAVE_JOURNEY:
      return {
        ...state,
        loading: true,
        submitSuccess: false,
        saveSuccess: false,
        error: null,
      };
    case EJourneyActionTypes.SAVE_JOURNEY_SUCCESS:
      return {
        ...state,
        submitSuccess: true,
        currentLot: action.payload.journey,
        loading: false,
        error: null,
      };
    case EJourneyActionTypes.SAVE_JOURNEY_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        submitSuccess: false,
        saveSuccess: false,
      };

    case EJourneyActionTypes.SAVE_JOURNEY_TEMPLATE:
      return {
        ...state,
        loading: true,
        submitSuccess: false,
        saveSuccess: false,
        error: null,
        refresh: false,
      };
    case EJourneyActionTypes.SAVE_JOURNEY_TEMPLATE_SUCCESS:
      const isDraft = action.payload.isDraft;
      let stateProps = {};
      if (isDraft) {
        stateProps = {
          submitSuccess: false,
          currentTemplate: action.payload.journey,
        };
      } else {
        stateProps = { submitSuccess: !!action.payload.submitted };
      }
      return {
        ...state,
        loading: false,
        error: null,
        saveSuccess: !!action.payload.notifySuccess,
        refresh: true,
        ...stateProps,
      };
    case EJourneyActionTypes.SAVE_JOURNEY_TEMPLATE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        submitSuccess: false,
        refresh: false,
      };

    case EJourneyActionTypes.GET_JOURNEY_TEMPLATES:
      return { ...state, error: false, loading: true, journeyTemplates: [] };
    case EJourneyActionTypes.GET_JOURNEY_TEMPLATES_SUCCESS:
      return {
        ...state,
        journeyTemplates: action.payload.journeyTemplates,
        loading: false,
        error: false,
      };
    case EJourneyActionTypes.GET_JOURNEY_TEMPLATES_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    case EJourneyActionTypes.GET_JOURNEY_TEMPLATE:
      return {
        ...state,
        loading: true,
        error: false,
        saveSuccess: false,
        refresh: false,
      };
    case EJourneyActionTypes.GET_JOURNEY_TEMPLATE_SUCCESS:
      return {
        ...state,
        currentTemplate: action.payload,
        loading: false,
        error: false,
      };
    case EJourneyActionTypes.GET_JOURNEY_TEMPLATE_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    case EJourneyActionTypes.DELETE_JOURNEY_TEMPLATE:
      return { ...state, loading: true, error: false };
    case EJourneyActionTypes.DELETE_JOURNEY_TEMPLATE_SUCCESS:
      return { ...state, deleteSuccess: true, loading: false, error: false };
    case EJourneyActionTypes.DELETE_JOURNEY_TEMPLATE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        deleteSuccess: false,
      };

    case EJourneyActionTypes.RESET_CURRENT_JOURNEY_TEMPLATE:
      return {
        ...state,
        currentTemplate: {},
        submitSuccess: false,
        saveSuccess: false,
        deleteSuccess: false,
      };

    case EJourneyActionTypes.ACTIVE_JOURNEY_STATUS:
      return {
        ...state,
        activeJourneysStatusLoading: true,
        activeJourneysStatus: [],
      };
    case EJourneyActionTypes.ACTIVE_JOURNEY_STATUS_SUCCESS:
      return {
        ...state,
        activeJourneysStatus: action.payload.journeys.data,
        activeJourneysStatusLoading: false,
      };
    case EJourneyActionTypes.ACTIVE_JOURNEY_STATUS_ERROR:
      return {
        ...state,
        error: action.payload.message,
        activeJourneysStatusLoading: false,
      };

    default:
      return state;
  }
}
