import React, { useState } from 'react';
import styled from 'styled-components';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { SearchIcon } from '../index';

interface Props {
  placeholder?: string;
  type?: string;
  Icon?: any;
  password?: boolean;
  width?: string;
  height?: string;
  borderCololor?: string;
  backgroundColor?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => {} | void | null;
  onKeyPress?: Function;
}

const Container = styled.div`
  display: inline-block;
  margin: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  font-size: 20px;
  position: absolute;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const IconRightContainer = styled.div<{ clickable?: boolean }>`
  width: 20px;
  height: 20px;
  font-size: 20px;
  position: absolute;
  right: 0;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  ${props =>
    props.clickable &&
    `
  & > svg {
    cursor: pointer;
  }`}
`;

const Input = styled.input<{
  icon?: boolean;
  typeText: boolean;
  borderCololor?: string;
  backgroundColor?: string;
}>`
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  flex: 1;
  height: 100%;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#f2f2f2'};
  border-radius: 10px 20px 15px;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #000;
  padding-right: 20px;
  padding-left: ${props => (props.icon ? `40px` : `20px`)};
  padding-right: ${props => (props.typeText ? `20px` : `40px`)};
  outline: none;
  border: 2px solid
    ${props => (props.borderCololor ? props.borderCololor : `#f2f2f2`)};
  ::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
  &:focus {
    border: 2px solid #a7bf2e;
    ::placeholder {
      color: #000;
    }
  }
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  @media (max-width: 1400px) {
    font-size: 13px;
  }
`;

const CustomInput = ({
  type = 'text',
  placeholder,
  Icon,
  width,
  height,
  borderCololor,
  backgroundColor,
  onChange,
  onKeyPress,
  ...rest
}: Props) => {
  const inputProps = {
    style: {
      width: width ? `${width}` : '250px',
      height: height ? `${height}` : '40px',
    },
  };

  const password = type === 'password';
  const search = type === 'search';
  const [customType, setCustomType] = useState(type);

  const handleToggleType = () => {
    const newType = customType === 'password' ? 'text' : 'password';
    setCustomType(newType);
  };

  return (
    <Container {...inputProps}>
      <InputContainer>
        <Input
          icon={Icon ? true : false}
          typeText={!password && !search}
          type={customType}
          placeholder={placeholder}
          borderCololor={borderCololor}
          backgroundColor={backgroundColor}
          onChange={e => onChange && onChange(e.target.value)}
          onKeyPress={e => onKeyPress && onKeyPress(e)}
          {...rest}
        />
        {Icon && (
          <IconContainer>
            <Icon />
          </IconContainer>
        )}
        {password && (
          <IconRightContainer clickable={true}>
            {customType === 'password' ? (
              <MdVisibility onClick={handleToggleType} />
            ) : (
              <MdVisibilityOff onClick={handleToggleType} />
            )}
          </IconRightContainer>
        )}
        {search && (
          <IconRightContainer>
            <SearchIcon />
          </IconRightContainer>
        )}
      </InputContainer>
    </Container>
  );
};

export default CustomInput;
