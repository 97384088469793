import { unAuthAxiosCall, getAuthToken } from '../axiosCall';
import { getOrganization } from '../../../Lib/Utils/auth';
import { getUUIDForFile } from '../../../Lib/Utils/functions';

export const uploadMedia = async (
  files: Array<{ file: File; path: string }>
): Promise<Array<string>> => {
  let fileUrls = [] as Array<string>;

  const { access_token: token } = await getAuthToken();
  if (token) {
    await Promise.all(
      files.map(async ({ file, path }) => {
        const organization = getOrganization();
        const fileName = `${getUUIDForFile(organization?._id)}/${path}`;

        let response = await unAuthAxiosCall(
          `/api/s3/signed-url/file/${fileName}`,
          {
            method: 'GET',
            source: 'presigned',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            excludeOrganization: true,
          }
        );
        const presignedUrl = response?.data;

        console.log('Obtained presigned URL', presignedUrl);

        if (presignedUrl) {
          response = await unAuthAxiosCall(presignedUrl, {
            method: 'PUT',
            source: 'custom',
            headers: {
              'Content-Type': file.type,
            },
            body: file,
            excludeOrganization: true,
          });

          if (response && response.status === 200) {
            const fileUrl = presignedUrl.substring(
              0,
              presignedUrl.indexOf('?')
            );

            console.log('Final URL', fileUrl);
            fileUrls.push(fileUrl);
          }
        }
      })
    );
  }
  return fileUrls;
};
