import styled from 'styled-components';

const CampaignActionButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const DetailsContainer = styled.div`
  margin: 0 32px;
  padding-bottom: 350px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #757575;
`;

// DETAILS
const DetailsFormContainer = styled.div`
  > div {
    margin-top: 33px;
  }
  label {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    > div {
      margin-left: 10px;
    }
  }
  li label {
    margin-bottom: 0;
  }
  .light-label {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #303030;
  }
  .checkbox-wrapper {
    margin-left: -11px;
    margin-top: -8px;
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);
    > label {
      margin-bottom: 5px;
    }
  }
`;


const CampaignTargetingContainer = styled.div``;

const CampaignTargetingSubContainer = styled.div`
  padding: 10px 30px;
`;

const CampaignTargetingFormContainer = styled.div`
  padding: 0;
  margin: 0 0 30px 0;

  ul {
    list-style: none;
    padding: 0;
  }

  > div {
    margin-top: 33px;
  }

  label {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    > div {
      margin-left: 6px;
    }
  }

  li label {
    margin-bottom: 0;
  }
  .checkbox-wrapper {
    margin-left: -11px;
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);

    > label {
      margin-bottom: 5px;
    }
  }
  .disabledText {
    color: rgba(117, 117, 117, 0.5) !important;
  }
`;

const NumberBadge = styled.div`
  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #d8f40c;
  background: #303030;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 5px 20px;
  height: 40px;
  min-width: 96px;
`;

const CampaignReviewSubmitContainer = styled.div`
  padding: 40px 50px;
  ul {
    padding: 0;
  }
`;

const CampaignReviewSubmitItemsContainer = styled.div`
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  div:first-child:nth-last-child(3),
  div:first-child:nth-last-child(3) ~ div:first-child {
    flex: 2;
  }

  > div:first-child:nth-last-child(2):first-child {
    flex: 0.8;
  }

  div.empty {
    display: flex;
    flex: 1;
  }

  ul {
    padding: 0;
  }
`;

const CampaignReviewSubmitItemsWrapper = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(117, 117, 117, 0.25);

  :last-child {
    border-bottom: none;
  }
`;

const CampaignReviewSubmitNumberBadge = styled.div`
  background: #303030;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 5px 20px;
  font-family: Encode Sans;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #d8f40c;
  padding: 10px 20px;
  width: 100px;
  height: 40px;
`;

const TargetingProductContainer = styled.div`
  .list-wrapper ul {
    padding: 12px;
  }
  .product-filter {
    display: flex;
    align-items: center;
  }
  .filter-select {
    width: 100%;
  }
`;

const ProductFilterButton = styled.div`
  cursor: pointer;
  margin-right: 16px;
`;

// ADVERT
const AdvertContainer = styled.div`
  margin: 0 32px;
  padding-bottom: 350px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #757575;

  .listColumnHeaders {
    padding: 0 45px 0 10px;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const AdvertFormContainer = styled.div`
  > div {
    margin-top: 33px;
  }
  label {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    > div {
      margin-left: 10px;
    }
  }
  li label {
    margin-bottom: 0;
  }
  .light-label {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #303030;
  }
  .checkbox-wrapper {
    margin-left: -11px;
    margin-top: -8px;
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);
    > label {
      margin-bottom: 5px;
    }
  }

  ul {
    padding: 0;
  }

  .custom-link {
    margin-left: 0;
  }
`;

const FormSubHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 32px;
  margin: 0 -32px;
  background: #303030;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  label {
    color: #ffffff;
    > span {
      padding: 0 10px 0 0;
    }
  }
`;

export {
  CampaignActionButtonsContainer,
  DetailsFormContainer,
  DetailsContainer,
  NumberBadge,
  CampaignTargetingContainer,
  CampaignTargetingSubContainer,
  CampaignTargetingFormContainer,
  CampaignReviewSubmitContainer,
  CampaignReviewSubmitItemsContainer,
  CampaignReviewSubmitItemsWrapper,
  CampaignReviewSubmitNumberBadge,
  ProductFilterButton,
  TargetingProductContainer,
  AdvertFormContainer,
  AdvertContainer,
  FormSubHeader,
};
