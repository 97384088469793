import i18n from 'i18next';

const shortName = {
  required: { value: true, message: i18n.t('This field is required') },
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
};

const claimType = {
  required: { value: true, message: i18n.t('This field is required') },
};

const salutation = {
  required: { value: true, message: i18n.t('Required') },
};

const bullets = {
  required: { value: true, message: i18n.t('This field is required') },
  maxLength: { value: 300, message: i18n.t('Max 300 characters') },
};

const attestor = {
  required: { value: true, message: i18n.t('This field is required') },
};

const attestorText = {
  required: { value: true, message: i18n.t('This field is required') },
  maxLength: { value: 300, message: i18n.t('Max 300 characters') },
};

const frequency = {
  required: { value: true, message: i18n.t('This field is required') },
  min: { value: 1, message: i18n.t('Must be greater than 0') },
};

const startDate = (isAttestorNoEndDate, attestorPeriodEndDate) => {
  return {
    validate: {
      dateRequired: value => {
        return value === undefined || value === '' || value === null
          ? i18n.t('Required field')
          : undefined;
      },
      smallerThanEndDate: value => {
        const formatedEndDate =
          attestorPeriodEndDate && new Date(attestorPeriodEndDate);

        return !isAttestorNoEndDate &&
          value &&
          formatedEndDate &&
          formatedEndDate < value
          ? i18n.t('Must be smaller than end date')
          : undefined;
      },
    },
  };
};

const endDate = (isAttestorNoEndDate, attestorPeriodStartDate) => {
  return {
    validate: {
      dateRequired: value => {
        return !isAttestorNoEndDate
          ? value === undefined || value === '' || value === null
            ? i18n.t('Required field')
            : undefined
          : undefined;
      },
      greaterThanStartDate: value => {
        const formatedStartDate =
          attestorPeriodStartDate && new Date(attestorPeriodStartDate);

        return formatedStartDate && value && value < formatedStartDate
          ? i18n.t('Must be greater than start date')
          : undefined;
      },
    },
  };
};

const website = {
  maxLength: { value: 500, message: i18n.t('Max 500 characters') },
  pattern: {
    value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
    message: i18n.t('Enter a valid website'),
  },
};

const email = {
  required: { value: true, message: i18n.t('This field is required') },
  pattern: {
    value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
    message: i18n.t('Enter a valid email'),
  },
};

const phone = {
  required: { value: true, message: i18n.t('This field is required') },
  pattern: {
    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i,
    message: i18n.t('Enter a valid phone number'),
  },
};

const countryCode = {
  required: { value: true, message: i18n.t('This field is required') },
  maxLength: { value: 10, message: i18n.t('Max 10 characters') },
  pattern: {
    value: /^[+\d](?:\d*)?$/i,
    message: i18n.t('Only + and numbers allowed'),
  },
};

const phoneExt = {
  maxLength: { value: 10, message: i18n.t('Max 10 characters') },
};

const phoneType = {
  required: { value: true, message: i18n.t('Required') },
};

const facilityId = {
  required: { value: true, message: i18n.t('Required') },
};

export default {
  shortName,
  claimType,
  bullets,
  attestor,
  attestorText,
  frequency,
  startDate,
  endDate,
  salutation,
  website,
  email,
  phone,
  phoneType,
  phoneExt,
  countryCode,
  facilityId,
};
