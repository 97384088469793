import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #000;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default () => (
  <Container>
    <svg
      width="11"
      height="9"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0C3.27273 0 0.943636 1.59943 0 3.85714C0.943636 6.11486 3.27273 7.71428 6 7.71428C8.72727 7.71428 11.0564 6.11486 12 3.85714C11.0564 1.59943 8.72727 0 6 0ZM6 6.42857C4.49455 6.42857 3.27273 5.27657 3.27273 3.85714C3.27273 2.43771 4.49455 1.28571 6 1.28571C7.50545 1.28571 8.72727 2.43771 8.72727 3.85714C8.72727 5.27657 7.50545 6.42857 6 6.42857ZM6 2.31429C5.09455 2.31429 4.36364 3.00343 4.36364 3.85714C4.36364 4.71086 5.09455 5.4 6 5.4C6.90545 5.4 7.63636 4.71086 7.63636 3.85714C7.63636 3.00343 6.90545 2.31429 6 2.31429Z"
        fill="#D8F40C"
      />
    </svg>
  </Container>
);
