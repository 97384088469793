import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

import { Facility } from '../../../../Lib/Utils/Validations';
import {
  FacilityUsersContainer as Container,
  FacilityUsersFormContainer as FormContainer,
  FacilityUsersListContainer as ListContainer,
  FormSubHeader,
} from '../styles';

import {
  Label,
  FormInput,
  InfoIcon,
  CustomTooltip,
  Button,
  Dropdown,
  ItemsList,
  PopUp,
} from '../../../../Components';

import { User } from './IUser';

import {
  Salutations,
  PhoneTypes,
  FacilityPermissionTypes,
} from '../../../../Lib/Configs';

const FacilityUsers = ({
  onChange: onChangeParent,
  onChangeMultiple,
  users = [],
  ...data
}) => {
  const initUser = {
    facilityUserId: '',
    facilityUserName: '',
    facilityUserLastName: '',
    facilityUserEmail: '',
    facilityUserEmailConfirm: '',
    facilityUserPassword: '',
    facilityUserPermission: 'rw',
  };
  const {
    facilityUsersFirstLoad = true,
    facilityUsers = [],
    existingFacilityUser,
  } = data;

  const [items, setItems] = useState<Array<{ title: string; data: any }>>([]);
  const [currentUser, setCurrentUser] = useState<User>(initUser);
  const [currentUserIndex, setCurrentUserIndex] = useState(-1);
  const [popUpShow, setPopUpShow] = useState(false);
  const [popupNewItems, setPopupNewItems] = useState<any>([]);
  const [showPassPopUp, setShowPassPopUp] = useState(false);
  const [userOptions, setUserOptions] = useState(
    [] as Array<{ label: string; value: string }>
  );
  const [showFields, setShowFields] = useState(false);

  const {
    errors,
    control,
    register,
    setError,
    setValue,
    clearError,
    getValues,
    reset,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    facilityUsersFirstLoad === false && triggerValidation();
    onChangeParent('facilityUsersFirstLoad', false);
  }, []);

  useEffect(() => {
    register({ name: 'facilityUsers' });
  }, [register]);

  useEffect(() => {
    // if (JSON.stringify(facilityUsers) !== JSON.stringify(items)) {
    //   onChangeMultiple({ facilityUsers: items, existingFacilityUser: null });
    //   // setValue('facilityUsers', items);
    // }
    if (!items || !items.length) {
      setError(
        'facilityUsers',
        'required',
        i18n.t('At least one user is required')
      );
    } else {
      errors.facilityUsers && clearError('facilityUsers');
    }
  }, [items]);

  useEffect(() => {
    const options = [{ label: '', value: '' }].concat(
      users
        .filter(({ id }) => !items.find(i => i.data?.facilityUserId === id))
        .map(({ id, firstName, lastName }) => ({
          label: `${firstName || ''} ${lastName || ''}`,
          value: id,
        }))
    );
    setUserOptions(options);
  }, [users, items]);

  useEffect(() => {
    if (existingFacilityUser) {
      const user =
        users.find(({ id }) => id === existingFacilityUser) || ({} as any);
      const mapped = {
        facilityUserId: user.id,
        facilityUserName: user.firstName,
        facilityUserLastName: user.lastName,
        facilityUserEmail: user.email,
        facilityUserEmailConfirm: user.email,
        facilityUserPermission: 'rw',
      };
      setCurrentUser(mapped);
    } else {
      setCurrentUser(initUser);
    }
  }, [existingFacilityUser]);

  useEffect(() => {
    setValue([
      { facilityUserName: currentUser.facilityUserName },
      { facilityUserLastName: currentUser.facilityUserLastName },
      { facilityUserEmail: currentUser.facilityUserEmail },
      { facilityUserEmailConfirm: currentUser.facilityUserEmailConfirm },
      { facilityUsers },
    ]);
  }, [
    currentUser.facilityUserName,
    currentUser.facilityUserLastName,
    currentUser.facilityUserEmail,
    currentUser.facilityUserEmailConfirm,
    facilityUsers,
  ]);

  useEffect(() => {
    if (JSON.stringify(facilityUsers) !== JSON.stringify(items)) {
      setItems(facilityUsers);
    }
  }, [facilityUsers]);

  const onChange = (key: string, value: any) => {
    setCurrentUser({ ...currentUser, [key]: value });
  };

  const currentItem = currentUser && {
    title: `${currentUser.facilityUserName ||
      ''} ${currentUser.facilityUserLastName || ''}`,
    data: currentUser,
  };

  const resetForm = event => {
    event.preventDefault();
    setCurrentUser(initUser);
    setCurrentUserIndex(-1);
    setShowFields(false);
    onChangeParent('existingFacilityUser', null);
    // Reset form validations
    Object.keys(initUser).forEach(k => setValue(k, initUser[k]));
  };

  const addNewItem = event => {
    event.preventDefault();
    triggerValidation().then(isValid => {
      if (isValid) {
        let newItems = [] as any;
        if (currentUserIndex === -1) {
          newItems = [...items, currentItem];
          setItems(newItems);
        } else {
          newItems = [...items];
          newItems.splice(currentUserIndex, 1, currentItem);
          setItems(newItems);
        }
        setCurrentUser(initUser);
        setCurrentUserIndex(-1);
        // Reset form validations
        Object.keys(initUser).forEach(k => setValue(k, initUser[k]));

        onChangeMultiple({
          facilityUsers: newItems,
          existingFacilityUser: null,
        });
      }
    });
  };

  const handleDeleteAction = index => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setPopUpShow(true);
    setPopupNewItems(newItems);
  };

  const popUpConfirmAction = () => {
    if (popupNewItems) {
      setItems(popupNewItems);
      onChangeMultiple({
        facilityUsers: popupNewItems,
        existingFacilityUser: null,
      });
    }
    setPopUpShow(false);
  };

  const handleActiveAction = (item, index) => {
    setCurrentUser(item);
    setCurrentUserIndex(index);
    setShowFields(true);
    // Set form validation values
    Object.keys(item).forEach(k => setValue(k, item[k]));
  };

  const handleAddNew = () => {
    setShowFields(true);
    setCurrentUser(initUser);
    setCurrentUserIndex(-1);
    onChangeParent('existingFacilityUser', null);
  };

  const selectedUser = items[currentUserIndex] ? true : false;

  return (
    <Container>
      <FormSubHeader>
        <div className="mr-2">
          {i18n.t('Add field app users who will enter supply chain data')}
        </div>
        <div data-tip data-for="addusers">
          <InfoIcon />
        </div>
        <CustomTooltip id="addusers" placement="right">
          <span>
            {i18n.t(
              'This information is internal and will not be seen by end consumers'
            )}
          </span>
        </CustomTooltip>
      </FormSubHeader>
      <div className="pageContainer">
        <FormContainer id="UserForm">
          <div className="row">
            <div className="col-6">
              <Label>{i18n.t('Select an existing user')}</Label>
              <Dropdown
                options={userOptions}
                defaultOption={existingFacilityUser}
                placeholder={i18n.t('Select')}
                action={value => {
                  onChangeParent('existingFacilityUser', value || null);
                  setCurrentUserIndex(-1);
                }}
              />
            </div>
            <div className="col-4 action-btn">
              <Button
                text={`+ ${i18n.t('New User')}`}
                type="dark"
                action={evt => {
                  evt.preventDefault();
                  handleAddNew();
                }}
              />
            </div>
          </div>

          {(showFields || existingFacilityUser) && (
            <>
              <div className="row">
                {/* <div className="col-2">
                  <Label>{i18n.t('Salutation')}</Label>
                  <Controller
                    as={
                      <Dropdown
                        required
                        defaultOption={currentUser.facilityUserSalutation}
                        options={Salutations}
                        placeholder={i18n.t('Select')}
                        action={value => onChange('facilityUserSalutation', value)}
                      />
                    }
                    name="facilityUserSalutation"
                    value={currentUser.facilityUserSalutation}
                    control={control}
                    onChange={([newValue]) => {
                      onChange('facilityUserSalutation', newValue);
                      return newValue;
                    }}
                    rules={Facility.salutation}
                    defaultValue={currentUser.facilityUserSalutation}
                  />
                  {errors.facilityUserSalutation && (
                    <span className="inlineErrorMessage">
                      {errors.facilityUserSalutation.message}.
                    </span>
                  )}
                </div> */}

                <div className="col-5">
                  <Label>{i18n.t('First name')}</Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        disabled={!!currentUser.facilityUserId}
                        placeholder={i18n.t('Enter the first name')}
                        maxLength={50}
                      />
                    }
                    name="facilityUserName"
                    value={currentUser.facilityUserName}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Facility.name}
                    defaultValue={currentUser.facilityUserName}
                  />
                  {errors.facilityUserName && (
                    <span className="inlineErrorMessage">
                      {errors.facilityUserName.message}.
                    </span>
                  )}
                </div>

                <div className="col-5">
                  <Label>{i18n.t('Last name')}</Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        disabled={!!currentUser.facilityUserId}
                        placeholder={i18n.t('Enter the last name')}
                        maxLength={50}
                      />
                    }
                    name="facilityUserLastName"
                    value={currentUser.facilityUserLastName}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Facility.name}
                    defaultValue={currentUser.facilityUserLastName}
                  />
                  {errors.facilityUserLastName && (
                    <span className="inlineErrorMessage">
                      {errors.facilityUserLastName.message}.
                    </span>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <Label>
                    {i18n.t('Email')}
                    <div data-tip data-for="email">
                      <InfoIcon />
                    </div>
                    <CustomTooltip id="email" placement="right">
                      <span>
                        {i18n.t(
                          'This email will also act as the user login to the SUKU Field App.'
                        )}
                      </span>
                    </CustomTooltip>
                  </Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        disabled={!!currentUser.facilityUserId}
                        value={currentUser.facilityUserEmail}
                        placeholder={i18n.t('Enter email address')}
                        maxLength={75}
                      />
                    }
                    name="facilityUserEmail"
                    value={currentUser.facilityUserEmail}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Facility.email}
                    defaultValue={currentUser.facilityUserEmail}
                  />
                  {errors.facilityUserEmail && (
                    <span className="inlineErrorMessage">
                      {errors.facilityUserEmail.message}.
                    </span>
                  )}
                </div>

                <div className="col-6">
                  <Label>{i18n.t('Confirm email')}</Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        disabled={!!currentUser.facilityUserId}
                        value={
                          !!currentUser.facilityUserId
                            ? currentUser.facilityUserEmail
                            : currentUser.facilityUserEmailConfirm
                        }
                        placeholder={i18n.t('Confirm email address')}
                        maxLength={75}
                      />
                    }
                    name="facilityUserEmailConfirm"
                    value={currentUser.facilityUserEmailConfirm}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={{
                      required: {
                        value: true,
                        message: i18n.t('This field is required'),
                      },
                      validate: {
                        matchesEmail: value => {
                          const { facilityUserEmail } = getValues();
                          return facilityUserEmail !== value
                            ? i18n.t('Emails do not match').toString()
                            : undefined;
                        },
                      },
                    }}
                    defaultValue={
                      !!currentUser.facilityUserId
                        ? currentUser.facilityUserEmail
                        : currentUser.facilityUserEmailConfirm
                    }
                  />
                  {errors.facilityUserEmailConfirm && (
                    <span className="inlineErrorMessage">
                      {errors.facilityUserEmailConfirm.message}.
                    </span>
                  )}
                </div>
              </div>

              {!existingFacilityUser && !currentUser.facilityUserId && (
                <div className="row">
                  <div className="col-9">
                    <Label>
                      {i18n.t('Default password')}
                      <div data-tip data-for="defaultpassword">
                        <InfoIcon />
                      </div>
                      <CustomTooltip id="defaultpassword" placement="right">
                        <span>
                          {i18n.t(
                            'User will be asked to change the password when they log in for the first time into the SUKU Field App.'
                          )}
                        </span>
                      </CustomTooltip>
                    </Label>
                    <Controller
                      as={
                        <FormInput
                          required
                          type="password"
                          value={currentUser.facilityUserPassword}
                          placeholder={i18n.t('Enter password')}
                          maxLength={50}
                        />
                      }
                      name="facilityUserPassword"
                      value={currentUser.facilityUserPassword}
                      control={control}
                      onChange={([evt]) => {
                        onChange(evt.target.name, evt.target.value);
                        return evt.target.value;
                      }}
                      rules={Facility.password}
                      defaultValue={currentUser.facilityUserPassword}
                    />
                    {errors.facilityUserPassword && (
                      <span className="inlineErrorMessage">
                        {errors.facilityUserPassword.message}.
                      </span>
                    )}
                  </div>
                  {/* <div className="col-3 reset-link">
                  <div onClick={() => setShowPassPopUp(true)}>
                    {i18n.t('Reset password')}
                  </div>
                </div> */}
                </div>
              )}

              {/* <div className="row">
                <div className="col-2">
                  <Label>{i18n.t('Country code')}</Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        value={currentUser.facilityUserCountryCode}
                        placeholder={i18n.t('Enter code')}
                      />
                    }
                    name="facilityUserCountryCode"
                    value={currentUser.facilityUserCountryCode}
                    control={control}
                    onChange={([evt]) => {
                      const regex = Facility.countryCode.pattern.value;
                      const value = evt.target.value;

                      if (!value || regex.test(value)) {
                        onChange(evt.target.name, evt.target.value);
                        return evt.target.value;
                      } else {
                        onChange(
                          evt.target.name,
                          currentUser.facilityUserCountryCode
                        );
                        return currentUser.facilityUserCountryCode;
                      }
                    }}
                    rules={Facility.countryCode}
                    defaultValue={currentUser.facilityUserCountryCode}
                  />
                  {errors.facilityUserCountryCode && (
                    <span className="inlineErrorMessage">
                      {errors.facilityUserCountryCode.message}.
                    </span>
                  )}
                </div>
                <div className="col-6">
                  <Label>{i18n.t('Phone number')}</Label>
                  <Controller
                    as={
                      <FormInput
                        required
                        subType="phone"
                        value={currentUser.facilityUserPhoneNumber}
                        placeholder={i18n.t('Enter phone number')}
                      />
                    }
                    name="facilityUserPhoneNumber"
                    value={currentUser.facilityUserPhoneNumber}
                    control={control}
                    onChange={([evt]) => {
                      const regex = Facility.phone.pattern.value;
                      const value = evt.target.value;

                      if (!value || regex.test(value)) {
                        onChange(evt.target.name, evt.target.value);
                        return evt.target.value;
                      } else {
                        onChange(
                          evt.target.name,
                          currentUser.facilityUserPhoneNumber
                        );
                        return currentUser.facilityUserPhoneNumber;
                      }
                    }}
                    rules={Facility.phone}
                    defaultValue={currentUser.facilityUserPhoneNumber}
                  />
                  {errors.facilityUserPhoneNumber && (
                    <span className="inlineErrorMessage">
                      {errors.facilityUserPhoneNumber.message}.
                    </span>
                  )}
                </div>
                <div className="col-2">
                  <Label>{i18n.t('Ext.')}</Label>
                  <Controller
                    as={
                      <FormInput
                        subType="number"
                        value={currentUser.facilityUserPhoneNumberExt}
                        placeholder={i18n.t('Enter ext.')}
                      />
                    }
                    name="facilityUserPhoneNumberExt"
                    value={currentUser.facilityUserPhoneNumberExt}
                    control={control}
                    onChange={([evt]) => {
                      onChange(evt.target.name, evt.target.value);
                      return evt.target.value;
                    }}
                    rules={Facility.phoneExt}
                    defaultValue={currentUser.facilityUserPhoneNumberExt}
                  />
                  {errors.facilityUserPhoneNumberExt && (
                    <span className="inlineErrorMessage">
                      {errors.facilityUserPhoneNumberExt.message}.
                    </span>
                  )}
                </div>
                <div className="col-2">
                  <Label>{i18n.t('Phone type')}</Label>
                  <Controller
                    as={
                      <Dropdown
                        required
                        options={PhoneTypes}
                        defaultOption={currentUser.facilityUserPhoneType}
                        placeholder={i18n.t('Select')}
                        action={value => onChange('facilityUserPhoneType', value)}
                      />
                    }
                    name="facilityUserPhoneType"
                    value={currentUser.facilityUserPhoneType}
                    control={control}
                    onChange={([newValue]) => {
                      onChange('facilityUserPhoneType', newValue);
                      return newValue;
                    }}
                    rules={Facility.phoneType}
                    defaultValue={currentUser.facilityUserPhoneType}
                  />
                  {errors.facilityUserPhoneType && (
                    <span className="inlineErrorMessage">
                      {errors.facilityUserPhoneType.message}.
                    </span>
                  )}
                </div>
              </div> */}

              <div className="row">
                <div className="col-lg-7 col-sm-12">
                  <Label>
                    {i18n.t('What permissions does this user have?')}
                    <div data-tip data-for="permissions">
                      <InfoIcon />
                    </div>
                    <CustomTooltip id="permissions" placement="right">
                      <span>
                        {i18n.t(
                          'Give permissions to users to either view or add/edit supply chain data via the SUKU Field App.'
                        )}
                      </span>
                    </CustomTooltip>
                  </Label>
                  <Controller
                    as={
                      <Dropdown
                        required
                        options={FacilityPermissionTypes}
                        defaultOption={currentUser.facilityUserPermission}
                        placeholder={i18n.t('Select')}
                        action={value =>
                          onChange('facilityUserPermission', value)
                        }
                      />
                    }
                    name="facilityUserPermission"
                    value={currentUser.facilityUserPermission}
                    control={control}
                    onChange={([newValue]) => {
                      onChange('facilityUserPermission', newValue);
                      return newValue;
                    }}
                    rules={Facility.phoneType}
                    defaultValue={currentUser.facilityUserPermission}
                  />
                  {errors.facilityUserPermission && (
                    <span className="inlineErrorMessage">
                      {errors.facilityUserPermission.message}.
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <Button text={i18n.t('Clear')} action={resetForm} />
                  <Button
                    text={selectedUser ? i18n.t('Save') : i18n.t('Add')}
                    type="dark"
                    action={addNewItem}
                  />
                </div>
              </div>
            </>
          )}
        </FormContainer>
        <ListContainer>
          <div className="row">
            <div className="col-12">
              <Label>
                {i18n.t('User list')}
                <div data-tip data-for="userlist">
                  <InfoIcon />
                </div>
                <CustomTooltip id="userlist" placement="right">
                  <span>
                    {i18n.t(
                      'These are all the users who has access to the SUKU Field App.'
                    )}
                  </span>
                </CustomTooltip>
              </Label>
              <ItemsList
                items={items}
                defaultActiveIndex={currentUserIndex}
                deleteAction={handleDeleteAction}
                activeAction={handleActiveAction}
              />
            </div>
            {errors.facilityUsers && (
              <span className="inlineErrorMessage mt-4 ml-3">
                {errors.facilityUsers.message}.
              </span>
            )}
          </div>
        </ListContainer>

        <PopUp
          title={i18n.t('Remove user from facility?')}
          content={i18n.t(
            'Removing the user from the facility will not allow him/her to capture any data for this facility.'
          )}
          cancelText={i18n.t('Cancel')}
          confirmText={i18n.t('Yes')}
          confirmAction={popUpConfirmAction}
          cancelAction={() => setPopUpShow(false)}
          show={popUpShow}
        />

        <PopUp
          title={i18n.t('Reset password to default?')}
          content={i18n.t(
            'Are you sure you want to reset the user password to the default password?'
          )}
          cancelText={i18n.t('Cancel')}
          confirmText={i18n.t('Yes')}
          confirmAction={() => setShowPassPopUp(false)}
          cancelAction={() => setShowPassPopUp(false)}
          show={showPassPopUp}
        />
      </div>
    </Container>
  );
};

export default FacilityUsers;
