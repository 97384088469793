import React, { Dispatch } from 'react';
import styled from 'styled-components';
import LangSelector from '../LangSelector';
import i18n from 'i18next';
import { availableLanguages } from '../../Rsc/lang';
import { FaUserAlt } from 'react-icons/fa';
import { BsFillGearFill } from 'react-icons/bs';
import { Button } from '..';
import Settings from './Settings';
import { withRouter } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { doLogout } from '../../Redux/User/userActions';

interface IUser {
  given_name?: string;
  family_name?: string;
  avatar?: string;
  badge?: number;
  notificationsEnabled: boolean;
}

interface IProps {
  currentUser: IUser;
  history?: any;
}

const Container = styled.div`
  height: 60px;
  background: radial-gradient(
    49.96% 19883.5% at 49.96% 100%,
    #3a3a3a 0%,
    #000000 100%
  );
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px;
`;

const Title = styled.div`
  color: #ffffff;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

const AvatarContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px 0 10px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 1px solid #ffffff;
`;

const Badge = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0%;
  background: #d8f40c;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 100%;
  top: -5px;
  right: 5px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
`;

const Avatar = styled.img`
  background: #000;
  margin: 0 15px 0 10px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  cursor: pointer;
`;

const mapDispatch = (dispatch: Dispatch<any>) => ({
  logoutAction: () => dispatch(doLogout()),
});

const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & IProps & {};

const TopBar = ({ currentUser, history, logoutAction }: Props) => {
  const handleLogout = () => {
    logoutAction();
    history.push('/login');
  };

  return (
    <Container>
      <LangSelector
        options={availableLanguages}
        defaultOption={i18n.language}
        action={newValue => i18n.changeLanguage(newValue)}
      />
      <Title className="ml-3">{`${
        currentUser.given_name
      } ${currentUser.family_name || ''}`}</Title>
      <AvatarContainer>
        {/* <Avatar src={currentUser.avatar || } /> */}
        <FaUserAlt color="white" size={20} />
        {currentUser.notificationsEnabled && <Badge>{currentUser.badge}</Badge>}
      </AvatarContainer>
      <Settings
        options={[
          {
            label: 'Sign Out',
            value: 'signout',
            action: handleLogout,
          },
        ]}
      />
    </Container>
  );
};

export default withRouter(connector(TopBar) as any) as any;
