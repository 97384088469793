import React from 'react';

import {
  Container,
  Head,
  Body,
  TitleContainer,
  ValueContainer,
  IconContainer,
  NumberIcon,
} from './styles';

interface Props {
  title: string;
  value?: any;
  icon?: any;
  content?: any;
  numberOption?: boolean;
  onClick?: any;
  fullHeight?: boolean;
}

const DataSections = ({
  title,
  value,
  icon,
  content,
  numberOption = true,
  onClick = null,
  fullHeight = false,
}: Props) => {
  return (
    <Container className={fullHeight ? 'h-100' : ''}>
      <Head onClick={() => onClick && onClick()} clickable={!!onClick}>
        <IconContainer>{icon}</IconContainer>
        <TitleContainer>
          {numberOption && <NumberIcon># </NumberIcon>}
          {title}
        </TitleContainer>
        <ValueContainer>{value}</ValueContainer>
      </Head>
      {content && <Body>{content}</Body>}
    </Container>
  );
};

export default DataSections;
