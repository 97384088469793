import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

type PlaceOptions =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'top-start'
  | 'top-end'
  | 'left-start'
  | 'left-end'
  | 'right-start'
  | 'right-end'
  | 'bottom-start'
  | 'bottom-end';

interface Props {
  text: any;
  children: any;
  placement?: PlaceOptions;
  secondary?: boolean;
}

type RPlaceOptions = 'top' | 'right' | 'bottom' | 'left';

interface RProps {
  children: any;
  placement?: RPlaceOptions;
  id?: string;
}

const SecondaryTooltip = withStyles(theme => ({
  tooltip: {
    borderRadius: 0,
    backgroundColor: '#A7BF2E',
    color: '#000',
    maxWidth: 350,
    fontFamily: 'Encode Sans, sans-serif',
    padding: '15px',
    fontSize: '11px',
    lineHeight: '14px',
  },
  arrow: {
    color: '#A7BF2E',
    left: '3% !important',
    position: 'absolute',
  },
}))(Tooltip);

const PrimaryTooltip = withStyles(theme => ({
  tooltip: {
    borderRadius: 0,
    backgroundColor: '#000',
    color: '#fff',
    maxWidth: 220,
    fontFamily: 'Encode Sans, sans-serif',
    padding: '15px',
    fontSize: '11px',
    lineHeight: '14px',
  },
  arrow: {
    color: '#000',
  },
}))(Tooltip);

const CustomTooltip = ({
  children,
  text,
  placement = 'right',
  secondary = false,
}: Props) => {
  return secondary ? (
    <SecondaryTooltip title={text} placement={placement} arrow>
      {children}
    </SecondaryTooltip>
  ) : (
    <PrimaryTooltip title={text} placement={placement} arrow>
      {children}
    </PrimaryTooltip>
  );
};

const Container = styled.div`
  .custom-tooltip {
    opacity: 1 !important;
    background-color: #000000;
    font-family: Encode Sans;
    font-size: 11px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-weight: normal;
    border-radius: 0;
    max-width: 300px;
    text-transform: none;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.3);
    &.place-right {
      &:after {
        border-right-color: black;
      }
    }
  }
`;

const RTooltip = ({ id, placement = 'right', children }: RProps) => (
  <Container>
    <ReactTooltip
      id={id}
      place={placement}
      effect="solid"
      className="custom-tooltip"
      delayHide={0}
    >
      {children}
    </ReactTooltip>
  </Container>
);

export default CustomTooltip;
export { RTooltip as CustomTooltip };
