import React from 'react';

export default () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 16H6V18H4V16ZM10 0L0 5V18H2V10H18V18H20V5L10 0ZM6 8H2V6H6V8ZM12 8H8V6H12V8ZM18 8H14V6H18V8ZM4 12H6V14H4V12ZM8 12H10V14H8V12ZM8 16H10V18H8V16ZM12 16H14V18H12V16Z"
    />
  </svg>
);
