import React from 'react';

export default () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.81875L8.18125 0L5 3.18125L1.81875 0L0 1.81875L3.18125 5L0 8.18125L1.81875 10L5 6.81875L8.18125 10L10 8.18125L6.81875 5L10 1.81875Z"
      fill="white"
    />
  </svg>
);
