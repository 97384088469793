import styled from 'styled-components';

const Container = styled.div`
  min-height: 100%;
  height: 400px;
  .gm-style-iw-t::after {
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) 51%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .gm-style-iw-c {
    background-color: #000 !important;
    border-radius: 0;
    color: #fff;
    padding: 13px !important;
  }
  .gm-style-iw-d {
    background-color: #000 !important;
    overflow: hidden !important;
  }

  button img {
    /* filter: invert(1); */
    display: none;
  }
`;

const InfoWindowStyled = styled.div<{ clickable?: boolean }>`
  background-color: #000;
  ${clickable => clickable && `cursor: pointer;`}
`;

export { InfoWindowStyled, Container };
