const moment = require('moment');
const storage = localStorage;

const tokenKey = 'suku_auth';
const userKey = 'suku_curr';

export const setToken = ({
  access_token,
  refresh_token,
  expires_in,
  refresh_expires_in,
}) => {
  const expires_at = moment().add(expires_in, 'seconds');
  const refresh_expires_at = moment().add(refresh_expires_in, 'seconds');

  storage.setItem(
    tokenKey,
    JSON.stringify({
      access_token,
      refresh_token,
      expires_at,
      refresh_expires_at,
    })
  );
};

export const setCurrentUser = user => {
  storage.setItem(userKey, JSON.stringify(user));
};

export const getCurrentUser = user => {
  const encodedUser = storage.getItem(userKey);
  if (encodedUser) {
    try {
      const user = JSON.parse(encodedUser);
      return user;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const getOrganization = () => {
  const user = getCurrentUser();
  try {
    const group = user.groups[0].split('/')[2] || 'organization';
    // const organization = group.split('_');
    return {
      _id: group,
      // name: organization[1],
    };
  } catch (e) {
    console.log('ERR getting organization: ', e);
    return null;
  }
};

export const isOrgAdmin = () => {
  const user = getCurrentUser();
  if (user && user.groups) {
    return !!user.groups.some(
      g => g.match('organizations') && !g.match('facilities')
    );
  } else {
    return false;
  }
};

export const getFacilities = () => {
  const user = getCurrentUser();
  try {
    return user.groups.map(
      group => group.split('facilities/')[1].split('_')[0]
    );
  } catch (e) {
    console.log('ERR getting facilities: ', e);
    return [];
  }
};

export const getToken = () => {
  const encodedStoredToken = storage.getItem(tokenKey);
  if (encodedStoredToken) {
    try {
      const token = JSON.parse(encodedStoredToken);
      return token;
    } catch (e) {
      return null;
    }
  }
  return null;
};

export function deleteToken() {
  storage.removeItem(tokenKey);
}

export function deleteCurrentUser() {
  storage.removeItem(userKey);
}
