import React, { useState } from 'react';
import i18n from 'i18next';
import moment from 'moment';

import OrderType from './OrderType';
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  PaginationContainer,
  PaginationList,
  PaginationArrow,
  TableHeadCell,
  OrderIconContainer,
  HeadTextContainer,
  FriendlyId,
} from './styles';
import { OrderIcon, PencilIcon, TrashIcon } from '../CustomIcons';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Button, PopUp } from '..';
import TableRow from './TableRow';
import { BsArchiveFill } from 'react-icons/bs';

type RowType = { [key: string]: any };

interface Props {
  headers: Array<{ text: string; order?: boolean; show?: boolean }>;
  rows: Array<RowType>;
  ignoreKeys?: Array<number>;
  pagination?: boolean;
  rowsPerPage?: number;
  pagesBtw?: number;
  editable?: boolean;
  handleEdit?: Function;
  handleArchive?: Function;
  deletable?: boolean;
  archivable?: boolean;
  handleDelete?: Function;
  deleteTitle?: string;
  deleteContent?: string;
  archiveContent?: string;
  archiveTitle?: string;
  showId?: boolean;
  clickable?: boolean;
  navDataKey?: any;
  friendlyId?: boolean;
}

const TableComponent = (props: Props) => {
  const {
    headers,
    rows,
    ignoreKeys = [],
    pagination,
    rowsPerPage = 50,
    pagesBtw = 5,
    editable,
    deletable,
    archivable,
    handleEdit,
    handleDelete,
    handleArchive,
    deleteTitle = '',
    deleteContent = '',
    archiveTitle = '',
    archiveContent = '',
    showId = true,
    clickable,
    navDataKey = 0,
    friendlyId = true,
  } = props;

  const [pageNumber, setPageNumber] = useState(1);
  const [orderBy, setOrderBy] = useState<{
    header: string | null;
    order: OrderType;
  }>({
    header: null,
    order: null,
  });
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showArchivePopup, setShowArchivePopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [showCompleteId, setShowCompleteId] = useState(false);

  const filteredHeaders = headers.filter(({ show = true }) => show);

  const sortedRows =
    rows && orderBy.header
      ? rows.sort((a, b) => {
          const filteredHeaderIndex = filteredHeaders.findIndex(
            ({ text }) => text === orderBy.header
          );

          const headerIndex = headers.findIndex(
            ({ text }) => text === orderBy.header
          );

          const countIgnoreKeysSmaller = ignoreKeys.filter(
            key => key <= headerIndex
          ).length;

          const idx = filteredHeaderIndex + countIgnoreKeysSmaller;

          if (moment(a[idx]).isValid() && moment(b[idx]).isValid()) {
            if (
              moment(a[idx], 'MM/DD/YYYY').isAfter(moment(b[idx], 'MM/DD/YYYY'))
            ) {
              return orderBy.order === 'up' ? -1 : 1;
            }
            if (
              moment(b[idx], 'MM/DD/YYYY').isAfter(moment(a[idx], 'MM/DD/YYYY'))
            ) {
              return orderBy.order === 'up' ? 1 : -1;
            }
            return 0;
          } else {
            if (a[idx] > b[idx]) {
              return orderBy.order === 'up' ? -1 : 1;
            }
            if (b[idx] > a[idx]) {
              return orderBy.order === 'up' ? 1 : -1;
            }
            return 0;
          }
        })
      : rows;

  const pages = () => {
    if (rows && rowsPerPage) {
      const pagesQty = Math.ceil(rows.length / rowsPerPage);
      return [...Array(pagesQty).keys()];
    } else {
      return [0];
    }
  };

  const paginate = pageNumber => {
    return sortedRows
      ? sortedRows.slice(
          (pageNumber - 1) * rowsPerPage,
          pageNumber * rowsPerPage
        )
      : [];
  };

  const handleChangePage = page => {
    setPageNumber(page);
  };

  const paginatedRow = pagination ? paginate(pageNumber) : sortedRows;

  const handleOrderClick = header => {
    const newOrder = () => {
      if (header === orderBy.header) {
        return orderBy.order === 'up' ? 'down' : 'up';
      } else {
        return 'down';
      }
    };
    setOrderBy({
      header,
      order: newOrder(),
    });
  };

  const handleArrowClick = add => {
    setPageNumber(pageNumber + add);
  };

  const pagesArray = pages();
  const lastPage = pagesArray[pagesArray.length - 1] + 1;
  const fromPage =
    Math.max(pageNumber - (pagesBtw + 2), 0) -
    Math.max(pagesBtw * 2 + (pageNumber - lastPage) - 1, 0);
  const toPage =
    pageNumber + pagesBtw - 1 + Math.max(pagesBtw - pageNumber + 2, 0);

  const handleDeleteAction = row => {
    setSelectedRow(row);
    setShowDeletePopup(true);
  };

  const handleArchiveAction = row => {
    setSelectedRow(row);
    setShowArchivePopup(true);
  };

  return (
    <Container>
      <TableContainer>
        <Table className="table">
          <TableHead>
            <TableRow>
              {filteredHeaders.map(({ text, order = true }, index) => (
                <th key={index} onClick={() => order && handleOrderClick(text)}>
                  <TableHeadCell>
                    {order && (
                      <OrderIconContainer
                        order={orderBy.header === text ? orderBy.order : null}
                      >
                        <OrderIcon />
                      </OrderIconContainer>
                    )}
                    <HeadTextContainer>{text}</HeadTextContainer>
                  </TableHeadCell>
                </th>
              ))}
              {editable && (
                <th>
                  <TableHeadCell>
                    <HeadTextContainer />
                  </TableHeadCell>
                </th>
              )}
              {deletable && (
                <th>
                  <TableHeadCell>
                    <HeadTextContainer />
                  </TableHeadCell>
                </th>
              )}
              {archivable && (
                <th>
                  <TableHeadCell>
                    <HeadTextContainer />
                  </TableHeadCell>
                </th>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              paginatedRow &&
              paginatedRow.map((row, rowKey) => (
                <TableRow
                  clickable={clickable}
                  nav={row[navDataKey]}
                  key={rowKey}
                >
                  {row.map((cell, cellKey) => {
                    const isId =
                      typeof cell === 'string' && cellKey === 0 && friendlyId;
                    const actionable = !!cell?.props?.action;
                    return (
                      !(cellKey === 0 && !showId) &&
                      !ignoreKeys.includes(cellKey) && (
                        <TableCell
                          key={`${rowKey}${cellKey}`}
                          isId={isId}
                          className={actionable ? 'action-cell' : ''}
                        >
                          {isId ? (
                            <FriendlyId
                              onClick={() => setShowCompleteId(!showCompleteId)}
                            >
                              <span>
                                {showCompleteId
                                  ? cell
                                  : `${cell.substr(0, 3)}...`}
                              </span>
                            </FriendlyId>
                          ) : (
                            cell
                          )}
                        </TableCell>
                      )
                    );
                  })}

                  {editable && (
                    <TableCell className="action-cell">
                      <div>
                        <Button
                          width={35}
                          icon={<PencilIcon />}
                          type="roundDark"
                          action={evt => {
                            evt.stopPropagation();
                            handleEdit && handleEdit(row);
                          }}
                        />
                      </div>
                    </TableCell>
                  )}
                  {deletable && (
                    <TableCell className="action-cell">
                      <Button
                        width={35}
                        icon={<TrashIcon color="white" />}
                        type="roundDark"
                        action={evt => {
                          evt.stopPropagation();
                          handleDeleteAction(row[0]);
                        }}
                      />
                    </TableCell>
                  )}
                  {archivable && (
                    <TableCell className="action-cell">
                      <Button
                        width={35}
                        icon={<BsArchiveFill color="white" />}
                        type="roundDark"
                        action={evt => {
                          evt.stopPropagation();
                          handleArchiveAction(row);
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {pagination && pagesArray.length > 1 && (
          <PaginationContainer>
            <PaginationArrow
              className={pageNumber > 1 ? 'active' : 'inactive'}
              onClick={() => pageNumber > 1 && handleArrowClick(-1)}
            >
              <MdKeyboardArrowLeft />
            </PaginationArrow>
            <PaginationList>
              <li
                key={0}
                onClick={() => handleChangePage(1)}
                className={pageNumber === 1 ? 'active' : ''}
              >
                {fromPage < 1 ? '1' : '1...'}
              </li>
              {pagesArray
                .slice(1, pages.length - 1)
                .slice(fromPage, toPage)
                .map(page => {
                  return (
                    <li
                      key={page}
                      onClick={() => handleChangePage(page + 1)}
                      className={pageNumber === page + 1 ? 'active' : ''}
                    >
                      {page + 1}
                    </li>
                  );
                })}
              <li
                key={pagesArray[pagesArray.length - 1]}
                onClick={() =>
                  handleChangePage(pagesArray[pagesArray.length - 1] + 1)
                }
                className={
                  pageNumber === pagesArray[pagesArray.length - 1] + 1
                    ? 'active'
                    : ''
                }
              >
                {toPage + 3 > lastPage
                  ? `${pagesArray[pagesArray.length - 1] + 1}`
                  : `...${pagesArray[pagesArray.length - 1] + 1}`}
              </li>
            </PaginationList>
            <PaginationArrow
              className={
                pageNumber <= pagesArray[pagesArray.length - 1]
                  ? 'active'
                  : 'inactive'
              }
              onClick={() =>
                pageNumber <= pagesArray[pagesArray.length - 1] &&
                handleArrowClick(1)
              }
            >
              <MdKeyboardArrowRight />
            </PaginationArrow>
          </PaginationContainer>
        )}
      </TableContainer>
      <PopUp
        cancelAction={() => setShowDeletePopup(false)}
        confirmAction={() => {
          setShowDeletePopup(false);
          handleDelete && handleDelete(selectedRow);
        }}
        show={showDeletePopup}
        cancelText={i18n.t('Cancel')}
        confirmText={i18n.t('Yes')}
        title={deleteTitle}
        content={deleteContent}
      />
      <PopUp
        cancelAction={() => setShowArchivePopup(false)}
        confirmAction={() => {
          setShowArchivePopup(false);
          handleArchive && handleArchive(selectedRow);
        }}
        show={showArchivePopup}
        cancelText={i18n.t('Cancel')}
        confirmText={i18n.t('Yes')}
        title={archiveTitle}
        content={archiveContent}
      />
    </Container>
  );
};

export default TableComponent;
