import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 20px 5px;
  display: flex;
  margin-bottom: 21px;
  min-height: 40px;
  display: flex;
  padding: 0 50px;
  border-left: 5px solid rgba(117, 117, 117, 0.25);
  flex-direction: column;
  &.active {
    border-left: 5px solid #757575;
  }
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
`;

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #303030;
  justify-content: space-between;
  position: relative;
`;
const ItemContent = styled.div`
  display: flex;
  max-height: 0px;
  opacity: 0;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
  .active > & {
    max-height: 10000px;
    opacity: 1;
    padding-bottom: 15px;
  }

  > div {
    flex: 1;
  }
`;

const ActionContainer = styled.div`
  position: absolute;
  right: -40px;
  &:focus {
    outline: none;
  }
  outline: none;
  border: 0;
  display: flex;
  background-color: #000;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`;

export { Container, ItemContainer, ItemTitle, ItemContent, ActionContainer };
