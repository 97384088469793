import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import { LightboxImage, LightboxVideo, LightboxContainer, LightboxClose } from './styles';

interface Props {
  onCloseCallback: Function;
  resource: any;
}

const Lightbox = ({ onCloseCallback, resource }: Props) => {
  const [loading, setLoading] = useState(true);

  const handleEsc = (event) => {
    if(event.keyCode === 27) {
      onCloseCallback();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEsc, false);

    return () => {
      document.removeEventListener('keydown', handleEsc, false);
    };
  }, []);

  const getResource = () => {
    let item = {} as any;
    if (resource.type === 'image') {
      item = (
        <LightboxImage
          src={resource.src}
          onLoad={() => setLoading(false)}
        />
      );
    }

    if (resource.type === 'video') {
      item = (
        <LightboxVideo
          src={resource.src}
          style={{ visibility: loading ? 'hidden' : 'visible' }}
          controls
          autoPlay
          onPlay={() => setLoading(false)}
        />
      );
    }
    return item;
  }

  return (
    <LightboxContainer>
      <LightboxClose
        onClick={() => onCloseCallback()}
      >
        <MdClose />
      </LightboxClose>

      {loading && (
        <CircularProgress
          style={{ position: 'absolute', color: '#FFFFFF', zIndex: 1000 }}
        />
      )}

      {getResource()}
    </LightboxContainer>
  );
}

export default Lightbox;
