export const generateNewRow = (
  rowNumber,
  valuesProp,
  template,
  id = null,
  subDocumentId = null,
  editLocked = false,
  tableFieldValue,
  setTableFieldValue
) => {
  if (template) {
    if (template.version && template.version[0]) {
      const newHeaders = Object.keys(template.version[0]).map(v => v);
      const newField = newHeaders.map((header, index) => {
        const {
          mandatory,
          type,
          values = null,
          placeholder = header,
          maxChars,
        } = template.version[0][header];
        let defaultValue = '';
        if (valuesProp !== null && valuesProp[header]) {
          defaultValue = valuesProp[header];
        } else if (tableFieldValue) {
          defaultValue =
            (tableFieldValue[rowNumber] &&
              tableFieldValue[rowNumber][header]) ||
            '';
        }
        return {
          // disabled: valuesProp !== null,
          disabled: editLocked,
          rowNumber: rowNumber,
          index: index,
          header: header,
          type: type,
          mandatory: mandatory,
          values: values,
          value: defaultValue,
          tableFieldValue: tableFieldValue,
          setTableFieldValue: setTableFieldValue,
          fromServer: valuesProp !== null,
          id: id,
          subDocumentId: subDocumentId,
          maxChars,
          placeholder,
        };
      });
      return newField;
    }
  }
};
