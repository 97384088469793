import styled from 'styled-components';

const DescriptionList = styled.ul`
  list-style: none;
  margin-left: 20px;
  li {
    &:before {
      content: '•';
      color: #a7bf2e;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
`;

const ThinLabel = styled.span`
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #757575;
`;

const MediaContainer = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    > div {
      width: 100px;
      height: 75px;
    }
  }
`;

export { DescriptionList, ThinLabel, MediaContainer };
