import React from 'react';
import styled from 'styled-components';


interface Props {
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  hoverColor?: string;
}


const Container = styled.div<{
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  hoverColor?: string;
}>`
  width: ${({ width }) => (width ? `${width}px` : '34px')};
  height: ${({ height }) => (height ? `${height}px` : '34px')};
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#757575'};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-top: 3px;
  }

  &:hover {
    svg path {
      fill: ${({ hoverColor, color }) => (hoverColor ? hoverColor : color)};
    }
  }
`;

export default (props: Props) => (
  <Container {...props}>
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16.88C12.04 17.18 11.94 17.5 11.71 17.71C11.6175 17.8027 11.5076 17.8762 11.3866 17.9264C11.2657 17.9766 11.136 18.0024 11.005 18.0024C10.874 18.0024 10.7444 17.9766 10.6234 17.9264C10.5024 17.8762 10.3925 17.8027 10.3 17.71L6.29 13.7C6.18094 13.5934 6.09801 13.463 6.0477 13.319C5.99739 13.175 5.98107 13.0213 6 12.87V7.75L1.21 1.62C1.04761 1.41153 0.974335 1.14726 1.00619 0.88493C1.03804 0.622602 1.17244 0.383546 1.38 0.22C1.57 0.08 1.78 0 2 0H16C16.22 0 16.43 0.08 16.62 0.22C16.8276 0.383546 16.962 0.622602 16.9938 0.88493C17.0257 1.14726 16.9524 1.41153 16.79 1.62L12 7.75V16.88ZM4.04 2L8 7.06V12.58L10 14.58V7.05L13.96 2H4.04Z"
        fill={props.color ? props.color : 'white'}
      />
    </svg>
  </Container>
);
