import { ECampaignActionTypes } from './campaignActionsTypes';

const initialState = {
  campaigns: [],
  currentCampaign: {},
  loading: false,
  submitSuccess: false,
  saveSuccess: false,
  deleteSuccess: false,
};

interface State {
  campaigns: any;
  currentCampaign: any;
  loading: boolean;
  error?: string;
  submitSuccess?: boolean;
  saveSuccess?: boolean;
  deleteSuccess?: boolean;
}

interface CampaignAction extends State {
  type: ECampaignActionTypes;
  payload: any;
}

type TCampaignReducerActions = CampaignAction;

export default function(
  state: State = initialState,
  action: TCampaignReducerActions
) {
  switch (action.type) {
    case ECampaignActionTypes.GET_CAMPAIGNS:
      return {
        ...state,
        campaigns: [],
        loading: true,
        error: null,
        deleteSuccess: false,
      };
    case ECampaignActionTypes.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload.campaigns,
        loading: false,
        error: null,
      };
    case ECampaignActionTypes.GET_CAMPAIGNS_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    case ECampaignActionTypes.GET_CAMPAIGN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ECampaignActionTypes.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        currentCampaign: action.payload.campaign,
        loading: false,
        error: null,
      };
    case ECampaignActionTypes.GET_CAMPAIGN_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    case ECampaignActionTypes.SAVE_CAMPAIGN:
      return {
        ...state,
        loading: true,
        error: null,
        submitSuccess: false,
        saveSuccess: false,
      };
    case ECampaignActionTypes.SAVE_CAMPAIGN_SUCCESS:
      const isDraft = action.payload.isDraft;
      let stateProps = {};
      if (isDraft) {
        stateProps = {
          submitSuccess: false,
          campaign: action.payload.campaign,
        };
      } else {
        stateProps = { submitSuccess: !!action.payload.submitted };
      }
      return {
        ...state,
        loading: false,
        error: null,
        saveSuccess: !!action.payload.notifySuccess,
        ...stateProps,
      };
    case ECampaignActionTypes.SAVE_CAMPAIGN_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        submitSuccess: false,
        saveSuccess: false,
        campaign: null,
      };

    case ECampaignActionTypes.DELETE_CAMPAIGN:
      return { ...state, loading: true, error: false };
    case ECampaignActionTypes.DELETE_CAMPAIGN_SUCCESS:
      return { ...state, deleteSuccess: true, loading: false, error: false };
    case ECampaignActionTypes.DELETE_CAMPAIGN_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        deleteSuccess: false,
      };

    default:
      return state;
  }
}
