import styled from 'styled-components';

const Container = styled.div``;

const TableContainer = styled.div`
  border-radius: 0 25px 25px 25px;
  overflow: visible;
  padding-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
`;

const Table = styled.table<{ headers: string[]; vertical?: boolean }>`
  border-spacing: 0;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  text-align: left;
  flex-direction: column;
  table-layout: auto;

  th,
  td {
    vertical-align: middle;
    border: none;
  }

  tr {
    height: 40px;
    &:nth-child(even) {
      background-color: #f9f9fb;
    }
    td:first-child,
    th:first-child {
      padding-left: 30px;
    }

    td:last-child,
    th:last-child {
      padding-right: 15px;
      /* width: 100%; */
      max-width: 50px;
      min-width: 0;
    }
  }

  thead {
    background-color: #303030;
    text-transform: uppercase;
    color: #ffffff;
    height: 50px;
    tr {
      height: auto;
      th {
        white-space: nowrap;
        min-width: 180px;
        cursor: pointer;
        height: 50px;
      }
    }
  }

  tbody {
    .remove-action {
      opacity: 0;
    }
    tr:hover {
      td:first-child {
        position: relative;
        ::after {
          content: '';
          height: 100%;
          background-color: #a7bf2e;
          width: 5px;
          left: 0;
          top: 0;
          position: absolute;
        }
      }

      .remove-action {
        opacity: 1;
      }
    }
  }

  ${({ vertical, headers }) =>
    vertical &&
    `
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
      border: none;
    }
    thead {
      display: none;
      font-weight: bolder;
    }
    tr {
      height: auto;
      border-top: 1px solid #aaa;
      &:first-child {
        border: none;
      }
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0px;
      padding: 10px 20px 20px 10px;
    }

    td {
      border: none;
      border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      font-weight: lighter;
      &:before {
        white-space: nowrap;
        font-weight: bolder;
        display: flex;
        text-align: left;
        margin-right: 10px;
      }
    }

    td,
    th {
      padding-left: 0px !important;
      padding-right: 0px !important;
      min-height: 50px;
      display: flex;
      align-items: center;
    }

    ${headers.map(
      (header, index) =>
        `td:nth-of-type(${index + 1}):before {
          content: '${header}';
        }`
    )}
    `}
`;

const HeadTextContainer = styled.span`
  display: flex;
`;

const ActionContainer = styled.button`
  &:focus {
    outline: none;
  }
  outline: none;
  border: 0;
  display: flex;
  background-color: #fff;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 20px;
  margin-right: 10px;
`;

const TableHeader = styled.div`
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  display: flex;
  flex: 1;
  width: 100%;
`;

export {
  Container,
  TableContainer,
  Table,
  HeadTextContainer,
  ActionContainer,
  TableHeader,
};
