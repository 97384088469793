import React from 'react';
import i18n from 'i18next';
import { Label, InfoIcon, CustomTooltip, FormInput } from '..';
import Validations from './Validations';

interface Props {
  index: number;
  fieldPlaceholder?: string;
  maxChars?: number;
  onChange: Function;

  Controller: any;
  control: any;
  errors: any;
}

const NumberItem = ({
  onChange,
  index,
  fieldPlaceholder,
  Controller,
  control,
  errors,
}: Props) => (
  <>
    <div className="col-12 col-lg-8">
      <Label>
        {i18n.t('Value')}
        <div data-tip data-for="fieldplaceholder">
          <InfoIcon />
        </div>
        <CustomTooltip id="fieldplaceholder">
          <span>
            {i18n.t(
              'This is the text that will be used as placeholder for the input'
            )}
          </span>
        </CustomTooltip>
      </Label>
      <Controller
        as={
          <FormInput
            required
            placeholder={i18n.t('Enter placeholder text')}
          />
        }
        name="fieldPlaceholder"
        value={fieldPlaceholder}
        control={control}
        onChange={([evt]) => {
          onChange(index, evt.target.name, evt.target.value);
          return evt.target.value;
        }}
        rules={Validations.name}
        defaultValue={fieldPlaceholder}
      />
      {errors.fieldPlaceholder && (
        <span className="inlineErrorMessage">
          {errors.fieldPlaceholder.message}.
        </span>
      )}
    </div>
  </>
);

export default NumberItem;
