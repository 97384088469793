import { EAttestorActionTypes } from './attestorActionsTypes';

const initialState = {
  attestors: [],
  loading: false,
  currentAttestor: null,
  submitSuccess: false,
  saveSuccess: false,
  deleteSuccess: false,
};

interface State {
  attestors: Array<any>;
  loading: boolean;
  error?: string;
  currentAttestor?: any;
  submitSuccess?: boolean;
  saveSuccess?: boolean;
  deleteSuccess?: boolean;
}

interface AttestorAction extends State {
  type: EAttestorActionTypes;
  payload: any;
}

type TAssetReducerActions = AttestorAction;

export default function(
  state: State = initialState,
  action: TAssetReducerActions
) {
  switch (action.type) {
    case EAttestorActionTypes.GET_ATTESTORS:
      return { ...state, loading: true, currentAttestor: null, deleteSuccess: false };
    case EAttestorActionTypes.GET_ATTESTORS_SUCCESS:
      return { ...state, attestors: action.payload.attestors, loading: false };
    case EAttestorActionTypes.GET_ATTESTORS_ERROR:
      return { ...state, error: action.error, loading: false };

    case EAttestorActionTypes.SAVE_ATTESTOR:
      return {
        ...state,
        loading: true,
        error: null,
        submitSuccess: false,
        saveSuccess: false,
      };
    case EAttestorActionTypes.SAVE_ATTESTOR_SUCCESS: {
      const isDraft = action.payload.isDraft;
      let stateProps = {};
      if (isDraft) {
        stateProps = {
          submitSuccess: false,
          currentAttestor: action.payload.attestor,
        };
      } else {
        stateProps = { submitSuccess: !!action.payload.submitted };
      }
      return {
        ...state,
        loading: false,
        error: null,
        saveSuccess: !!action.payload.notifySuccess,
        ...stateProps,
      };
    }
    case EAttestorActionTypes.SAVE_ATTESTOR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        submitSuccess: false,
        saveSuccess: false,
        currentAttestor: null,
      };

    case EAttestorActionTypes.CLEAR_ATTESTOR:
      return {
        ...state,
        loading: false,
        error: null,
        submitSuccess: false,
        saveSuccess: false,
        currentAttestor: null,
      };

    case EAttestorActionTypes.GET_ATTESTOR:
      return { ...state, currentAttestor: null, loadingClaim: true, error: null };
    case EAttestorActionTypes.GET_ATTESTOR_SUCCESS:
      return {
        ...state,
        currentAttestor: action.payload.attestor,
        loading: false,
        error: null,
      };
    case EAttestorActionTypes.GET_ATTESTOR_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        currentAttestor: null,
      };

    case EAttestorActionTypes.DELETE_ATTESTOR:
      return { ...state, loading: true, error: false };
    case EAttestorActionTypes.DELETE_ATTESTOR_SUCCESS:
      return { ...state, deleteSuccess: true, loading: false, error: false };
    case EAttestorActionTypes.DELETE_ATTESTOR_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        deleteSuccess: false,
      };

    default:
      return state;
  }
}
