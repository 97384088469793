import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ObjectID } from 'bson';
import { useForm, FormContext } from 'react-hook-form';
import Toast from '../../../Lib/Utils/toast';
import { User } from '../../../Lib/Utils/Validations';
import {
  doSaveUser,
  doGetUser,
  resetState,
} from '../../../Redux/User/userActions';
import {
  ContentBox,
  Title,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
  Loading,
} from '../../../Components';

import { DataProps } from './types';
import { FaUserAlt } from 'react-icons/fa';
import { IconContainer, ActionButtonsContainer } from './styles';
import UserDetails from './UserDetails';
import UserReviewSubmit from './UserReviewSubmit';
import { doGetFacilities } from '../../../Redux/Facility/facilityActions';

interface RootState {
  user: any;
  facility: any;
}

const mapState = (state: RootState) => ({
  user: state.user.user,
  submitSuccess: state.user.submitSuccess,
  saveSuccess: state.user.saveSuccess,
  loading: state.user.loading,
  error: state.user.error,
  facilities: state.facility.facilities,
  loadingFacilities: state.facility.loading,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  saveUserAction: (payload, actionType, notifySuccess) =>
    dispatch(doSaveUser(payload, actionType, notifySuccess)),
  getUserAction: (facilityId, actionType) =>
    dispatch(doGetUser(facilityId, actionType)),
  resetFormAction: () => dispatch(resetState()),
  getFacilitiesAction: (includeDrafts, includeMembers) =>
    dispatch(doGetFacilities(includeDrafts, includeMembers)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & { history?: any; location?: any; match?: any };

const NewUser = (props: Props) => {
  const {
    saveUserAction,
    getUserAction,
    resetFormAction,
    getFacilitiesAction,
    history,
    match,
    location,
    submitSuccess,
    saveSuccess,
    error,
    loading,
    user,
    facilities,
    loadingFacilities,
  } = props;

  const entityId = match.params.userId;
  const isDraft = !!match.path.match('/draft');

  const [data, setData] = useState({ userFacilities: [] as any } as DataProps);
  const [tabIndex, setTabIndex] = useState(0);

  const formMethods = useForm({ mode: 'onBlur' });

  const tabs = [
    i18n.t('User Details'),
    !entityId ? i18n.t('Review & Submit') : i18n.t('Review & Save'),
  ];

  useEffect(() => {
    if (entityId) {
      getUserAction(entityId, isDraft ? 'get-draft' : 'get-entity');
    }
    getFacilitiesAction(false, true);

    // will be called on component unmount
    return () => {
      resetFormAction();
    };
  }, []);

  useEffect(() => {
    if (submitSuccess) {
      Toast.success(i18n.t('Your data have been successfully saved.'));
      history.push(`/users`);
    } else if (saveSuccess) {
      Toast.success(i18n.t('Data saved successfully.'));
    } else if (error) {
      Toast.error(error);
    }
  }, [error, submitSuccess, saveSuccess]);

  useEffect(() => {
    let {
      _id,
      id,
      firstName,
      lastName,
      email,
      confirmEmail,
      password,
      groups = [],
    } = user;

    let userFacilities = [];
    if (isDraft) {
      userFacilities = groups;
    } else {
      userFacilities =
        facilities
          ?.filter(f => f.users?.find(u => u.id === id))
          ?.map(f => f._id) || [];
    }

    setData({
      _id,
      id,
      firstName,
      lastName,
      email,
      confirmEmail: confirmEmail || email,
      password,
      userFacilities,
      previousGroups: userFacilities,
    });
  }, [user, facilities]);

  useEffect(() => {
    if (location) {
      const newIndex = getDefaultActiveTab();
      if (tabIndex !== newIndex) handleTabChange(newIndex);
    }
  }, [location, tabIndex]);

  const handleChange = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const getDefaultActiveTab = () => {
    const query = new URLSearchParams(location.search);
    const tabFromQuery = query.get('tab');
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/[^a-zA-Z0-9]/g, '') === tabFromQuery
    );
    return findTabIndex !== -1 ? findTabIndex : 0;
  };

  const handleTabChange = index => {
    const tabName = tabs[index].replace(/[^a-zA-Z0-9]/g, '');
    const query = new URLSearchParams(location.search);
    const stepFromQuery = query.get('step');
    const stepSearch = stepFromQuery ? `&step=${stepFromQuery}` : '';
    history.push({
      search: `?tab=${tabName}${stepSearch}`,
    });
    setTabIndex(index);
  };

  const handleBackButton = () => {
    saveUser(false);
    handleTabChange(tabIndex - 1);
  };

  const handleNextButton = () => {
    saveUser(false);
    handleTabChange(tabIndex + 1);
  };

  const getMappedData = () => {
    const {
      _id,
      id,
      firstName,
      lastName,
      email,
      password,
      userPermission,
      userFacilities = [],
    } = data;

    const mappedData = {
      ...data,
      _id,
      id: id || entityId || new ObjectID().toString(),
      firstName,
      lastName,
      email,
      password,
      userPermission,
      groups: userFacilities,
    };

    return mappedData;
  };

  const validate = async () => {
    formMethods.register({ name: 'email' }, User.email);
    formMethods.register({ name: 'firstName' }, User.name);
    formMethods.register({ name: 'lastName' }, User.name);
    formMethods.register({ name: 'userFacilities' }, User.facilities());
    formMethods.register({ name: 'confirmEmail' }, {
      validate: {
        matchesEmail: value => {
          const { email } = formMethods.getValues();
          return email !== value
            ? i18n.t('Emails do not match').toString()
            : undefined;
        },
      },
    });

    if (isDraft) {
      formMethods.register({ name: 'password' }, User.password);
    }

    formMethods.setValue(
      Object.keys(data).map(key => ({
        [key]: data[key],
      }))
    );

    const result = await formMethods.triggerValidation();

    if (!result) {
      setData({ ...data, validate: result });
    }

    return result;
  };

  const saveUser = async (notifySuccess?: boolean) => {

    const actionType = isDraft
      ? data._id
        ? 'save-draft'
        : 'create-draft'
      : 'save-entity';

    if (actionType === 'save-entity') {
      // Name must be entered at minimum
      if (!data.firstName) {
        Toast.error(i18n.t('Must enter name to save data.'));
        handleTabChange(0);
        return;
      }

      if (!isDraft) {
        const result = await validate();
        if (!result) {
          Toast.error(i18n.t('There are items that require your attention.'));
          return;
        }
      }

      const mappedData = getMappedData();
      saveUserAction(mappedData, actionType, notifySuccess);
    }
  };

  const submitUser = async () => {
    const result = await validate();
    if (result) {
      const mappedData = getMappedData();
      const actionType =
        isDraft || !entityId ? 'submit-create-entity' : 'submit-save-entity';
      saveUserAction(mappedData, actionType, false);
    } else {
      Toast.error(i18n.t('There are items that require your attention.'));
    }
  };

  return (
    <>
      <Title
        title={i18n.t('Add User')}
        subtitle={`${data.firstName || ''} ${data.lastName || ''}`}
        icon={
          <IconContainer>
            <FaUserAlt size={18} color="rgb(216, 244, 12)" />
          </IconContainer>
        }
      />
      <ContentBox>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={handleTabChange}
          initialSelectedIndex={getDefaultActiveTab()}
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Tab key={index}>{tab}</Tab>
            ))}

            <ActionButtonsContainer>
              {tabIndex > 0 && (
                <Button
                  text={i18n.t('Back')}
                  type="secondary"
                  action={handleBackButton}
                />
              )}
              {!isDraft && tabIndex < tabs.length - 1 && (
                <Button
                  text={i18n.t('Save')}
                  type="secondary"
                  action={() => saveUser(true)}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  action={handleNextButton}
                  text={i18n.t('Next')}
                  type="secondary"
                />
              )}
              {tabIndex === tabs.length - 1 && (
                <Button
                  text={
                    isDraft || !entityId ? i18n.t('Submit') : i18n.t('Save')
                  }
                  type="secondary"
                  action={() => {
                    submitUser();
                  }}
                />
              )}
            </ActionButtonsContainer>
          </TabList>

          <FormContext {...formMethods}>
            <TabPanel>
              <UserDetails
                onChange={(name, value) => handleChange(name, value)}
                formErrors={formMethods.errors}
                {...data}
                facilities={facilities}
                isDraft={isDraft}
              />
            </TabPanel>
            <TabPanel>
              <UserReviewSubmit
                onChange={(name, value) => handleChange(name, value)}
                {...data}
                formMethods={formMethods}
                isDraft={isDraft}
                entityId={entityId}
                facilities={facilities}
              />
            </TabPanel>
          </FormContext>
        </Tabs>
      </ContentBox>
      {(loadingFacilities || loading) && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(NewUser) as any) as any
) as any;
