import { config } from 'dotenv';

config({ silent: true });
export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_PREFIX =
  process.env.REACT_APP_API_URL_PREFIX || '/api/discoverbackend';
export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
export const KEYCLOAK_REALM =
  process.env.REACT_APP_KEYCLOAK_REALM || 'suku-dev';
export const KEYCLOAK_API = process.env.REACT_APP_KEYCLOAK_API;
export const PRESIGNED_URL = process.env.REACT_APP_PRESIGNED_URL;
export const AUTH_EXPLORER_URL =
  process.env.AUTH_EXPLORER_URL ||
  'http://hedera-auth.us-east-1.elasticbeanstalk.com/';

export const I18N_DEBUG = process.env.REACT_APP_I18N_DEBUG;
