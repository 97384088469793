import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { User } from '../../../Lib/Utils/Validations';
import {
  UserContainer as Container,
  UserFormContainer as FormContainer,
  UserListContainer as ListContainer,
  FormSubHeader,
  UserFormContainer,
} from './styles';

import {
  Label,
  FormInput,
  InfoIcon,
  CustomTooltip,
  Button,
  Dropdown,
  ItemsList,
  PopUp,
  TransferList,
} from '../../../Components';

import {
  Salutations,
  PhoneTypes,
  FacilityPermissionTypes,
} from '../../../Lib/Configs';

interface Props {
  onChange: Function;
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  formErrors?: any;
  confirmEmail?: string;
  validate?: boolean;
  userDetailsFirstLoad?: boolean;
  userFacilities: Array<string>;
  facilities?: any;
  isDraft?: boolean;
}

const FacilityUsers = ({
  onChange,
  formErrors,
  facilities,
  isDraft,
  ...data
}: Props) => {
  const {
    userDetailsFirstLoad = true,
    id,
    firstName,
    password,
    lastName,
    email,
    confirmEmail,
    userFacilities,
    validate,
  } = data;

  const [defaultFacilities, setDefaultFacilities] = useState([] as any);

  const {
    errors,
    control,
    register,
    setError,
    setValue,
    clearError,
    getValues,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    userDetailsFirstLoad === false && triggerValidation();
    onChange('userDetailsFirstLoad', false);
  }, []);

  useEffect(() => {
    setValue([{ firstName }, { lastName }, { email }, { confirmEmail }]);
  }, [firstName, lastName, email, confirmEmail]);

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      triggerValidation();
    }
  }, [formErrors, validate]);

  useEffect(() => {
    if (facilities) {
      const mapped = facilities?.map(f => ({
        text: f.registeredName,
        selected: !!userFacilities.find(uf => uf === f._id),
        data: f._id,
      }));
      setDefaultFacilities(mapped);
    }
  }, [facilities, userFacilities]);

  useEffect(() => {
    setValue('userFacilities', userFacilities);
    const facilitiesError = !userFacilities || userFacilities.length === 0;

    if (facilitiesError) {
      setError(
        'userFacilities',
        'required',
        i18n.t('You must select at least one facility')
      );
    } else {
      errors.userFacilities && clearError('userFacilities');
    }
  }, [userFacilities]);

  return (
    <Container>
      <FormSubHeader>
        <div className="mr-2">
          {i18n.t('Add a user who will enter supply chain data')}
        </div>
        <div data-tip data-for="addusers">
          <InfoIcon />
        </div>
        <CustomTooltip id="addusers" placement="right">
          <span>
            {i18n.t(
              'This information is internal and will not be seen by end consumers'
            )}
          </span>
        </CustomTooltip>
      </FormSubHeader>
      <div>
        <FormContainer id="UserForm">
          <div className="row label-spacing">
            <div className="col-5">
              <Label>{i18n.t('First name')}</Label>
              <Controller
                as={
                  <FormInput
                    required
                    placeholder={i18n.t('Enter the first name')}
                    maxLength={50}
                  />
                }
                name="firstName"
                value={firstName}
                control={control}
                onChange={([evt]) => {
                  onChange(evt.target.name, evt.target.value);
                  return evt.target.value;
                }}
                rules={User.name}
                defaultValue={firstName}
              />
              {errors.firstName && (
                <span className="inlineErrorMessage">
                  {errors.firstName.message}.
                </span>
              )}
            </div>

            <div className="col-5">
              <Label>{i18n.t('Last name')}</Label>
              <Controller
                as={
                  <FormInput
                    required
                    placeholder={i18n.t('Enter the last name')}
                    maxLength={50}
                  />
                }
                name="lastName"
                value={lastName}
                control={control}
                onChange={([evt]) => {
                  onChange(evt.target.name, evt.target.value);
                  return evt.target.value;
                }}
                rules={User.name}
                defaultValue={lastName}
              />
              {errors.lastName && (
                <span className="inlineErrorMessage">
                  {errors.lastName.message}.
                </span>
              )}
            </div>
          </div>

          <div className="row label-spacing">
            <div className="col-6">
              <Label>
                {i18n.t('Email')}
                <div data-tip data-for="email">
                  <InfoIcon />
                </div>
                <CustomTooltip id="email" placement="right">
                  <span>
                    {i18n.t(
                      'This email will also act as the user login to the SUKU Field App.'
                    )}
                  </span>
                </CustomTooltip>
              </Label>
              <div data-tip data-for="useredit">
                <Controller
                  as={
                    <FormInput
                      required
                      value={email}
                      placeholder={i18n.t('Enter email address')}
                      maxLength={75}
                      disabled={!!id}
                    />
                  }
                  name="email"
                  value={email}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.value);
                    return evt.target.value;
                  }}
                  rules={User.email}
                  defaultValue={email}
                />
              </div>
              {errors.email && (
                <span className="inlineErrorMessage">
                  {errors.email.message}.
                </span>
              )}
            </div>
            {!!id && (
              <CustomTooltip id="useredit" placement="right">
                <span>
                  {i18n.t(
                    'Cannot update username/email once user is created.'
                  )}
                </span>
              </CustomTooltip>
            )}

            {!id && (
              <div className="col-6">
                <Label>{i18n.t('Confirm email')}</Label>
                <Controller
                  as={
                    <FormInput
                      required
                      value={confirmEmail}
                      placeholder={i18n.t('Confirm email address')}
                      maxLength={75}
                    />
                  }
                  name="confirmEmail"
                  value={confirmEmail}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.value);
                    return evt.target.value;
                  }}
                  rules={{
                    required: {
                      value: true,
                      message: i18n.t('This field is required'),
                    },
                    validate: {
                      matchesEmail: value => {
                        const { email } = getValues();
                        return email !== value
                          ? i18n.t('Emails do not match').toString()
                          : undefined;
                      },
                    },
                  }}
                  defaultValue={confirmEmail}
                />
                {errors.confirmEmail && (
                  <span className="inlineErrorMessage">
                    {errors.confirmEmail.message}.
                  </span>
                )}
              </div>
            )}
          </div>

          {isDraft && (
            <div className="row label-spacing">
              <div className="col-9">
                <Label>
                  {i18n.t('Default password')}
                  <div data-tip data-for="defaultpassword">
                    <InfoIcon />
                  </div>
                  <CustomTooltip id="defaultpassword" placement="right">
                    <span>
                      {i18n.t(
                        'User will be asked to change the password when they log in for the first time into the SUKU Field App.'
                      )}
                    </span>
                  </CustomTooltip>
                </Label>
                <Controller
                  as={
                    <FormInput
                      required
                      type="password"
                      value={password}
                      placeholder={i18n.t('Enter password')}
                      maxLength={75}
                    />
                  }
                  name="password"
                  value={password}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.value);
                    return evt.target.value;
                  }}
                  rules={User.password}
                  defaultValue={password}
                />
                {errors.password && (
                  <span className="inlineErrorMessage">
                    {errors.password.message}.
                  </span>
                )}
              </div>
            </div>
          )}

          <div className="row label-spacing">
            <div className="col-lg-7 col-sm-12">
              <Label>
                {i18n.t('What permissions does this user have?')}
                <div data-tip data-for="permissions">
                  <InfoIcon />
                </div>
                <CustomTooltip id="permissions" placement="right">
                  <span>
                    {i18n.t(
                      'Give permissions to users to either view or add/edit supply chain data via the SUKU Field App.'
                    )}
                  </span>
                </CustomTooltip>
              </Label>
              <Dropdown
                required
                options={FacilityPermissionTypes}
                defaultOption={'rw'}
                placeholder={i18n.t('Select')}
                action={value => onChange('userPermissions', value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-3">
              <Label>{i18n.t('Map the user to one or more facility')}</Label>
              <div>
                <TransferList
                  fromPlaceholder={i18n.t('Search')}
                  toPlaceholder={i18n.t('Type to filter')}
                  items={defaultFacilities}
                  handleChange={items => {
                    onChange(
                      'userFacilities',
                      items.filter(i => i.selected).map(i => i.data)
                    );
                  }}
                />
                {errors.userFacilities && (
                  <span className="inlineErrorMessage pull-right mr-3 mt-3">
                    {errors.userFacilities.message}.
                  </span>
                )}
              </div>
            </div>
          </div>
        </FormContainer>
      </div>
    </Container>
  );
};

export default FacilityUsers;
