import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;

  .field-required {
    margin-right: 5px;
    font-size: 14px;
    color: #757575;
  }
`;

const Select = styled.select`
  border: 0;
  border-radius: 30px;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41 0L6 4.59L10.59 0L12 1.42L6 7.42L0 1.42L1.41 0Z' fill='%230F0F0F'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  font-family: Encode Sans, sans-serif;
  font-size: 14px;
  height: 40px;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #f2f2f2;
  &:focus {
    outline: none;
  }
  @media (max-width: 576px) {
    width: 100px;
  }
  option[value=''] {
    font-weight: normal;
  }
`;

export { Container, Select };
