import React from 'react';

export default () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 12H13.5V14.82L15.94 16.23L15.19 17.53L12 15.69V12ZM16 7H2V18H6.67C6.24 17.09 6 16.07 6 15C6 13.1435 6.7375 11.363 8.05025 10.0503C9.36301 8.7375 11.1435 8 13 8C14.07 8 15.09 8.24 16 8.67V7ZM2 20C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V4C0 2.89 0.89 2 2 2H3V0H5V2H13V0H15V2H16C16.5304 2 17.0391 2.21071 17.4142 2.58579C17.7893 2.96086 18 3.46957 18 4V10.1C19.24 11.36 20 13.09 20 15C20 16.8565 19.2625 18.637 17.9497 19.9497C16.637 21.2625 14.8565 22 13 22C11.09 22 9.36 21.24 8.1 20H2ZM13 10.15C11.7137 10.15 10.4801 10.661 9.57053 11.5705C8.66098 12.4801 8.15 13.7137 8.15 15C8.15 17.68 10.32 19.85 13 19.85C13.6369 19.85 14.2676 19.7246 14.856 19.4808C15.4444 19.2371 15.9791 18.8798 16.4295 18.4295C16.8798 17.9791 17.2371 17.4444 17.4808 16.856C17.7246 16.2676 17.85 15.6369 17.85 15C17.85 12.32 15.68 10.15 13 10.15Z"
      fill="#757575"
    />
  </svg>
);
