import { EMarketplaceActionTypes } from './marketplaceActionsTypes';

const initialState = {
  marketplaces: null,
  loading: false,
  error: null,
};

interface State {
  marketplaces: any;
  loading: boolean;
  error?: string | null;
}

interface MarketplaceAction extends State {
  type: EMarketplaceActionTypes;
  payload: any;
}

type TMarketplaceReducerActions = MarketplaceAction;

export default function(
  state: State = initialState,
  action: TMarketplaceReducerActions
) {
  switch (action.type) {
    case EMarketplaceActionTypes.GET_MARKETPLACES:
      return { ...state, loading: true, marketplaces: null, error: null };
    case EMarketplaceActionTypes.GET_MARKETPLACES_SUCCESS:
      return {
        ...state,
        marketplaces: action.payload.marketplaces.data,
        loading: false,
        error: null,
      };
    case EMarketplaceActionTypes.GET_MARKETPLACES_ERROR:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
