import { EAlertActionTypes } from './alertActionsTypes';

const initialState = {
  type: '',
  message: '',
};

interface State {
  type: string;
  message: string;
}

interface AlertAction extends State {
  type: EAlertActionTypes;
  payload: any;
}

type TAlertReducerActions = AlertAction;

export default function(
  state: State = initialState,
  action: TAlertReducerActions
) {
  switch (action.type) {
    case EAlertActionTypes.ALERT_SUCCESS:
      return {
        type: 'alert-success',
        message: action.payload.message,
      };
    case EAlertActionTypes.ALERT_ERROR:
      return {
        type: 'alert-danger',
        message: action.payload.message,
      };
    case EAlertActionTypes.ALERT_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
