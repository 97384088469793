import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  subTitle?: string;
  description?: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const H2 = styled.h2`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 45px;
  color: #000000;
  margin: 20px;
  @media (max-width: 1400px) {
    margin: 10px;
  }
`;

const H3 = styled.h3`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 33px;
  color: #a7bf2e;
  margin: 0 0 10px 0;
`;

const P = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 30px 0;
  @media (max-width: 1400px) {
    min-width: 40px;
  }
`;

const SimpleTitle = ({ title, subTitle, description }: Props) => {
  return (
    <Container>
      <H2>{title}</H2>
      {subTitle && <H3>{subTitle}</H3>}
      {description && <P>{description}</P>}
    </Container>
  );
};

export default SimpleTitle;
