import * as facilityService from '../../Services/Api/facilityService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EFacilityActionTypes } from './facilityActionsTypes';
import {
  GetFacilityActionType,
  SaveFacilityActionType,
} from '../../Services/Api/facilityService/types';

const getFacilities = createAction(EFacilityActionTypes.GET_FACILITIES);
const getFacilitiesSuccess = createAction(
  EFacilityActionTypes.GET_FACILITIES_SUCCESS,
  (facilities: any) => ({ facilities })
);
const getFacilitiesError = createAction(
  EFacilityActionTypes.GET_FACILITIES_ERROR,
  (message: string) => ({ message })
);

const saveFacility = createAction(EFacilityActionTypes.SAVE_FACILIY);
const saveFacilitySuccess = createAction(
  EFacilityActionTypes.SAVE_FACILIY_SUCCESS,
  (
    facility: any,
    isDraft?: boolean,
    submitted?: boolean,
    notifySuccess?: boolean
  ) => ({
    facility,
    isDraft,
    submitted,
    notifySuccess,
  })
);
const saveFacilityError = createAction(
  EFacilityActionTypes.SAVE_FACILIY_ERROR,
  (message: string) => ({ message })
);

const getFacility = createAction(EFacilityActionTypes.GET_FACILITY);
const getFacilitySuccess = createAction(
  EFacilityActionTypes.GET_FACILITY_SUCCESS,
  (facility: any) => ({ facility })
);
const getFacilityError = createAction(
  EFacilityActionTypes.GET_FACILITY_ERROR,
  (message: string) => ({ message })
);

export const resetCurrentFacility = createAction(
  EFacilityActionTypes.RESET_CURRENT_FACILITY
);

const deleteFacility = createAction(
  EFacilityActionTypes.DELETE_FACILITY,
  (facilityId: any) => ({ facilityId })
);
const deleteFacilitySuccess = createAction(
  EFacilityActionTypes.DELETE_FACILITY_SUCCESS,
  (payload: any) => payload
);
const deleteFacilityError = createAction(
  EFacilityActionTypes.DELETE_FACILITY_ERROR,
  (message: string) => ({ message })
);

export const doGetFacilities = (
  includeDrafts?: boolean,
  includeMembers?: boolean,
  payload?: any
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getFacilities());
  return await tap(
    facilityService.getFacilities(includeDrafts, includeMembers, payload),
    facilities => {
      dispatch(getFacilitiesSuccess(facilities));
    },
    error => {
      const _message = 'Failed to retrieve Facilities. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getFacilitiesError(message));
    }
  );
};

export const doSaveFacility = (
  payload: any,
  actionType: SaveFacilityActionType,
  notifySuccess?: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveFacility());
  return await tap(
    facilityService.saveFacility(payload, actionType),
    response => {
      let data = response.data ? response.data.data : {};
      const isDraft = !!actionType.match('draft');
      if (isDraft) {
        data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the facility _id
      }
      dispatch(
        saveFacilitySuccess(
          data,
          isDraft,
          !!actionType.match('submit'),
          notifySuccess
        )
      );
    },
    error => {
      const _message = 'Failed to save Facility. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveFacilityError(message));
    }
  );
};

export const doGetFacility = (
  facilityId: string,
  actionType: GetFacilityActionType
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getFacility());
  return await tap(
    facilityService.getFacility(facilityId, actionType),
    facility => {
      dispatch(getFacilitySuccess(facility));
    },
    error => {
      const _message = 'Failed to retrieve Facility. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getFacilityError(message));
    }
  );
};

export const doDeleteFacility = (
  facilityId: string,
  isDraft: boolean
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(deleteFacility(facilityId));
  return await tap(
    facilityService.deleteFacility(facilityId, isDraft),
    response => {
      dispatch(deleteFacilitySuccess(response.data));
    },
    error => {
      const _message = 'Failed to delete Journey. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(deleteFacilityError(message));
    }
  );
};
