import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

interface Props {
  name: string;
  checked?: boolean;
  onChange?: Function;
  alwaysOn?: boolean;
  disabled?: boolean;
}

const PrimarySwitch = withStyles(() => ({
  switchBase: {
    color: '#757575',
    '&$checked': {
      color: '#D8F40C',
    },
    '&$checked + $track': {
      backgroundColor: '#70801F',
      opacity: 1,
    },
  },
  checked: {},
  track: {
    backgroundColor: '#B6B6B6',
    opacity: 1,
  },
}))(Switch);

const AlwaysOnSwitch = withStyles(() => ({
  switchBase: {
    color: '#D8F40C',
    '&$checked': {
      color: '#D8F40C',
    },
    '&$checked + $track': {
      backgroundColor: '#70801F',
      opacity: 1,
    },
  },
  checked: {},
  track: {
    backgroundColor: '#70801F',
    opacity: 1,
  },
}))(Switch);

const CustomSwitch = ({
  name,
  checked,
  onChange = () => {},
  alwaysOn,
  ...rest
}: Props) => {
  return alwaysOn ? (
    <AlwaysOnSwitch
      checked={checked}
      name={name}
      onChange={evt => onChange(evt)}
      {...rest}
    />
  ) : (
    <PrimarySwitch
      checked={checked}
      name={name}
      onChange={evt => onChange(evt)}
      {...rest}
    />
  );
};

export default CustomSwitch;
