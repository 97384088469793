import styled from 'styled-components';

const AssetDetailsContainer = styled.div`
  margin: 0 32px;
  padding-bottom: 60px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #757575;
`;

const AssetDetailsFormContainer = styled.div`
  > div {
    margin-top: 33px;
  }
  label {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    > div {
      margin-left: 6px;
    }
  }

  li label {
    margin-bottom: 0;
  }
  .checkbox-wrapper {
    margin-left: -11px;
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);

    > label {
      margin-bottom: 5px;
    }
  }
`;

const AssetActionButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const SwitchLabel = styled.div<{ selected?: boolean }>`
  ${props => `
    ${props.selected ? 'color: #303030;' : 'color: rgba(117, 117, 117, 0.3);'}
  `}
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

const AssetReviewSubmitContainer = styled.div`
  padding: 40px 50px;
`;

const AssetReviewSubmitItemsWrapper = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(117, 117, 117, 0.25);
`;

const AssetReviewSubmitItemsContainer = styled.div`
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  div:first-child:nth-last-child(3),
  div:first-child:nth-last-child(3) ~ div:first-child {
    flex: 2;
  }
`;

const AssetReviewSubmitMarketableDisclaimer = styled.div`
  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  svg {
    margin-right: 10px;
  }
`;

const AssetReviewSubmitExtraDataList = styled.div`
  h3 {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #757575;
    margin: 20px 0;
  }

  div {
    display: flex;
    font-family: Encode Sans;
    margin-bottom: 20px;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #757575;
      flex: 0.25;
    }

    ul,
    p {
      flex: 1;
      font-family: Encode Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #757575;
    }
  }
`;

const AssetReviewSubmitInputsListContainer = styled.div`
  margin: 10px 40px;
`;

const AssetReviewSubmitSmartTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 200%;
  }
  a {
    width: fit-content;
  }
  span {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-top: 10px;
  }
`;

const AssetReviewSubmitMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 200%;
  }
  ul {
    padding: 0;
  }
  .promotionalDisclaimer {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      margin-left: 5px;
      background-color: #000;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      color: #d8f40c;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const ExtrasListContainer = styled.div`
  margin: 0px -32px;
  min-height: 400px;

  .empty-msg {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
  }
`;

const ExtrasListHeader = styled.div`
  background: #303030;
  height: 50px;
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 10px 0 32px;
  color: #ffffff;

  .first-value {
    min-width: 180px;
    margin-right: 14px;
  }
  .second-value {
    width: 100%;
  }
`;

const AssetMediaContainer = styled.div`
  margin: 35px 32px;

  .instructions {
    font-family: Encode Sans;
    font-style: normal;
    font-size: 14px;
    color: #757575;
  }

  .media-container {
    margin-bottom: 40px;

    .inlineErrorMessage {
      margin-left: 0;
    }

    .error-messages > div,
    .custom-link {
      margin-left: 0;
    }

    ul {
      padding: 0;
    }
  }
`;

const AssetSmartTagsContainer = styled.div`
  min-height: 830px;
  margin: 0 32px;
  padding-bottom: 60px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #303030;

  a {
    color: #a7bf2e;
    font-weight: 600;
  }

  .tags-checkboxes {
    display: flex;
    align-items: center;

    label {
      font-weight: normal;
      margin-right: 20px;
      margin-left: -11px;
    }
  }

  .last-highlighted {
    color: #a7bf2e;
  }

  .highlighted-description {
    color: #a7bf2e;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    ul {
      padding-left: 20px;
      line-height: 27px;
    }

    li {
      font-family: Encode Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #757575;
    }
  }

  .gallery-wrapper {
    min-height: 250px;
  }
`;

const AssetDataListContainer = styled.div`
  margin: 0px -32px;
  min-height: 800px;

  .empty-msg {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
  }
`;

const AssetDataListHeader = styled.div`
  background: #303030;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 0 32px;
  color: #ffffff;
`;

const AssetDataListContent = styled.div`
  padding: 40px 40px 40px 60px;

  li label {
    margin-bottom: 20px;
  }
`;

export {
  AssetActionButtonsContainer,
  SwitchLabel,
  AssetDetailsContainer,
  AssetDetailsFormContainer,
  AssetReviewSubmitContainer,
  AssetReviewSubmitItemsWrapper,
  AssetReviewSubmitItemsContainer,
  AssetReviewSubmitMarketableDisclaimer,
  AssetReviewSubmitExtraDataList,
  AssetReviewSubmitInputsListContainer,
  AssetReviewSubmitSmartTagsContainer,
  AssetReviewSubmitMediaContainer,
  ExtrasListContainer,
  ExtrasListHeader,
  AssetMediaContainer,
  AssetSmartTagsContainer,
  AssetDataListContainer,
  AssetDataListHeader,
  AssetDataListContent,
};
