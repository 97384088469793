import React, { useState, useEffect } from 'react';
import i18n from 'i18next';

import {
  Container,
  StepsList,
  StepItem,
  IconContainer,
  TitleContainer,
  NumberContainer,
  StepContent,
  DropdownContainer,
  AddItemIcon,
  Check,
} from './styles';

import {
  CustomTooltip,
  Dropdown,
  Button,
  Label,
  CheckFillIcon,
} from '../index';
import { MdAdd } from 'react-icons/md';

interface Props {
  steps?: Array<{
    number: number;
    icon?: any;
    title: string;
    subTitle: string;
    disabled?: boolean;
    content?: any;
    contentProps?: any;
    facilities?: string[];
    done?: boolean;
    parentData?: any;
    showAddButton?: boolean;
    withAdd?: boolean;
    checked?: boolean;
  }>;
  showCreate?: boolean;
  handleAdd?: Function;
  defaultStep?: number;
  header?: any;
  showFacilities?: boolean;
  onChange?: (value: number) => {} | void | null;
  loading?: boolean;
}

const numberSort = ({ number: numberA }, { number: numberB }) => {
  if (numberA === -1) return 1;
  if (numberB === -1) return -1;

  if (numberA > numberB) {
    return 1;
  }
  if (numberB > numberA) {
    return -1;
  }
  return 0;
};

const Steps = ({
  steps,
  header,
  defaultStep,
  showFacilities = true,
  showCreate = false,
  handleAdd,
  onChange,
  loading = false,
}: Props) => {
  const [active, setActive] = useState(0);
  const [facilitySelected, setFacilitySelected] = useState('');
  const [createMode, setCreateMode] = useState(false);

  const handleButtonClick = number => {
    setActive(number);
    onChange && onChange(number);
  };

  const activeStep = steps && steps.find(({ number }) => number === active);
  const Content = activeStep ? activeStep.content : null;

  useEffect(() => {
    activeStep?.facilities && setFacilitySelected(activeStep?.facilities[0]);
  }, [activeStep]);

  useEffect(() => {
    if (!activeStep && steps && steps[0]) {
      const firstStepNumber = steps[0].number;
      setActive(firstStepNumber);
    }
  }, [steps]);

  useEffect(() => {
    if (typeof defaultStep === 'number' && defaultStep !== active) {
      setActive(defaultStep);
    }
  }, [defaultStep, loading]);

  return (
    <Container>
      <StepsList className="nav nav-tabs">
        {steps
          ?.sort(numberSort)
          .map(
            (
              {
                number,
                icon,
                title,
                subTitle,
                disabled = false,
                facilities,
                done = false,
                parentData,
                showAddButton = false,
                checked = false,
              },
              index
            ) => {
              return (
                <React.Fragment key={index}>
                  <StepItem
                    className={`${active === number ? 'active' : ''}${
                      done ? ' done' : ''
                    } `}
                    key={number}
                  >
                    <div className="step-wrapper">
                      {facilities && facilities.length > 0 && (
                        <>
                          <div
                            data-tip
                            data-for={`${number}-facilities`}
                            className="facilities"
                          >
                            {facilities.length}
                          </div>
                        </>
                      )}
                      {checked && (
                        <Check>
                          <CheckFillIcon />
                        </Check>
                      )}

                      <button
                        disabled={disabled}
                        onClick={() => {
                          handleButtonClick(number);
                          setCreateMode(false);
                        }}
                      >
                        {icon ? (
                          <IconContainer>{icon}</IconContainer>
                        ) : (
                          <NumberContainer>{number}</NumberContainer>
                        )}
                      </button>
                      {showCreate && (
                        <AddItemIcon className="add-item-icon">
                          {showAddButton && (
                            <Button
                              width={34}
                              height={34}
                              icon={<MdAdd color="#fff" size={16} />}
                              type="roundDark"
                              action={() => {
                                handleButtonClick(number);
                                setCreateMode(true);
                              }}
                            />
                          )}
                        </AddItemIcon>
                      )}
                      <TitleContainer
                        className={showCreate ? 'clickable' : ''}
                        onClick={() => {
                          handleButtonClick(number);
                          setCreateMode(false);
                        }}
                      >
                        {title}
                        <small>{subTitle}</small>
                      </TitleContainer>
                    </div>
                  </StepItem>
                  {facilities && facilities.length > 0 && (
                    <CustomTooltip
                      id={`${number}-facilities`}
                      placement="bottom"
                    >
                      <ul className="facilityList list">
                        {facilities.map((facility, index) => (
                          <li key={index}>{facility}</li>
                        ))}
                      </ul>
                    </CustomTooltip>
                  )}
                </React.Fragment>
              );
            }
          )}
      </StepsList>
      {Content && header}
      <StepContent>
        {showFacilities &&
          activeStep &&
          activeStep.facilities &&
          activeStep.facilities.length > 1 && (
            <DropdownContainer>
              <Label className="mr-2">{i18n.t('Select facility')}</Label>
              <Dropdown
                defaultOption={facilitySelected}
                options={activeStep.facilities.map(facility => ({
                  value: facility,
                  label: facility,
                }))}
                action={selected => setFacilitySelected(selected)}
              />
            </DropdownContainer>
          )}
        {Content && (
          <Content
            createMode={createMode}
            setCreateMode={setCreateMode}
            {...activeStep?.contentProps}
          />
        )}
      </StepContent>
    </Container>
  );
};

export default Steps;
