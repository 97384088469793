import React from 'react';

export default () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 2.16666C8.80746 2.16666 5.41663 5.5575 5.41663 9.75C5.41663 15.4375 13 23.8333 13 23.8333C13 23.8333 20.5833 15.4375 20.5833 9.75C20.5833 5.5575 17.1925 2.16666 13 2.16666ZM7.58329 9.75C7.58329 6.76 10.01 4.33333 13 4.33333C15.99 4.33333 18.4166 6.76 18.4166 9.75C18.4166 12.87 15.2966 17.5392 13 20.4533C10.7466 17.5608 7.58329 12.8375 7.58329 9.75Z"
      fill="white"
    />
    <path
      d="M13 12.4583C14.4957 12.4583 15.7083 11.2458 15.7083 9.75C15.7083 8.25423 14.4957 7.04166 13 7.04166C11.5042 7.04166 10.2916 8.25423 10.2916 9.75C10.2916 11.2458 11.5042 12.4583 13 12.4583Z"
      fill="white"
    />
  </svg>
);
