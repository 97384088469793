import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Toast from '../../../Lib/Utils/toast';
import { useForm, FormContext } from 'react-hook-form';
import { Campaign } from '../../../Lib/Utils/Validations';
import { ObjectID } from 'bson';

import { doGetAssetTemplates } from '../../../Redux/Asset/assetActions';
import { doGetMarketplaces } from '../../../Redux/Marketplace/marketplaceActions';
import { doGetBrands } from '../../../Redux/Catalog/catalogActions';
import {
  doGetCampaign,
  doSaveCampaign,
} from '../../../Redux/Campaign/campaignActions';

import {
  CampaignIcon,
  ContentBox,
  Title,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
  Loading,
  CustomTooltip,
} from '../../../Components';

import { CampaignActionButtonsContainer } from './styles';

import CampaignDetails from './CampaignDetails';
import CampaignAdvert from './CampaignAdvert';

import Targeting from './Targeting';
import CampaignReviewSubmit from './CampaignReviewSubmit';

interface RootState {
  campaign: any;
  asset: any;
  marketplace: any;
  catalog: any;
}

interface InheritProps {
  showTitle?: boolean;
  submitCallback?: Function;
  campaignId?: string;
  useStore?: Array<string>;
  draft?: boolean;
}

const mapState = (state: RootState) => ({
  campaign: state.campaign.currentCampaign,
  loadingCampaign: state.campaign.loading,
  errorCampaign: state.campaign.error,
  submitSuccess: state.campaign.submitSuccess,
  saveSuccess: state.campaign.saveSuccess,

  assets: state.asset.assets,
  loadingAsset: state.asset.loading,
  marketplaces: state.marketplace.marketplaces,
  loadingMarketplaces: state.marketplace.loading,
  brands: state.catalog.brands,
  loadingCatalog: state.catalog.loading,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getAssetTemplatesAction: () => dispatch(doGetAssetTemplates(false)),
  getMarketplacesAction: () => dispatch(doGetMarketplaces()),
  getBrandsAction: () => dispatch(doGetBrands()),
  getCampaignAction: (id, actionType) =>
    dispatch(doGetCampaign(id, actionType)),
  saveCampaignAction: (campaign, actionType, notifySuccess) =>
    dispatch(doSaveCampaign(campaign, actionType, notifySuccess)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  InheritProps & { history?: any; location?: any; match?: any };

const NewCampaign = (props: Props) => {
  const {
    history,
    match,
    location,
    showTitle = true,
    submitCallback,
    getAssetTemplatesAction,
    getMarketplacesAction,
    getBrandsAction,
    assets,
    loadingAsset,
    marketplaces,
    loadingMarketplaces,
    brands,
    loadingCatalog,
    getCampaignAction,
    saveCampaignAction,
    campaign,
    loadingCampaign,
    campaignId,
    draft,
    errorCampaign,
    submitSuccess,
    saveSuccess,
  } = props;

  const isDraft = draft != null ? draft : !!match.path.match('/draft');
  const entityId = match.params.campaignId || campaignId;
  const loading =
    loadingCampaign || loadingAsset || loadingMarketplaces || loadingCatalog;

  const [data, setData] = useState({
    noEndDate: false,
    noProductTargeting: false,
    organizationId: '',
    name: '',
    description: '',
    startDate: '',
    endDate: '',
    userTargeting: {
      constraints: {},
      notifications: [],
    },
    assetTargeting: {
      constraints: {},
      adverts: [],
    },
    _id: null,
  } as any);

  useEffect(() => {
    if (entityId) {
      getCampaignAction(entityId, isDraft ? 'get-draft' : 'get-entity');
    }
    getAssetTemplatesAction();
    getMarketplacesAction();
    getBrandsAction();
  }, []);

  useEffect(() => {
    let {
      _id,
      name,
      description,
      startDate,
      endDate,
      noEndDate,
      assetTargeting = {},
    } = campaign;

    const { adverts = [], constraints = {} } = assetTargeting;

    const advertItems = adverts.map(a => ({
      advertName: a.name,
      advertDescription: a.description,
      advertStartDate: a.endDate,
      advertEndDate: a.startDate,
      name: a.urls,
    }));
    const selectedMarketableProducts = constraints.skukus || [];

    setData({
      _id,
      name,
      description,
      startDate: moment(startDate).format('MM/DD/YYYY'),
      endDate: moment(endDate).format('MM/DD/YYYY'),
      noEndDate,
      advertItems,
      selectedMarketableProducts,
    });
  }, [campaign]);

  const [tabIndex, setTabIndex] = useState(0);
  const formMethods = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (submitSuccess) {
      Toast.success(i18n.t('Your data has been successfully submitted.'));
      history.push(`/campaigns`);
    } else if (saveSuccess) {
      Toast.success(i18n.t('Your data has been successfully saved.'));
    } else if (errorCampaign) {
      Toast.error(errorCampaign);
    }
  }, [errorCampaign, submitSuccess, saveSuccess]);

  const tabs = [
    i18n.t('Overview'),
    i18n.t('Targeting'),
    i18n.t('Advert'),
    isDraft || !entityId ? i18n.t('Review & Submit') : i18n.t('Review & Save'),
  ];

  const handleChange = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const getDefaultActiveTab = () => {
    const query = new URLSearchParams(location.search);
    const tabFromQuery = query.get('tab');
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/[^a-zA-Z0-9]/g, '') === tabFromQuery
    );
    return findTabIndex !== -1 ? findTabIndex : 0;
  };

  const handleTabChange = index => {
    const tabName = tabs[index].replace(/[^a-zA-Z0-9]/g, '');
    const query = new URLSearchParams(location.search);
    history.push({
      search: `?tab=${tabName}`,
    });
    setTabIndex(index);
  };

  const handleBackButton = () => {
    saveCampaign(false);
    handleTabChange(tabIndex - 1);
  };

  const handleNextButton = () => {
    saveCampaign(false);
    handleTabChange(tabIndex + 1);
  };

  useEffect(() => {
    if (location) {
      const newIndex = getDefaultActiveTab();
      if (tabIndex !== newIndex) handleTabChange(newIndex);
    }
  }, [location, tabIndex]);

  const validate = async () => {
    const { noEndDate, noProductTargeting } = data;

    formMethods.register({ name: 'name' }, Campaign.name);
    formMethods.register({ name: 'description' }, Campaign.description);
    formMethods.register({ name: 'startDate' }, Campaign.startDate());
    formMethods.register(
      { name: 'endDate' },
      Campaign.endDate(() => data)
    );
    formMethods.register(
      { name: 'marketableProducts' },
      Campaign.marketableProducts(() => data)
    );

    formMethods.register({ name: 'advertItems' }, Campaign.advertItems());

    formMethods.setValue(
      Object.keys(data).map(key => ({
        [key]: data[key],
      }))
    );

    const result = await formMethods.triggerValidation();

    if (!result) {
      setData({ ...data, validate: result });
    }

    return result;
  };

  const getMappedData = () => {
    const {
      _id,
      name,
      description,
      startDate,
      endDate,
      noEndDate,
      marketableProducts = [],
      advertItems = [],
    } = data;

    const selectedMarketableProducts = marketableProducts
      .filter(({ selected }) => selected)
      .map(({ data }) => data.skuku);

    const advertsMappedData = advertItems.map(advert => ({
      name: advert.advertName,
      description: advert.advertDescription,
      startDate: advert.advertStartDate,
      endDate: advert.advertEndDate,
      urls: advert.name,
    }));

    const campaignMappedData = {
      _id: _id || entityId || new ObjectID().toString(),
      name,
      description,
      startDate,
      endDate: noEndDate ? '' : endDate,
      assetTargeting: {
        constraints: {
          skukus: selectedMarketableProducts,
        },
        adverts: advertsMappedData,
      },
    };
    return campaignMappedData;
  };

  const saveCampaign = async (notifySuccess?: boolean) => {
    // Name must be entered at minimum
    if (!data.name) {
      Toast.error(i18n.t('Must enter name to save data.'));
      handleTabChange(0);
      return;
    }

    if (!isDraft) {
      const result = await validate();
      if (!result) {
        Toast.error(i18n.t('There are items that require your attention.'));
        return;
      }
    }

    const actionType = isDraft
      ? data._id
        ? 'save-draft'
        : 'create-draft'
      : 'save-entity';
    const mappedData = getMappedData();
    saveCampaignAction(mappedData, actionType, notifySuccess);
  };

  const submitCampaign = async () => {
    const result = await validate();
    if (result) {
      const mappedData = getMappedData();
      const actionType =
        isDraft || !entityId ? 'submit-create-entity' : 'submit-save-entity';
      saveCampaignAction(mappedData, actionType, true);
    } else {
      Toast.error(i18n.t('There are items that require your attention.'));
    }
  };

  const lastTabIndex =
    data && data.noProductTargeting ? tabs.length - 2 : tabs.length - 1;

  return (
    <>
      {showTitle && (
        <Title
          title={i18n.t('New Campaign')}
          subtitle={data.name}
          icon={<CampaignIcon />}
        />
      )}
      <ContentBox>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={handleTabChange}
          initialSelectedIndex={getDefaultActiveTab()}
        >
          <TabList>
            {tabs.map((tab, index) =>
              index === 2 && data && data.noProductTargeting ? null : (
                <Tab key={index}>{tab}</Tab>
              )
            )}

            <CampaignActionButtonsContainer>
              {tabIndex > 0 && (
                <Button
                  text={i18n.t('Back')}
                  type="secondary"
                  action={handleBackButton}
                />
              )}
              {tabIndex < lastTabIndex && (
                <Button
                  text={i18n.t('Save')}
                  type="secondary"
                  action={() => saveCampaign(true)}
                />
              )}
              {tabIndex < lastTabIndex && (
                <Button
                  action={handleNextButton}
                  text={i18n.t('Save & Next')}
                  type="secondary"
                />
              )}
              {tabIndex === lastTabIndex && (
                <>
                  <span data-tip data-for="submitTooltip">
                    <Button
                      action={submitCampaign}
                      text={i18n.t('Submit')}
                      type="secondary"
                      disabled={true}
                    />
                  </span>
                  <CustomTooltip id="submitTooltip" placement="right">
                    <span>{i18n.t('Coming soon!')}</span>
                  </CustomTooltip>
                </>
              )}
            </CampaignActionButtonsContainer>
          </TabList>

          <TabPanel>
            <CampaignDetails
              onChange={(name, value) => handleChange(name, value)}
              {...data}
            />
          </TabPanel>
          <TabPanel>
            <Targeting
              onChange={(name, value) => handleChange(name, value)}
              history={history}
              {...data}
              assets={assets}
              marketplaces={marketplaces}
              brands={brands}
            />
          </TabPanel>
          {!data.noProductTargeting && (
            <TabPanel>
              <CampaignAdvert
                onChange={(name, value) => handleChange(name, value)}
                {...data}
              />
            </TabPanel>
          )}
          <TabPanel>
            <CampaignReviewSubmit
              onChange={(name, value) => handleChange(name, value)}
              {...data}
              formMethods={formMethods}
            />
          </TabPanel>
        </Tabs>
      </ContentBox>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(NewCampaign) as any) as any
) as any;
