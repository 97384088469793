import styled from 'styled-components';

const JourneyDetailsContainer = styled.div`
  margin: 40px 30px 0;
  padding-bottom: 60px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #757575;
`;

const JourneyDetailsSubtitle = styled.div`
  margin-top: 20px;
`;

const JourneyDetailsChecklist = styled.div`
  margin-left: 100px;
  margin-top: 23px;

  > div {
    display: flex;
    margin-top: 22px;
    > div {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
    svg {
      min-width: 20px;
    }
    label {
      margin: -1px 5px 0 0;
      min-width: 100px;
    }
    p {
      margin-top: 5px;
      max-width: 530px;
      width: -webkit-fill-available;
    }
  }

  @media (max-width: 991px) {
    margin-left: 0;
  }
`;

const JourneyDetailsFormContainer = styled.div`
  margin-top: 15px;
  > div {
    margin-top: 30px;
  }
  label {
    margin-bottom: 27px;
    display: flex;
    > div {
      margin-left: 6px;
    }
  }
`;

const JourneyActionButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const JourneyStepsContainer = styled.div`
  display: flex;
  .tabs-wrapper {
    flex: 1;
  }
`;

const JourneyStepsFacilityContainer = styled.div`
  margin: 44px 35px 0;
  min-height: 520px;
  .row > div {
    margin-bottom: 31px;
  }
  label {
    margin-bottom: 12px;
    display: flex;
    > div {
      margin-left: 6px;
    }
  }
  .switch-wrapper {
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);
  }
`;

const SwitchLabel = styled.div<{ selected?: boolean }>`
  ${props => `
    ${props.selected ? 'color: #303030;' : 'color: rgba(117, 117, 117, 0.3);'}
  `}
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

const JourneyStepsInboundContainer = styled.div`
  margin: 44px 35px 0;
  min-height: 520px;
  padding-bottom: 30px;
  .row > div {
    margin-bottom: 25px;
  }
  label {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    > div {
      margin-left: 6px;
    }
  }
  .checkbox-wrapper {
    margin-left: -11px;
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);
  }
`;

const JourneyReviewSubmitContainer = styled.div`
  padding: 40px 50px;
`;

export {
  JourneyDetailsContainer,
  JourneyDetailsFormContainer,
  JourneyDetailsSubtitle,
  JourneyDetailsChecklist,
  JourneyActionButtonsContainer,
  JourneyStepsContainer,
  JourneyStepsFacilityContainer,
  SwitchLabel,
  JourneyStepsInboundContainer,
  JourneyReviewSubmitContainer,
};
