import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import {
  FacilityReviewSubmitContainer as Container,
  FacilityReviewSubmitItemsContainer as ItemsContainer,
  FacilityReviewSubmitItemsWrapper as ItemsWrapper,
  FacilityReviewSubmitMediaContainer as MediaContainer,
  FacilityReviewItemsListHeaderContainer as ItemsListHeader,
  FacilityReviewSubmitItemsListContent as ItemsListContent,
  FacilityReviewClaimsListContainer as FacilityClaimsListContainer,
  FacilityClaimsTypeContainer,
} from './styles';

import {
  EditableItem,
  Link,
  MediaUpload,
  ItemsList,
  EyeIcon,
  Tooltip,
  MedalIcon,
} from '../../../Components';

interface Props {
  _id?: string;
  onChange: Function;
  formMethods: any;
  registeredName?: string;
  description?: string;
  quote?: string;
  quoteSource?: string;
  website?: string;
  noWebsite?: boolean;
  ethAddress?: string;
  facilityDetailFirstLoad?: boolean;
  contactSalutation?: string;
  contactFirstName?: string;
  contactLastName?: string;
  contactEmail?: string;
  contactPhone?: string;
  contactPhoneExt?: string;
  contactCountryCode?: string;
  contactPhoneType?: string;
  addressName?: string;
  country?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  longitude?: number;
  latitude?: number;
  email?: string;
  facilityCountryCode?: string;
  facilityPhoneType?: string;
  facilityPhone?: string;
  facilityPhoneExt?: string;
  facilityUsers?: Array<any>;
  photos?: Array<any>;
  videos?: Array<any>;
  noClaims?: boolean;
  facilityClaimsItems?: Array<any>;
  isDraft?: boolean;
  entityId?: string;
  embedded?: boolean;
}

const FacilityReviewSubmit = ({
  onChange,
  formMethods,
  isDraft,
  entityId,
  embedded,
  ...data
}: Props) => {
  const {
    _id,
    registeredName,
    description,
    quote,
    quoteSource,
    website,
    noWebsite,
    ethAddress,
    contactSalutation,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone,
    contactPhoneExt,
    contactCountryCode,
    contactPhoneType,
    addressName,
    country,
    state,
    city,
    zipCode,
    addressLineOne,
    addressLineTwo,
    longitude,
    latitude,
    email,
    facilityCountryCode,
    facilityPhone,
    facilityPhoneExt,
    facilityPhoneType,
    facilityUsers,
    photos,
    videos,
    noClaims,
    facilityClaimsItems,
  } = data;

  const { errors, clearError } = formMethods;

  useEffect(() => {
    clearError();
  }, []);

  const baseUrl = isDraft
    ? entityId
      ? `/facilities/${entityId}/draft`
      : '/facilities/draft'
    : `/facilities/${entityId}/edit`;

  const summaryError =
    !!errors.registeredName ||
    !!errors.description ||
    !!errors.quote ||
    !!errors.quoteSource ||
    !!errors.website;

  const pointOfContactError =
    !!errors.contactSalutation ||
    !!errors.contactFirstName ||
    !!errors.contactLastName ||
    !!errors.contactEmail ||
    !!errors.contactCountryCode ||
    !!errors.contactPhone ||
    !!errors.contactPhoneExt ||
    !!errors.contactPhoneType;

  const facilityInformationError =
    !!errors.addressName ||
    !!errors.country ||
    !!errors.state ||
    !!errors.city ||
    !!errors.zipCode ||
    !!errors.addressLineOne ||
    !!errors.addressLineTwo ||
    !!errors.longitude ||
    !!errors.latitude ||
    !!errors.email ||
    !!errors.facilityCountryCode ||
    !!errors.facilityPhone ||
    !!errors.facilityPhoneExt ||
    !!errors.facilityPhoneType;

  const usersError = !!errors.facilityUsers;

  const mediaError = !!errors.photos;

  const claimsError = !!errors.claims;

  const [showPasswords, setShowPasswords] = useState(false);

  const handleToggleShowPasswords = () => {
    setShowPasswords(!showPasswords);
  };

  const usersList =
    facilityUsers &&
    facilityUsers.map(({ data }) => ({
      content: (
        <ItemsListContent>
          <div className="col-3 pl-0">{`${data.facilityUserSalutation ||
            ''} ${data.facilityUserName || ''} ${data.facilityUserLastName ||
            ''}`}</div>
          <div className="col-3">{data.facilityUserEmail || ''}</div>
          <div className="col-3">
            {showPasswords || data.userChanged
              ? data.facilityUserPassword || ''
              : '*******'}
          </div>
        </ItemsListContent>
      ),
      data: { facilityUserPermission: data.facilityUserPermission },
    }));

  const contactName =
    (contactSalutation || contactFirstName || contactLastName) &&
    `${contactSalutation || ''} ${contactFirstName || ''} ${contactLastName ||
      ''}`;

  const contactPhoneNumber = `${
    contactCountryCode ? `(${contactCountryCode}) ` : ''
  }${contactPhone || ''}`;

  const facilityAddress = [
    addressName ? <p className="mb-1">{addressName}</p> : '',
    addressLineOne ? <p className="mb-1">{addressLineOne}</p> : '',
    addressLineTwo ? <p className="mb-1">{addressLineTwo}</p> : '',
    state || city ? (
      <p className="mb-1">
        <span>{state}</span>, <span>{city}</span>
      </p>
    ) : (
      ''
    ),
    zipCode ? <p className="mb-1">{zipCode}</p> : '',
    country ? <p className="mb-1">{country}</p> : '',
  ];

  const facilityPhoneNumber = `${
    facilityCountryCode ? `(${facilityCountryCode}) ` : ''
  }${facilityPhone || ''}`;

  return (
    <Container>
      <ItemsWrapper>
        <EditableItem
          title={i18n.t('Summary')}
          incomplete={summaryError}
          collapsible={true}
          linkTo={
            embedded ? { search: '?tab=Overview' } : `${baseUrl}?tab=Overview`
          }
          content={
            <>
              <ItemsContainer>
                <EditableItem
                  editable={false}
                  title={i18n.t('Name')}
                  content={registeredName}
                />
              </ItemsContainer>

              <ItemsContainer>
                <EditableItem
                  editable={false}
                  title={i18n.t('Long description')}
                  content={description}
                />
              </ItemsContainer>

              <ItemsContainer className="quote-item">
                <EditableItem
                  editable={false}
                  title={i18n.t('Quote')}
                  content={quote}
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Quote source')}
                  content={quoteSource}
                />
              </ItemsContainer>

              <ItemsContainer>
                <EditableItem
                  editable={false}
                  title={i18n.t('Website')}
                  content={website && !noWebsite && <Link url={website || ''} />}
                />
              </ItemsContainer>

              {/* <ItemsContainer>
                <EditableItem
                  editable={false}
                  title={i18n.t('SUKU wallet address (Ethereum address)')}
                  content={ethAddress}
                />
              </ItemsContainer> */}
            </>
          }
        />
      </ItemsWrapper>

      <ItemsWrapper>
        <EditableItem
          collapsible={true}
          title={i18n.t('Point of contact')}
          incomplete={pointOfContactError}
          linkTo={
            embedded ? { search: '?tab=Contact' } : `${baseUrl}?tab=Contact`
          }
          content={
            <>
              <ItemsContainer className="contact-item">
                <EditableItem
                  editable={false}
                  title={i18n.t('Point of contact')}
                  content={contactName}
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Email')}
                  content={contactEmail}
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Phone number')}
                  content={contactPhoneNumber}
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Ext')}
                  content={contactPhoneExt}
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Type')}
                  content={contactPhoneType}
                />
              </ItemsContainer>
            </>
          }
        />
      </ItemsWrapper>

      <ItemsWrapper>
        <EditableItem
          collapsible={true}
          title={i18n.t('Facility information')}
          incomplete={facilityInformationError}
          linkTo={
            embedded ? { search: '?tab=Contact' } : `${baseUrl}?tab=Contact`
          }
          content={
            <>
              <ItemsContainer>
                <EditableItem
                  editable={false}
                  title={i18n.t('Address')}
                  content={facilityAddress}
                />
              </ItemsContainer>

              <ItemsContainer className="secondary-contact-item">
                <EditableItem
                  editable={false}
                  title={i18n.t('Email')}
                  content={email}
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Phone number')}
                  content={facilityPhoneNumber}
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Ext')}
                  content={facilityPhoneExt}
                />
                <EditableItem
                  editable={false}
                  title={i18n.t('Type')}
                  content={facilityPhoneType}
                />
              </ItemsContainer>
            </>
          }
        />
      </ItemsWrapper>

      <ItemsWrapper>
        <EditableItem
          collapsible={true}
          title={i18n.t('Users')}
          incomplete={usersError}
          content={
            <div className="mr-1">
              <ItemsListHeader>
                <div className="col-3 pl-0">{i18n.t('Name')}</div>
                <div className="col-3">{i18n.t('Email')}</div>
                <div className="col-3">
                  <div>{i18n.t('Default password')}</div>
                  <div
                    className="ml-2 clickable"
                    onClick={() => handleToggleShowPasswords()}
                  >
                    <EyeIcon />
                  </div>
                </div>
              </ItemsListHeader>
              <ItemsList
                items={usersList ? usersList : []}
                editAction={index => console.log(index)}
              />
            </div>
          }
          linkTo={embedded ? { search: '?tab=Users' } : `${baseUrl}?tab=Users`}
        />
      </ItemsWrapper>

      <EditableItem
        title={i18n.t('Media')}
        incomplete={mediaError}
        collapsible={true}
        linkTo={embedded ? { search: '?tab=Media' } : `${baseUrl}?tab=Media`}
        content={
          <MediaContainer>
            <h3>
              {i18n.t('Photos displayed in the media gallery of your facility')}
            </h3>
            <MediaUpload
              proloadedItemsLinks={photos}
              readonly
              showLink
              linksList={[2]}
            />
            <h3>
              {i18n.t('Videos displayed in the media gallery of your facility')}
            </h3>
            <MediaUpload
              type="video"
              proloadedItemsLinks={videos}
              readonly
              showLink
              linksList={[1]}
            />
          </MediaContainer>
        }
      />
    </Container>
  );
};

export default FacilityReviewSubmit;
