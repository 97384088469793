import { EGlobalActionTypes } from './globalActionsTypes';

const initialState = {
  loading: false,
};

interface State {
  loading: boolean;
}

interface GlobalAction extends State {
  type: EGlobalActionTypes;
  payload: any;
}

type TGlobalReducerActions = GlobalAction;

export default function(
  state: State = initialState,
  action: TGlobalReducerActions
) {
  switch (action.type) {
    case EGlobalActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload.value,
      };
    default:
      return state;
  }
}
