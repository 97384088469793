import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ObjectID } from 'bson';
import { Claim } from '../../../Lib/Utils/Validations';
import Toast from '../../../Lib/Utils/toast';

import {
  AttestorIcon,
  ContentBox,
  Title,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
  Loading,
} from '../../../Components';

import { ClaimActionButtonsContainer } from './styles';

import AttestorContact from './AttestorContact';
import AttestorReviewSubmit from './AttestorReviewSubmit';

import {
  doSaveAttestor,
  doClearAttestor,
  doGetAttestor,
} from '../../../Redux/Attestor/attestorActions';

interface RootState {
  claim: any;
  attestor: any;
}

const mapState = (state: RootState) => ({
  attestor: state.attestor.currentAttestor,
  attestorLoading: state.attestor.loading,
  attestorError: state.attestor.error,
  submitSuccess: state.attestor.submitSuccess,
  saveSuccess: state.attestor.saveSuccess,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  saveAttestorAction: (attestor, actionType, notifySuccess) =>
    dispatch(doSaveAttestor(attestor, actionType, notifySuccess)),
  getAttestorAction: (attestorId, actionType) =>
    dispatch(doGetAttestor(attestorId, actionType)),
  clearAttestor: () => dispatch(doClearAttestor()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & { history?: any; location?: any; match?: any };

const NewAttestor = (props: Props) => {
  const {
    saveAttestorAction,
    getAttestorAction,
    clearAttestor,
    history,
    match,
    location,
    attestor,
    attestorLoading: loading,
    submitSuccess,
    saveSuccess,
    attestorError,
  } = props;

  const isDraft = !!match.path.match('/draft');
  const entityId = match.params.attestorId;

  const attestorShortName = (attestor && attestor.attestorShortName) || '';

  const [data, setData] = useState({
    attestorShortName,
    attestorCompany: '',
    attestorSalutation: '',
    attestorFirstName: '',
    attestorLastName: '',
    attestorWebsite: '',
    attestorEmail: '',
    attestorEmailConfirmation: '',
    attestorCountryCode: '',
    attestorPhoneType: '',
    attestorPhone: '',
    attestorPhoneExt: '',
    attestorNotes: '',
  } as any);

  const [tabIndex, setTabIndex] = useState(0);

  const formMethods = useForm({ mode: 'onBlur' });

  const tabs = [i18n.t('Contact'), i18n.t('Review & Submit')];

  useEffect(() => {
    entityId &&
      getAttestorAction(entityId, isDraft ? 'get-draft' : 'get-entity');

    // will be called on component unmount
    return () => {
      clearAttestor();
    };
  }, []);

  useEffect(() => {
    if (attestor) {
      const {
        _id,
        shortName: attestorShortName,
        salutation: attestorSalutation,
        firstName: attestorFirstName,
        lastName: attestorLastName,
        company: attestorCompany,
        link: attestorWebsite,
        email: attestorEmail,
        phone: {
          countryCode: attestorCountryCode,
          number: attestorPhone,
          extension: attestorPhoneExt,
          type: attestorPhoneType,
        },
        notes: attestorNotes,
        attestorEmailConfirmation,
      } = attestor;

      setData({
        ...attestor,
        attestorShortName,
        attestorCompany,
        attestorSalutation,
        attestorFirstName,
        attestorLastName,
        attestorWebsite,
        attestorEmail,
        attestorEmailConfirmation,
        attestorCountryCode,
        attestorPhoneType,
        attestorPhone,
        attestorPhoneExt,
        attestorNotes,
        _id,
      });
    }
  }, [attestor]);

  useEffect(() => {
    if (submitSuccess) {
      Toast.success(i18n.t('Your data has been successfully submitted.'));
      history.push(`/claims`);
    } else if (saveSuccess) {
      Toast.success(i18n.t('Your data has been successfully saved.'));
    } else if (attestorError) {
      Toast.error(attestorError);
    }
  }, [attestorError, submitSuccess, saveSuccess]);

  useEffect(() => {
    const hash = location.hash;
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/\s/g, '') === hash.replace('#', '')
    );
    const tabIndex = findTabIndex !== -1 ? findTabIndex : 0;
    setTabIndex(tabIndex);
  }, []);

  const handleChange = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const getDefaultActiveTab = () => {
    const query = new URLSearchParams(location.search);
    const tabFromQuery = query.get('tab');
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/[^a-zA-Z0-9]/g, '') === tabFromQuery
    );
    return findTabIndex !== -1 ? findTabIndex : 0;
  };

  const handleTabChange = index => {
    const tabName = tabs[index].replace(/[^a-zA-Z0-9]/g, '');
    const query = new URLSearchParams(location.search);
    history.push({
      search: `?tab=${tabName}`,
    });
    setTabIndex(index);
  };

  const handleBackButton = () => {
    saveAttestor(false);
    handleTabChange(tabIndex - 1);
  };

  const handleNextButton = () => {
    saveAttestor(false);
    handleTabChange(tabIndex + 1);
  };

  useEffect(() => {
    if (location) {
      const newIndex = getDefaultActiveTab();
      if (tabIndex !== newIndex) handleTabChange(newIndex);
    }
  }, [location, tabIndex]);

  const validate = async () => {
    const { getValues } = formMethods;

    formMethods.register({ name: 'attestorShortName' }, Claim.shortName);
    formMethods.register({ name: 'attestorCompany' }, Claim.shortName);
    formMethods.register({ name: 'attestorSalutation' }, Claim.salutation);
    formMethods.register({ name: 'attestorFirstName' }, Claim.shortName);
    formMethods.register({ name: 'attestorLastName' }, Claim.shortName);
    formMethods.register({ name: 'attestorWebsite' }, Claim.website);
    formMethods.register({ name: 'attestorEmail' }, Claim.email);
    formMethods.register(
      { name: 'attestorEmailConfirmation' },
      {
        required: {
          value: true,
          message: i18n.t('This field is required'),
        },
        validate: {
          matchesEmail: value => {
            const { attestorEmail } = getValues();
            return attestorEmail !== value
              ? i18n.t('Emails do not match').toString()
              : undefined;
          },
        },
      }
    );
    formMethods.register({ name: 'attestorCountryCode' }, Claim.countryCode);
    formMethods.register({ name: 'attestorCountryCode' }, Claim.phoneType);
    formMethods.register({ name: 'attestorPhone' }, Claim.phone);
    formMethods.register({ name: 'attestorPhoneExt' }, Claim.phoneExt);

    formMethods.setValue(
      Object.keys(data).map(key => ({
        [key]: data[key],
      }))
    );

    const result = await formMethods.triggerValidation();

    console.log('=====================');
    console.log('SET DATA', data);
    console.log('=====================');

    if (!result) {
      setData({ ...data, validate: result });
    }

    return result;
  };

  const getMappedData = () => {
    const {
      attestorShortName,
      attestorCompany,
      attestorSalutation,
      attestorFirstName,
      attestorLastName,
      attestorWebsite,
      attestorEmail,
      attestorEmailConfirmation,
      attestorCountryCode,
      attestorPhoneType,
      attestorPhone,
      attestorPhoneExt,
      attestorNotes,
      _id,
    } = data;

    const attestorMappedData = {
      _id: _id || entityId || new ObjectID().toString(),
      shortName: attestorShortName,
      salutation: attestorSalutation,
      firstName: attestorFirstName,
      lastName: attestorLastName,
      company: attestorCompany,
      link: attestorWebsite,
      email: attestorEmail,
      phone: {
        countryCode: attestorCountryCode,
        number: attestorPhone,
        extension: attestorPhoneExt,
        type: attestorPhoneType,
      },
      notes: attestorNotes,
      attestorEmailConfirmation,
    };

    return attestorMappedData;
  };

  const saveAttestor = async (notifySuccess?: boolean) => {
    if (!data.attestorShortName) {
      Toast.error(i18n.t('Must enter name to save data.'));
      handleTabChange(0);
      return;
    }

    if (!isDraft) {
      const result = await validate();
      if (!result) {
        Toast.error(i18n.t('There are items that require your attention.'));
        return;
      }
    }

    const actionType = isDraft
      ? data._id
        ? 'save-draft'
        : 'create-draft'
      : 'save-entity';
    const mappedData = getMappedData();
    saveAttestorAction(mappedData, actionType, notifySuccess);
  };

  const handleSubmit = async () => {
    const result = await validate();
    if (result) {
      const mappedData = getMappedData();
      const actionType =
        isDraft || !entityId ? 'submit-create-entity' : 'submit-save-entity';
      saveAttestorAction(mappedData, actionType, false);
    } else {
      Toast.error(i18n.t('There are items that require your attention.'));
    }
  };

  console.log('=====================');
  console.log('data', data);
  console.log('=====================');
  return (
    <>
      <Title
        title={i18n.t('New Attestor')}
        subtitle={data.attestorShortName}
        icon={<AttestorIcon />}
      />
      <ContentBox>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={handleTabChange}
          initialSelectedIndex={getDefaultActiveTab()}
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Tab key={index}>{tab}</Tab>
            ))}

            <ClaimActionButtonsContainer>
              {tabIndex > 0 && (
                <Button
                  text={i18n.t('Back')}
                  type="secondary"
                  action={handleBackButton}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  text={i18n.t('Save')}
                  type="secondary"
                  action={() => saveAttestor(true)}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  action={handleNextButton}
                  text={i18n.t('Save & Next')}
                  type="secondary"
                />
              )}
              {tabIndex === tabs.length - 1 && (
                <Button
                  action={() => handleSubmit()}
                  text={i18n.t('Submit')}
                  type="secondary"
                />
              )}
            </ClaimActionButtonsContainer>
          </TabList>

          <TabPanel>
            <AttestorContact
              onChange={(name, value) => handleChange(name, value)}
              {...data}
              formMethods={formMethods}
            />
          </TabPanel>
          <TabPanel>
            <AttestorReviewSubmit
              {...data}
              formMethods={formMethods}
              isDraft={isDraft}
              entityId={entityId}
            />
          </TabPanel>
        </Tabs>
      </ContentBox>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(NewAttestor) as any) as any
);
