import React from 'react';
import i18n from 'i18next';
import { Label, InfoIcon, CustomTooltip, FormInput } from '..';
import Validations from './Validations';

interface Props {
  index: number;
  fieldPlaceholder?: string;
  maxChars?: number;
  onChange: Function;
  Controller: any;
  control: any;
  errors: any;
}

const TextItem = ({
  onChange,
  index,
  maxChars,
  fieldPlaceholder,
  Controller,
  control,
  errors,
}: Props) => (
  <>
    <div className="col-12 col-lg-8">
      <Label>
        {i18n.t('Value')}
        <div data-tip data-for="fieldvalue">
          <InfoIcon />
        </div>
        <CustomTooltip id="fieldvalue">
          <span>

            {i18n.t(
              'This is the text that will be used as placeholder for the input'
            )}
          </span>
        </CustomTooltip>
      </Label>
      <Controller
        as={
          <FormInput
            required
            placeholder={i18n.t('Enter placeholder text')}
          />
        }
        name="fieldPlaceholder"
        value={fieldPlaceholder}
        control={control}
        onChange={([evt]) => {
          onChange(index, evt.target.name, evt.target.value);
          return evt.target.value;
        }}
        rules={Validations.name}
        defaultValue={fieldPlaceholder}
      />
      {errors.fieldPlaceholder && (
        <span className="inlineErrorMessage">
          {errors.fieldPlaceholder.message}.
        </span>
      )}
    </div>
    <div className="col-12 col-lg-4">
      <Label>
        {i18n.t('Max chars')}
        <div data-tip data-for="fieldplaceholder">
          <InfoIcon />
        </div>
        <CustomTooltip id="fieldplaceholder">
          <span>
            {i18n.t('Number of characters the user can enter into the field.')}
          </span>
        </CustomTooltip>
      </Label>

      <Controller
        as={
          <FormInput
            required
            subType="number"
            placeholder={i18n.t('Enter number')}
          />
        }
        name="maxChars"
        value={maxChars}
        control={control}
        onChange={([evt]) => {
          const value = evt.target.value;
          if (!value || value > 0) {
            onChange(index, evt.target.name, value);
            return value;
          } else {
            onChange(index, evt.target.name, maxChars);
            return maxChars;
          }
        }}
        rules={Validations.type}
        defaultValue={maxChars}
      />
      {errors.maxChars && (
        <span className="inlineErrorMessage">
          {errors.maxChars.message}.
        </span>
      )}
    </div>
  </>
);

export default TextItem;
