import React from 'react';

export default (props: { color?: string }) => (
  <svg
    width="33"
    height="41"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.9559 14.4H20.8768C20.2059 12.544 18.449 11.2 16.3726 11.2C14.2963 11.2 12.5393 12.544 11.8685 14.4H6.78936C6.26228 14.4 3.59494 14.24 3.59494 11.2V9.6C3.59494 6.672 6.05465 6.4 6.78936 6.4H23.049C23.7198 8.256 25.4767 9.6 27.5531 9.6C28.8239 9.6 30.0427 9.09429 30.9413 8.19411C31.8399 7.29394 32.3448 6.07304 32.3448 4.8C32.3448 3.52696 31.8399 2.30606 30.9413 1.40589C30.0427 0.505713 28.8239 0 27.5531 0C25.4767 0 23.7198 1.344 23.049 3.2H6.78936C4.21785 3.2 0.400513 4.896 0.400513 9.6V11.2C0.400513 15.904 4.21785 17.6 6.78936 17.6H11.8685C12.5393 19.456 14.2963 20.8 16.3726 20.8C18.449 20.8 20.2059 19.456 20.8768 17.6H25.9559C26.483 17.6 29.1503 17.76 29.1503 20.8V22.4C29.1503 25.328 26.6906 25.6 25.9559 25.6H9.69629C9.02546 23.744 7.26853 22.4 5.19215 22.4C3.92133 22.4 2.70256 22.9057 1.80395 23.8059C0.905345 24.7061 0.400513 25.927 0.400513 27.2C0.400513 28.473 0.905345 29.6939 1.80395 30.5941C2.70256 31.4943 3.92133 32 5.19215 32C7.26853 32 9.02546 30.656 9.69629 28.8H25.9559C28.5274 28.8 32.3448 27.088 32.3448 22.4V20.8C32.3448 16.112 28.5274 14.4 25.9559 14.4ZM27.5531 3.2C27.9767 3.2 28.383 3.36857 28.6825 3.66863C28.9821 3.96869 29.1503 4.37565 29.1503 4.8C29.1503 5.22435 28.9821 5.63131 28.6825 5.93137C28.383 6.23143 27.9767 6.4 27.5531 6.4C27.1295 6.4 26.7233 6.23143 26.4237 5.93137C26.1242 5.63131 25.9559 5.22435 25.9559 4.8C25.9559 4.37565 26.1242 3.96869 26.4237 3.66863C26.7233 3.36857 27.1295 3.2 27.5531 3.2ZM5.19215 28.8C4.76854 28.8 4.36229 28.6314 4.06275 28.3314C3.76322 28.0313 3.59494 27.6243 3.59494 27.2C3.59494 26.7757 3.76322 26.3687 4.06275 26.0686C4.36229 25.7686 4.76854 25.6 5.19215 25.6C5.61576 25.6 6.02201 25.7686 6.32155 26.0686C6.62109 26.3687 6.78936 26.7757 6.78936 27.2C6.78936 27.6243 6.62109 28.0313 6.32155 28.3314C6.02201 28.6314 5.61576 28.8 5.19215 28.8Z"
      fill={props.color ? props.color : "#A7BF2E" }
    />
  </svg>
);
