import styled from 'styled-components';

const Label = styled.label<{ disabled?: boolean; thin?: boolean }>`
  ${props => `
    font-family: Encode Sans;
    font-style: normal;
    font-weight: ${props.thin ? '100' : '600'};
    font-size: 14px;
    color: ${props.disabled ? 'rgba(117, 117, 117, 0.5)' : '#303030'};
    margin: 0;
  `}
`;

const FormErrorLabel = styled.div`
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #f40c28;
`;

export { Label, FormErrorLabel };

export default Label;
