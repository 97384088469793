import React from 'react';
import i18n from 'i18next';
import {
  Label,
  InfoIcon,
  CustomTooltip,
  FormInput,
  Checkbox,
  CloseCircleIcon,
} from '..';
import {
  AddItemContainer,
  AddItemIcon,
  CheckboxItemContainer as Container,
} from './styles';
import { MdAdd } from 'react-icons/md';

interface Props {
  index: number;
  options: Array<string>;
  onChange: Function;
}

const CheckboxItem = ({ onChange, index, options }: Props) => {
  const addOption = () => {
    onChange(index, 'options', [...options, '']);
  };

  const handleChange = (idx: number, value: any) => {
    const updatedOptions = options.map((el, i) =>
      i === idx ? value : el
    );
    onChange(index, 'options', updatedOptions);
  };

  const deleteOption = idx => {
    const updatedOptions = options.filter((_, i) => i !== idx);
    onChange(index, 'options', updatedOptions);
  };

  return (
    <>
      <Container className="col-12">
        <Label>
          {i18n.t('Values')}
          <div data-tip data-for="fieldvalues">
            <InfoIcon />
          </div>
          <CustomTooltip id="fieldvalues">
            <span>
              {i18n.t(
                'Add options to the list. The first value will be the default selected value'
              )}
            </span>
          </CustomTooltip>
        </Label>
        <ul className="mb-4">
          {options.map((option, idx) => (
            <li key={idx}>
              <Checkbox disabled name="checkbox" />
              <div className="delete-input">
                <FormInput
                  value={option}
                  placeholder={`${i18n.t('Option')} ${idx + 1}`}
                  onChange={evt => handleChange(idx, evt.target.value)}
                  autoFocus
                />
              </div>
              <div
                className="delete-option"
                onClick={() => deleteOption(idx)}
              >
                <CloseCircleIcon />
              </div>
            </li>
          ))}
        </ul>
        <AddItemContainer className="add-option" onClick={() => addOption()}>
          <AddItemIcon className="add-option-icon">
            <MdAdd color="#fff" size={24} />
          </AddItemIcon>
          <span>{i18n.t('Add option')}</span>
        </AddItemContainer>
      </Container>
    </>
  );
};

export default CheckboxItem;
