import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  name: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: Function;
  options: Array<{ label?: string; value?: string, disabled?: boolean }>;
}

const HtmlRadio = withStyles(() => ({
  root: {
    color: '#757575',
    '&$checked': {
      color: '#757575',
    },
  },
  checked: {},
}))(Radio);

const Container = styled.div`
  .MuiFormGroup-root label {
    margin-bottom: 0;
  }
  .MuiFormGroup-root label span {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #757575;
  }
`;

const CustomRadioGroup = ({
  name,
  value,
  disabled,
  onChange,
  options,
  ...rest
}: Props) => {
  return (
    <Container>
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={evt => onChange && onChange(evt)}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<HtmlRadio color="default" />}
            label={option.label}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </Container>

    // <HtmlRadioGroup
    //   value={value}
    //   name={name}
    //   disabled={disabled}
    //   color="default"
    //   onChange={evt => onChange(evt)}
    //   {...rest}
    // />
  );
};

export default CustomRadioGroup;
