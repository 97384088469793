import React from 'react';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import i18n from 'i18next';

import { TrashIcon } from '../CustomIcons';
import Tooltip, { CustomTooltip } from '../Tooltip';
import {
  ControlsContainer as Container,
  ControlsArrowButton as ArrowButton,
  ControlsTrashButton as TrashButton,
} from './styles';

interface Props {
  number: number;
  itemsList: Array<any>;
  setItemsList: (value: Array<any>) => void;
}

const ItemControls = ({ number, itemsList, setItemsList }: Props) => {
  const first = number === 0;
  const last = number === itemsList.length - 1;

  const handleUpArrowClick = e => {
    e.stopPropagation();

    const updatedItems = itemsList.slice();
    const element = updatedItems[number];
    updatedItems.splice(number, 1);
    updatedItems.splice(number - 1, 0, element);

    setItemsList(updatedItems);
  };

  const handleDownArrowClick = e => {
    e.stopPropagation();

    const updatedItems = itemsList.slice();
    const element = updatedItems[number];
    updatedItems.splice(number, 1);
    updatedItems.splice(number + 1, 0, element);

    setItemsList(updatedItems);
  };

  const handleDeleteClick = () => {
    console.log('=====================');
    console.log('!!! DELETE');
    console.log('=====================');
    const updatedItems = itemsList.filter((_, index) => index !== number);
    setItemsList(updatedItems);
  };

  return (
    <Container first={first} last={last}>
      {!first && (
        <ArrowButton className="up" onClick={handleUpArrowClick}>
          <MdKeyboardArrowUp size="3em" />
        </ArrowButton>
      )}

      <div data-tip data-for="trashicon">
        <TrashButton onClick={handleDeleteClick} data-for="trashTooltip">
          <TrashIcon color="white" />
        </TrashButton>
      </div>
      <CustomTooltip placement="left" id="trashicon">
        <span>{i18n.t('Delete this item')}</span>
      </CustomTooltip>
      {!last && (
        <ArrowButton className="down" onClick={handleDownArrowClick}>
          <MdKeyboardArrowDown size="3em" />
        </ArrowButton>
      )}
    </Container>
  );
};

export default ItemControls;
