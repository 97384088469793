import * as marketplaceService from '../../Services/Api/marketplaceService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EMarketplaceActionTypes } from './marketplaceActionsTypes';

const getMarketplaces = createAction(EMarketplaceActionTypes.GET_MARKETPLACES);
const getMarketplacesSuccess = createAction(
  EMarketplaceActionTypes.GET_MARKETPLACES_SUCCESS,
  (marketplaces: any) => ({ marketplaces })
);
const getMarketplacesError = createAction(
  EMarketplaceActionTypes.GET_MARKETPLACES_ERROR,
  (message: string) => ({ message })
);

export const doGetMarketplaces = (
  payload = null
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getMarketplaces());
  return await tap(
    marketplaceService.getMarketplaces(payload),
    response => {
      dispatch(getMarketplacesSuccess(response.data));
    },
    error => {
      const _message =
        'Failed to retrieve Marketplaces. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getMarketplacesError(message));
    }
  );
};
