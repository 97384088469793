export enum EClaimActionTypes {
  GET_CLAIMS = 'GET_CLAIMS',
  GET_CLAIMS_SUCCESS = 'GET_CLAIMS_SUCCESS',
  GET_CLAIMS_ERROR = 'GET_CLAIMS_ERROR',

  GET_CLAIM = 'GET_CLAIM',
  GET_CLAIM_SUCCESS = 'GET_CLAIM_SUCCESS',
  GET_CLAIM_ERROR = 'GET_CLAIM_ERROR',

  GET_ATTESTORS = 'GET_ATTESTORS',
  GET_ATTESTORS_SUCCESS = 'GET_ATTESTORS_SUCCESS',
  GET_ATTESTORS_ERROR = 'GET_ATTESTORS_ERROR',

  ADD_CLAIM_VERIFICATION = 'ADD_CLAIM_VERIFICATION',
  ADD_CLAIM_VERIFICATION_SUCCESS = 'ADD_CLAIM_VERIFICATION_SUCCESS',
  ADD_CLAIM_VERIFICATION_ERROR = 'ADD_CLAIM_VERIFICATION_ERROR',

  SAVE_CLAIM = 'SAVE_CLAIM',
  SAVE_CLAIM_SUCCESS = 'SAVE_CLAIM_SUCCESS',
  SAVE_CLAIM_ERROR = 'SAVE_CLAIM_ERROR',
  SAVE_CLAIM_CLEAR = 'SAVE_CLAIM_CLEAR',

  DELETE_CLAIM = 'DELETE_CLAIM',
  DELETE_CLAIM_SUCCESS = 'DELETE_CLAIM_SUCCESS',
  DELETE_CLAIM_ERROR = 'DELETE_CLAIM_ERROR',

  RESET_CLAIMS = 'RESET_CLAIMS',
}
