export enum EAttestorActionTypes {
  GET_ATTESTORS = 'GET_ATTESTORS',
  GET_ATTESTORS_SUCCESS = 'GET_ATTESTORS_SUCCESS',
  GET_ATTESTORS_ERROR = 'GET_ATTESTORS_ERROR',

  SAVE_ATTESTOR = 'SAVE_ATTESTOR',
  SAVE_ATTESTOR_SUCCESS = 'SAVE_ATTESTOR_SUCCESS',
  SAVE_ATTESTOR_ERROR = 'SAVE_ATTESTOR_ERROR',

  GET_ATTESTOR = 'GET_ATTESTOR',
  GET_ATTESTOR_SUCCESS = 'GET_ATTESTOR_SUCCESS',
  GET_ATTESTOR_ERROR = 'GET_ATTESTOR_ERROR',

  CLEAR_ATTESTOR = 'CLEAR_ATTESTOR',

  DELETE_ATTESTOR = 'DELETE_ATTESTOR',
  DELETE_ATTESTOR_SUCCESS = 'DELETE_ATTESTOR_SUCCESS',
  DELETE_ATTESTOR_ERROR = 'DELETE_ATTESTOR_ERROR',
}
