import React from 'react';
import AnimalNutrition from '../../Rsc/Images/claim_animal_nutrition.png';
import Certification from '../../Rsc/Images/claim_certificate.png';
import Welfare from '../../Rsc/Images/claim_animal_welfare.png';
import Environment from '../../Rsc/Images/claim_environment.png';
import Fresh from '../../Rsc/Images/claim_fresh.png';
import Health from '../../Rsc/Images/claim_health.png';
import HormoneFree from '../../Rsc/Images/claim_hormone_free.png';
import ISO from '../../Rsc/Images/claim_iso.png';
import Softness from '../../Rsc/Images/claim_softness.png';
import Taste from '../../Rsc/Images/claim_taste.png';
import Temperature from '../../Rsc/Images/claim_temperature.png';
import Tender from '../../Rsc/Images/claim_tender.png';
import Vaccine from '../../Rsc/Images/claim_vaccine.png';
import Safety from '../../Rsc/Images/claim_safety.png';

const AnimalNutritionIcon = <img src={AnimalNutrition} width="40" />;
const CertificationIcon = <img src={Certification} width="25" />;
const WelfareIcon = <img src={Welfare} width="25" />;
const EnvironmentIcon = <img src={Environment} width="25" />;
const FreshIcon = <img src={Fresh} width="25" />;
const HealthIcon = <img src={Health} width="25" />;
const HormoneFreeIcon = <img src={HormoneFree} width="25" />;
const ISOIcon = <img src={ISO} width="25" />;
const SoftnessIcon = <img src={Softness} width="25" />;
const TasteIcon = <img src={Taste} width="25" />;
const TemperatureIcon = <img src={Temperature} width="25" />;
const TenderIcon = <img src={Tender} width="25" />;
const VaccineIcon = <img src={Vaccine} width="25" />;
const SafetyIcon = <img src={Safety} width="25" />;

export default {
  AnimalNutritionIcon,
  CertificationIcon,
  WelfareIcon,
  EnvironmentIcon,
  FreshIcon,
  HealthIcon,
  HormoneFreeIcon,
  ISOIcon,
  SoftnessIcon,
  TasteIcon,
  TemperatureIcon,
  TenderIcon,
  VaccineIcon,
  SafetyIcon,
};
