import React, { useState } from 'react';
import i18n from 'i18next';
import { useLocation } from 'react-router-dom';

import {
  CampaignTargetingContainer as Container,
  CampaignTargetingSubContainer as SubContainer,
} from '../styles';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  CustomTooltip,
} from '../../../../Components';

import User from './User';
import Product from './Product';

interface Props {
  onChange: Function;
  history?: any;
  assets?: Array<any>;
  marketplaces?: Array<any>;
  brands?: Array<any>;
}

const JourneySteps = ({
  onChange,
  assets,
  marketplaces,
  brands,
  ...data
}: Props) => {
  const { history } = data;

  const location = useLocation();

  const tabs = ['Product Targeting', 'User Targeting'];

  const query = new URLSearchParams(location.search);
  const subFromQuery = query.get('sub');

  const activeTab =
    subFromQuery &&
    tabs.findIndex(tab => tab.replace(/[^a-zA-Z0-9]/g, '') === subFromQuery);

  const [tabIndex, setTabIndex] = useState(activeTab ? activeTab : 0);

  const handleTabChange = index => {
    const subTabName = tabs[index].replace(/[^a-zA-Z0-9]/g, '');
    history.push({
      search: `?tab=Targeting&sub=${subTabName}`,
    });
    setTabIndex(index);
  };

  return (
    <Container>
      <div className="tabs-wrapper">
        <Tabs
          withBorderRadius={false}
          backgroundColor="#303030"
          selectedIndex={tabIndex}
          onSelect={handleTabChange}
        >
          <TabList>
            <CustomTooltip placement="top" id="coming-soon">
              <span>{i18n.t('Coming soon!')}</span>
            </CustomTooltip>
            {tabs.map((name, index) => (
              <Tab key={`${index}-${name}`} disabled={index === 1}>
                {index === 1 ? (
                  <div data-tip data-for="coming-soon">
                    {i18n.t(name)}
                  </div>
                ) : (
                  i18n.t(name)
                )}
              </Tab>
            ))}
          </TabList>

          <TabPanel>
            <SubContainer>
              <Product
                onChange={onChange}
                {...data}
                assets={assets}
                marketplaces={marketplaces}
                brands={brands}
              />
            </SubContainer>
          </TabPanel>
          <TabPanel>
            <SubContainer>
              <User onChange={onChange} {...data} />
            </SubContainer>
          </TabPanel>
        </Tabs>
      </div>
    </Container>
  );
};

export default JourneySteps;
