import React from 'react';
import QRCode from 'qrcode.react';

const imageSize = 35;

const defaultImageSettings = (size = 300) => ({
  src: require('../../../Rsc/Images/qr-logo.png'),
  height: (size * imageSize) / 100,
  width: (size * imageSize) / 100,
});

export const generateQRUrl = (
  value: string,
  id = 'qr-code',
  hidden = true,
  size = 300,
  level: 'H' | 'L' | 'M' | 'Q' | undefined = 'H',
  imageSettings = defaultImageSettings(size)
) => (
  <div id={id} style={hidden ? { display: 'none' } : {}}>
    <QRCode
      value={value}
      level={level}
      imageSettings={imageSettings}
      size={size}
      renderAs="canvas"
    />
  </div>
);
