import { EAssetActionTypes } from './assetActionsTypes';

const initialState = {
  assets: [],
  currentAsset: {},
  loading: false,
  submitSuccess: false,
  saveSuccess: false,
  assetAttributes: {
    loading: false,
    error: null,
    assetsInput: null,
    assetsOutput: null,
  },
  loadingPreviousOutputs: false,
  previousOutputs: null,
  inputAssets: [],
  outputAssets: [],
  deleteSuccess: false,
};

interface State {
  assets: Array<any>; // TODO Map to backend structure
  currentAsset: any;
  loading: boolean;
  error?: string;
  submitSuccess: boolean;
  saveSuccess: boolean;
  deleteSuccess?: boolean;
  loadingPreviousOutputs?: boolean;
  previousOutputs: Array<any> | null;
  assetAttributes: {
    loading: boolean;
    error: string | null;
    assetsInput: Array<any> | null;
    assetsOutput: Array<any> | null;
  };
}

interface AssetAction extends State {
  type: EAssetActionTypes;
  payload: any;
}

type TAssetReducerActions = AssetAction;

export default function(
  state: State = initialState,
  action: TAssetReducerActions
) {
  switch (action.type) {
    case EAssetActionTypes.GET_ASSETS:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
        submitSuccess: false,
      };
    case EAssetActionTypes.GET_ASSETS_SUCCESS:
      return {
        ...state,
        assets: action.payload.assets,
        loading: false,
        error: null,
      };
    case EAssetActionTypes.GET_ASSETS_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    case EAssetActionTypes.GET_ASSET:
      return { ...state, loading: true, submitSuccess: false };
    case EAssetActionTypes.GET_ASSET_SUCCESS:
      return { ...state, currentAsset: action.payload.asset, loading: false };
    case EAssetActionTypes.GET_ASSET_ERROR:
      return { ...state, error: action.payload.message, loading: false };

    case EAssetActionTypes.SAVE_ASSET:
      return {
        ...state,
        loading: true,
        submitSuccess: false,
        saveSuccess: false,
        error: null,
      };
    case EAssetActionTypes.SAVE_ASSET_SUCCESS:
      return { ...state, submitSuccess: true, loading: false, error: null };
    case EAssetActionTypes.SAVE_ASSET_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        submitSuccess: false,
      };

    case EAssetActionTypes.SAVE_ASSET_TEMPLATE:
      return {
        ...state,
        loading: true,
        submitSuccess: false,
        saveSuccess: false,
        error: null,
      };
    case EAssetActionTypes.SAVE_ASSET_TEMPLATE_SUCCESS:
      const isDraft = action.payload.isDraft;
      let stateProps = {};
      if (isDraft) {
        stateProps = {
          submitSuccess: false,
          currentAsset: action.payload.asset,
        };
      } else {
        stateProps = {
          submitSuccess: !!action.payload.submitted,
        };
      }
      return {
        ...state,
        loading: false,
        error: null,
        saveSuccess: !!action.payload.notifySuccess,
        ...stateProps,
      };
    case EAssetActionTypes.SAVE_ASSET_TEMPLATE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        submitSuccess: false,
        saveSuccess: false,
      };

    case EAssetActionTypes.RESET_CURRENT_ASSET:
      return {
        ...state,
        currentAsset: {},
        submitSuccess: false,
        saveSuccess: false,
        previousOutputs: null,
        inputAssets: [],
        outputAssets: [],
        assets: [],
      };

    case EAssetActionTypes.GET_ASSET_ATTRIBUTES:
      return {
        ...state,
        assetAttributes: {
          ...state.assetAttributes,
          inputAssets: [],
          outputAssets: [],
          loading: true,
          error: null,
        },
      };
    case EAssetActionTypes.GET_ASSET_ATTRIBUTES_INPUT_SUCCESS:
      return {
        ...state,
        assetAttributes: {
          ...state.assetAttributes,
          loading: false,
          assetsInput: action.payload.assets,
          error: null,
        },
      };
    case EAssetActionTypes.GET_ASSET_ATTRIBUTES_OUTPUT_SUCCESS:
      return {
        ...state,
        assetAttributes: {
          ...state.assetAttributes,
          loading: false,
          assetsOutput: action.payload.assets,
          error: null,
        },
      };
    case EAssetActionTypes.GET_ASSET_ATTRIBUTES_ERROR:
      return {
        ...state,
        assetAttributes: {
          ...state.assetAttributes,
          loading: false,
          assets: null,
          error: action.payload.message,
        },
      };

    case EAssetActionTypes.GET_JOURNEY_ASSETS:
      return { ...state, loading: true };
    case EAssetActionTypes.GET_JOURNEY_ASSETS_SUCCESS:
      return {
        ...state,
        inputAssets: action.payload.inputs,
        outputAssets: action.payload.outputs,
        loading: false,
      };
    case EAssetActionTypes.GET_JOURNEY_ASSETS_ERROR:
      return { ...state, error: action.error, loading: false };

    case EAssetActionTypes.DELETE_JOURNEY_ASSETS:
      return {
        ...state,
        loading: true,
        submitSuccess: false,
        saveSuccess: false,
        error: null,
      };
    case EAssetActionTypes.DELETE_JOURNEY_ASSETS_SUCCESS:
      return { ...state, submitSuccess: true, loading: false, error: null };
    case EAssetActionTypes.DELETE_JOURNEY_ASSETS_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        submitSuccess: false,
      };

    case EAssetActionTypes.DELETE_ASSET_TEMPLATE:
      return { ...state, loading: true, error: false };
    case EAssetActionTypes.DELETE_ASSET_TEMPLATE_SUCCESS:
      return { ...state, deleteSuccess: true, loading: false, error: false };
    case EAssetActionTypes.DELETE_ASSET_TEMPLATE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        deleteSuccess: false,
      };

    case EAssetActionTypes.GET_ASSETS_PREVIOUS_STEP:
      return {
        ...state,
        loadingPreviousOutputs: true,
        error: null,
        submitSuccess: false,
        previousOutputs: null,
      };
    case EAssetActionTypes.GET_ASSETS_PREVIOUS_STEP_SUCCESS:
      return {
        ...state,
        previousOutputs: action.payload.inputs,
        loadingPreviousOutputs: false,
        error: null,
      };
    case EAssetActionTypes.GET_ASSETS_PREVIOUS_STEP_ERROR:
      return {
        ...state,
        error: action.error,
        loadingPreviousOutputs: false,
        previousOutputs: [],
      };

    default:
      return state;
  }
}
