import React, { useEffect } from 'react';
import i18n from 'i18next';
import moment from 'moment';
import {
  CampaignReviewSubmitContainer as Container,
  CampaignReviewSubmitItemsContainer as ItemsContainer,
  CampaignReviewSubmitItemsWrapper as ItemsWrapper,
  CampaignReviewSubmitNumberBadge as NumberBadge,
} from './styles';

import { EditableItem, MediaUpload } from '../../../Components';

interface Props {
  onChange: Function;
  formMethods?: any;
  noEndDate?: boolean;
  endDate?: string;
  startDate?: string;
  name?: string;
  description?: string;
  noProductTargeting?: boolean;
  marketableProducts?: Array<any>;
  isBornOnOrAfter?: boolean;
  isBornNoLaterThan?: boolean;
  isNumberOfScans?: boolean;
  isNumberOfReviews?: boolean;
  isSukuEarned?: boolean;
  isCompletedBasicProfile?: boolean;
  isWalletCreated?: boolean;
  isPushNotificationEnabled?: boolean;
  bornOnOrAfter?: Date;
  bornNoLaterThan?: Date;
  numberOfScans?: number;
  numberOfReviews?: number;
  sukuEarned?: number;
  notificationMessage?: string;
  advertItems?: Array<any>;
}

const ClaimReviewSubmit = ({ onChange, ...data }: Props) => {
  const {
    name,
    description,
    noEndDate,
    startDate,
    endDate,
    noProductTargeting = false,
    marketableProducts = [],
    isBornOnOrAfter = false,
    isBornNoLaterThan = false,
    isNumberOfScans = false,
    isNumberOfReviews = false,
    isSukuEarned = false,
    isCompletedBasicProfile = false,
    isWalletCreated = false,
    isPushNotificationEnabled = false,
    bornOnOrAfter,
    bornNoLaterThan,
    numberOfScans,
    numberOfReviews,
    sukuEarned,
    notificationMessage,
    advertItems = [],
    formMethods,
  } = data;

  const { errors, clearError } = formMethods;

  useEffect(() => {
    clearError();
  }, []);

  const detailsError =
    !!errors.name ||
    !!errors.description ||
    !!errors.startDate ||
    !!errors.endDate;

  const productTargetingError = !!errors.marketableProducts;
  const advertError = !!errors.advertItems;

  const campaignDuration = noEndDate
    ? `From ${moment(startDate).format('MM/DD/YYYY')}, not expires`
    : `From ${moment(startDate).format('MM/DD/YYYY')} to ${moment(
        endDate
      ).format('MM/DD/YYYY')}`;

  const selectedMarketableProducts =
    marketableProducts &&
    marketableProducts
      .filter(({ selected }) => selected)
      .map(({ text }) => text);

  const userTargeting = () => {
    const conditions: Array<any> = [];
    isBornOnOrAfter && conditions.push(`Born on or after: ${bornOnOrAfter}`);
    isBornNoLaterThan &&
      conditions.push(`Born no later than: ${bornNoLaterThan}`);
    isNumberOfScans &&
      conditions.push(`Completed number of scans: ${numberOfScans}`);
    isNumberOfReviews &&
      conditions.push(`Completed number of reviews: ${numberOfReviews}`);
    isSukuEarned && conditions.push(`SUKU earned (min): ${sukuEarned}`);
    isCompletedBasicProfile && conditions.push(`Completed basic profile`);
    isWalletCreated && conditions.push(`Wallet created`);
    return conditions;
  };

  return (
    <Container>
      <ItemsWrapper>
        <EditableItem
          incomplete={detailsError}
          title={i18n.t('Summary')}
          collapsible={true}
          linkTo="?tab=Overview"
          content={
            <>
              <EditableItem
                editable={false}
                title={i18n.t('Name')}
                content={name}
              />

              <EditableItem
                editable={false}
                title={i18n.t('Description')}
                content={description}
              />

              <EditableItem
                editable={false}
                title={i18n.t('Duration of the campaign')}
                content={
                  !startDate || (!noEndDate && !endDate) ? '' : campaignDuration
                }
              />
            </>
          }
        />
      </ItemsWrapper>

      <ItemsWrapper>
        <EditableItem
          incomplete={productTargetingError}
          title={i18n.t('Product targeting')}
          collapsible={true}
          linkTo="?tab=Targeting&sub=ProductTargeting"
          content={
            <>
              {noProductTargeting ? (
                <h6>
                  {i18n.t(
                    'No product targeting; notification will be sent to target user'
                  )}
                </h6>
              ) : (
                <EditableItem
                  editable={false}
                  title={i18n.t(
                    'Marketable products to include in this campaign'
                  )}
                  content={
                    selectedMarketableProducts.length > 0 && 
                    <div className="row">
                      {selectedMarketableProducts &&
                        selectedMarketableProducts.map((text, index) => (
                          <p key={index} className="col-md-3 col-4">
                            {text}
                          </p>
                        ))}
                    </div>
                  }
                />
              )}
            </>
          }
        />
      </ItemsWrapper>

      <ItemsWrapper>
        <EditableItem
          title={i18n.t('User targeting')}
          collapsible={true}
          editable={false}
          content={<h6>{i18n.t('Coming soon...')}</h6>}
        />
      </ItemsWrapper>

      <ItemsWrapper>
        <EditableItem
          incomplete={advertError}
          title={i18n.t('Adverts')}
          collapsible={true}
          linkTo="?tab=Advert"
          content={
            advertItems.length > 0 && 
            <>
              {advertItems.map(
                ({
                  advertName,
                  advertStartDate,
                  advertEndDate,
                  advertHasEndDate,
                  advertDescription,
                  advertMedia = [],
                }) => {
                  return (
                    <>
                      <div className="row">
                        <div className="col-md-4 col-2m-12">
                          <EditableItem
                            editable={false}
                            title={i18n.t('Advertisement name')}
                            content={advertName}
                          />
                        </div>
                        <div className="col-md-8 col-2m-12">
                          <EditableItem
                            editable={false}
                            title={i18n.t(
                              'Validity period of this advertisement'
                            )}
                            content={`${i18n.t('From')} ${moment(
                              advertStartDate
                            ).format('MM/DD/YYYY')} ${
                              advertHasEndDate
                                ? `${i18n.t('to')} ${moment(
                                    advertEndDate
                                  ).format('MM/DD/YYYY')}`
                                : `. ${i18n.t('Does not expires')}`
                            }`}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 col-2m-12">
                          <div className="empty"></div>
                        </div>
                        <div className="col-md-8 col-2m-12">
                          <EditableItem
                            editable={false}
                            title={i18n.t('Advertisement description')}
                            content={advertDescription}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 col-2m-12">
                          <div className="empty"></div>
                        </div>
                        <div className="col-md-8 col-2m-12">
                          <EditableItem
                            editable={false}
                            title={i18n.t('Images for this advertisement')}
                            content={
                              <MediaUpload
                                proloadedItemsLinks={advertMedia}
                                readonly={true}
                                showLink={false}
                              />
                            }
                          />
                        </div>
                      </div>
                    </>
                  );
                }
              )}
            </>
          }
        />
      </ItemsWrapper>
    </Container>
  );
};

export default ClaimReviewSubmit;
