import React, { useState } from 'react';
import i18n from 'i18next';
import { MdAdd } from 'react-icons/md';

import ItemControls from './ItemControls';
import { Container, AddItemContainer, AddItemIcon } from './styles';
import { FormInput } from '..';

type ListType = 'primary' | 'readonly';

interface Props {
  items: any;
  setItems: (value: Array<any>) => void;
  listType?: ListType;
  placeholderLeft?: string;
  placeholderRight?: string;
  confirmDelete?: boolean;
  deleteTitle?: string;
  deleteContent?: any;
}

const DynamicList = ({
  items,
  setItems,
  listType = 'primary',
  placeholderLeft,
  placeholderRight,
  confirmDelete,
  deleteTitle,
  deleteContent,
}: Props) => {
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [selectedRow, setSelectedRow] = useState(-1);

  const addItem = () => {
    setItems([...items, { title: '', description: '' }]);
  };

  const handleChange = (index: number, name: string, value: any) => {
    const updatedItems = items.map((el, idx) =>
      idx === index ? { ...el, [name]: value } : el
    );
    setItems(updatedItems);
  };

  if (listType === 'primary') {
    return (
      <Container>
        <ul>
          {items.map((item, index) => (
            <li
              key={index}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(-1)}
              onClick={() => setSelectedRow(index)}
              className={selectedRow === index ? 'selected' : ''}
            >
              <div className="first-value">
                <FormInput
                  styleType="border"
                  placeholder={placeholderLeft}
                  value={item.title}
                  defaultValue={item.title}
                  name="title"
                  onChange={evt =>
                    handleChange(index, evt.target.name, evt.target.value)
                  }
                />
              </div>
              <div className="second-value">
                <FormInput
                  type="textarea"
                  styleType="border"
                  showChars={false}
                  placeholder={placeholderRight}
                  value={item.description}
                  name="description"
                  onChange={evt =>
                    handleChange(index, evt.target.name, evt.target.value)
                  }
                />
              </div>
              {hoverIndex === index && (
                <ItemControls
                  number={index}
                  itemsList={items}
                  setItemsList={setItems}
                  confirmDelete
                  deleteTitle={deleteTitle}
                  deleteContent={deleteContent}
                />
              )}
            </li>
          ))}
        </ul>
        <AddItemContainer onClick={() => addItem()}>
          <span>{i18n.t('Add Row')}</span>
          <AddItemIcon>
            <MdAdd color="#fff" />
          </AddItemIcon>
        </AddItemContainer>
      </Container>
    );
  }
  if (listType === 'readonly') {
    return (
      <Container>
        <ul className="readonly">
          {items.map((item, index) => (
            <li key={index}>
              <div className="first-value">{item.title}</div>
              <div className="second-value">{item.description}</div>
            </li>
          ))}
        </ul>
      </Container>
    );
  }
  return <div></div>; // Use it for alternative list types
};

export default DynamicList;
