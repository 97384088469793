import React from 'react';
import styled from 'styled-components';

interface Props {
  text?: string;
  email?: string;
}

const Container = styled.div`
  align-items: center;
  justify-content: center;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  flex-direction: column;
`;

const Wave = styled.div`
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='1440' height='215' viewBox='0 0 1440 215' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.52353e-05 214.318H1440C1440 214.318 1440 -73.0798 1440 17.8142C1325.97 90.0245 1143.32 119.818 913.245 119.818C518.178 119.818 338.556 17.8142 3.42707e-05 17.8142C-3.7158e-05 -67.0202 2.52353e-05 214.318 2.52353e-05 214.318Z' fill='black'/%3E%3C/svg%3E%0A");
  background-size: cover;
  height: 215px;
  width: 100%;
  margin: 0;
`;

const InfoContainer = styled.div`
  text-align: center;
  background-color: #000;
  margin: -10px 0 0 0;
  padding-bottom: 32px;
`;

const TextContainer = styled.span`
  color: #fff;
`;

const EmailLink = styled.a`
  margin-left: 5px;
  color: #d8f40c;
  &:hover {
    color: #a7bf2e;
    text-decoration: none;
  }
`;

const ContactFooter = ({ text, email }: Props) => {
  return (
    <Container>
      <Wave></Wave>
      <InfoContainer>
        {text && <TextContainer>{text}</TextContainer>}
        {email && <EmailLink href={`mailto:${email}`}>{email}</EmailLink>}
      </InfoContainer>
    </Container>
  );
};

export default ContactFooter;
