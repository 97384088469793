import React, { useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';
import { Campaign } from '../../../Lib/Utils/Validations';

import {
  DetailsFormContainer as FormContainer,
  DetailsContainer as Container,
} from './styles';

import {
  Label,
  FormInput,
  InfoIcon,
  Tooltip,
  Checkbox,
  DatePicker,
} from '../../../Components';

interface Props {
  onChange: Function;
  noEndDate?: boolean;
  endDate?: string;
  startDate?: string;
  name?: string;
  description?: string;
}

const CampaignDetails = ({ onChange, ...data }: Props) => {
  const { name, description, noEndDate = false, startDate, endDate } = data;

  const {
    errors,
    setError,
    clearError,
    control,
    setValue,
    getValues,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setValue([
      { name: name },
      { description: description },
      { noEndDate: noEndDate },
      { startDate: startDate },
      { endDate: endDate },
    ]);
  }, [name, description, noEndDate, startDate, endDate]);

  return (
    <Container>
      <FormContainer className="row">
        <div className="col-12">
          <Label>{i18n.t('Name')}</Label>

          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the campaign name')}
                maxLength={75}
              />
            }
            name="name"
            value={name}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Campaign.name}
            defaultValue={name}
          />
          {errors.name && (
            <span className="inlineErrorMessage">{errors.name.message}.</span>
          )}
        </div>

        <div className="col-12">
          <Label>{i18n.t('Description')}</Label>

          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Defines the campaign description')}
                type="textarea"
                maxLength={250}
              />
            }
            name="description"
            value={description}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Campaign.description}
            defaultValue={description}
          />
          {errors.description && (
            <span className="inlineErrorMessage">
              {errors.description.message}.
            </span>
          )}
        </div>

        <div className="col-12">
          <Label>
            {i18n.t('What is the duration of the campaign')}
            <Tooltip
              text={i18n.t('All adverts will expire when the campaign expires')}
            >
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </Label>
        </div>

        <div className="col-12 col-lg-2 mt-0">
          <div className="light-label">{i18n.t('Start date')}</div>

          <Controller
            as={
              <DatePicker
                placeholder={i18n.t('Select')}
                required
                value={startDate}
                onChange={value => onChange('startDate', value)}
              />
            }
            name="startDate"
            value={startDate}
            control={control}
            onChange={([value]) => {
              onChange('startDate', value);
              triggerValidation(['startDate', 'endDate']);

              return value;
            }}
            rules={Campaign.startDate()}
            defaultValue={startDate}
          />
          {errors.startDate && (
            <span className="inlineErrorMessage">
              {errors.startDate.message}.
            </span>
          )}
        </div>
        <div
          className="col-12 col-lg-2 mt-0"
          style={{ opacity: noEndDate ? 0.5 : 1 }}
        >
          <div className="light-label">{i18n.t('End date')}</div>

          <Controller
            as={
              <DatePicker
                placeholder={i18n.t('Select')}
                disabled={noEndDate}
                value={endDate}
                onChange={value => onChange('endDate', value)}
              />
            }
            name="endDate"
            value={endDate}
            control={control}
            onChange={([value]) => {
              onChange('endDate', value);
              triggerValidation('endDate');
              return value;
            }}
            rules={Campaign.endDate(getValues)}
            defaultValue={endDate}
          />
          {errors.endDate && (
            <span className="inlineErrorMessage">
              {errors.endDate.message}.
            </span>
          )}
        </div>
        <div className="col-12 col-lg-2 mt-0">
          <div className="checkbox-wrapper">
            <div className="light-label">
              <Label>
                <Controller
                  as={<Checkbox name="noEndDate" checked={noEndDate} />}
                  name="noEndDate"
                  value={noEndDate}
                  control={control}
                  onChange={([evt]) => {
                    onChange(evt.target.name, evt.target.checked);
                    setValue('noEndDate', evt.target.checked);
                    triggerValidation('endDate');
                    return evt.target.checked;
                  }}
                  defaultValue={noEndDate}
                />

                {i18n.t('No end date')}
              </Label>
            </div>
          </div>
        </div>
      </FormContainer>
    </Container>
  );
};

export default CampaignDetails;
