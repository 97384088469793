import * as stepService from '../../Services/Api/stepService';
import { ClientError } from '../../Lib/Utils/exceptions';
import { tap } from '../../Lib/Utils/tap';
import { Action } from 'redux';
import { RootState } from '../Reducers';
import { createAction } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { EStepActionTypes } from './stepActionsType';

const saveStep = createAction(EStepActionTypes.SAVE_STEP);
const saveStepSuccess = createAction(
  EStepActionTypes.SAVE_STEP_SUCCESS,
  (step: any) => ({ step })
);
const saveStepError = createAction(
  EStepActionTypes.SAVE_STEP_ERROR,
  (message: string) => ({ message })
);

const getStepTemplate = createAction(EStepActionTypes.GET_STEP_TEMPLATE);
const getStepTemplateSuccess = createAction(
  EStepActionTypes.GET_STEP_TEMPLATE_SUCCESS,
  (stepTemplate: any) => ({ stepTemplate })
);
const getStepTemplateError = createAction(
  EStepActionTypes.GET_STEP_TEMPLATE_ERROR,
  (message: string) => ({ message })
);

export const doSaveStep = (
  payload
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveStep());
  return await tap(
    stepService.saveStep(payload),
    response => {
      dispatch(saveStepSuccess(response.data.journey));
    },
    error => {
      const _message = 'Failed to save Step. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveStepError(message));
    }
  );
};

export const doUpdateStep = (
  stepId,
  payload
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(saveStep());
  return await tap(
    stepService.updateStep(stepId, payload),
    response => {
      dispatch(saveStepSuccess(response.data));
    },
    error => {
      const _message = 'Failed to save Step. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(saveStepError(message));
    }
  );
};

export const doGetStepTemplate = (
  stepTemplateId: string
): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
  dispatch(getStepTemplate());
  return await tap(
    stepService.getStepTemplate(stepTemplateId),
    response => {
      dispatch(getStepTemplateSuccess(response.data ? response.data.data : {}));
    },
    error => {
      const _message = 'Failed to retrieve Claims. Please try again later';
      const message =
        error instanceof ClientError ? _message : 'Internal Error';
      dispatch(getStepTemplateError(message));
    }
  );
};
