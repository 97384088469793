import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  subtitle?: string;
  subtitleArray?: Array<string>;
  icon?: any;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #303030;
  margin-bottom: 16px;
  letter-spacing: 1px;

  .title {
    text-transform: uppercase;
    margin-left: 10px;
  }
  .arrow {
    margin: 0 10px;
    color: #a7bf2e;
  }

  @media (max-width: 1400px) {
    font-size: 16px;
  }
`;

const Title = ({ title, subtitle, subtitleArray, icon }: Props) => {
  return (
    <Container>
      {icon}
      <div className="title">{title}</div>
      {subtitle && (
        <>
          <div className="arrow"> {`>`} </div>
          <div className="subtitle">{subtitle}</div>
        </>
      )}
      {subtitleArray &&
        subtitleArray.map(sub => (
          <>
            <div className="arrow"> {`>`} </div>
            <div className="subtitle">{sub}</div>
          </>
        ))}
    </Container>
  );
};

export default Title;
