import React from 'react';

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1111 0V6.66667H20V0H11.1111ZM11.1111 20H20V8.88889H11.1111V20ZM0 20H8.88889V13.3333H0V20ZM0 11.1111H8.88889V0H0V11.1111Z"
    />
  </svg>
);

