import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import moment from 'moment';
import { doGetFacilities } from '../../../Redux/Facility/facilityActions';
import { linkToTransaction } from '../../../Services/Api/authExplorer';
import { doGetClaims } from '../../../Redux/Claim/claimActions';

import {
  doGetJourneys,
  doGetJourneyTemplate,
} from '../../../Redux/Journey/journeyActions';
import VerticalTable, {
  VerticalTableProps,
} from '../../../Components/VerticalTable';

interface RootState {
  journey: any;
  facility: any;
  claim: any;
}

const mapState = (state: RootState) => ({
  currentJourney: state.journey.journeys,
  currentTemplate: state.journey.currentTemplate,
  facilities: state.facility.facilities,
  claims: state.claim.claims || [],
  loading: state.journey.loading,
  error: state.journey.error,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getJourneyAction: journeyId => dispatch(doGetJourneys(journeyId)),
  getJourneyTemplateAction: (journeyTemplateId, actionType) =>
    dispatch(doGetJourneyTemplate(journeyTemplateId, actionType)),
  getFacilitiesAction: () => dispatch(doGetFacilities()),
  getClaimsAction: includeDrafts => dispatch(doGetClaims(null, includeDrafts)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { history?: any; location?: any; match?: any };

type ClaimListProps = Array<{
  name: string;
  link?: string;
}>;
const ClaimList = ({ rows }: { rows: ClaimListProps }) => {
  return (
    <ul className="m-3">
      {rows &&
        rows.map((row, rowKey) => (
          <li key={rowKey}>
            {row.link ? (
              <a target="blank" href={row.link}>
                {row.name}
              </a>
            ) : (
              <span>{row.name}</span>
            )}
          </li>
        ))}
    </ul>
  );
};

const ProofJourney = (props: Props) => {
  const {
    match,
    getJourneyAction,
    getFacilitiesAction,
    getJourneyTemplateAction,
    getClaimsAction,
    facilities,
    claims,
    currentJourney,
    currentTemplate,
  } = props;

  const [proofRows, setProofRows] = useState([] as VerticalTableProps);
  const [lotProofRows, setlotProofRows] = useState([] as VerticalTableProps);
  const [lotRows, setLotRows] = useState([] as VerticalTableProps);
  const [stepTables, setStepTables] = useState([] as Array<VerticalTableProps>);

  useEffect(() => {
    getJourneyAction(match.params.journeyId);
    getFacilitiesAction();
    getJourneyTemplateAction(match.params.journeyTemplateId, 'get-entity');
    getClaimsAction(false);
  }, []);

  useEffect(() => {
    if (currentTemplate) {
      const { tx } = currentTemplate;
      if (tx && tx.status === 'success')
        setProofRows([
          {
            title: i18n.t('Transaction Address'),
            value: tx.txAddress,
            link: linkToTransaction(tx.txAddress),
          },
          {
            title: i18n.t('Transaction Hash'),
            value: tx.txHash,
            link: linkToTransaction(tx.txAddress),
          },
          {
            title: i18n.t('Transaction Network'),
            value: tx.network,
            link: linkToTransaction(tx.txAddress),
          },
        ]);
    }
  }, [currentTemplate]);

  useEffect(() => {
    if (currentJourney && facilities) {
      const { tx } = currentJourney;
      if (tx && tx.status === 'success')
        setlotProofRows([
          {
            title: i18n.t('Transaction Address'),
            value: tx.txAddress,
            link: linkToTransaction(tx.txAddress),
          },
          {
            title: i18n.t('Transaction Hash'),
            value: tx.txHash,
            link: linkToTransaction(tx.txAddress),
          },
          {
            title: i18n.t('Transaction Network'),
            value: tx.network,
            link: linkToTransaction(tx.txAddress),
          },
        ]);

      setLotRows([
        { title: i18n.t('Lot ID'), value: currentJourney.lotId },
        {
          title: i18n.t('Start Date'),
          value: moment(currentJourney.createdAt).format('MMM Do YY'),
        },
        {
          title: i18n.t('End Date'),
          value: moment(currentJourney.updatedAt).format('MMM Do YY'),
        },
      ]);
      if (currentJourney.steps) {
        const stepsTables = [] as Array<VerticalTableProps>;
        for (const value of currentJourney.steps) {
          const { step } = value;
          const facility = facilities.find(
            facility => facility._id === step.facility._id
          );
          if (facility) {
            const stepRows = [
              { title: i18n.t('Facility'), value: facility.registeredName },
              { title: i18n.t('Description'), value: step.description },
              {
                title: i18n.t('Email'),
                value: facility.primaryContact.email.address,
              },
              {
                title: i18n.t('Phone'),
                value: facility.primaryContact.phone.number,
              },
              {
                title: i18n.t('Web'),
                value: facility.url ? facility.url[0] : 'Unavailable',
              },
            ] as VerticalTableProps;
            const { tx } = step;
            if (tx && tx.status === 'success')
              stepRows.push({
                title: i18n.t('Transaction Hash'),
                value: tx.txHash,
                link: linkToTransaction(tx.txAddress),
              });
            const facilityClaims = claims.filter(
              claim => claim.facilityId === facility._id
            );
            if (facilityClaims)
              stepRows.push({
                title: i18n.t('Claims'),
                value: <ClaimList rows={facilityClaims} />,
              });
            stepsTables.push(stepRows);
          }
        }
        setStepTables(stepsTables);
      }
    }
  }, [currentJourney, facilities]);

  return (
    <>
      <h2>{i18n.t('Journet Template Blockchain Proof')}</h2>
      <VerticalTable rows={proofRows} />
      <hr />
      <h2>{i18n.t('Lot Blockchain Proof')}</h2>
      <VerticalTable rows={lotProofRows} />
      <hr />
      <h2>{i18n.t('Lot information')}</h2>
      <VerticalTable rows={lotRows} />
      <hr />
      <h2>{i18n.t('Steps')}</h2>
      {stepTables &&
        stepTables.map((table, tableKey) => (
          <div key={tableKey}>
            <h3>{table[0].value}</h3>
            <VerticalTable rows={table} />
            <hr />
          </div>
        ))}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(ProofJourney) as any) as any
);
