import { EClaimActionTypes } from './claimActionsTypes';

const initialState = {
  claims: null,
  attestors: [],
  currentClaim: null,
  currentAttestor: {},
  loadingClaims: false,
  loadingAttestors: false,
  addVerification: {
    loading: false,
    error: null,
    verification: null,
  },
  submitSuccess: false,
  saveSuccess: false,
  deleteSuccess: false,
  loading: false,
};

interface State {
  claims: Array<any> | null;
  attestors: Array<any>;
  currentClaim: any;
  loadingClaims: boolean;
  loadingAttestors: boolean;
  error?: string;
  addVerification: {
    loading: boolean;
    error: string | null;
    verification?: any;
  };
  submitSuccess?: boolean;
  saveSuccess?: boolean;
  deleteSuccess?: boolean;
  loading?: boolean;
}

interface ClaimAction extends State {
  type: EClaimActionTypes;
  payload: any;
}

type TClaimReducerActions = ClaimAction;

export default function(
  state: State = initialState,
  action: TClaimReducerActions
) {
  switch (action.type) {
    case EClaimActionTypes.GET_CLAIMS:
      return {
        ...state,
        claims: null,
        loading: true,
        error: null,
        currentClaim: null,
        deleteSuccess: false,
      };
    case EClaimActionTypes.GET_CLAIMS_SUCCESS:
      return {
        ...state,
        claims: action.payload.claims,
        loading: false,
        error: null,
      };
    case EClaimActionTypes.GET_CLAIMS_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        claims: [],
      };

    case EClaimActionTypes.GET_CLAIM:
      return { ...state, currentClaim: null, loadingClaim: true, error: null };
    case EClaimActionTypes.GET_CLAIM_SUCCESS:
      return {
        ...state,
        currentClaim: action.payload.claim,
        loadingClaim: false,
        error: null,
      };
    case EClaimActionTypes.GET_CLAIM_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loadingClaim: false,
        currentClaim: null,
      };

    case EClaimActionTypes.ADD_CLAIM_VERIFICATION:
      return {
        ...state,
        addVerification: {
          ...state.addVerification,
          loading: true,
          error: null,
          verification: null,
        },
      };

    case EClaimActionTypes.ADD_CLAIM_VERIFICATION_SUCCESS:
      return {
        ...state,
        addVerification: {
          ...state.addVerification,
          loading: false,
          error: null,
          verification: action.payload.addVerification,
        },
      };

    case EClaimActionTypes.ADD_CLAIM_VERIFICATION_ERROR:
      return {
        ...state,
        addVerification: {
          ...state.addVerification,
          loading: false,
          error: action.payload.message,
          verification: null,
        },
      };

    case EClaimActionTypes.SAVE_CLAIM:
      return {
        ...state,
        loading: true,
        error: null,
        submitSuccess: false,
        saveSuccess: false,
      };
    case EClaimActionTypes.SAVE_CLAIM_SUCCESS: {
      const isDraft = action.payload.isDraft;
      let stateProps = {};
      if (isDraft) {
        stateProps = {
          submitSuccess: false,
          currentClaim: action.payload.claim,
        };
      } else {
        stateProps = { submitSuccess: !!action.payload.submitted };
      }
      return {
        ...state,
        loading: false,
        error: null,
        saveSuccess: !!action.payload.notifySuccess,
        ...stateProps,
      };
    }
    case EClaimActionTypes.SAVE_CLAIM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        submitSuccess: false,
        saveSuccess: false,
        currentClaim: null,
      };
    case EClaimActionTypes.SAVE_CLAIM_CLEAR:
      return {
        ...state,
        loading: false,
        error: null,
        submitSuccess: false,
        saveSuccess: false,
        currentClaim: null,
      };

    case EClaimActionTypes.DELETE_CLAIM:
      return { ...state, loading: true, error: false };
    case EClaimActionTypes.DELETE_CLAIM_SUCCESS:
      return { ...state, deleteSuccess: true, loading: false, error: false };
    case EClaimActionTypes.DELETE_CLAIM_ERROR:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        deleteSuccess: false,
      };

    case EClaimActionTypes.RESET_CLAIMS:
      return {
        ...state,
        claims: null,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
}
