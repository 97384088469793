import React, { useEffect } from 'react';
import i18n from 'i18next';

import {
  AttestorReviewSubmitContainer as Container,
  AttestorReviewSubmitItemsWrapper as ItemsWrapper,
} from './styles';

import { Label, EditableItem, Link } from '../../../Components';

import { Salutations, PhoneTypes } from '../../../Lib/Configs';

interface Props {
  attestorShortName?: string;
  attestorCompany?: string;
  attestorSalutation?: string;
  attestorFirstName?: string;
  attestorLastName?: string;
  attestorWebsite?: string;
  attestorEmail?: string;
  attestorEmailConfirmation?: string;
  attestorContactFirstLoad?: boolean;
  attestorCountryCode?: string;
  attestorPhoneType?: string;
  attestorPhone?: string;
  attestorPhoneExt?: string;
  attestorNotes?: string;
  formMethods: any;
  isDraft?: boolean;
  entityId?: string;
}

const AttestorReviewSubmit = ({
  formMethods,
  isDraft,
  entityId,
  ...data
}: Props) => {
  const {
    attestorShortName,
    attestorCompany,
    attestorSalutation,
    attestorFirstName,
    attestorLastName,
    attestorWebsite,
    attestorEmail,
    attestorEmailConfirmation,
    attestorCountryCode,
    attestorPhoneType,
    attestorPhone,
    attestorPhoneExt,
    attestorNotes,
    attestorContactFirstLoad,
  } = data;

  const { errors, clearError } = formMethods;

  useEffect(() => {
    clearError();
  }, []);

  const contactErrors =
    !!errors.attestorShortName ||
    !!errors.attestorCompany ||
    !!errors.attestorSalutation ||
    !!errors.attestorFirstName ||
    !!errors.attestorLastName ||
    !!errors.attestorWebsite ||
    !!errors.attestorEmail ||
    !!errors.attestorEmailConfirmation ||
    !!errors.attestorCountryCode ||
    !!errors.attestorPhoneType ||
    !!errors.attestorPhone ||
    !!errors.attestorPhoneExt ||
    !!errors.attestorContactFirstLoad;

  const attestorName =
    (attestorSalutation || attestorFirstName || attestorLastName) &&
    `${attestorSalutation && attestorSalutation} ${attestorFirstName &&
      attestorFirstName} ${attestorLastName && attestorLastName}`;

  const baseUrl = isDraft
    ? entityId
      ? `/claims/attestors/${entityId}/draft`
      : '/claims/attestors/draft'
    : `/claims/attestors/${entityId}/edit`;

  return (
    <Container>
      <ItemsWrapper>
        <EditableItem
          incomplete={contactErrors}
          title={i18n.t('Contact')}
          linkTo={`${baseUrl}?tab=Contact`}
          content={
            <>
              <div className="row">
                <div className="col-md-9 col-sm-12">
                  <EditableItem
                    title={i18n.t('Short name')}
                    content={attestorShortName}
                    editable={false}
                  />
                </div>
                <div className="col-md-3 col-sm-12">
                  <EditableItem
                    title={i18n.t('Company')}
                    content={attestorCompany}
                    editable={false}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <EditableItem
                    title={i18n.t('Attestor name')}
                    content={attestorName}
                    editable={false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <EditableItem
                    title={i18n.t('Email')}
                    content={attestorEmail}
                    editable={false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <EditableItem
                    title={i18n.t('Phone number')}
                    content={attestorPhone}
                    editable={false}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <EditableItem
                    title={i18n.t('Website')}
                    content={attestorWebsite && <Link url={attestorWebsite} />}
                    editable={false}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <EditableItem
                    title={i18n.t('Notes')}
                    content={attestorNotes}
                    editable={false}
                  />
                </div>
              </div>
            </>
          }
        />
      </ItemsWrapper>
    </Container>
  );
};

export default AttestorReviewSubmit;
