import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { doGetUsers } from '../../../Redux/User/userActions';
import {
  Table,
  HeaderFilter,
  InProgressButton,
  Loading,
} from '../../../Components';
import { Container } from './styles';
import Toast from '../../../Lib/Utils/toast';
import moment from 'moment';
import { FaUserAlt } from 'react-icons/fa';
import { isOrgAdmin } from '../../../Lib/Utils/auth';

interface RootState {
  user: any;
}

const mapState = (state: RootState) => ({
  users: state.user.users,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  getUsersAction: (includeDrafts, includeAdmins) => dispatch(doGetUsers(includeDrafts, includeAdmins)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { history?: any; location?: any };

const UsersList = (props: Props) => {
  const { history, users, loading, error, getUsersAction, location } = props;

  const [rows, setRows] = useState([] as any);
  const [filteredRows, setFilteredRows] = useState([] as any);
  const [filters, setFilters] = useState({});
  const [textFilter, setTextFilter] = useState('');

  useEffect(() => {
    getUsersAction(true, true);
  }, []);

  useEffect(() => {
    const defaultRows = users
      .sort((a, b) =>
        moment(a.createdTimestamp || a.createdAt).isBefore(
          moment(b.createdTimestamp || a.createdAt)
        )
          ? 1
          : -1
      )
      .map(user => {
        const {
          id,
          _id,
          firstName,
          lastName,
          email,
          username,
          isDraft,
          createdTimestamp,
          createdAt,
        } = user;
        return {
          id: isDraft ? _id : id?.replace(new RegExp(/\-/g), ''),
          name: `${firstName || ''} ${lastName || ''}`,
          email: email || username,
          createdAt: moment(createdTimestamp || createdAt).format('MM/DD/YYYY'),
          inProgress: false,
          objectId: isDraft ? _id : id,
        };
      })
      .map(row => Object.values(row));
    setRows(defaultRows);
  }, [users]);

  const headers = [
    { text: i18n.t('ID'), order: true },
    { text: i18n.t('Name'), order: true },
    { text: i18n.t('Email'), order: true },
    { text: i18n.t('Created'), order: true },
    { text: '', order: false, key: 'inProgress' },
  ];

  const inputHandler = value => {
    setTextFilter(value);
  };

  const handleResetFilters = () => {
    setFilters({});
  };

  const applyFilters = () => {
    let newFilteredRows = rows;

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        if (key === 'inprogress') {
          newFilteredRows = newFilteredRows.filter(row => !!row[4]);
        }
      }
    });

    if (textFilter && textFilter !== '') {
      newFilteredRows = newFilteredRows.filter(
        row =>
          row
            .join(' ')
            .toUpperCase()
            .search(textFilter.toUpperCase()) !== -1
      );
    }

    setFilteredRows(newFilteredRows);
  };

  useEffect(() => applyFilters(), [filters, textFilter, rows]);

  return (
    <>
      <Container>
        <HeaderFilter
          title={i18n.t('Existing Users')}
          titleIcon={<FaUserAlt color="rgb(216, 244, 12)" />}
          searchPlaceholder={i18n.t('Search all columns')}
          actionButtonText={`+ ${i18n.t('New User')}`}
          setFilters={setFilters}
          inputHandler={value => inputHandler(value)}
          actionButtonHandler={() => history.push('/users/draft')}
          selects={[]}
          resetFiltersHandler={handleResetFilters}
          name="users"
          withFilters={false}
          actionButton={!!isOrgAdmin()}
        />
        <Table
          headers={headers}
          rows={filteredRows}
          pagination
          rowsPerPage={20}
          pagesBtw={2}
          ignoreKeys={[5]}
          editable={!!isOrgAdmin()}
          handleEdit={data => {
            data[4]
              ? history.push(`/users/${data[5]}/draft`)
              : history.push(`/users/${data[5]}/edit`);
          }}
        />
      </Container>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(UsersList) as any) as any
) as any;
