export enum EFacilityActionTypes {
  GET_FACILITIES = 'GET_FACILITIES',
  GET_FACILITIES_SUCCESS = 'GET_FACILITIES_SUCCESS',
  GET_FACILITIES_ERROR = 'GET_FACILITIES_ERROR',

  SAVE_FACILIY = 'SAVE_FACILIY',
  SAVE_FACILIY_SUCCESS = 'SAVE_FACILIY_SUCCESS',
  SAVE_FACILIY_ERROR = 'SAVE_FACILIY_ERROR',

  GET_FACILITY = 'GET_FACILITY',
  GET_FACILITY_SUCCESS = 'GET_FACILITY_SUCCESS',
  GET_FACILITY_ERROR = 'GET_FACILITY_ERROR',

  RESET_CURRENT_FACILITY = 'RESET_CURRENT_FACILITY',

  DELETE_FACILITY = 'DELETE_FACILITY',
  DELETE_FACILITY_SUCCESS = 'DELETE_FACILITY_SUCCESS',
  DELETE_FACILITY_ERROR = 'DELETE_FACILITY_ERROR',
}
