import React from 'react';

const Alert = () => (
  <svg
    width="30"
    height="25"
    viewBox="0 0 30 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 0L0 25H30L15 0ZM15 5.26316L25.2682 22.3684H4.73182L15 5.26316ZM13.6364 10.5263V15.7895H16.3636V10.5263H13.6364ZM13.6364 18.4211V21.0526H16.3636V18.4211"
      fill="#D8F40C"
    />
  </svg>
);

export default Alert;
