export enum ECatalogActionTypes {
  GET_BRANDS = 'GET_BRANDS',
  GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS',
  GET_BRANDS_ERROR = 'GET_BRANDS_ERROR',

  GET_IDENTIFIER_TYPES = 'GET_IDENTIFIER_TYPES',
  GET_IDENTIFIER_TYPES_SUCCESS = 'GET_IDENTIFIER_TYPES_SUCCESS',
  GET_IDENTIFIER_TYPES_ERROR = 'GET_IDENTIFIER_TYPES_ERROR',

  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR',

  GET_MARKETPLACES = 'GET_MARKETPLACES',
  GET_MARKETPLACES_SUCCESS = 'GET_MARKETPLACES_SUCCESS',
  GET_MARKETPLACES_ERROR = 'GET_MARKETPLACES_ERROR',

  GET_CLAIM_TYPES = 'GET_CLAIM_TYPES',
  GET_CLAIM_TYPES_SUCCESS = 'GET_CLAIM_TYPES_SUCCESS',
  GET_CLAIM_TYPES_ERROR = 'GET_CLAIM_TYPES_ERROR',

  GET_CATALOGEDASSET = 'GET_CATALOGEDASSET',
  GET_CATALOGEDASSET_INPUT_SUCCESS = 'GET_CATALOGEDASSET_INPUT_SUCCESS',
  GET_CATALOGEDASSET_OUTPUT_SUCCESS = 'GET_CATALOGEDASSET_OUTPUT_SUCCESS',
  GET_CATALOGEDASSET_ERROR = 'GET_CATALOGEDASSET_ERROR',

  GET_ASSETCATALOG_INPUT_SUCCESS = 'GET_ASSETCATALOG_INPUT_SUCCESS',
  GET_ASSETCATALOG_OUTPUT_SUCCESS = 'GET_ASSETCATALOG_OUTPUT_SUCCESS',
  GET_ASSETCATALOG = 'GET_ASSETCATALOG',
  GET_ASSETCATALOG_ERROR = 'GET_ASSETCATALOG_ERROR',
}
