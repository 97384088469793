import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: relative;
  width: 150px;
  > div.DayPickerInput {
    width: 100%;
  }
  align-items: center;
  &.disabled {
    svg {
      path {
        fill: rgba(117, 117, 117, 0.5) !important;
      }
    }
  }
  .DayPickerInput {
    z-index: 2;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  pointer-events: none;
  svg {
    path {
      fill: #757575;
    }
  }
`;

const Overlay = styled.div`
  z-index: 100;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  background: #f9f9fb;
  border-radius: 8px;
  position: absolute;
  left: 100px;
  overflow: visible;
  margin-top: 14px;
  font-family: Encode Sans;
  ::after {
    content: '';
    z-index: -1;
    position: absolute;
    left: 20px;
    top: -15px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #a7bf2e;
  }

  .DayPicker-Month {
    display: flex;
    flex-direction: column;
  }

  .DayPicker-wrapper {
    padding: 0px;
  }

  .DayPicker-Caption {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    align-items: center;
    color: #303030;
    margin-bottom: 25px;
    padding: 0px;
    padding-right: 70px;

    div {
      flex-wrap: nowrap;
    }

    display: flex;
    font-size: 10px;
    > div {
      margin-right: 10px;
      flex: 1;
      height: 38px;
    }
    .select-input > div {
      /* padding-left: 10px; */
    }
  }

  .DayPicker-Weekday {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);
  }

  .DayPicker-Day {
    outline: none;
    padding: 0.5em 0.7em;
    &:focus {
      outline: none;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #a7bf2e;
  }
`;

const WeekDayContainer = styled.div`
  font-family: Encode Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: rgba(117, 117, 117, 0.5);
`;

const NavBarContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 15px;
  display: flex;
  height: 38px;
  align-items: center;
  button {
    cursor: pointer;
    margin-left: 10px;
    border: none;
    outline: none;
    background: #a7bf2e;
    border-radius: 100%;
    color: #fff;
    font-family: Encode Sans;
    text-align: center;
    font-weight: bolder;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: none;
    }
  }
`;

export { Container, Overlay, IconContainer, WeekDayContainer, NavBarContainer };
