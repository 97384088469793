import React, { useState } from 'react';
import styled from 'styled-components';
import EnumItem from './types';
import Step from './Step';

interface Props {
  steps?: Array<EnumItem> | null;
}

const numberSort = ({ number: numberA }, { number: numberB }) => {
  if (numberA === -1) return 1;
  if (numberB === -1) return -1;

  if (numberA > numberB) {
    return 1;
  }
  if (numberB > numberA) {
    return -1;
  }
  return 0;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const JourneyStepsPreview = ({ steps }: Props) => {
  const findDefaultOpen = steps && steps.find(step => step.open);
  const [openStep, setOpenStep] = useState(
    findDefaultOpen ? findDefaultOpen.number : null
  );

  const handleOpenStep = itemIndex => {
    if (openStep === itemIndex) {
      setOpenStep(null);
    } else {
      setOpenStep(itemIndex);
    }
  };

  return (
    <Container>
      {steps &&
        steps
          .sort(numberSort)
          .map(
            (
              {
                number,
                name,
                value,
                icon,
                status,
                open,
                inboundAssets,
                outboundAssets,
                queryLink,
                ...rest
              },
              index
            ) => {
              return (
                <Step
                  number={index}
                  key={`${index}-${name}`}
                  name={name}
                  value={value}
                  icon={icon}
                  step={rest}
                  inboundAssets={inboundAssets}
                  outboundAssets={outboundAssets}
                  status={status}
                  open={index === openStep}
                  openAction={handleOpenStep}
                  queryLink={queryLink}
                />
              );
            }
          )}
    </Container>
  );
};

export default JourneyStepsPreview;
