import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm, Controller } from 'react-hook-form';

import { FacilityMediaContainer as Container } from './styles';

import {
  Label,
  MediaUpload,
  CustomTooltip,
  InfoIcon,
} from '../../../Components';

interface Props {
  onChange: Function;
  photos?: Array<any>;
  videos?: Array<any>;
  assetMediaFirstLoad?: boolean;
}

const FacilityMedia = ({ onChange, ...data }: Props) => {
  const { photos = [], videos = [], assetMediaFirstLoad = true } = data;

  const {
    errors,
    setValue,
    register,
    setError,
    clearError,
    triggerValidation,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    assetMediaFirstLoad === false && triggerValidation();
    onChange('assetMediaFirstLoad', false);
  }, []);

  useEffect(() => {
    register({ name: 'photos' });
  }, [register]);

  useEffect(() => {
    setValue('photos', photos);
    if (photos.length === 0) {
      setError('photos', 'required', i18n.t('At least one photo is required'));
    } else {
      clearError('photos');
    }
  }, [photos]);

  return (
    <Container className="row">
      <div className="col-12 mb-3">
        <Label>
          {`* `}
          {i18n.t(
            'Add one or more photos that you like displayed in the media gallery for facility'
          )}
          <div data-tip data-for="morephotos">
            <InfoIcon />
          </div>
          <CustomTooltip id="morephotos" placement="right">
            <span>{i18n.t('Upload photos that are 4x3')}</span>
          </CustomTooltip>
        </Label>
        <div className="instructions mt-2">
          {i18n.t('Drag the images to rearrange them')}
        </div>
      </div>
      <div className="col-12 media-container">
        <div>
          {errors.photos && (
            <span className="inlineErrorMessage mr-3 mt-2">
              {errors.photos.message}.
            </span>
          )}
        </div>
        <MediaUpload
          proloadedItemsLinks={photos}
          handleChange={items => onChange('photos', items)}
          accept={['image/png', 'image/jpg', 'image/jpeg']}
          maxFileSize={2 * 1024 * 1024} // 2 MB
          showLink
        />
      </div>
      <div className="col-12 mb-3">
        <Label>
          {i18n.t(
            'Add one or more videos that you like displayed in the media gallery for facility'
          )}
          <div data-tip data-for="morevideos">
            <InfoIcon />
          </div>
          <CustomTooltip id="morevideos" placement="right">
            <span>{i18n.t('Upload videos that are')}</span>
          </CustomTooltip>
        </Label>
        <div className="instructions mt-2">
          {i18n.t('Drag the videos to rearrange them')}
        </div>
      </div>
      <div className="col-12 media-container">
        <MediaUpload
          proloadedItemsLinks={videos}
          handleChange={items => onChange('videos', items)}
          type="video"
          accept={['video/mp4']}
          maxFileSize={10 * 1024 * 1024} // 10 MB
          featured={[0]}
        />
      </div>
    </Container>
  );
};

export default FacilityMedia;
