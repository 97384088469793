import styled from 'styled-components';

const ClaimActionButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const DetailsContainer = styled.div`
  margin: 0 32px;
  padding-bottom: 60px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #757575;
`;

// DETAILS
const DetailsFormContainer = styled.div`
  > div {
    margin-top: 33px;
  }
  label {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    > div {
      margin-left: 10px;
    }
  }

  li label {
    margin-bottom: 0;
  }
  .checkbox-wrapper {
    margin-left: -11px;
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);

    > label {
      margin-bottom: 5px;
    }
  }
`;

const ClaimReviewSubmitContainer = styled.div`
  padding: 40px 50px;
`;

const ClaimReviewSubmitItemsContainer = styled.div`
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  div:first-child:nth-last-child(3),
  div:first-child:nth-last-child(3) ~ div:first-child {
    flex: 2;
  }
  ul {
    padding: 0 0 0 20px;
  }
`;

const ClaimReviewSubmitIconContainer = styled.div`
  svg,
  img {
    width: 24px;
    margin-right: 0px;
  }
`;

const ClaimReviewSubmitItemsWrapper = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(117, 117, 117, 0.25);
`;

const VerificationContainer = styled.div`
  margin: 0 32px;
  padding-bottom: 60px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #757575;
`;

const VerificationFormContainer = styled.div`
  .disabled {
    color: rgba(117, 117, 117, 0.5);
    label {
      color: rgba(117, 117, 117, 0.5);
    }
  }

  > div {
    margin-top: 33px;
  }
  label {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &.secondary {
      font-weight: normal;
    }
    > div {
      margin-left: 10px;
    }
  }

  li label {
    margin-bottom: 0;
  }
  .checkbox-wrapper {
    margin-left: -11px;
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;

    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);

    > label {
      margin-bottom: 5px;
      font-weight: normal !important;
    }
  }

  .inline-checkbox {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    > div.checkbox-wrapper {
      margin-right: 20px;
      :last-child {
        margin-right: 0px;
      }
    }
  }
`;

// Mapping
const MappingContainer = styled.div`
  margin: 0 32px;
  padding-bottom: 60px;
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #757575;
`;

const MappingFormContainer = styled.div`
  > div {
    margin-top: 33px;
  }
  label {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    > div {
      margin-left: 10px;
    }
  }

  li label {
    margin-bottom: 0;
  }
  .checkbox-wrapper {
    margin-left: -11px;
    display: flex;
    align-items: center;
    font-family: Encode Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(117, 117, 117, 0.5);

    > label {
      margin-bottom: 5px;
    }
  }

  p.description {
    font-family: Encode Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #757575;
  }
`;

const MappingDisclaimerContainer = styled.div`
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #f40c28;
`;

export {
  ClaimActionButtonsContainer,
  DetailsFormContainer,
  DetailsContainer,
  ClaimReviewSubmitContainer,
  ClaimReviewSubmitItemsContainer,
  ClaimReviewSubmitIconContainer,
  ClaimReviewSubmitItemsWrapper,
  VerificationContainer,
  VerificationFormContainer,
  MappingContainer,
  MappingFormContainer,
  MappingDisclaimerContainer,
};
