import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import i18n from 'i18next';
import { useHistory } from 'react-router';

import { JourneyStepsContainer as Container } from '../styles';
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  StepTabs,
  CustomTooltip,
} from '../../../../Components';
import JourneyStepsFacility from './JourneyStepsFacility';
import JourneyStepsInboundAssets from './JourneyStepsInboundAssets';
import JourneyStepsOutboundAssets from './JourneyStepsOutboundAssets';
import { useLocation } from 'react-router-dom';

interface Props {
  onChange: Function;
  journeySteps?: any;
  facilities?: Array<any>;
  claims?: Array<any>;
}

interface StepData {
  name?: string;
  value?: string;
}

const JourneySteps = ({ onChange, ...data }: Props, ref) => {
  const { journeySteps, facilities, claims } = data;
  const history = useHistory();

  const [tabIndex, setTabIndex] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);

  const facilityRef = useRef() as any;

  const location = useLocation();

  useImperativeHandle(ref, () => ({
    validate() {
      if (facilityRef && facilityRef.current) {
        return facilityRef.current.validate();
      }
    },
  }));

  const handleStepsChange = items => {
    setIsLastStep(items.some(x => x.number === -1 && x.active === true));

    const prevActive = journeySteps && journeySteps.find(step => step.active);
    const newActive = items.find(step => step.active);

    if (prevActive?.number !== newActive?.number) {
      setTabIndex(0);
    }

    const query = new URLSearchParams(location.search);
    const tabFromQuery = query.get('tab');
    const newActiveNumber = newActive?.number;
    const stepSearch = newActiveNumber ? `&step=${newActiveNumber}` : '';
    history.push({
      search: `?tab=${tabFromQuery}${stepSearch}`,
    });

    onChange('journeySteps', items);
  };

  const query = new URLSearchParams(location.search);
  const stepFromQuery = query.get('step');

  const activeStep = journeySteps && journeySteps.find(step => step.active);

  const stepsWithActive = journeySteps?.map(step => {
    if (stepFromQuery) {
      return {
        ...step,
        active: stepFromQuery == step.number,
      };
    } else {
      return step;
    }
  });

  const handleChange = (name: string, value: any) => {
    if (activeStep) {
      const { number } = activeStep;
      const newJourneySteps = journeySteps.map(step => {
        if (step.number === number) {
          const newKeyValue = { [name]: value };
          return {
            ...step,
            data: {
              ...step.data,
              ...newKeyValue,
            },
          };
        } else {
          return step;
        }
      });
      onChange('journeySteps', newJourneySteps);
    }
  };

  const activeStepData = activeStep && activeStep.data;
  const isActiveFacility = activeStepData && activeStepData.isActiveFacility;

  return (
    <Container>
      <StepTabs
        items={journeySteps ? stepsWithActive : []}
        handleStepsChange={handleStepsChange}
      />
      <div className="tabs-wrapper">
        <Tabs
          withBorderRadius={false}
          backgroundColor="#0F0F0F"
          selectedIndex={tabIndex}
          onSelect={tabIndex => setTabIndex(tabIndex)}
        >
          {activeStep && (
            <>
              <TabList>
                <Tab>{i18n.t('Facility')}</Tab>
                {isActiveFacility ? (
                  <Tab>{i18n.t('Inbound Assets')}</Tab>
                ) : (
                  <>
                    <Tab data-tip data-for="inbound" disabled>
                      {i18n.t('Inbound Assets')}
                    </Tab>
                    <CustomTooltip id="inbound" placement="bottom">
                      <span>
                        {i18n.t('This facility status is set to Static')}
                      </span>
                    </CustomTooltip>
                  </>
                )}
                {isActiveFacility ? (
                  <Tab>{i18n.t('Outbound Assets')}</Tab>
                ) : (
                  <>
                    <Tab data-tip data-for="outbound" disabled>
                      {i18n.t('Outbound Assets')}
                    </Tab>
                    <CustomTooltip id="outbound" placement="bottom">
                      <span>
                        {i18n.t('This facility status is set to Static')}
                      </span>
                    </CustomTooltip>
                  </>
                )}
              </TabList>
              <TabPanel>
                <JourneyStepsFacility
                  onChange={handleChange}
                  ref={facilityRef}
                  {...activeStepData}
                  facilities={facilities}
                  claims={claims}
                />
              </TabPanel>

              {isActiveFacility && (
                <>
                  <TabPanel>
                    <JourneyStepsInboundAssets
                      onChange={handleChange}
                      {...activeStepData}
                    />
                  </TabPanel>
                  <TabPanel>
                    <JourneyStepsOutboundAssets
                      isLastStep={isLastStep}
                      onChange={handleChange}
                      {...activeStepData}
                    />
                  </TabPanel>
                </>
              )}
            </>
          )}
        </Tabs>
      </div>
    </Container>
  );
};

export default forwardRef(JourneySteps);
