import i18n from 'i18next';

const name = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 75, message: i18n.t('Max 75 characters') },
};

const description = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 250, message: i18n.t('Max 250 characters') },
};

const startDate = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return {
    validate: {
      dateRequired: value => {
        return value === undefined || value === '' || value === null
          ? i18n.t('Required field')
          : undefined;
      },
      graterThanToday: value => {
        return value < today
          ? i18n.t('Must be today or in the future')
          : undefined;
      },
    },
  };
};

const endDate = getValues => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return {
    validate: {
      dateRequired: value => {
        const { noEndDate } = getValues();
        if (noEndDate) return undefined;
        return value === undefined || value === '' || value === null
          ? i18n.t('Required field')
          : undefined;
      },
      graterThanToday: value => {
        const { startDate, noEndDate } = getValues();
        return !noEndDate && value < startDate
          ? i18n.t('Must be greater than start date')
          : undefined;
      },
    },
  };
};

const customEndDateAfterStartDate = (endData, startDate) => {
  const formatedStartDate = new Date(startDate);
  const formatedEndDate = new Date(endData);
  return formatedStartDate < formatedEndDate
    ? i18n.t('End date must be greater than start date')
    : '';
};

const customBornOnOrAfter = getValues => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return {
    validate: {
      dateRequired: value => {
        const { isBornOnOrAfter } = getValues();
        return isBornOnOrAfter &&
          (value === undefined || value === '' || value === null)
          ? i18n.t('Required field')
          : undefined;
      },
      smallerThanToday: value => {
        const { isBornOnOrAfter } = getValues();
        return isBornOnOrAfter && value > today
          ? i18n.t('Must be in the past')
          : undefined;
      },
    },
  };
};

const customBornNoLaterThan = getValues => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return {
    validate: {
      dateRequired: value => {
        const { isBornNoLaterThan } = getValues();
        return isBornNoLaterThan &&
          (value === undefined || value === '' || value === null)
          ? i18n.t('Required field')
          : undefined;
      },
      smallerThanToday: value => {
        const { isBornNoLaterThan } = getValues();
        return isBornNoLaterThan && value > today
          ? i18n.t('Must be in the past')
          : undefined;
      },
      greaterThanBornOnOrAfter: value => {
        const { isBornNoLaterThan, bornOnOrAfter } = getValues();
        return isBornNoLaterThan && value < bornOnOrAfter
          ? i18n.t('Must be greater than "Born on or after"')
          : undefined;
      },
    },
  };
};

const numberOfScans = getValues => {
  return {
    validate: {
      required: value => {
        const { isNumberOfScans } = getValues();
        return isNumberOfScans
          ? value === undefined || value === '' || value === null
            ? i18n.t('Required field')
            : undefined
          : undefined;
      },
      greaterZero: value => {
        const { isNumberOfScans } = getValues();
        return isNumberOfScans
          ? value < 0
            ? i18n.t('Greater than or equal to zero')
            : undefined
          : undefined;
      },
    },
  };
};

const numberOfReviews = getValues => {
  return {
    validate: {
      required: value => {
        const { isNumberOfReviews } = getValues();
        return isNumberOfReviews
          ? value === undefined || value === '' || value === null
            ? i18n.t('Required field')
            : undefined
          : undefined;
      },
      greaterZero: value => {
        const { isNumberOfReviews } = getValues();
        return isNumberOfReviews
          ? value < 0
            ? i18n.t('Greater than or equal to zero')
            : undefined
          : undefined;
      },
    },
  };
};

const sukuEarned = getValues => {
  return {
    validate: {
      required: value => {
        const { isSukuEarned } = getValues();
        return isSukuEarned
          ? value === undefined || value === '' || value === null
            ? i18n.t('Required field')
            : undefined
          : undefined;
      },
      greaterZero: value => {
        const { isSukuEarned } = getValues();
        return isSukuEarned
          ? value < 0
            ? i18n.t('Greater than or equal to zero')
            : undefined
          : undefined;
      },
    },
  };
};

const advertName = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
};

const advertDescription = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 75, message: i18n.t('Max 75 characters') },
};

const customAdvertStartDate = (startDate, endDate, noEndDate, getValues) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const formatedStartDate = startDate && new Date(startDate);
  const formatedEndtDate = endDate && new Date(endDate);
  return {
    validate: {
      dateRequired: value =>
        value === undefined || value === '' || value === null
          ? i18n.t('Required field')
          : undefined,
      greaterThanCampaignStartDate: value =>
        formatedStartDate && value && value < formatedStartDate
          ? i18n.t('Must be greater than campaign start date')
          : undefined,
      smallerThanCampaignEndDate: value =>
        formatedEndtDate && value && value > formatedEndtDate && !noEndDate
          ? i18n.t('Must be smaller than campaign end date')
          : undefined,
      smallerThanEndDate: value => {
        const { advertHasEndDate, advertEndDate } = getValues();
        return !advertHasEndDate &&
          value &&
          advertEndDate &&
          advertEndDate < value
          ? i18n.t('Must be smaller than end date')
          : undefined;
      },
      greaterThanToday: value => {
        return value > today
          ? undefined
          : i18n.t('Must be today or in the future');
      },
    },
  };
};

const customAdvertEndtDate = (startDate, endDate, noEndDate, getValues) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const formatedStartDate = startDate && new Date(startDate);
  const formatedEndtDate = endDate && new Date(endDate);

  return {
    validate: {
      dateRequired: value => {
        const { advertHasEndDate } = getValues();
        return !advertHasEndDate
          ? value === undefined || value === '' || value === null
            ? i18n.t('Required field')
            : undefined
          : undefined;
      },
      greaterThanCampaignStartDate: value =>
        formatedStartDate && value && value < formatedStartDate
          ? i18n.t('Must be greater than campaign start date')
          : undefined,
      smallerThanCampaignEndDate: value =>
        formatedEndtDate && value && value > formatedEndtDate && !noEndDate
          ? i18n.t('Must be smaller than campaign end date')
          : undefined,
      smallerThanEndDate: value => {
        const { advertHasEndDate, advertStartDate } = getValues();

        return !advertHasEndDate &&
          value &&
          advertStartDate &&
          advertStartDate > value
          ? i18n.t('Must be greater than start date')
          : undefined;
      },
      greaterThanToday: value => {
        const { advertHasEndDate } = getValues();
        return advertHasEndDate
          ? undefined
          : value > today
          ? undefined
          : i18n.t('Must be today or in the future');
      },
    },
  };
};

const notificationMessage = value => {
  return !value ? i18n.t('Required field') : '';
};

const marketableProducts = getValues => {
  return {
    validate: {
      required: value => {
        const { noProductTargeting } = getValues();
        if (noProductTargeting) return undefined;
        return !value || value.filter(({ selected }) => selected).length === 0
          ? i18n.t('Required field')
          : undefined;
      },
    },
  };
};

const advertItems = () => {
  return {
    validate: {
      required: value => {
        return !value || value.length === 0
          ? i18n.t('Required field')
          : undefined;
      },
    },
  };
};

export default {
  name,
  description,
  startDate,
  endDate,
  customEndDateAfterStartDate,
  customBornOnOrAfter,
  customBornNoLaterThan,
  numberOfScans,
  numberOfReviews,
  sukuEarned,
  advertName,
  advertDescription,
  customAdvertStartDate,
  customAdvertEndtDate,
  notificationMessage,
  marketableProducts,
  advertItems,
};
