import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import AssetsList from '../../Asset/List';
import NewAsset from '../../Asset/New';
import {
  JourneyDetailsSubtitle as Subtitle,
  JourneyDetailsContainer as Container,
} from './styles';
import { Label, Loading } from '../../../Components';

interface Props {
  onChange: Function;
  onRefresh: (entityName: string) => void,
  createMode?: boolean;
  assets?: Array<any>;
  refAssets?: Array<string>;
  currentAssetId?: { assetId: string; skuku: string };
  editCallback?: Function;
  setCreateMode?: Function;
}

const AssetStep = ({
  onChange,
  onRefresh,
  createMode,
  assets,
  refAssets = [],
  setCreateMode = () => {},
}: Props) => {
  const [params, setParams] = useState(
    {} as { assetId: string; skuku: string }
  );
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (createMode) {
      setParams({} as any);
      setLoading(true);
      setTimeout(() => setLoading(false), 500);
    }
  }, [createMode]);

  const handleSave = (skuku, submit) => {
    if (skuku && !refAssets.includes(skuku)) {
      onChange('refAssets', [...refAssets, skuku]);
    }
    onRefresh('asset');

    if (submit) {
      setCreateMode(false);
      setEditMode(false);
    }
  }

  return (
    <Container>
      <div className="col-12 mb-5">
        <Label>{i18n.t('Create assets')}</Label>
        <Subtitle className="mb-3">
          {i18n.t('Here you can create')} <b>{i18n.t('new')}</b>{' '}
          {i18n.t('assets that will be used as')}{' '}
          <b>{i18n.t('inputs')}</b>{' '}
          {i18n.t('and')}{' '}
          <b>{i18n.t('outputs')}</b>{' '}
          {i18n.t('to the facilities that you created in the previous step.')}
        </Subtitle>
        <Subtitle>
          {i18n.t(
            'If an asset already exists (e.g., it was created as part of another journey or it was created separately under the'
          )}{' '}
          <b>{i18n.t('Assets')}</b>{' '}
          {i18n.t('tab), then youll be able to add it to this journey in')}{' '}
          <b>{i18n.t('Step')} 5</b>.
        </Subtitle>
      </div>

      {loading ? (
        <Loading top={300} />
      ) : createMode || editMode ? (
        <NewAsset
          showTitle={false}
          onSave={handleSave}
          draft={
            !params.assetId ||
            !!assets?.find(
              a =>
                (a._id === params.assetId || a.skuku === params.skuku) &&
                a.isDraft
            )
          }
          {...params}
        />
      ) : (
        <AssetsList
          showHeader={false}
          parentAssets={assets}
          editCallback={(assetId, skuku) => {
            setParams({ assetId, skuku });
            setEditMode(true);
          }}
          useStore={['facilities']}
        />
      )}
    </Container>
  );
};

export default AssetStep;
