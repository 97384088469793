import React from 'react';

export default (props: { color?: string }) => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9322 24C21.6691 24 23.3063 23.48 24.6839 22.6L29.5554 27.48L32.3904 24.64L27.5189 19.78C28.3831 18.4222 28.8662 16.8563 28.9173 15.2468C28.9684 13.6373 28.5857 12.0438 27.8093 10.6338C27.033 9.22371 25.8918 8.04924 24.5056 7.23384C23.1195 6.41843 21.5396 5.99222 19.9322 6C14.9409 6 10.9479 10 10.9479 15C10.9479 20 14.9409 24 19.9322 24ZM19.9322 10C21.2559 10 22.5255 10.5268 23.4615 11.4645C24.3976 12.4021 24.9235 13.6739 24.9235 15C24.9235 16.3261 24.3976 17.5979 23.4615 18.5355C22.5255 19.4732 21.2559 20 19.9322 20C18.6084 20 17.3388 19.4732 16.4028 18.5355C15.4667 17.5979 14.9409 16.3261 14.9409 15C14.9409 13.6739 15.4667 12.4021 16.4028 11.4645C17.3388 10.5268 18.6084 10 19.9322 10ZM36.9026 0H4.9583C2.76214 0 0.965271 1.8 0.965271 4V28C0.965271 30.2 2.76214 32 4.9583 32H36.9026C39.0987 32 40.8956 30.2 40.8956 28V4C40.8956 1.8 39.0987 0 36.9026 0ZM36.9026 28H4.9583V4H36.9026V28Z"
      fill={props.color ? props.color : "#A7BF2E" }
    />
  </svg>
);
