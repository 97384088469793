import styled from 'styled-components';

const Wrapper = styled.div`
  .loading-spinner {
    position: fixed;
  }
`;

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 150px;

  .form-field {
    position: relative;
    display: inline-block;
    .inlineErrorMessage {
      left: 12px;
      bottom: -12px;
    }
  }

  @media (max-width: 1400px) {
    padding-bottom: 70px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  margin: 5px;
`;

export {
  Container,
  ButtonContainer,
  ButtonsContainer,
  Wrapper,
}
