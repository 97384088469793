import styled from 'styled-components';

const IconContainer = styled.div`
  display: flex;
  path {
    fill: #d8f40c;
  }
`;

const NavigationButton = styled.button`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  background: #000000;
  border-radius: 30px;
  width: 24px;
  height: 24px;
  text-decoration: none;
  color: #fff !important;
  justify-content: center;
  outline: none;
  border: none;
  &:focus {
    outline: none;
  }
`;

const ClaimsContent = styled.div`
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dropDownContainer {
      flex: 1;
      padding-left: 15px;
      z-index: 3;
    }
  }
`;

const MapInfo = styled.div`
  p {
    &.name {
      font-weight: bold;
    }
    margin: 0;
  }
`;

const ChartDropdownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-end;
  }
  .select-input {
    width: 150px;
    > div > div:first-child {
      justify-content: flex-end;
    }
  }
`;

const ChartDatesControlContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  label {
    margin-right: 10px;
  }
`;

export {
  IconContainer,
  NavigationButton,
  ClaimsContent,
  MapInfo,
  ChartDropdownContainer,
  ChartDatesControlContainer,
};
