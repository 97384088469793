import React, { Component } from 'react';
import styled from 'styled-components';
import SideBar from 'react-sidebar';
import i18n from 'i18next';

import DashboardIcon from '../CustomIcons/MenuDashboard';
import JourneyIcon from '../CustomIcons/MenuJourney';
import AssetIcon from '../CustomIcons/MenuAsset';
import FacilityIcon from '../CustomIcons/MenuFacility';
import ClaimIcon from '../CustomIcons/MenuClaim';
import CampaignIcon from '../CustomIcons/MenuCampaign';
import SideBarHeader from './SideBarHeader';
import NavItem from './NavItem';
import { FaUserAlt } from 'react-icons/fa';

type State = {
  sidebarDocked: boolean;
  sidebarOpen: boolean;
  selectedMenu: string;
};

interface Props {
  children?: any;
  selectedMenu?: string;
}

const mql = window.matchMedia(`(min-width: 800px)`);

const sidebarStyles = {
  sidebar: {
    background: 'linear-gradient(164.89deg, #000000 0%, #3A3A3A 100%)',
  },
};

const ContentContainer = styled.div`
  width: 243px;

  overflow-y: hidden;
  .nav-item-wrapper {
    margin-bottom: 10px;
  }
`;

const ToggleButton = styled.div`
  position: absolute;
  top: 16px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 22px;
  display: none;

  > div {
    border: 1px solid #fff;
    margin: 5px 0;
    width: 25px;
  }

  @media (max-width: 799px) {
    display: block;
  }
`;

class MenuSideBar extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: false,
      selectedMenu: '',
    };
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);

    const ctx = this;
    i18n.on('languageChanged', () => ctx.forceUpdate());
  }

  componentDidMount() {
    this.setActiveMenu();
  }

  componentDidUpdate() {
    this.setActiveMenu();
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  setActiveMenu() {
    const url = window.location.pathname.split('/');
    let selectedMenu = this.props.selectedMenu;

    if (!selectedMenu && url && url[1]) {
      selectedMenu = url[1];
    }
    if (selectedMenu && selectedMenu !== this.state.selectedMenu) {
      this.setState({ selectedMenu });
    }
  }

  render() {
    const routes = [
      {
        linkTo: 'home',
        name: i18n.t('Dashboard'),
        icon: DashboardIcon,
      },
      {
        linkTo: 'journeys',
        name: i18n.t('Journeys'),
        icon: JourneyIcon,
      },
      {
        linkTo: 'assets',
        name: i18n.t('Assets'),
        icon: AssetIcon,
      },
      {
        linkTo: 'facilities',
        name: i18n.t('Facilities'),
        icon:  FacilityIcon,
      },
      {
        linkTo: 'users',
        name: i18n.t('Users'),
        icon: FaUserAlt,
      },
      {
        linkTo: 'claims',
        name: i18n.t('Claims & Attestors'),
        icon: ClaimIcon,
      },
      {
        linkTo: 'campaigns',
        name: i18n.t('Campaigns'),
        icon: CampaignIcon,
      },
    ];

    const menuItems = routes.map(({ linkTo, name, icon }, i) => (
      <div className="nav-item-wrapper" key={i}>
        <NavItem
          linkTo={linkTo}
          name={name}
          active={this.state.selectedMenu === linkTo}
          Icon={icon}
        />
      </div>
    ));

    const sidebarContent = (
      <ContentContainer>
        <SideBarHeader />
        {menuItems}
      </ContentContainer>
    );

    return (
      <>
        <SideBar
          sidebar={sidebarContent}
          open={this.state.sidebarOpen}
          docked={this.state.sidebarDocked}
          onSetOpen={this.onSetSidebarOpen}
          shadow
          styles={sidebarStyles}
        >
          <ToggleButton
            className="sidebar-toggle"
            onClick={() => this.onSetSidebarOpen(true)}
          >
            <div />
            <div />
            <div />
          </ToggleButton>
          {this.props.children}
        </SideBar>
      </>
    );
  }
}

export default MenuSideBar;
