import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, 230px);
  }

  .error-messages {
  }
`;

const DeleteButton = styled.a`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background: #000;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  font-weight: bold;
  line-height: 30px;
  width: 24px;
  height: 24px;
  transition: all 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.5;
  }
`;

const LinkButton = styled.a`
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #d8f40c;
  background: #000;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  width: 24px;
  height: 24px;
  transition: all 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.5;
  }
`;

const FeaturedIcon = styled.div<{ selected?: boolean }>`
  ${props => `
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #000;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    color: ${props.selected ? '#d8f40c' : '#fff'};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const PlayButton = styled.div`
  position: absolute;
  top: 60px;
  left: 80px;
  background: #000;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  width: 34px;
  height: 34px;
  transition: all 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.5;
  }
`;

const ButtonContainer = styled.div`
  height: 150px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
`;

const ImagePreview = styled.div`
  overflow: hidden;
  width: 200px;
  height: 150px;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin-right: 30px;
  margin-bottom: 30px;
  border-radius: 12px;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .file-subtype {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    color: #757575;
    padding: 5px;
    span {
      word-break: break-all;
    }
  }
`;

const Button = styled.button`
  font-family: Poppins;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;
  color: #000000;
  text-transform: uppercase;
  background: transparent;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const IconContainer = styled.div`
  border-radius: 30px;
  margin-right: 10px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
`;

const LightboxImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  transform: translate(0px, 0px) scale(1);
  transition: transform 0.5s ease-out;
`;

const LightboxVideo = styled.video`
  max-width: 100%;
  max-height: 100%;
  transform: translate(0px, 0px);
  transition: transform 0.5s ease-out;
`;

const fadeIn = keyframes`
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
`;

const LightboxContainer = styled.div`
  top: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  z-index: 10000;
  animation: ${fadeIn} 0.5s;
`;

const LightboxClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  color: #ffffff;
  cursor: pointer;
  z-index: 10;
  background: none;
  border: none;

  svg {
    width: 30px;
    height: 30px;
  }
`;

const VideoPreview = styled.div`
  width: 200px;
  height: 150px;
  display: block;
  position: relative;
  margin-right: 30px;
  margin-bottom: 30px;
  border-radius: 12px;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);

  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 12px;
    object-fit: cover;
  }
`;

const CustomLinkContainer = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
  margin-left: 40px;

  .custom-url {
    flex: 1;
    margin-right: 5px;
  }

  input {
    opacity: 1;
    position: relative;
    z-index: 1;
  }
`;

export {
  Container,
  Button,
  ButtonContainer,
  IconContainer,
  ImagePreview,
  DeleteButton,
  LinkButton,
  FeaturedIcon,
  LightboxImage,
  LightboxVideo,
  LightboxContainer,
  LightboxClose,
  VideoPreview,
  PlayButton,
  CustomLinkContainer,
};
