import React from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import { withTranslation, WithTranslation } from 'react-i18next';

interface EnumSocialItem {
  title: string;
  link: string;
  icon: any;
}

interface Props extends WithTranslation {
  social?: Array<EnumSocialItem>;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 42px 10% 35px 10%;
  flex-direction: column;
  background-color: #000;
`;

const Logo = styled.img`
  margin-bottom: 40px;
`;

const Text = styled.p`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #fff;
  color: #757575;
`;

const TextLink = styled.a`
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
`;

const Copyright = styled.p`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  text-align: center;
  color: #fff;
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SocialElement = styled.a`
  background: #303030;
  margin: 10px;
  border-radius: 4px 12px 8px;
  color: #fff;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
  &:hover {
    color: #fff;
    text-decoration: none;
    background: #404040;
  }
`;

const Footer = ({ social }: Props) => {
  return (
    <Container>
      <Logo src={require('../../Rsc/Images/logo.svg')} />
      <Text>
        {i18n.t('Citizens Reserve is a member of the')}{' '}
        <TextLink href="https://entethalliance.org/">
          Enterprise Ethereum Alliance
        </TextLink>{' '}
        {i18n.t('and')}{' '}
        <TextLink href="https://digitalchamber.org/">
          Chamber of Digital Commerce.
        </TextLink>
      </Text>
      <Copyright>
        {i18n.t('Copyright © 2020 Citizens Reserve.')}
        <br />
        {i18n.t(
          'All Rights Reserved. Nothing provided herein shall be deemed to constitute a prospectus of any sort or a solicitation for investment, nor does it in any way pertain to an offering or a solicitation of an offer to buy any financial instruments in any jurisdiction. Additionally, any information provided herein does not constitute an offer, solicitation or sale of the SUKU tokens in any jurisdiction in which such offer, solicitation or sale would be unlawful.'
        )}
      </Copyright>

      {social && (
        <SocialContainer>
          {social.map(element => (
            <SocialElement
              target="_blank"
              title={element.title}
              href={element.link}
              key={element.title}
            >
              {element.icon}
            </SocialElement>
          ))}
        </SocialContainer>
      )}
    </Container>
  );
};

export default withTranslation()(Footer);
