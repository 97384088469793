import React from 'react';
import styled from 'styled-components';
import { TrashIcon } from '../CustomIcons';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import Tooltip from '../Tooltip';

interface EnumListItem {
  number: number;
  name: string;
  active: boolean;
  icon: any;
  data?: any;
}

interface Props {
  number: number;
  itemsList: Array<EnumListItem>;
  setItemsList: (value: Array<EnumListItem>) => void;
  deleteItemAction: any;
}

const Container = styled.div<{ first: boolean; last: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  right: -15px;
  flex-direction: column;
  height: 110px;
  z-index: 100;
  ${props => props.first && !props.last && `justify-content:flex-end`}
  ${props => props.last && !props.first && `justify-content:flex-start`}
  ${props => props.last && props.first && `justify-content:center`}
`;

const DeleteTooltip = styled.div`
  left: 40px;
`;

const TrashButton = styled.div`
  position: relative;
  border-radius: 100%;
  background-color: #000;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  & path {
    fill: #fff;
  }
  &:hover {
    ${DeleteTooltip} {
      display: flex;
    }
  }
`;

const ArrowButton = styled.div`
  background-color: #000;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  margin: 5px 0;
  border: none;
  color: #fff;
  & path {
    fill: #fff;
  }
  &.up {
    border-radius: 100% 100% 50% 50%;
  }
  &.down {
    border-radius: 50% 50% 100% 100%;
  }
`;

const ItemControls = ({
  number,
  itemsList,
  setItemsList,
  deleteItemAction,
}: Props) => {
  const first = number === 0;
  const last = number === itemsList.length - 2;

  const handleUpArrowClick = e => {
    e.stopPropagation();
    const newNumber = itemNumber => {
      if (itemNumber === number) return itemNumber - 1;
      if (itemNumber === number - 1) return itemNumber + 1;
      return itemNumber;
    };
    const newItemsList = itemsList.map(item => {
      return {
        ...item,
        number: newNumber(item.number),
      };
    });
    setItemsList(newItemsList);
  };

  const handleDownArrowClick = e => {
    e.stopPropagation();
    const newNumber = itemNumber => {
      if (itemNumber === number) return itemNumber + 1;
      if (itemNumber === number + 1) return itemNumber - 1;
      return itemNumber;
    };
    const newItemsList = itemsList.map(item => {
      return {
        ...item,
        number: newNumber(item.number),
      };
    });
    setItemsList(newItemsList);
  };

  const handleDeleteClick = e => {
    e.stopPropagation();
    const deleteAction = () => {
      const nextActiveNumber = itemNumber => {
        if (itemsList.length <= 2) {
          return itemNumber === -1;
        } else if (itemsList.length === number + 2) {
          return itemNumber === number - 1;
        } else {
          return itemNumber === number + 1;
        }
      };

      const newItemsList = itemsList
        .map(item => {
          return {
            ...item,
            active: nextActiveNumber(item.number),
          };
        })
        .filter(item => item.number !== number)
        .map(item => {
          return {
            ...item,
            number: item.number > number ? item.number - 1 : item.number,
          };
        });
      setItemsList(newItemsList);
    };

    deleteItemAction(() => deleteAction);
  };

  return (
    <Container first={first} last={last}>
      {!first && (
        <ArrowButton className="up" onClick={handleUpArrowClick}>
          <MdKeyboardArrowUp size="3em" />
        </ArrowButton>
      )}
      <Tooltip text="Delete this step.">
        <TrashButton onClick={handleDeleteClick} data-for="trashTooltip">
          <TrashIcon color="#fff" />
        </TrashButton>
      </Tooltip>
      {!last && (
        <ArrowButton className="down" onClick={handleDownArrowClick}>
          <MdKeyboardArrowDown size="3em" />
        </ArrowButton>
      )}
    </Container>
  );
};

export default ItemControls;
