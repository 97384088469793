import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FaGlobeAmericas } from 'react-icons/fa';

interface Props {
  options: object;
  defaultOption?: string;
  action: (selected: string) => void;
}

const Container = styled.div`
  display: inline-block;
`;

const Select = styled.select`
  border: 0;
  padding-left: 40px;
  border-radius: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  width: 150px;
  height: 32px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41 0L6 4.59L10.59 0L12 1.42L6 7.42L0 1.42L1.41 0Z' fill='%230F0F0F'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  &:focus {
    outline: none;
  }
  @media (max-width: 576px) {
    width: 100px;
  }
`;

const IconContainer = styled.div`
  width: 25px;
  height: 32px;
  position: absolute;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #000;
`;

const LangSelector = ({
  options,
  action,
  defaultOption = Object.keys(options)[0],
}: Props) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState(defaultOption);

  const handleWindowResize = () => {
    const width =
      selectRef && selectRef.current ? selectRef.current.offsetWidth : null;
    width && setIsMobile(width < 150);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
  });

  const handleChange = event => {
    const newValue = event.target.value;
    setSelected(newValue);
    action(newValue);
  };

  return (
    <Container>
      <IconContainer>
        <FaGlobeAmericas />
      </IconContainer>
      <Select onChange={handleChange} value={selected} ref={selectRef}>
        {Object.keys(options).map(key => {
          const value = options[key];
          return (
            <option key={key} value={key}>
              {isMobile ? value.substring(0, 3) : value}
            </option>
          );
        })}
      </Select>
    </Container>
  );
};

export default LangSelector;
