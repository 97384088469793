import i18n from 'i18next';

const name = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
};

const brand = {
  required: { value: true, message: i18n.t('Required field') },
};

const type = {
  required: { value: true, message: i18n.t('Required field') },
};

const identifierValue = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 50, message: i18n.t('Max 50 characters') },
};

const description = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 350, message: i18n.t('Max 350 characters') },
};

const typeOptions = {
  required: { value: true, message: i18n.t('Required field') },
};

const website = {
  required: { value: true, message: i18n.t('Required field') },
  maxLength: { value: 2048, message: i18n.t('Max 2048 characters') },
  pattern: {
    value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
    message: i18n.t('Enter a valid website'),
  },
};

const photos = () => {
  return {
    validate: {
      photoLength: value => {
        return value === undefined || value === null || value.length === 0
          ? i18n.t('Required field')
          : undefined;
      },
    },
  };
};

const fields = () => {
  return {
    validate: {
      validFields: (value = [] as any) => {
        return value.find(f => f.valid === false)
          ? i18n.t('Incomplete fields')
          : undefined;
      },
    },
  };
};

export default {
  name,
  brand,
  type,
  identifierValue,
  description,
  typeOptions,
  website,
  photos,
  fields,
};
