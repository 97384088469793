import React, { useState, useEffect } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import {
  FormInput,
  CalendarIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  Dropdown,
} from '../index';

import {
  Container,
  Overlay,
  IconContainer,
  WeekDayContainer,
  NavBarContainer,
} from './styles';

import moment from 'moment';

// interface Props {
//   onChange?: (string) => {};
//   placeholder?: string;
//   required?: boolean;
// }

// interface WeekdayProps {
//   weekday?: any;
//   className?: any;
//   localeUtils?: any;
//   locale?: any;
// }

// interface OverlayProps {
//   children?: any;
// }

// interface NavbarProps {
//   nextMonth?: any;
//   previousMonth?: any;
//   onPreviousClick?: any;
//   onNextClick?: any;
//   className?: any;
//   localeUtils?: any;
// }

const CustomInput = ({
  onChange,
  placeholder = 'Select',
  required = true,
  disabled = false,
  value = undefined,
  ref = null,
  disabledDays = {},
}) => {
  // const newDateValue = value ? new Date(value) : new Date();
  const newDateValue = value ? new Date(value) : '';
  const [selectedMonth, setMonth] = useState(newDateValue);

  const today = new Date();

  const FORMAT = 'MM/DD/YYYY';

  const OverlayComponent = ({ children, ...props }) => (
    <Overlay className="datepicker-overlay" {...props}>
      {children}
    </Overlay>
  );

  const InputComponent = props => (
    <FormInput
      {...props}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      ref={ref}
    />
  );

  const Weekday = props => {
    const { weekday, className, localeUtils, locale } = props;
    const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
    return (
      <WeekDayContainer className={className} title={weekdayName}>
        {weekdayName.slice(0, 3)}
      </WeekDayContainer>
    );
  };

  const Navbar = props => {
    const {
      nextMonth,
      previousMonth,
      onPreviousClick,
      onNextClick,
      className,
      localeUtils,
    } = props;
    return (
      <NavBarContainer className={className}>
        <button onClick={() => onPreviousClick()}>
          <ArrowLeftIcon />
        </button>
        <button onClick={() => onNextClick()}>
          <ArrowRightIcon />
        </button>
      </NavBarContainer>
    );
  };

  const currentYear = selectedMonth
    ? selectedMonth.getFullYear()
    : today.getFullYear();
  const fromMonth = new Date(currentYear, 0);
  const toMonth = new Date(currentYear + 10, 11);

  const formatOnChange = value => {
    const formattedDate = moment(value).format(FORMAT);
    onChange && onChange(formattedDate);
  };

  function YearMonthForm({ date, localeUtils, onChange }) {
    const months = localeUtils.getMonths();

    const years = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i);
    }

    const handleChange = function handleChange(name, value) {
      const defaultYear = selectedMonth
        ? selectedMonth.getFullYear()
        : today.getFullYear();
      const defaultMonth = selectedMonth
        ? selectedMonth.getMonth()
        : today.getFullYear();
      const newDate = new Date(
        name === 'year' ? value : defaultYear,
        name === 'month' ? value : defaultMonth
      );
      setMonth(newDate);
      onChange(newDate);
    };

    return (
      <form id="dateForm" className="DayPicker-Caption">
        <Dropdown
          name="month"
          options={months.map((month, i) => ({ label: month, value: i }))}
          defaultOption={date.getMonth()}
          action={selected => handleChange('month', selected)}
        />

        <Dropdown
          name="year"
          options={years.map(year => ({ label: year, value: year }))}
          defaultOption={date.getFullYear()}
          action={selected => handleChange('year', selected)}
        />
      </form>
    );
  }

  return (
    <Container className={disabled ? 'disabled' : ''}>
      <DayPickerInput
        value={selectedMonth ? formatDate(selectedMonth) : ''}
        onDayChange={day => formatOnChange(day)}
        onDayPickerHide={() => formatOnChange(value ? value : '')}
        component={InputComponent}
        overlayComponent={OverlayComponent}
        format={FORMAT}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          month: selectedMonth,
          fromMonth: fromMonth,
          toMonth: toMonth,
          weekdayElement: <Weekday />,
          navbarElement: <Navbar />,
          captionElement: ({ localeUtils }) => (
            <YearMonthForm
              date={selectedMonth || today}
              onChange={onChange}
              localeUtils={localeUtils}
            />
          ),
          disabledDays,
        }}
      />
      <IconContainer>
        <CalendarIcon />
      </IconContainer>
    </Container>
  );
};

export default CustomInput;
