import React from 'react';

const Distributor = (props: { color?: string }) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.92662 13.9446C7.55757 14.0785 7.15054 14.0611 6.79429 13.8961C6.43803 13.7311 6.16144 13.432 6.02482 13.064C5.95556 12.882 5.92346 12.6879 5.93041 12.4933C5.93737 12.2987 5.98324 12.1075 6.06533 11.9309C6.14741 11.7543 6.26404 11.596 6.40833 11.4652C6.55261 11.3345 6.72164 11.2339 6.90542 11.1696C7.67502 10.8884 8.52602 11.2806 8.80722 12.0428C8.94114 12.4118 8.92372 12.8188 8.75876 13.1751C8.5938 13.5314 8.29468 13.8079 7.92662 13.9446ZM6.39482 9.77837C4.86302 10.3334 4.07122 12.0354 4.61882 13.5746C4.89709 14.31 5.45373 14.9064 6.16832 15.2346C6.88291 15.5628 7.69799 15.5965 8.43722 15.3284C9.96902 14.7734 10.7534 13.0714 10.1984 11.5396C9.63602 10.0004 7.92662 9.22337 6.39482 9.77837ZM10.8644 2.62258L6.00262 4.41337L7.02382 7.18097L11.8856 5.39757L10.8644 2.62258ZM14.1056 9.31217L10.5684 10.6072C10.6942 10.8218 10.7978 11.0438 10.8866 11.2806C10.9754 11.5174 11.0346 11.7616 11.0716 11.9984L14.6088 10.7034L14.1056 9.31217ZM13.5284 5.58257L7.27542 7.87657L7.63802 8.85337C8.56302 8.91997 9.42882 9.32697 10.0874 10.0004L14.5496 8.36497L13.5284 5.58257ZM0.548828 0.713379V2.19338H2.83543L5.47722 9.40837C5.89906 9.14602 6.36988 8.9723 6.86102 8.89777L3.87882 0.713379"
      fill={props.color ? props.color : "#A7BF2E" }
    />
  </svg>
);

export default Distributor;
