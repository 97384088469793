import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';

import {
  AssetDetailsFormContainer as FormContainer,
  AssetDetailsContainer as Container,
  ExtrasListContainer,
  ExtrasListHeader,
} from './styles';

import {
  Label,
  InfoIcon,
  Tooltip,
  Checkbox,
  Button,
  CustomTooltip,
} from '../../../Components';
import DynamicList from '../../../Components/DynamicList';

interface Props {
  onChange: Function;
  extras?: Array<{ title?: string; description?: string }>;
  assetExtrasFirstLoad?: boolean;
  noExtras?: boolean;
}

const AssetExtras = ({ onChange, ...data }: Props) => {
  const { noExtras = true, assetExtrasFirstLoad = true, extras = [] } = data;

  const [isEditing, setIsEditing] = useState(false);

  const { errors, triggerValidation } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    assetExtrasFirstLoad === false && triggerValidation();
    onChange('assetExtrasFirstLoad', false);
  }, []);

  const handleToggleExtrasAction = () => {
    setIsEditing(!isEditing);
  };

  return (
    <Container>
      <FormContainer className="row">
        <div className="col-12">
          <div className="checkbox-wrapper">
            <Label>
              <Checkbox
                onChange={evt => onChange(evt.target.name, evt.target.checked)}
                checked={noExtras}
                name="noExtras"
              />
              {i18n.t('No extras data is required for this asset')}
              <div data-tip data-for="noextras">
                <InfoIcon />
              </div>
              <CustomTooltip id="noextras">
                <div>
                  {i18n.t(
                    'This can be additional product information such as specifications, cooking tips, cooking instructions, etc.'
                  )}
                  <br />
                  <br />
                  {i18n.t('A scenario where extras data may not be desired')}
                </div>
              </CustomTooltip>
            </Label>
          </div>
        </div>
        {!noExtras && (
          <>
            <div className="col-12">
              <Label>
                {i18n.t('Capture additional information for end consumers')}
                <Tooltip
                  text={i18n.t('Capture additional information such as')}
                >
                  <div>
                    <InfoIcon />
                  </div>
                </Tooltip>
              </Label>
            </div>
            <div className="col-12 mt-0">
              <ExtrasListContainer>
                <ExtrasListHeader>
                  <div className="first-value">{i18n.t('Heading')}</div>
                  <div className="second-value">{i18n.t('Details')}</div>
                  <Button
                    text={isEditing ? i18n.t('Save') : i18n.t('Edit')}
                    type="secondary"
                    action={() => handleToggleExtrasAction()}
                  />
                </ExtrasListHeader>
                <DynamicList
                  items={extras}
                  setItems={values => onChange('extras', values)}
                  placeholderLeft={i18n.t('Enter extra heading')}
                  placeholderRight={i18n.t('Enter extra details')}
                  listType={isEditing ? 'primary' : 'readonly'}
                  confirmDelete
                  deleteTitle={i18n.t('Delete this row?')}
                  deleteContent={i18n.t(
                    'Are you sure you want to delete this row?'
                  )}
                />
                {!isEditing && extras.length === 0 && (
                  <div className="empty-msg">
                    {i18n.t('No extras added yet')}
                  </div>
                )}
              </ExtrasListContainer>
            </div>
          </>
        )}
      </FormContainer>
    </Container>
  );
};

export default AssetExtras;
