import { EAnalyticsActionTypes } from './analyticActionsTypes';

const initialState = {
  dashboard: [],
  loading: false,
  error: null,
};

interface State {
  dashboard: Array<any>;
  loading: boolean;
  error?: string | null;
}

interface AnalyticAction extends State {
  type: EAnalyticsActionTypes;
  payload: any;
}

type TAnalyticReducerActions = AnalyticAction;

export default function(
  state: State = initialState,
  action: TAnalyticReducerActions
) {
  switch (action.type) {
    case EAnalyticsActionTypes.GET_DASHBOARD:
      return { ...state, loading: true, dashboard: [] };

    case EAnalyticsActionTypes.GET_DASHBOARD_SUCCESS:
      return { ...state, dashboard: action.payload.dashboard, loading: false };

    case EAnalyticsActionTypes.GET_DASHBOARD_ERROR:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}
