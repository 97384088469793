import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ObjectID } from 'bson';
import { useForm, FormContext } from 'react-hook-form';
import { Facility } from '../../../Lib/Utils/Validations';
import Toast from '../../../Lib/Utils/toast';
import {
  doSaveFacility,
  doGetFacility,
  resetCurrentFacility,
} from '../../../Redux/Facility/facilityActions';
import { doGetUsers } from '../../../Redux/User/userActions';
import {
  FacilityIcon,
  ContentBox,
  Title,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Button,
  Loading,
} from '../../../Components';

import { FacilityActionButtonsContainer } from './styles';

import FacilityDetails from './FacilityDetails';
import FacilityContact from './FacilityContact';
import FacilityUsers from './FacilityUsers';
import FacilityMedia from './FacilityMedia';
import FacilityReviewSubmit from './FacilityReviewSubmit';
import { DataProps } from './types';

interface RootState {
  facility: any;
  user: any;
}

interface InheritProps {
  showTitle?: boolean;
  onSave?: Function;
  facilityId?: string;
  draft?: boolean;
}

const mapState = (state: RootState) => ({
  facility: state.facility.currentFacility,
  submitSuccess: state.facility.submitSuccess,
  saveSuccess: state.facility.saveSuccess,
  loading: state.facility.loading,
  error: state.facility.error,
  users: state.user.users,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  saveFacilityAction: (payload, actionType, notifySuccess) =>
    dispatch(doSaveFacility(payload, actionType, notifySuccess)),
  getFacilityAction: (facilityId, actionType) =>
    dispatch(doGetFacility(facilityId, actionType)),
  resetFormAction: () => dispatch(resetCurrentFacility()),
  getUsersAction: () => dispatch(doGetUsers()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  InheritProps & { history?: any; location?: any; match?: any };

const NewFacility = (props: Props) => {
  const {
    saveFacilityAction,
    getFacilityAction,
    resetFormAction,
    getUsersAction,
    history,
    match,
    location,
    submitSuccess,
    saveSuccess,
    onSave,
    error,
    loading,
    facility,
    showTitle = true,
    facilityId,
    draft,
    users,
  } = props;

  const entityId = match.params.facilityId || facilityId;
  const isDraft = draft != null ? draft : !!match.path.match('/draft');

  const [data, setData] = useState({ registeredName: '' } as DataProps);
  const [tabIndex, setTabIndex] = useState(0);

  const formMethods = useForm({ mode: 'onBlur' });

  const tabs = [
    i18n.t('Overview'),
    i18n.t('Contact'),
    i18n.t('Users'),
    i18n.t('Media'),
    isDraft || !entityId ? i18n.t('Review & Submit') : i18n.t('Review & Save'),
  ];

  useEffect(() => {
    if (entityId) {
      getFacilityAction(entityId, isDraft ? 'get-draft' : 'get-entity');
    }
    getUsersAction();

    // will be called on component unmount
    return () => {
      resetFormAction();
    };
  }, []);

  useEffect(() => {
    if (submitSuccess) {
      Toast.success(i18n.t('Your data has been successfully submitted.'));
      // If onSave exists then this component is controlled as child and should not redirect
      if (onSave) {
        history.push({
          search: '',
        });
        onSave(facility._id || entityId, true);
      } else {
        history.push(`/facilities`);
      }
    } else if (saveSuccess) {
      Toast.success(i18n.t('Your data has been successfully saved.'));
    } else if (error) {
      Toast.error(error);
    }
  }, [error, submitSuccess, saveSuccess]);

  useEffect(() => {
    let {
      _id,
      registeredName,
      description,
      claimsSummary,
      quote = {},
      url,
      ethAddress,
      email,
      address,
      location,
      phone,
      photos,
      videos,
      primaryContact = {},
      users = [],
    } = facility;

    // const facilityAddress = address && address.length ? address[0] : {};
    const facilityAddress = address ? address : {};
    const {
      country,
      name,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      zipCode,
    } = facilityAddress;

    const contactPhone = phone && phone.length ? phone[0] : {};
    const { countryCode, number, type, ext } = contactPhone;
    const {
      salutation: contactSalutation,
      firstName: contactFirstName,
      lastName: contactLastName,
      email: contactEmail = {},
      phone: facilityContactPhone = {},
    } = primaryContact;

    if (photos && photos.length) {
      photos = photos.map(p => ({ src: p }));
    }
    if (videos && videos.length) {
      videos = videos.map(v => ({ src: v }));
    }
    const facilityUsers = users.map(
      ({ id, firstName, lastName, email, confirmEmail, password }) => ({
        title: `${firstName} ${lastName}`,
        data: {
          facilityUserId: id,
          facilityUserEmail: email,
          facilityUserEmailConfirm: confirmEmail,
          facilityUserLastName: lastName,
          facilityUserName: firstName,
          facilityUserPermission: 'rw',
          facilityUserPassword: password,
        },
      })
    );

    setData({
      _id,
      registeredName,
      description,
      noWebsite: !!(!url || url.length === 0 || url[0] === ''),
      quoteSource: quote.by,
      quote: quote.quote,
      website: url && url.length ? url[0] : '',
      ethAddress,
      email: email && email.length ? email[0].address : '',
      facilityCountryCode: countryCode,
      facilityPhone: number,
      facilityPhoneType: type,
      facilityPhoneExt: ext,
      country,
      zipCode,
      state: state || city,
      city,
      addressName: name,
      addressLineOne,
      addressLineTwo,
      longitude:
        location?.coordinates && location?.coordinates.length > 0
          ? location.coordinates[0]
          : undefined,
      latitude:
        location?.coordinates && location?.coordinates.length > 1
          ? location.coordinates[1]
          : undefined,
      photos,
      videos,
      contactSalutation,
      contactFirstName,
      contactLastName,
      contactEmail: contactEmail.address,
      contactCountryCode: facilityContactPhone.countryCode,
      contactPhone: facilityContactPhone.number,
      contactPhoneType: facilityContactPhone.type,
      contactPhoneExt: facilityContactPhone.ext,
      facilityUsers,
      previousUsers: users,
    });
  }, [facility]);

  useEffect(() => {
    if (location) {
      const newIndex = getDefaultActiveTab();
      if (tabIndex !== newIndex) handleTabChange(newIndex);
    }
  }, [location, tabIndex]);

  const handleChange = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const handleChangeMultiple = (obj: any) => {
    setData({ ...data, ...obj });
  };

  const getDefaultActiveTab = () => {
    const query = new URLSearchParams(location.search);
    const tabFromQuery = query.get('tab');
    const findTabIndex = tabs.findIndex(
      tab => tab.replace(/[^a-zA-Z0-9]/g, '') === tabFromQuery
    );
    return findTabIndex !== -1 ? findTabIndex : 0;
  };

  const handleTabChange = index => {
    const tabName = tabs[index].replace(/[^a-zA-Z0-9]/g, '');
    const query = new URLSearchParams(location.search);
    const stepFromQuery = query.get('step');
    const stepSearch = stepFromQuery ? `&step=${stepFromQuery}` : '';
    history.push({
      search: `?tab=${tabName}${stepSearch}`,
    });
    setTabIndex(index);
  };

  const handleBackButton = () => {
    saveFacility(false);
    handleTabChange(tabIndex - 1);
  };

  const handleNextButton = () => {
    saveFacility(false);
    handleTabChange(tabIndex + 1);
  };

  const getMappedData = () => {
    const {
      _id,
      website,
      quote,
      quoteSource,
      country,
      zipCode,
      state,
      city,
      addressLineOne,
      addressLineTwo,
      addressName,
      latitude,
      longitude,
      email,
      facilityCountryCode,
      facilityPhone,
      facilityPhoneExt,
      facilityPhoneType,
      contactSalutation,
      contactFirstName,
      contactLastName,
      contactEmail,
      contactCountryCode,
      contactPhone,
      contactPhoneType,
      contactPhoneExt,
      facilityUsers = [],
    } = data;

    const users = facilityUsers.map(
      ({
        data: {
          facilityUserId,
          facilityUserEmail,
          facilityUserEmailConfirm,
          facilityUserLastName,
          facilityUserName,
          facilityUserPassword,
          facilityUserPermission,
        },
      }) => ({
        id: facilityUserId,
        firstName: facilityUserName,
        lastName: facilityUserLastName,
        email: facilityUserEmail,
        username: facilityUserEmail,
        confirmEmail: facilityUserEmailConfirm,
        password: facilityUserPassword,
      })
    );

    const mappedData = {
      ...data,
      _id: _id || entityId || new ObjectID().toString(),
      quote: { quote: quote, by: quoteSource },
      url: [website],
      address:
        // [
        {
          country,
          zipCode,
          state,
          city,
          addressLineOne,
          addressLineTwo,
          name: addressName,
          additionalAddressInfo: '',
        },
      // ],
      location: {
        coordinates: [
          longitude ? Number(longitude) : null,
          latitude ? Number(latitude) : null,
        ],
      },
      email: [{ address: email, verified: true }],
      phone: [
        {
          countryCode: facilityCountryCode,
          number: facilityPhone,
          type: facilityPhoneType,
          ext: facilityPhoneExt,
          default: true,
        },
      ],
      primaryContact: {
        salutation: contactSalutation,
        firstName: contactFirstName,
        lastName: contactLastName,
        email: { address: contactEmail },
        phone: {
          countryCode: contactCountryCode,
          number: contactPhone,
          ext: contactPhoneExt,
          type: contactPhoneType,
        },
      },
      users,
    };

    return mappedData;
  };

  const validate = async () => {
    const { noWebsite = false, country } = data;

    formMethods.register({ name: 'registeredName' }, Facility.name);
    formMethods.register({ name: 'description' }, Facility.longDescription);
    formMethods.register({ name: 'quote' }, Facility.quote);
    formMethods.register({ name: 'quoteSource' }, Facility.quoteSource);
    formMethods.register({ name: 'website' }, Facility.website(noWebsite));

    formMethods.register({ name: 'contactSalutation' }, Facility.salutation);
    formMethods.register({ name: 'contactFirstName' }, Facility.contactName);
    formMethods.register({ name: 'contactLastName' }, Facility.contactName);
    formMethods.register({ name: 'contactEmail' }, Facility.email);
    formMethods.register({ name: 'contactCountryCode' }, Facility.countryCode);
    formMethods.register({ name: 'contactPhone' }, Facility.phone);
    formMethods.register({ name: 'contactPhoneExt' }, Facility.phoneExt);
    formMethods.register({ name: 'contactPhoneType' }, Facility.phoneType);

    formMethods.register({ name: 'addressName' }, Facility.addressName);
    formMethods.register({ name: 'country' }, Facility.country);
    formMethods.register({ name: 'state' }, Facility.state(country));
    formMethods.register({ name: 'city' }, Facility.city);
    formMethods.register({ name: 'zipCode' }, Facility.zipCode);
    formMethods.register({ name: 'addressLineOne' }, Facility.address);
    formMethods.register({ name: 'longitude' }, Facility.location);
    formMethods.register({ name: 'latitude' }, Facility.location);
    formMethods.register({ name: 'email' }, Facility.email);
    formMethods.register({ name: 'facilityCountryCode' }, Facility.countryCode);
    formMethods.register({ name: 'facilityPhone' }, Facility.phone);
    formMethods.register({ name: 'facilityPhoneExt' }, Facility.phoneExt);
    formMethods.register({ name: 'facilityPhoneType' }, Facility.phoneType);

    formMethods.register({ name: 'photos' }, Facility.photo());
    formMethods.register({ name: 'facilityUsers' }, Facility.users());

    formMethods.setValue(
      Object.keys(data).map(key => ({
        [key]: data[key],
      }))
    );

    const result = await formMethods.triggerValidation();

    if (!result) {
      setData({ ...data, validate: result });
    }

    return result;
  };

  const saveFacility = async (notifySuccess?: boolean) => {
    // Name must be entered at minimum
    if (!data.registeredName) {
      Toast.error(i18n.t('Must enter name to save data.'));
      handleTabChange(0);
      return;
    }

    if (!isDraft) {
      const result = await validate();
      if (!result) {
        Toast.error(i18n.t('There are items that require your attention.'));
        return;
      }
    }

    const actionType = isDraft
      ? data._id
        ? 'save-draft'
        : 'create-draft'
      : 'save-entity';
    const mappedData = getMappedData();
    saveFacilityAction(mappedData, actionType, notifySuccess);
    onSave && onSave(mappedData._id);
  };

  const submitFacility = async () => {
    const result = await validate();
    if (result) {
      const mappedData = getMappedData();
      const actionType =
        isDraft || !entityId ? 'submit-create-entity' : 'submit-save-entity';
      saveFacilityAction(mappedData, actionType, false);
    } else {
      Toast.error(i18n.t('There are items that require your attention.'));
    }
  };

  return (
    <>
      {showTitle && (
        <Title
          title={i18n.t('New Facility')}
          subtitle={data.registeredName}
          icon={<FacilityIcon />}
        />
      )}
      <ContentBox>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={handleTabChange}
          initialSelectedIndex={getDefaultActiveTab()}
        >
          <TabList>
            {tabs.map((tab, index) => (
              <Tab key={index}>{tab}</Tab>
            ))}

            <FacilityActionButtonsContainer>
              {tabIndex > 0 && (
                <Button
                  text={i18n.t('Back')}
                  type="secondary"
                  action={handleBackButton}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  text={i18n.t('Save')}
                  type="secondary"
                  action={() => saveFacility(true)}
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  action={handleNextButton}
                  text={i18n.t('Save & Next')}
                  type="secondary"
                />
              )}
              {tabIndex === tabs.length - 1 && (
                <Button
                  text={
                    isDraft || !entityId ? i18n.t('Submit') : i18n.t('Save')
                  }
                  type="secondary"
                  action={() => {
                    submitFacility();
                  }}
                />
              )}
            </FacilityActionButtonsContainer>
          </TabList>

          <FormContext {...formMethods}>
            <TabPanel>
              <FacilityDetails
                onChange={(name, value) => handleChange(name, value)}
                formErrors={formMethods.errors}
                {...data}
              />
            </TabPanel>
            <TabPanel>
              <FacilityContact
                onChange={(name, value) => handleChange(name, value)}
                formErrors={formMethods.errors}
                {...data}
              />
            </TabPanel>
            <TabPanel>
              <FacilityUsers
                onChange={(name, value) => handleChange(name, value)}
                onChangeMultiple={obj => handleChangeMultiple(obj)}
                {...data}
                users={users}
              />
            </TabPanel>
            <TabPanel>
              <FacilityMedia
                onChange={(name, value) => handleChange(name, value)}
                {...data}
              />
            </TabPanel>
            <TabPanel>
              <FacilityReviewSubmit
                onChange={(name, value) => handleChange(name, value)}
                {...data}
                formMethods={formMethods}
                isDraft={isDraft}
                entityId={entityId}
                embedded={!!onSave}
              />
            </TabPanel>
          </FormContext>
        </Tabs>
      </ContentBox>
      {loading && <Loading top={300} />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(NewFacility) as any) as any
) as any;
