import { authAxiosCall } from '../axiosCall';

import { SaveStep } from './types';

export const saveStep = async ({
  facilityId,
  organizationId,
  showDate,
  description,
  facilityType,
  inputTemplate,
  inputs,
  outputs,
  claims,
}: SaveStep) =>
  authAxiosCall('/steps/templates', {
    method: 'POST',
    body: JSON.stringify({
      facilityId,
      organizationId,
      showDate,
      description,
      facilityType,
      inputTemplate,
      inputs,
      outputs,
      claims,
    }),
  });

export const updateStep = async (stepId, payload) =>
  authAxiosCall(`/steps/${stepId}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const getStepTemplate = async stepTemplateId =>
  authAxiosCall(`/steptemplates/${stepTemplateId}`, {
    method: 'GET',
  });
