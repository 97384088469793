import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 28px;
  > div {
    margin-bottom: 19px;
  }
`;

const LotNumber = styled.div`
  min-width: 300px;
  position: relative;

  .inlineErrorMessage {
    left: 0;
  }
`;

export {
  Container,
  LotNumber
}