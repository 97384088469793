import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Container } from './styles';

interface Props {
  children?: any;
  disabled?: boolean;
  withBorderRadius?: boolean;
  backgroundColor?: string;
  selectedIndex?: number;
  onSelect?: any;
  initialSelectedIndex?: any;
  className?: string;
}

const TabsComponent = ({
  children,
  withBorderRadius = true,
  backgroundColor,
  selectedIndex = 0,
  onSelect = null,
  initialSelectedIndex = null,
  ...rest
}: Props) => {
  const setSelectedIndex = initialSelectedIndex
    ? initialSelectedIndex
    : selectedIndex;
  return (
    <Container
      withBorderRadius={withBorderRadius}
      backgroundColor={backgroundColor}
    >
      <Tabs
        {...rest}
        selectedIndex={setSelectedIndex}
        onSelect={onSelect && onSelect}
      >
        {children}
      </Tabs>
    </Container>
  );
};
TabsComponent.tabsRole = 'Tabs';

const TabListComponent = ({ children, ...rest }: Props) => (
  <TabList {...rest}>{children}</TabList>
);
TabListComponent.tabsRole = 'TabList';

const TabComponent = ({ children, disabled = false, ...rest }: Props) => (
  <Tab disabled={disabled} {...rest}>
    {children}
  </Tab>
);
TabComponent.tabsRole = 'Tab';

const TabPanelComponent = ({ children, ...rest }: Props) => (
  <TabPanel {...rest}>{children}</TabPanel>
);
TabPanelComponent.tabsRole = 'TabPanel';

export {
  TabsComponent as Tabs,
  TabListComponent as TabList,
  TabComponent as Tab,
  TabPanelComponent as TabPanel,
};
