import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const TitleContainer = styled.div<{ editable?: boolean }>`
  display: flex;
  margin: ${({ editable }) => (editable ? '0 0 30px 0' : '0 0 20px 0')};
  align-items: center;
  position: relative;
`;

const Title = styled.h3`
  font-family: 'Encode Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  margin: 0;
  & strong {
    font-weight: bold !important;
  }
`;

const EditContainer = styled.button`
  &:focus {
    outline: none;
  }
  outline: none;
  border: 0;
  width: 20px;
  height: 20px;
  background: #000000;
  border-radius: 20px;
  display: flex;
  margin-left: 5px;
  color: #d8f40c;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
`;

const Content = styled.div<{ collapsible: boolean; open: boolean }>`
  font-family: 'Encode Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #757575;
  overflow: hidden;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  max-height: ${({ collapsible, open }) =>
    !collapsible ? '10000px' : open ? '10000px' : '0px'};
  opacity: ${({ collapsible, open }) =>
    !collapsible ? '1' : open ? '1' : '0'};
`;

const CollapseActionContainer = styled.div`
  position: absolute;
  right: 0;
  &:focus {
    outline: none;
  }
  outline: none;
  border: 0;
  display: flex;
  background-color: #000;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`;

const IncompleteLabel = styled.span`
  font-family: Encode Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #f40c28;
  margin-left: 10px;
`;

export {
  Content,
  Container,
  EditContainer,
  Title,
  TitleContainer,
  IncompleteLabel,
  CollapseActionContainer,
}
