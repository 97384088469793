import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #000;
  border-radius: 4px 12px 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default () => (
  <Container>
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4H2C1.46957 4 0.960859 4.21071 0.585786 4.58579C0.210714 4.96086 0 5.46957 0 6V10C0 10.5304 0.210714 11.0391 0.585786 11.4142C0.960859 11.7893 1.46957 12 2 12H3V16C3 16.2652 3.10536 16.5196 3.29289 16.7071C3.48043 16.8946 3.73478 17 4 17H6C6.26522 17 6.51957 16.8946 6.70711 16.7071C6.89464 16.5196 7 16.2652 7 16V12H10L15 16V0L10 4ZM19.5 8C19.5 9.71 18.54 11.26 17 12V4C18.53 4.75 19.5 6.3 19.5 8Z"
        fill="#D8F40C"
      />
    </svg>
  </Container>
);
