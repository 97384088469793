import styled from 'styled-components';

const Container = styled.div<{ showPopUp: boolean }>`
  display: ${p => (p.showPopUp ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.75);
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Frame = styled.div`
  position: relative;
  width: 445px;
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 40px;
  overflow: hidden;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 16px;
`;

const Header = styled.div`
  font-family: 'Encode Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  padding: 32px 20px 20px 20px;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  color: #fff;
  text-align: center;
  background: radial-gradient(
    50.11% 1673.72% at 50.11% 0%,
    #3a3a3a 0%,
    #000000 100%
  );
  & div:first-child {
    margin-bottom: 18px;
    color: #d8f40c;
  }
`;

const Content = styled.div`
  font-family: 'Encode Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 30px 50px 0 50px;
  color: #757575;
`;

const ButtonsContainer = styled.div`
  margin: 36px 0 0 0;
  padding: 0 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

const ButtonContainer = styled.div``;

export {
  Container,
  Frame,
  CloseButton,
  Header,
  Content,
  ButtonsContainer,
  ButtonContainer,
};
