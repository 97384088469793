import { authAxiosCall } from '../axiosCall';
import {
  SaveCampaign,
  SaveCampaignActionType,
  GetCampaignActionType,
} from './types';
import { AxiosResponse } from 'axios';
import { isOrgAdmin } from '../../../Lib/Utils/auth';

export const getCampaigns = async (payload: any, includeDrafts?: boolean) => {
  // let response = await authAxiosCall('/campaigns', {
  //   method: 'GET',
  //   query: payload && payload,
  // });
  let response;
  let campaigns = []; // response && response.data ? response.data.data : [];

  if (isOrgAdmin() && includeDrafts) {
    response = await authAxiosCall('/drafts/collections/campaigns', {
      method: 'GET',
    });
    const drafts = response && response.data ? response.data.data : [];
    campaigns = campaigns.concat(
      drafts.map(d => ({
        ...d.draft,
        campaignId: d.draft._id,
        _id: d._id,
        isDraft: true,
        createdAt: d.createdAt,
        updatedAt: d.updatedAt,
      }))
    );
  }
  return campaigns;
};

export const getCampaign = async (
  id: string,
  actionType: GetCampaignActionType
) => {
  const path =
    actionType === 'get-draft' ? `/drafts/${id}` : `/campaigns/${id}`;

  const response = await authAxiosCall(path, {
    method: 'GET',
  });
  let data = response?.data?.data || {};

  const isDraft = !!actionType?.match('draft');
  if (isDraft) {
    data = { ...data.draft, _id: data._id }; // The Draft has its own _id different from the facility _id
  }

  return data;
};

export const saveCampaign = async (
  { _id, ...campaign }: SaveCampaign,
  actionType: SaveCampaignActionType
): Promise<AxiosResponse<any> | undefined> => {
  let path = '/campaigns';
  let method = 'POST';
  switch (actionType) {
    case 'create-draft':
      path = `/drafts/collections/campaigns`;
      method = 'POST';
      break;
    case 'save-draft':
      path = `/drafts/${_id}`;
      method = 'PUT';
      break;
    case 'submit-create-entity':
      path = `/campaigns`;
      method = 'POST';
      break;
    case 'save-entity':
    case 'submit-save-entity':
      path = `/campaigns/id/${_id}`;
      method = 'PUT';
      break;
  }

  const response = await authAxiosCall(path, {
    method,
    body: JSON.stringify(campaign),
  });

  if (actionType === 'submit-create-entity') {
    await deleteDraft(_id);
  }

  return response;
};

export const deleteCampaign = async (_id: string, isDraft: boolean) => {
  let response;
  if (isDraft) {
    response = await deleteDraft(_id);
  } else {
    response = await authAxiosCall(`/campaigns/${_id}`, {
      method: 'PUT',
      body: JSON.stringify({
        archived: true,
      }),
    });
  }
  return response;
};

const deleteDraft = async (_id?: string) => {
  if (_id) {
    try {
      await authAxiosCall(`/drafts/${_id}`, {
        method: 'DELETE',
      });
    } catch (err) {
      // Do not throw draft might not exist
      console.log(`ERR removing draft`, err.message);
    }
  }
  return true;
};
