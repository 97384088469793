import React, { useState, useEffect, Dispatch } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { Journey } from '../../../../Lib/Utils/Validations';
import {
  JourneyIcon,
  Title,
  Steps,
  FormInput,
  Loading,
  QrPdf,
} from '../../../../Components';

import {
  Container,
  StepsContainer,
  HeaderInput,
  LotLabel,
  LotInputContainer,
  QRHeadContainer,
} from './styles';

import StepContent from './StepContent';
import { FacilityTypeIcons } from '../../../../Lib/Configs';
import { Controller, useForm } from 'react-hook-form';

import {
  doGetJourneys,
  doCleanJourneys,
} from '../../../../Redux/Journey/journeyActions';
import {
  doUpdateStep,
  doGetStepTemplate,
} from '../../../../Redux/Step/stepActions';
import {
  doGetClaims,
  doAddClaimVerification,
  doCleanClaims,
} from '../../../../Redux/Claim/claimActions';

import {
  doSaveAssets,
  doGetJourneyAssets,
  doDeleteJourneyAssets,
  doGetOutpustPreviousStep,
  resetCurrentAsset,
} from '../../../../Redux/Asset/assetActions';

import { getFacilities } from '../../../../Lib/Utils/auth';

interface RootState {
  journey: any;
  claim: any;
  catalog: any;
  asset: any;
  step: any;
}

const mapState = (state: RootState) => ({
  currentJourney: state.journey.journeys,
  claims: state.claim.claims,
  loadingJourney: state.journey.loading,
  loadingClaim: state.claim.loading,

  addVerification: state.claim.addVerification,

  loadingCatalog: state.catalog.loading,
  assetsInput: state.catalog.assetsInput,
  assetsOutput: state.catalog.assetsOutput,

  inputAssets: state.asset.inputAssets,
  outputAssets: state.asset.outputAssets,
  asset: state.asset,
  previousOutputs: state.asset.previousOutputs,
  loadingPreviousOutputs: state.asset.loadingPreviousOutputs,
  loadingAsset: state.asset.loading,
  submitSuccessAsset: state.asset.submitSuccess,

  loadingStep: state.step.loading,
  errorStep: state.step.error,
  stepTemplate: state.step.stepTemplate,
});

const mapDispatch = (dispatch: Dispatch<any>) => ({
  doGetJourneysAction: journeyId => dispatch(doGetJourneys(journeyId)),
  doCleanJourneysAction: () => dispatch(doCleanJourneys()),
  getClaimsAction: (facilityId, _id = '') =>
    dispatch(doGetClaims(facilityId, false, { _id: _id })),
  getJourneyAssets: (lotId, stepTemplateId, assetIds) =>
    dispatch(doGetJourneyAssets(lotId, stepTemplateId, assetIds)),
  doAddClaimVerificationAction: (verificationId, files) =>
    dispatch(doAddClaimVerification(verificationId, files)),
  doSaveAssetsAction: (assets, inputs = true, stepId) =>
    dispatch(doSaveAssets(assets, inputs, stepId)),
  doUpdateStepAction: (stepId, payload) =>
    dispatch(doUpdateStep(stepId, payload)),
  doDeleteJourneyAssetsAction: (assets, input = true) =>
    dispatch(doDeleteJourneyAssets(assets, input)),
  doGetOutpustPreviousStepAction: assetIds =>
    dispatch(doGetOutpustPreviousStep(assetIds)),
  doGetStepTemplateAction: stepTemplateId =>
    dispatch(doGetStepTemplate(stepTemplateId)),
  doResetCurrentAssetAction: () => dispatch(resetCurrentAsset()),
  doCleanClaimsAction: () => dispatch(doCleanClaims()),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  history?: any;
  location?: any;
  match?: any;
  currentUser?: any;
};

const ViewJourneyProduct = (props: Props) => {
  const orderSort = ({ order: numberA }, { order: numberB }) => {
    if (numberA === -1) return 1;
    if (numberB === -1) return -1;

    if (numberA > numberB) {
      return 1;
    }
    if (numberB > numberA) {
      return -1;
    }
    return 0;
  };

  const {
    history,
    location,
    match,
    doGetJourneysAction,
    doCleanJourneysAction,
    getClaimsAction,
    doCleanClaimsAction,
    currentJourney,
    claims,
    inputAssets,
    outputAssets,
    getJourneyAssets,
    doAddClaimVerificationAction,
    addVerification,
    doSaveAssetsAction,
    asset,
    doUpdateStepAction,
    currentUser,
    doDeleteJourneyAssetsAction,
    doGetOutpustPreviousStepAction,
    previousOutputs,
    stepTemplate,
    doGetStepTemplateAction,
    submitSuccessAsset,
    doResetCurrentAssetAction,
    loadingJourney,
    loadingClaim,
    loadingCatalog,
    loadingStep,
    loadingPreviousOutputs,
    loadingAsset,
  } = props;

  const userFacilities = getFacilities();

  const { journeyId } = match?.params;

  const [activeStepNumber, setActiveStepNumber] = useState(0);
  const [journeyProduct, setJourneyProduct] = useState({} as any);
  const [steps, setSteps] = useState(
    [] as Array<{
      number: number;
      icon?: any;
      title: string;
      subTitle: string;
      disabled?: boolean;
      content?: any;
      checked?: boolean;
    }>
  );
  const [lotId, setLotId] = useState(journeyProduct.lotId || '');
  const [firstLoad, setfirstLoad] = useState(true);
  const [customLoading, setCustomLoading] = useState(false);

  const loading =
    loadingJourney ||
    loadingClaim ||
    loadingCatalog ||
    loadingStep ||
    loadingPreviousOutputs ||
    loadingAsset ||
    customLoading;

  const journeyName = journeyProduct.journeyName || 'Krajcik-Leffler';

  const getJourney = () => {
    if (journeyId) {
      doGetJourneysAction(journeyId);
    }
  };

  useEffect(() => {
    doCleanClaimsAction();
    getJourney();
    return () => {
      doCleanJourneysAction();
    };
  }, []);

  const getDefaultActiveStep = () => {
    const query = new URLSearchParams(location.search);
    const stepFromQuery = query.get('step');
    return stepFromQuery ? parseInt(stepFromQuery) : 0;
  };

  useEffect(() => {
    if (location) {
      const stepFromQuery = getDefaultActiveStep();
      if (stepFromQuery !== activeStepNumber) {
        doCleanJourneysAction();
        setActiveStepNumber(stepFromQuery);
      }
    }
  }, [location]);

  const handleStepChange = number => {
    history.push({
      search: `?step=${number}`,
    });
    setActiveStepNumber(number);
  };

  const populateInboundsFromPreviousOutbounds = currentStep => {
    if (currentJourney && currentJourney.steps) {
      const currentStepIndex = currentJourney.steps.findIndex(
        ({ step }) => step._id === currentStep._id
      );

      if (currentStepIndex > 0) {
        const previoustOutputAssets =
          currentJourney.steps[currentStepIndex - 1].step.outputs;
        doGetOutpustPreviousStepAction(
          previoustOutputAssets.map(({ assetId }) => assetId)
        );
      }
    }
  };

  const updateStep = (force = false) => {
    const populatedData = location.state && location.state.data;
    if (populatedData && populatedData.journey) {
      setJourneyProduct(populatedData.journey);
    }

    if (!currentJourney || force) {
      getJourney();
    }

    if (currentJourney && currentJourney.steps && currentJourney.steps.length) {
      if (JSON.stringify(currentJourney) !== JSON.stringify(journeyProduct)) {
        setJourneyProduct(currentJourney);
      }

      let activeStep = {} as any;

      if (activeStepNumber === 0 && firstLoad) {
        const firstStep = currentJourney.steps[0];
        const findFirstFacilityStep = currentJourney.steps.find(({ step }) =>
          userFacilities.includes(step.facility._id)
        );

        const query = new URLSearchParams(location.search);
        const stepFromQuery = query.get('step');

        if (
          findFirstFacilityStep &&
          activeStepNumber !== findFirstFacilityStep.order &&
          stepFromQuery === null
        ) {
          setfirstLoad(false);
          handleStepChange(findFirstFacilityStep.order);
          activeStep = findFirstFacilityStep && findFirstFacilityStep;
        } else {
          activeStep = firstStep;
        }
      } else {
        activeStep =
          currentJourney.steps.find(step => step.order === activeStepNumber) ||
          null;
      }

      if (activeStep) {
        if (lotId !== currentJourney.lotId) {
          setLotId(currentJourney.lotId);
        }

        if (activeStep && activeStep.step) {
          const { step } = activeStep;
          const allAssetIds = step.inputs
            .map(({ assetId }) => assetId)
            .concat(step.outputs.map(({ assetId }) => assetId));

          getJourneyAssets(lotId, step.templateLocator, allAssetIds);
          populateInboundsFromPreviousOutbounds(step);
          doGetStepTemplateAction(step.templateLocator);
        }
      }
    }
  };

  useEffect(() => {
    updateStep();
  }, [currentJourney, activeStepNumber]);

  useEffect(() => {
    getJourney();
    doCleanClaimsAction();
  }, [activeStepNumber]);

  useEffect(() => {
    if (submitSuccessAsset) {
      doResetCurrentAssetAction();
      setfirstLoad(true);
      updateStep(true);
    }
  }, [submitSuccessAsset]);

  useEffect(() => {
    if (claims === null) {
      if (currentJourney?.steps?.length && stepTemplate) {
        const activeStep =
          currentJourney.steps.find(step => step.order === activeStepNumber)
            ?.step || currentJourney.steps[0].step;
        const claimsIds = stepTemplate.claims.join();

        if (activeStep) {
          getClaimsAction(activeStep.facility._id, claimsIds);
        }
      }
    }
  }, [stepTemplate, claims, currentJourney]);

  const saveData = (step, data) => {};

  useEffect(() => {
    setSteps([]);

    if (!currentJourney) {
      getJourney();
    }

    if (currentJourney && claims) {
      const periodicClaims = claims?.filter(
        claim =>
          claim.supportingVerifications &&
          claim.supportingVerifications[0] &&
          claim.supportingVerifications[0].renewalType === 'periodic'
      );

      const lotClaims = claims?.filter(
        claim =>
          claim.supportingVerifications &&
          claim.supportingVerifications[0] &&
          claim.supportingVerifications[0].renewalType === 'journey'
      );

      const onetimeClaims = claims?.filter(
        claim =>
          claim.supportingVerifications &&
          claim.supportingVerifications[0] &&
          claim.supportingVerifications[0].renewalType === 'one-time'
      );

      const othersClaims = claims?.filter(
        claim => claim.supportingVerifications.length === 0
      );

      const steps = currentJourney.steps
        ?.sort(orderSort)
        .map(({ order, step }, index, allSteps) => {
          const { inputs, outputs } = step;
          const filteredInputAssets =
            inputAssets && inputAssets.length
              ? inputAssets.map(asset => {
                  const { assetTemplate, assets } = asset;
                  const filteredAssets = assets
                    .filter(({ _id }) =>
                      inputs.map(({ assetId }) => assetId).includes(_id)
                    )
                    .map(asset => {
                      return {
                        ...asset,
                        subDocumentId: inputs.find(
                          ({ assetId }) => assetId === asset._id
                        )._id,
                      };
                    });
                  return {
                    assetTemplate,
                    assets: filteredAssets,
                  };
                })
              : [];

          const filteredOutputAssets =
            outputAssets && outputAssets.length
              ? outputAssets.map(asset => {
                  const { assetTemplate, assets } = asset;
                  const filteredAssets = assets
                    .filter(({ _id }) =>
                      outputs.map(({ assetId }) => assetId).includes(_id)
                    )
                    .map(asset => {
                      return {
                        ...asset,
                        subDocumentId: outputs.find(
                          ({ assetId }) => assetId === asset._id
                        )._id,
                      };
                    });
                  return {
                    assetTemplate,
                    assets: filteredAssets,
                  };
                })
              : [];

          const previousChecked =
            allSteps
              .slice(0, index)
              .filter(({ step }) => step.complete || step.static).length ===
            index;

          return {
            number: order,
            checked: step.complete || step.static,
            showDate: step.showDate,
            claims: step.claims,
            createdAt: step.createdAt,
            facilityId: step.facility._id,
            value: step.facilityType,
            title: `Step ${index + 1}`,
            subTitle: step.facility.name,
            icon: FacilityTypeIcons[step.facilityTypeIcon || step.facilityType], // TODO remove in the future, only for backwards compatibility
            facilities: [],
            done: false,
            content: StepContent,
            contentProps: {
              claims,
              step,
              lotId: currentJourney.lotId,
              periodicClaims,
              lotClaims,
              onetimeClaims,
              othersClaims,
              assetsInput: filteredInputAssets,
              assetsOutput: filteredOutputAssets,
              loading: loading,
              doAddClaimVerificationAction,
              addVerification,
              doSaveAssetsAction,
              doUpdateStepAction,
              doDeleteJourneyAssetsAction,
              asset,
              inputsSkuku:
                inputAssets && inputAssets.length
                  ? inputAssets[0].assetTemplate.skuku
                  : '', // step.inputs[0]?.skuku,
              outputsSkuku:
                outputAssets && outputAssets.length
                  ? outputAssets[0].assetTemplate.skuku
                  : '', // step.outputs[0]?.skuku,
              userFacilities: userFacilities,
              nextChecked:
                allSteps[index + 1] && allSteps[index + 1].step.complete,
              previousChecked,
              previousOutputs,
            },
            saveData,
          };
        });

      setSteps(steps);
    }
  }, [
    claims,
    inputAssets,
    outputAssets,
    currentJourney,
    addVerification,
    asset,
    loadingAsset,
    stepTemplate,
    activeStepNumber,
    previousOutputs,
  ]);

  const handleChange = (name: string, value: any) => {
    setJourneyProduct({ ...journeyProduct, [name]: value });
  };

  const { errors, control } = useForm({
    mode: 'onBlur',
  });

  const qrCodesInput =
    (inputAssets &&
      inputAssets.length &&
      inputAssets.map(({ assetTemplate }) => ({
        lotId,
        skuku: assetTemplate.skuku,
      }))) ||
    [];

  const qrCodesOutput =
    (outputAssets &&
      outputAssets.length &&
      outputAssets.map(({ assetTemplate }) => ({
        lotId,
        skuku: assetTemplate.skuku,
      }))) ||
    [];

  const qrCodes = qrCodesInput.concat(qrCodesOutput);

  const Header = () => {
    return (
      <div className="col-12 mb-3 mt-5 p-0">
        <HeaderInput className="justify-content-end">
          <LotLabel className="mr-2">
            Lot <span>#</span>
          </LotLabel>
          <LotInputContainer lotChars={lotId.length}>
            <Controller
              as={
                <FormInput
                  required
                  placeholder="Lot ID"
                  styleType="roundBorder"
                  maxLength={30}
                />
              }
              name="lotId"
              value={lotId}
              control={control}
              onChange={([evt]) => {
                handleChange(evt.target.name, evt.target.value);
                return evt.target.value;
              }}
              rules={Journey.lotId()}
              defaultValue={lotId}
              disabled={true}
            />
            {errors.lotId && (
              <span className="inlineErrorMessage">
                {errors.lotId.message}.
              </span>
            )}
          </LotInputContainer>
          <QRHeadContainer>
            <QrPdf
              qr={qrCodes}
              tooltip={
                qrCodes.length === 0
                  ? i18n.t(
                      'No inbound or outbound assets to generate QR codes for.'
                    )
                  : i18n.t(
                      'Generate QR codes for all the assets at this facility.'
                    )
              }
              disabled={qrCodes.length === 0}
            />
          </QRHeadContainer>
        </HeaderInput>
      </div>
    );
  };

  return (
    <>
      <Container>
        <Title
          title={i18n.t('Product Journeys')}
          subtitleArray={
            journeyName && journeyProduct.lotId
              ? [journeyName, `Lot ID ${journeyProduct.lotId}`]
              : []
          }
          icon={<JourneyIcon />}
        />

        <StepsContainer>
          <Steps
            steps={steps}
            header={<Header />}
            onChange={handleStepChange}
            loading={loading}
            showFacilities={false}
            defaultStep={
              activeStepNumber || (steps && steps.length ? steps[0].number : 1)
            }
          />
        </StepsContainer>
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default withRouter(
  withTranslation()(connector(ViewJourneyProduct) as any) as any
);
