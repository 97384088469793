import React from 'react';
import styled from 'styled-components';
import { MdSearch } from 'react-icons/md';
import { FiFilter } from 'react-icons/fi';

interface Props {
  text?: string;
  placeholder?: string;
  onChangeAction?: any;
  filterIcon?: boolean;
  disabled?: boolean;
  value?: string;
}

const Container = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #a7bf2e;
  box-sizing: border-box;
  & svg {
    color: #a7bf2e;
  }
`;

const Input = styled.input`
  max-width: 100%;
  flex: 1;
  border: 0;
  outline: none;
  font-family: 'Encode Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  ::-webkit-input-placeholder {
    color: rgba(117, 117, 117, 0.5);
  }
  :-ms-input-placeholder {
    color: rgba(117, 117, 117, 0.5);
  }
  ::placeholder {
    color: rgba(117, 117, 117, 0.5);
  }
`;

const SearchInput = ({
  text,
  placeholder,
  onChangeAction,
  filterIcon = false,
  disabled = false,
  value,
}: Props) => {
  const handleChange = e => {
    onChangeAction && onChangeAction(e.target.value);
  };

  return (
    <Container>
      <Input
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
        value={value}
      />
      {filterIcon ? (
        <FiFilter size="25px" color="#979797" />
      ) : (
        <MdSearch size="25px" />
      )}
    </Container>
  );
};

export default SearchInput;
