import React, { useState, useEffect } from 'react';
import i18n from 'i18next';

import {
  Input,
  Button,
  FormSelect,
  Label,
  Checkbox,
  FilterClearIcon,
  FilterIcon,
  DatePicker,
  Title,
  Dropdown,
} from '../index';

import {
  Container,
  HeadContainer,
  FilterContainer,
  FilterTitle,
  TitleContainer,
  ControlContainer,
  IconContainer,
  // Title,
  InputContainer,
  CloseContainer,
} from './styles';

type SelectType = 'select' | 'date' | 'checkbox' | string;

interface Props {
  name?: string;
  title?: string;
  subtitle?: string;
  titleIcon?: any;
  searchPlaceholder?: string;
  actionButtonText?: string;
  activeCheckHandler?: (key: string, value: boolean) => {} | void;
  selectHandler?: Function;
  setFilters?: (filters: Array<any>) => {} | void;
  resetFiltersHandler?: () => {} | void;
  inputHandler?: (value: string) => {} | void;
  actionButtonHandler?: () => {} | null;
  showMarketFilter?: boolean;
  selects?: Array<{
    placeholder?: string;
    key: string;
    options?: any;
    type?: SelectType;
    label?: string;
    multi?: boolean;
  }> | null;
  actionButton?: boolean;
  titleOnly?: boolean;
  useDropdown?: boolean;
  searchInput?: boolean;
  withFilters?: boolean;
}

const HeaderFilter = (props: Props) => {
  const {
    name = 'default',
    title = '',
    subtitle,
    titleIcon,
    actionButton = true,
    searchPlaceholder = 'Search for an asset by name',
    actionButtonText = '+ New Asset',
    setFilters,
    inputHandler = () => {},
    actionButtonHandler,
    selects,
    resetFiltersHandler,
    titleOnly = false,
    useDropdown = false,
    searchInput = true,
    withFilters = true,
  } = props;

  const [showFilter, setShowFilter] = useState(false);
  const [selectedOption, setSelectedOption] = useState({} as any);
  const [remember, setRemember] = useState(false);

  useEffect(() => {
    const savedOptions = localStorage.getItem(name);
    if (savedOptions) {
      const parsed = JSON.parse(savedOptions);
      setFilters && setFilters(parsed);
      setSelectedOption(parsed);
      setRemember(true);
    }
  }, []);

  useEffect(() => {
    if (remember) {
      localStorage.setItem(name, JSON.stringify(selectedOption));
    } else {
      localStorage.removeItem(name);
    }
  }, [remember, selectedOption]);

  const selectOptionHandler = (key, value) => {
    const updatedOptions = { ...selectedOption, [key]: value };
    setSelectedOption(updatedOptions);
    setFilters && setFilters(updatedOptions);
  };

  const clearFilters = () => {
    resetFiltersHandler && resetFiltersHandler();
    setSelectedOption({});
    setShowFilter(false);
  };

  return (
    <Container>
      <HeadContainer>
        <TitleContainer>
          <IconContainer>{titleIcon}</IconContainer>
          <Title title={title} subtitle={subtitle} />
        </TitleContainer>
        {!titleOnly && (
          <ControlContainer className="justify-content-end">
            {searchInput && (
              <Input
                placeholder={searchPlaceholder}
                type="search"
                width="100%"
                height="34px"
                borderCololor="#75757550"
                backgroundColor="#ffffff"
                onChange={inputHandler}
              />
            )}
            {withFilters && (
              <div className="justify-content-end d-flex">
                <Button
                  text={
                    <FilterIcon
                      color="#D8F40C"
                      backgroundColor="#000"
                      hoverColor="#fff"
                    />
                  }
                  type="text"
                  action={() => setShowFilter(!showFilter)}
                />
              </div>
            )}
            {actionButton && (
              <Button
                text={actionButtonText}
                height={34}
                type="dark"
                action={actionButtonHandler}
              />
            )}
          </ControlContainer>
        )}
      </HeadContainer>

      <FilterContainer show={showFilter} selects={selects?.length}>
        <InputContainer className="remember">
          <Label>
            <Checkbox
              onChange={() => setRemember(!remember)}
              checked={remember}
              name="remember"
            />
            {i18n.t('Remember selections')}
          </Label>
        </InputContainer>
        <FilterTitle className="mr-3">{i18n.t('Filter by')}:</FilterTitle>
        {selects?.map(
          ({
            placeholder,
            key,
            options,
            type = 'select',
            label = '',
            multi = false,
          }) => (
            <InputContainer className={type} key={key}>
              {type === 'date' && (
                <DatePicker
                  required={false}
                  placeholder={placeholder}
                  value={
                    selectedOption[key] ? new Date(selectedOption[key]) : ''
                  }
                  onChange={val => selectOptionHandler(key, val)}
                />
              )}
              {type === 'select' &&
                (useDropdown ? (
                  <Dropdown
                    options={options}
                    placeholder={placeholder}
                    searchable={true}
                    action={val => selectOptionHandler(key, val)}
                    defaultOption={selectedOption[key] || ''}
                    multi={multi}
                  />
                ) : (
                  <FormSelect
                    placeholder={placeholder}
                    options={options}
                    defaultOption={selectedOption[key] || ''}
                    action={val => selectOptionHandler(key, val)}
                  />
                ))}
              {type === 'checkbox' && (
                <Label>
                  <Checkbox
                    onChange={evt =>
                      selectOptionHandler(key, evt.target.checked)
                    }
                    checked={selectedOption[key] ? true : false}
                    name={key}
                  />
                  {label}
                </Label>
              )}
            </InputContainer>
          )
        )}
        <CloseContainer>
          <Button
            text={
              <FilterClearIcon
                color="#D8F40C"
                backgroundColor="#000"
                hoverColor="#fff"
              />
            }
            type="text"
            action={() => clearFilters()}
          />
        </CloseContainer>
      </FilterContainer>
    </Container>
  );
};

export default HeaderFilter;
