import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;

  .list-wrapper {
    flex: 1;
    &.disabled {
      opacity: 0.5;
      cursor: unset;
    }
  }
`;

const ListContainer = styled.div`
  position: relative;
  background: #f9f9fb;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
  margin: 0;
`;

const List = styled.ul`
  margin: 0;
  padding: 12px;
  height: 140px;
  min-height: 140px;
  overflow-y: scroll;
  box-sizing: border-box;
  resize: vertical;
`;

const ItemContainer = styled.li`
  list-style: none;
  padding: 0;
  color: #757575;
  font-family: 'Encode Sans', sans-serif;
  font-size: 14px;
  & label {
    position: relative;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    & p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      margin-bottom: 0;
      margin-top: 0;
    }
    &:hover span {
      background-color: #efefef;
    }
    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    & input:checked ~ span:after {
      display: block;
    }
  }
`;

const Checkbox = styled.span`
  height: 20px;
  padding-left: 18px;
  margin-right: 18px;
  background-color: #fff;
  border: 1px solid rgba(117, 117, 117, 0.25);
  box-sizing: border-box;
  display: block;
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 7px;
    top: 9px;
    width: 5px;
    height: 10px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const ControlButton = styled.button`
  cursor: pointer;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: #a7bf2e;
  margin: 5px 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  padding: 0;
  &.disabled {
    cursor: unset;
    opacity: 0.2;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 14px 55px 14px;
`;

const SelectAll = styled.div`
  border-top: 1px solid rgba(117, 117, 117, 0.25);
  padding: 12px;
  & label {
    color: #a7bf2e;
  }
`;

export {
  Container,
  ControlsContainer,
  SelectAll,
  ControlButton,
  Checkbox,
  ItemContainer,
  List,
  ListContainer,
};
