import React from 'react';
import i18n from 'i18next';
import { CustomTooltip, Button, WorkInProgressIcon } from '../index';

interface Props {
  action: (any) => void;
}

const InProgressButton = ({ action }: Props) => {
  return (
    <Button
      width={34}
      text={
        <div>
          <div data-tip data-for="inprogress">
            <WorkInProgressIcon />
          </div>
          <CustomTooltip id="inprogress" placement="top">
            <span>{i18n.t('In progress')}</span>
          </CustomTooltip>
        </div>
      }
      type="text"
      action={action}
    />
  );
};

export default InProgressButton;
