import React, { useEffect } from 'react';
import i18n from 'i18next';
import { Controller } from 'react-hook-form';

import { Claim } from '../../../Lib/Utils/Validations';
import {
  DetailsFormContainer as FormContainer,
  DetailsContainer as Container,
} from './styles';

import {
  Label,
  FormInput,
  InfoIcon,
  Dropdown,
  CustomTooltip,
} from '../../../Components';

import { Salutations, PhoneTypes } from '../../../Lib/Configs';

interface Props {
  attestorShortName?: string;
  attestorCompany?: string;
  attestorSalutation?: string;
  attestorFirstName?: string;
  attestorLastName?: string;
  attestorWebsite?: string;
  attestorEmail?: string;
  attestorEmailConfirmation?: string;
  attestorContactFirstLoad?: boolean;
  attestorCountryCode?: string;
  attestorPhoneType?: string;
  attestorPhone?: string;
  attestorPhoneExt?: string;
  attestorNotes?: string;
  onChange: Function;
  formMethods: any;
}

const AttestorContact = ({ onChange, formMethods, ...data }: Props) => {
  const {
    attestorShortName,
    attestorCompany,
    attestorSalutation,
    attestorFirstName,
    attestorLastName,
    attestorWebsite,
    attestorEmail,
    attestorEmailConfirmation,
    attestorCountryCode = '',
    attestorPhoneType,
    attestorPhone = '',
    attestorPhoneExt,
    attestorContactFirstLoad = true,
    attestorNotes = '',
  } = data;

  const {
    errors,
    control,
    triggerValidation,
    setValue,
    getValues,
  } = formMethods;

  useEffect(() => {
    attestorContactFirstLoad === false && triggerValidation();
    onChange('attestorContactFirstLoad', false);
  }, []);

  useEffect(() => {
    if (data) {
      setValue([
        { attestorShortName },
        { attestorCompany },
        { attestorSalutation },
        { attestorFirstName },
        { attestorLastName },
        { attestorWebsite },
        { attestorEmail },
        { attestorEmailConfirmation },
        { attestorCountryCode },
        { attestorPhoneType },
        { attestorPhone },
        { attestorPhoneExt },
        { attestorNotes },
      ]);
    }
  }, [
    attestorShortName,
    attestorCompany,
    attestorSalutation,
    attestorFirstName,
    attestorLastName,
    attestorWebsite,
    attestorEmail,
    attestorEmailConfirmation,
    attestorCountryCode,
    attestorPhone,
    attestorPhoneType,
    attestorPhoneExt,
    attestorNotes,
  ]);

  console.log('=====================');
  console.log('attestorSalutation', attestorSalutation);
  console.log('=====================');

  return (
    <Container>
      <FormContainer className="row">
        <div className="col-12 col-lg-8">
          <Label>
            {i18n.t('Short name')}
            <div data-tip data-for="shortname">
              <InfoIcon />
            </div>
            <CustomTooltip id="shortname" placement="right">
              <span>{i18n.t('Friendly name to identify the attestor')}</span>
            </CustomTooltip>
          </Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the attestor short name')}
                maxLength={50}
              />
            }
            name="attestorShortName"
            value={attestorShortName}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Claim.shortName}
            defaultValue={attestorShortName}
          />
          {errors.attestorShortName && (
            <span className="inlineErrorMessage">
              {errors.attestorShortName.message}.
            </span>
          )}
        </div>

        <div className="col-12 col-lg-4">
          <Label>
            {i18n.t('Company')}
            <div data-tip data-for="company">
              <InfoIcon />
            </div>
            <CustomTooltip id="company" placement="right">
              <span>{i18n.t('Organization that the attestor belongs to')}</span>
            </CustomTooltip>
          </Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the company name')}
                maxLength={50}
              />
            }
            name="attestorCompany"
            value={attestorCompany}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Claim.shortName}
            defaultValue={attestorCompany}
          />
          {errors.attestorCompany && (
            <span className="inlineErrorMessage">
              {errors.attestorCompany.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-lg-2">
          <Label>{i18n.t('Salutation')}</Label>
          <Controller
            as={
              <Dropdown
                required
                options={Salutations}
                placeholder={i18n.t('Select')}
                action={newValue => onChange('attestorSalutation', newValue)}
              />
            }
            name="attestorSalutation"
            value={attestorSalutation}
            control={control}
            onChange={([newValue]) => {
              onChange('attestorSalutation', newValue);
              return newValue;
            }}
            rules={Claim.salutation}
            defaultOption={attestorSalutation}
          />
          {errors.attestorSalutation && (
            <span className="inlineErrorMessage">
              {errors.attestorSalutation.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-lg-3">
          <Label>{i18n.t('First name')}</Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Enter the first name')}
                maxLength={50}
              />
            }
            name="attestorFirstName"
            value={attestorFirstName}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Claim.shortName}
            defaultValue={attestorFirstName}
          />
          {errors.attestorFirstName && (
            <span className="inlineErrorMessage">
              {errors.attestorFirstName.message}.
            </span>
          )}
        </div>

        <div className="col-4 col-lg-3">
          <Label>{i18n.t('Last name')}</Label>
          <Controller
            as={
              <FormInput required placeholder={i18n.t('Enter the last name')} maxLength={50} />
            }
            name="attestorLastName"
            value={attestorLastName}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Claim.shortName}
            defaultValue={attestorLastName}
          />
          {errors.attestorLastName && (
            <span className="inlineErrorMessage">
              {errors.attestorLastName.message}.
            </span>
          )}
        </div>

        <div className="col-12 col-lg-4">
          <Label>
            {i18n.t('Website')}
            <div data-tip data-for="website">
              <InfoIcon />
            </div>
            <CustomTooltip id="website" placement="right">
              <span>{i18n.t('Website for the attestor company')}</span>
            </CustomTooltip>
          </Label>
          <Controller
            as={<FormInput placeholder={i18n.t('Enter website')} />}
            name="attestorWebsite"
            value={attestorWebsite}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Claim.website}
            defaultValue={attestorWebsite}
          />
          {errors.attestorWebsite && (
            <span className="inlineErrorMessage">
              {errors.attestorWebsite.message}.
            </span>
          )}
        </div>

        <div className="col-6 col-lg-4">
          <Label>{i18n.t('Email')}</Label>
          <Controller
            as={
              <FormInput required placeholder={i18n.t('Enter email address')} />
            }
            name="attestorEmail"
            value={attestorEmail}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={Claim.email}
            defaultValue={attestorEmail}
          />
          {errors.attestorEmail && (
            <span className="inlineErrorMessage">
              {errors.attestorEmail.message}.
            </span>
          )}
        </div>

        <div className="col-6 col-lg-4">
          <Label>{i18n.t('Confirm email')}</Label>
          <Controller
            as={
              <FormInput
                required
                placeholder={i18n.t('Confirm email address')}
              />
            }
            name="attestorEmailConfirmation"
            value={attestorEmailConfirmation}
            control={control}
            onChange={([evt]) => {
              onChange(evt.target.name, evt.target.value);
              return evt.target.value;
            }}
            rules={{
              required: {
                value: true,
                message: i18n.t('This field is required'),
              },
              validate: {
                matchesEmail: value => {
                  const { attestorEmail } = getValues();
                  return attestorEmail !== value
                    ? i18n.t('Emails do not match').toString()
                    : undefined;
                },
              },
            }}
            defaultValue={attestorEmailConfirmation}
          />
          {errors.attestorEmailConfirmation && (
            <span className="inlineErrorMessage">
              {errors.attestorEmailConfirmation.message}.
            </span>
          )}
        </div>
        <div className="col-0 col-lg-4"></div>

        <div className="col-12 col-xl-8 row pr-0">
          <div className="col-4 col-lg-2">
            <Label style={{ height: 20 }}>{i18n.t('Country code')}</Label>
            <Controller
              as={<FormInput required placeholder={i18n.t('Enter code')} maxLength={10} />}
              name="attestorCountryCode"
              value={attestorCountryCode}
              control={control}
              onChange={([evt]) => {
                const regex = Claim.countryCode.pattern.value;
                const value = evt.target.value;

                if (!value || regex.test(value)) {
                  onChange(evt.target.name, evt.target.value);
                  return evt.target.value;
                } else {
                  onChange(evt.target.name, attestorCountryCode);
                  return attestorCountryCode;
                }
              }}
              rules={Claim.countryCode}
              defaultValue={attestorCountryCode}
            />
            {errors.attestorCountryCode && (
              <span className="inlineErrorMessage">
                {errors.attestorCountryCode.message}.
              </span>
            )}
          </div>

          <div className="col-8 col-lg-5">
            <Label>{i18n.t('Phone number')}</Label>
            <Controller
              as={
                <FormInput
                  required
                  placeholder={i18n.t('Enter phone number')}
                />
              }
              name="attestorPhone"
              value={attestorPhone}
              control={control}
              onChange={([evt]) => {
                const regex = Claim.phone.pattern.value;
                const value = evt.target.value;

                if (!value || regex.test(value)) {
                  onChange(evt.target.name, evt.target.value);
                  return evt.target.value;
                } else {
                  onChange(evt.target.name, attestorPhone);
                  return attestorPhone;
                }
              }}
              rules={Claim.phone}
              defaultValue={attestorPhone}
            />
            {errors.attestorPhone && (
              <span className="inlineErrorMessage">
                {errors.attestorPhone.message}.
              </span>
            )}
          </div>

          <div className="col-4 col-lg-3">
            <Label>{i18n.t('Ext')}</Label>
            <Controller
              as={
                <FormInput subType="number" placeholder={i18n.t('Enter ext')} />
              }
              name="attestorPhoneExt"
              value={attestorPhoneExt}
              control={control}
              onChange={([evt]) => {
                onChange(evt.target.name, evt.target.value);
                return evt.target.value;
              }}
              rules={Claim.phoneExt}
              defaultValue={attestorPhoneExt}
            />
            {errors.attestorPhoneExt && (
              <span className="inlineErrorMessage">
                {errors.attestorPhoneExt.message}.
              </span>
            )}
          </div>

          <div className="col-4 col-lg-2 pr-0">
            <Label>{i18n.t('Phone type')}</Label>
            <Controller
              as={
                <Dropdown
                  required
                  options={PhoneTypes}
                  placeholder={i18n.t('Select')}
                  action={newValue => onChange('claimType', newValue)}
                />
              }
              name="attestorPhoneType"
              value={attestorPhoneType}
              control={control}
              onChange={([newValue]) => {
                onChange('attestorPhoneType', newValue);
                return newValue;
              }}
              rules={Claim.phoneType}
              defaultOption={attestorPhoneType}
            />
            {errors.attestorPhoneType && (
              <span className="inlineErrorMessage">
                {errors.attestorPhoneType.message}.
              </span>
            )}
          </div>
        </div>

        <div className="col-12">
          <Label>
            {i18n.t('Notes')}
            <div data-tip data-for="notes">
              <InfoIcon />
            </div>
            <CustomTooltip id="notes" placement="right">
              <span>{i18n.t('Brief description of the validator')}</span>
            </CustomTooltip>
          </Label>
          <FormInput
            type="textarea"
            value={attestorNotes}
            name="attestorNotes"
            placeholder={i18n.t('Enter the notes about the attestor')}
            onChange={evt => onChange(evt.target.name, evt.target.value)}
          />
        </div>
      </FormContainer>
    </Container>
  );
};

export default AttestorContact;
